.hero {
    position: relative;

    #banner {
        /* Add zoom-in and zoom-out effect SCSS */
        @keyframes zoomIn {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
        }
      
        // @keyframes zoomOut {
        //   50% {
        //     transform: scale(1.1);
        //   }
        //   100% {
        //     transform: scale(1);
        //   }
        // }
      
        .carousel-item {
          &.active {
            img {
              &.bannerImg {
                animation: zoomInOut 10s infinite; /* Add infinite loop */
              }
            }
          }
        }
      }
      
      @keyframes zoomInOut {
        0%, 100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
      

    &.page-title {
        background-color: #000000;
        min-height: 236px;

        .hero-content {
            .title {
                padding-top: 8rem;

                h1 {
                    font-size: 34px;
                }
            }
        }
    }

    .hero-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .container {
            height: 100%;
        }

        .hero-logo {
            position: absolute;
            top: 3rem;
            right: 0;
        }



        .text-right-bottom {
            position: absolute;
            right: 9%;
            bottom: 18%;
            color: #FFF;
            font-size: 2rem;
        }



        .title {

            color: #FFF;
            padding-top: 15rem;

            h1 {
                font-weight: bold;
                font-size: 52px;

                span {

                    &.big {
                        font-size: 42px;
                    }
                }

                .vission {
                    font-size: 34px;
                }

            }

            p {
                max-width: 361px;
                font-size: 16px;
                color: #FFF;
                font-weight: 300;
                text-align: justify;
                margin-top: 200px
            }
        }
    }
}

.text-gradient {
    @include GradientText();
}

@media screen and (max-width: 767px) {


    .hero {
        padding-top: 41px;

        &.page-title {
            min-height: 130px;

            .hero-content {
                .title {
                    padding-top: 5.5rem;

                    h1 {
                        font-size: 24px;
                    }
                }
            }
        }

        >img {

            object-fit: cover;
        }

        .hero-content {

            //position: relative;
            .hero-logo {
                display: none;
            }

            .vr-girl {
                display: none;
            }

            .text-right-bottom {
                display: none;
            }

            .title {
                padding-top: 7rem;


                h4 {
                    font-size: .9rem;
                }

                h1 {
                    font-size: 37px;

                    span {
                        &.big {
                            font-size: 2rem;
                        }
                    }

                    span {
                        font-size: 17px;
                    }

                    .vission {
                        display: flex;
                        text-transform: lowercase;

                        span {
                            font-size: 23px;

                            &:last-child {
                                padding-left: 5px;
                            }
                        }
                    }
                }

                p {
                    font-size: 12px !important;
                    margin-top: 175px;
                    width: 248px;
                    text-align: justify;
                    display: none;
                }
            }

            .arrow {
                display: none;
            }
        }
    }
}