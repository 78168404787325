
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 40px;
  display: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000000;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham3 {

  .top {
    stroke-dasharray: 40 130;
  }

  .middle {
    stroke-dasharray: 40 140;
  }

  .bottom {
    stroke-dasharray: 40 205;
  }

  &.active {
    .top {
      stroke-dasharray: 75 130;
      stroke-dashoffset: -63px;
    }

    .middle {
      stroke-dashoffset: -102px;
    }

    .bottom {
      stroke-dasharray: 110 205;
      stroke-dashoffset: -86px;
    }
  }
}


.ham4 {

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &.active {
    .top {
      stroke-dashoffset: -68px;
    }

    .bottom {
      stroke-dashoffset: -68px;
    }
  }
}