.available-balance {
    text-align: center;
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    padding: 12px 20px;
    margin: 10px 0;
    border-radius: 10px;

    span {
        font-weight: bold;
        color: $primary;
    }
}

.withdraw_methods {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
        background-color: #FFF;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-radius: 6px;
        margin: 20px;
        border: 3px solid transparent;
        cursor: pointer;

        &.active {
            border-color: $cyan;
        }

        img {
            width: 60px;
            margin-bottom: 10px;
        }
    }
}