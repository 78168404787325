.d-title {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 14px;
}



.gradient-num {
    @include gradientReverse();
    padding: 1px;
    border-radius: 6px;

    span {
        border-radius: 4px;
        background-color: $lightGray;
        color: #000;
        min-height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.section-detail {
    font-size: .9rem;
}

.section-title {
    font-family: $font-secondary;
    color: #FFF;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;


    &.title-sm {
        font-size: 3rem;
    }
}

.spinner-border {
    color: $primary;
}

.icon_status {
    text-align: center;

    .tick {
        margin-left: 0 !important;
    }
}

.text-gray {
    color: $gray !important;
}

.text-green {
    color: $green;
}

.text-primary {
    color: $primary !important;
}

.text-secondary {
    color: $secondary !important;
}

.sub-heading {
    max-width: 700px;
    margin: auto;
    font-size: 1.3rem;
}

.whatsapp-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.buy-coin {
    position: relative;

    h1 {
        color: $primary;
        font-size: 17px;
        margin: 0;
    }

    span {
        color: $secondary;
        font-size: 17px;
        margin: 0;
        margin-left: 10px;
    }

    .coin-wrap {
        margin-bottom: 20px;

    }

    .wrap {
        position: relative;

        .arrow-icon {
            background-color: #131d26;
            @include flexcenter;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            position: absolute;
            top: 43%;
            right: 38px;

            .icon {
                @include flexcenter;
                border-radius: 50%;
                background-color: $primary;
                padding: 9px;
            }

            svg {
                width: 25px;
                color: #000000;
            }
        }
    }

}

.custom-dropdown {
    width: 100%;
    margin: auto;
    position: relative;

    .custom-input {
        background-color: #000;
        border-radius: 80px;
        position: relative;
        padding: 10px 30px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
        -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
        -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
        overflow: hidden;
        transition: all 0.4s ease;
        cursor: pointer;
        width: 90%;
        margin: auto;
        height: 50px;

        &::after {
            position: absolute;
            content: attr(data-label);
            font-size: 16px;
            text-align: left;
            font-weight: bold;
            color: $primary;
        }
    }

    .icon-input-box {
        position: relative;

        .icon {
            position: absolute;
            top: 50%;
            right: 5%;
            transform: translate(-50%, -50%);
        }
    }

    .list-backgroud {
        border-radius: 20px;
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 3;
        background-color: #000;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        height: calc(100vh - 271px);
        overflow-y: scroll;
        // padding: 14px;
        margin-top: 10px;
        padding: 20px;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                background-color: #000;
                border-radius: 80px;
                position: relative;
                padding: 10px 30px;
                border: 2px solid rgba(255, 255, 255, 0.5);
                box-shadow: -1px -1px 7px 2px rgba(255, 190, 24, 0.51);
                -webkit-box-shadow: -1px -1px 7px 2px rgba(255, 190, 24, 0.51);
                -moz-box-shadow: -1px -1px 7px 2px rgba(255, 190, 24, 0.51);
                overflow: hidden;
                cursor: pointer;
                height: 50px;
                width: 100%;
                margin: auto;
                margin-bottom: 13px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon {
                    svg {
                        width: 20px;
                        color: $secondary;
                    }
                }

                a {
                    display: block;
                    width: 100%;
                    text-align: center;
                    color: #FFF;
                }

                text-align: center;

                .active {
                    color: $primary;
                }

                &:last-child {
                    .divider {
                        display: none;
                    }
                }
            }
        }
    }

}

.full-screen-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $primary;
}

.ref-link-actions {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .share-icon-button {
        .dropdown-toggle {
            background-color: transparent;
            border: transparent;
            display: flex;
            align-items: center;
            gap: 10px;
            color: $primary;

            &:hover {
                background: transparent;
            }
        }

        .dropdown-menu {
            &.show {
                background: #000;
            }

            .dropdown-item {
                color: #fff;
                cursor: pointer;

                span {
                    margin-left: 10px;
                }

                &:hover {
                    background-color: #65676B;
                }
            }
        }

    }
}

.auto-collect {
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    align-items: center;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    margin-top: 20px;
    padding: 20px;
    flex-direction: column;
    text-align: center;


    h6 {
        color: #FFF;
        margin: 0 0 20px 0;
        line-height: 1.6;

        span {
            color: $primary;
            font-weight: bold;
            font-size: 20px;
        }
    }
}

.title {
    &.center {
        text-align: center;

        h3 {
            margin: auto;
        }
    }

    p {
        color: $primary;
        font-weight: 500;
        margin: 0;
    }

    h3 {
        font-family: $font-primary;
        font-size: 3rem;
        max-width: 400px;
    }
}

.promo-section {
    padding-top: 0 !important;
    position: relative;

    .button {
        position: absolute;
        top: 38%;
        right: 5%;
        background: #6e6e6e;
        color: white;
    }
}



.progress {
    height: 9px;
    background-color: #BFBFBF;
}

.progress-bar-striped {
    background-image: none;
    height: 9px;
    border-radius: 8px;
}

.offcanvas-custom {
    &.size-40 {
        width: 510px;
    }

    .offcanvas-header {
        padding: 2rem 2.3rem;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

    .offcanvas-body {
        padding: 3rem;

        h5 {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.01em;
            color: rgb(142, 142, 142);
        }
    }
}


.icon-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 2rem;
        margin-bottom: .8rem;

        span {
            position: absolute;
            left: 0;
            top: 0;
            color: $primary;
        }
    }
}

.icon-list-2 {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        margin-bottom: 3rem;

        .icon {
            width: 120px;

            img {
                width: 100%;
            }
        }

        .info {
            padding-left: 2rem;

            h4 {
                color: #FFF;
                margin: 0 0 .1rem 0;
                font-size: 1.2rem;
                text-transform: uppercase;
            }

            p {
                margin: 0;
                color: #c6c9e5;
                font-size: .9rem;
                max-width: 300px;
            }
        }
    }
}

.icon-bullet {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 1.5rem;
        margin-bottom: .6rem;

        &::before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 8px;
            background-color: $primary;
        }
    }
}

.placehoder-loader {
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;

    &.fullpage {
        position: absolute;
        height: 100%;
        width: 100%;
        flex-direction: column;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 1);
        z-index: 9;

        &.bg-white {
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
}

.pills-cyan {
    .nav-item {
        .nav-link {
            color: rgba(0, 0, 0, 1);
            font-size: 15px;
            padding: 18px 16px;
            border-radius: 12px 12px 0 0;
            border: none;
            font-weight: 500;

            &.active {
                @include gradientDark();
                color: #FFF;

            }


        }
    }
}


.pills-golden {
    .nav-pills {
        .nav-link {
            color: #CCD3D3;
            border-radius: 0;

            &:hover {
                color: $primary;
            }

            &.active {
                background-color: transparent;
                color: $primary;
                font-weight: 500;
                border-bottom: 2px solid $primary;
            }
        }
    }
}

// .mobile-bg {
//     height: 100vh;
// }
// .desktop-bg {
//     height: 100vh;
// }


@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.simple-section {
    padding: 40px 0;

}

.gradient {
    background: rgb(180, 166, 102);
    background: linear-gradient(80deg, rgba(190, 169, 75, 1) 0%, rgba(143, 140, 114, 1) 50%, rgba(255, 255, 255, 0) 100%);
}

.website-section {
    padding: 10px 0px !important;
}


.inline-action {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0px;


    li {
        padding: 0px 4px;
        list-style: none !important;

        button,
        a {
            border-radius: 50% !important;
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            transition: 0.5s;
            font-size: 13px;

            span,
            i {
                font-size: 13px;
            }
        }

        .primary {
            border: 1px solid transparent;
            background: $primary;
            color: #ffffff;

            &:hover {
                border: 1px solid $primary;
                background: transparent;
                color: $primary;
            }
        }

        .red {
            border: 1px solid transparent;
            background: red;
            color: #ffffff;

            &:hover {
                border: 1px solid red;
                background: transparent;
                color: red;
            }
        }

        .black {
            border: 1px solid transparent;
            background: black;
            color: #ffffff;

            &:hover {
                border: 1px solid black;
                background: transparent;
                color: black;
            }
        }

        .gray {
            border: 1px solid transparent;
            background: $gray;
            color: #000000;

            &:hover {
                border: 1px solid $gray;
                background: transparent;
                color: $gray;
            }
        }
    }
}

.blink {
    animation: blinker 2.7s linear infinite;
    font-size: 22px;
    font-weight: 500;
    color: black;
    font-style: italic;
    font-family: sans-serif;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media screen and (max-width: 767px) {
    .promo-section {
        .button {
            top: 37%;
            right: 5%;
            padding: 2px;
            font-size: 6px;
            border-radius: 2px;
        }
    }

    .blink {
        font-size: 12px;
        margin-top: 34px !important;
    }

    .website-section {
        padding: 10px 12px !important;
    }

    .simple-section {
        padding: 10px 0px !important;
    }

    .table-button {
        display: flex;
        justify-content: center;

        .nav-item {
            width: 100% !important;
        }
    }

    .mobile_button {
        .button {
            margin: 5px !important;

            span {
                padding: 0.6rem .3rem;
                min-width: 105px;
                width: 100%;
            }
        }
    }

    .pills-cyan {

        .nav-item {

            .nav-link {
                font-size: 14px;
                padding: 6px 9px;
                border-radius: 12px 12px 0 0;
                font-weight: 500;
            }
        }

    }

    .mobile-nav-pills {
        overflow-y: hidden;
        overflow-x: scroll !important;
        height: 54px;
        display: flex !important;
        flex-wrap: nowrap;

        .nav-item {

            .nav-link {
                min-width: 139px !important;
                padding: 12px 9px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;
                text-align: center;
                justify-content: center;
            }

            &:nth-last-child(3) {
                min-width: 200px;
            }

            &:nth-last-child(2) {
                min-width: 200px;
            }

            &:last-child {
                min-width: 150px;
            }


        }

    }
}