@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.d-title {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 14px;
}

.gradient-num {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  padding: 1px;
  border-radius: 6px;
}
.gradient-num span {
  border-radius: 4px;
  background-color: #FAFAFA;
  color: #000;
  min-height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-detail {
  font-size: 0.9rem;
}

.section-title {
  font-family: "Barlow", sans-serif;
  color: #FFF;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}
.section-title.title-sm {
  font-size: 3rem;
}

.spinner-border {
  color: #FFBE18;
}

.icon_status {
  text-align: center;
}
.icon_status .tick {
  margin-left: 0 !important;
}

.text-gray {
  color: #8D8D8D !important;
}

.text-green {
  color: #28a745;
}

.text-primary {
  color: #FFBE18 !important;
}

.text-secondary {
  color: #45F882 !important;
}

.sub-heading {
  max-width: 700px;
  margin: auto;
  font-size: 1.3rem;
}

.whatsapp-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.buy-coin {
  position: relative;
}
.buy-coin h1 {
  color: #FFBE18;
  font-size: 17px;
  margin: 0;
}
.buy-coin span {
  color: #45F882;
  font-size: 17px;
  margin: 0;
  margin-left: 10px;
}
.buy-coin .coin-wrap {
  margin-bottom: 20px;
}
.buy-coin .wrap {
  position: relative;
}
.buy-coin .wrap .arrow-icon {
  background-color: #131d26;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 43%;
  right: 38px;
}
.buy-coin .wrap .arrow-icon .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background-color: #FFBE18;
  padding: 9px;
}
.buy-coin .wrap .arrow-icon svg {
  width: 25px;
  color: #000000;
}

.custom-dropdown {
  width: 100%;
  margin: auto;
  position: relative;
}
.custom-dropdown .custom-input {
  background-color: #000;
  border-radius: 80px;
  position: relative;
  padding: 10px 30px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  cursor: pointer;
  width: 90%;
  margin: auto;
  height: 50px;
}
.custom-dropdown .custom-input::after {
  position: absolute;
  content: attr(data-label);
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  color: #FFBE18;
}
.custom-dropdown .icon-input-box {
  position: relative;
}
.custom-dropdown .icon-input-box .icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(-50%, -50%);
}
.custom-dropdown .list-backgroud {
  border-radius: 20px;
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 3;
  background-color: #000;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: calc(100vh - 271px);
  overflow-y: scroll;
  margin-top: 10px;
  padding: 20px;
}
.custom-dropdown .list-backgroud ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.custom-dropdown .list-backgroud ul li {
  background-color: #000;
  border-radius: 80px;
  position: relative;
  padding: 10px 30px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 7px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 7px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 7px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  cursor: pointer;
  height: 50px;
  width: 100%;
  margin: auto;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.custom-dropdown .list-backgroud ul li .icon svg {
  width: 20px;
  color: #45F882;
}
.custom-dropdown .list-backgroud ul li a {
  display: block;
  width: 100%;
  text-align: center;
  color: #FFF;
}
.custom-dropdown .list-backgroud ul li .active {
  color: #FFBE18;
}
.custom-dropdown .list-backgroud ul li:last-child .divider {
  display: none;
}

.full-screen-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #FFBE18;
}

.ref-link-actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.ref-link-actions .share-icon-button .dropdown-toggle {
  background-color: transparent;
  border: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #FFBE18;
}
.ref-link-actions .share-icon-button .dropdown-toggle:hover {
  background: transparent;
}
.ref-link-actions .share-icon-button .dropdown-menu.show {
  background: #000;
}
.ref-link-actions .share-icon-button .dropdown-menu .dropdown-item {
  color: #fff;
  cursor: pointer;
}
.ref-link-actions .share-icon-button .dropdown-menu .dropdown-item span {
  margin-left: 10px;
}
.ref-link-actions .share-icon-button .dropdown-menu .dropdown-item:hover {
  background-color: #65676B;
}

.auto-collect {
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  width: 100%;
  margin: auto;
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  flex-direction: column;
  text-align: center;
}
.auto-collect h6 {
  color: #FFF;
  margin: 0 0 20px 0;
  line-height: 1.6;
}
.auto-collect h6 span {
  color: #FFBE18;
  font-weight: bold;
  font-size: 20px;
}

.title.center {
  text-align: center;
}
.title.center h3 {
  margin: auto;
}
.title p {
  color: #FFBE18;
  font-weight: 500;
  margin: 0;
}
.title h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  max-width: 400px;
}

.promo-section {
  padding-top: 0 !important;
  position: relative;
}
.promo-section .button {
  position: absolute;
  top: 38%;
  right: 5%;
  background: #6e6e6e;
  color: white;
}

.progress {
  height: 9px;
  background-color: #BFBFBF;
}

.progress-bar-striped {
  background-image: none;
  height: 9px;
  border-radius: 8px;
}

.offcanvas-custom.size-40 {
  width: 510px;
}
.offcanvas-custom .offcanvas-header {
  padding: 2rem 2.3rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.offcanvas-custom .offcanvas-body {
  padding: 3rem;
}
.offcanvas-custom .offcanvas-body h5 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgb(142, 142, 142);
}

.icon-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.icon-list li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.8rem;
}
.icon-list li span {
  position: absolute;
  left: 0;
  top: 0;
  color: #FFBE18;
}

.icon-list-2 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.icon-list-2 li {
  display: flex;
  margin-bottom: 3rem;
}
.icon-list-2 li .icon {
  width: 120px;
}
.icon-list-2 li .icon img {
  width: 100%;
}
.icon-list-2 li .info {
  padding-left: 2rem;
}
.icon-list-2 li .info h4 {
  color: #FFF;
  margin: 0 0 0.1rem 0;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.icon-list-2 li .info p {
  margin: 0;
  color: #c6c9e5;
  font-size: 0.9rem;
  max-width: 300px;
}

.icon-bullet {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.icon-bullet li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.6rem;
}
.icon-bullet li::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 8px;
  background-color: #FFBE18;
}

.placehoder-loader {
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.placehoder-loader.fullpage {
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  z-index: 9;
}
.placehoder-loader.fullpage.bg-white {
  background-color: rgba(255, 255, 255, 0.7);
}

.pills-cyan .nav-item .nav-link {
  color: rgb(0, 0, 0);
  font-size: 15px;
  padding: 18px 16px;
  border-radius: 12px 12px 0 0;
  border: none;
  font-weight: 500;
}
.pills-cyan .nav-item .nav-link.active {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF;
}

.pills-golden .nav-pills .nav-link {
  color: #CCD3D3;
  border-radius: 0;
}
.pills-golden .nav-pills .nav-link:hover {
  color: #FFBE18;
}
.pills-golden .nav-pills .nav-link.active {
  background-color: transparent;
  color: #FFBE18;
  font-weight: 500;
  border-bottom: 2px solid #FFBE18;
}

@-webkit-keyframes rotating
/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.simple-section {
  padding: 40px 0;
}

.gradient {
  background: rgb(180, 166, 102);
  background: linear-gradient(80deg, rgb(190, 169, 75) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
}

.website-section {
  padding: 10px 0px !important;
}

.inline-action {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0px;
}
.inline-action li {
  padding: 0px 4px;
  list-style: none !important;
}
.inline-action li button,
.inline-action li a {
  border-radius: 50% !important;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  font-size: 13px;
}
.inline-action li button span,
.inline-action li button i,
.inline-action li a span,
.inline-action li a i {
  font-size: 13px;
}
.inline-action li .primary {
  border: 1px solid transparent;
  background: #FFBE18;
  color: #ffffff;
}
.inline-action li .primary:hover {
  border: 1px solid #FFBE18;
  background: transparent;
  color: #FFBE18;
}
.inline-action li .red {
  border: 1px solid transparent;
  background: red;
  color: #ffffff;
}
.inline-action li .red:hover {
  border: 1px solid red;
  background: transparent;
  color: red;
}
.inline-action li .black {
  border: 1px solid transparent;
  background: black;
  color: #ffffff;
}
.inline-action li .black:hover {
  border: 1px solid black;
  background: transparent;
  color: black;
}
.inline-action li .gray {
  border: 1px solid transparent;
  background: #8D8D8D;
  color: #000000;
}
.inline-action li .gray:hover {
  border: 1px solid #8D8D8D;
  background: transparent;
  color: #8D8D8D;
}

.blink {
  animation: blinker 2.7s linear infinite;
  font-size: 22px;
  font-weight: 500;
  color: black;
  font-style: italic;
  font-family: sans-serif;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@media screen and (max-width: 767px) {
  .promo-section .button {
    top: 37%;
    right: 5%;
    padding: 2px;
    font-size: 6px;
    border-radius: 2px;
  }
  .blink {
    font-size: 12px;
    margin-top: 34px !important;
  }
  .website-section {
    padding: 10px 12px !important;
  }
  .simple-section {
    padding: 10px 0px !important;
  }
  .table-button {
    display: flex;
    justify-content: center;
  }
  .table-button .nav-item {
    width: 100% !important;
  }
  .mobile_button .button {
    margin: 5px !important;
  }
  .mobile_button .button span {
    padding: 0.6rem 0.3rem;
    min-width: 105px;
    width: 100%;
  }
  .pills-cyan .nav-item .nav-link {
    font-size: 14px;
    padding: 6px 9px;
    border-radius: 12px 12px 0 0;
    font-weight: 500;
  }
  .mobile-nav-pills {
    overflow-y: hidden;
    overflow-x: scroll !important;
    height: 54px;
    display: flex !important;
    flex-wrap: nowrap;
  }
  .mobile-nav-pills .nav-item .nav-link {
    min-width: 139px !important;
    padding: 12px 9px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .mobile-nav-pills .nav-item:nth-last-child(3) {
    min-width: 200px;
  }
  .mobile-nav-pills .nav-item:nth-last-child(2) {
    min-width: 200px;
  }
  .mobile-nav-pills .nav-item:last-child {
    min-width: 150px;
  }
}
body {
  font-family: "Montserrat", sans-serif;
  max-width: 480px;
  min-width: auto;
}

a {
  color: #FFBE18;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #45F882;
}

.skiptranslate {
  display: none;
}

@media (min-width: 900px) {
  html, body {
    max-width: 495px;
    margin-left: auto;
    margin-right: auto;
  }
}
.button {
  display: inline-block;
  vertical-align: middle;
  padding: 0.6rem 1.3rem;
  border: none;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 20px;
  font-weight: normal;
  color: #000;
  font-weight: 700;
}
.button.hover-on-dark:hover {
  color: #FFF;
  border-color: #FFF;
}
.button.button-sm {
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
  font-weight: normal;
}
.button.disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.btn-img {
  border: none;
  background-color: transparent;
}
.btn-img img {
  display: block;
}

.button-close {
  width: 40px;
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;
  border-radius: 0.4rem;
}
.button-close:hover, .button-close:focus {
  background-color: rgba(191, 154, 65, 0.05);
}

.copy-link {
  border: none;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
}

.button-long {
  width: 100%;
}

.button-dark {
  background-color: #253250;
  color: #FFF;
}
.button-dark:hover {
  background-color: transparent;
  border-color: #253250;
  color: #253250;
}

.button-bs {
  border-radius: 20px;
  background: #FFBE18;
  color: #000;
  font-weight: 700;
}
.button-bs:hover, .button-bs:active, .button-bs:focus {
  opacity: 0.8;
}

.white-button {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
}
.white-button:hover, .white-button:active, .white-button:focus {
  color: #000000;
  background: rgb(128, 128, 128) !important;
}

.button-outline-bs {
  border-color: #FFBE18;
  color: #FFBE18;
  background-color: transparent;
  border-radius: 80px;
}
.button-outline-bs:hover {
  color: #FFF;
  background: linear-gradient(91deg, #FFBE18 0%, #FF002B 49.48%, #FF4060 98.97%);
}

.input-height {
  height: 56px;
}

.input-radius {
  border-radius: 0.375rem;
}

.button-icon-bs {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.button-icon-bs svg {
  width: 20px;
}
.button-icon-bs:hover, .button-icon-bs.active {
  color: #000000;
  background-color: #FFBE18;
}

.button-black {
  background-color: #1F1F1F;
  color: #FFF;
}
.button-black:hover {
  background-color: transparent;
  border-color: #1F1F1F;
  color: #1F1F1F;
}

.button-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0.6rem 0.6rem 1.3rem;
}
.button-icon:has(svg) {
  gap: 20px;
}
.button-icon:has(svg) svg {
  width: 30px;
}
.button-icon span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}
.button-icon.icon-right span {
  margin-left: 0.8rem;
}
.button-icon.icon-left {
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0.6rem 0.6rem 0.6rem 0.6rem;
}
.button-icon.icon-left span,
.button-icon.icon-left i {
  margin-left: 0;
  margin-right: 0.4rem;
}
.button-icon.button-dark span {
  background-color: #F3F6FD;
  color: #253250;
}

.button-light {
  background-color: #E8E8E8;
  color: #65676B;
}
.button-light:hover {
  background-color: transparent;
  border-color: #65676B;
  color: #65676B;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.button-gradient {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  color: #000;
  padding: 1px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
}
.button-gradient span {
  background-color: #FAFAFA;
  padding: 0.6rem 1.3rem;
  display: inline-block;
  border-radius: 6px;
  min-width: 130px;
  width: 100%;
}

.two-buttons {
  margin-top: 20px;
}
.two-buttons .button {
  width: 100%;
  min-height: 70px;
}
.two-buttons .button.left {
  border-radius: 0 0 0 18px;
}
.two-buttons .button.right {
  border-radius: 0 0 18px 0;
}
.two-buttons .button span {
  line-height: unset;
}

.button-primary {
  background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
  color: #FFF;
  font-weight: 700;
}
.button-primary:hover, .button-primary:active, .button-primary:focus {
  color: #FFF;
  background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
}

.button-secondary {
  background-color: #4FC4C4;
  color: #45F882 !important;
  font-weight: bold;
}
.button-secondary:hover {
  background-color: transparent;
  border-color: #4FC4C4;
  color: #4FC4C4 !important;
  opacity: 1;
}

.button-outline-secondary {
  border-color: #1F1F1F;
  color: #1F1F1F;
  background-color: transparent;
}
.button-outline-secondary:hover {
  color: #FFF;
  background-color: #1F1F1F;
}

.button-outline-secondary {
  border-color: #45F882;
  color: #45F882;
  background-color: transparent;
}
.button-outline-secondary:hover {
  color: #FFF;
  background-color: #45F882;
}

.button-outline-gray {
  border-color: #FAFAFA;
  color: #FAFAFA;
  background-color: transparent;
}
.button-outline-gray:hover {
  color: #FFF;
  background-color: #FAFAFA;
}

.button-outline-primary {
  border-color: #FFBE18;
  color: #FFBE18;
  background-color: transparent;
}
.button-outline-primary:hover {
  color: #FFF;
  background-color: #FFBE18;
}

.button-fb {
  background-color: #3C5A9A;
  color: #FFF;
  font-weight: bold;
}
.button-fb:hover {
  background-color: transparent;
  border-color: #3C5A9A;
  color: #3C5A9A;
  opacity: 1;
}

.button-green {
  background-color: #28a745;
  color: #FFF;
}
.button-green:hover {
  color: #FFF;
  opacity: 0.8;
}

.button-danger {
  background-color: #dc3545;
  color: #FFF;
}
.button-danger:hover {
  color: #FFF;
  opacity: 0.8;
}

.button-round {
  border-radius: 0.4rem;
}

.button-block {
  width: 100%;
  display: block;
}

.button-gray {
  background-color: #D1D1D1;
  color: #1F1F1F !important;
  font-weight: 500;
}
.button-gray:hover {
  background-color: transparent;
  border-color: #8D8D8D;
  color: #8D8D8D !important;
  opacity: 1;
}

.button-outline-gray {
  background-color: #F5F5F5;
  border: 1px solid #707070;
  color: #292724;
  font-weight: bold;
}
.button-outline-gray:hover {
  color: #292724;
  background-color: #FFF;
}

.download-icon {
  display: flex;
  gap: 20px;
  cursor: pointer;
}
.download-icon li {
  padding: 5px !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  text-align: center !important;
}
.download-icon .excel {
  background: green;
}
.download-icon .excel:hover svg path {
  stroke: #ffffff !important;
}
.download-icon .pdf {
  border: 1px solid red;
}
.download-icon .pdf svg path {
  stroke: #f50000 !important;
}
.download-icon .pdf:hover {
  background: red;
}
.download-icon .pdf:hover svg path {
  stroke: rgb(255, 255, 255) !important;
}
.download-icon .csv {
  background: blue;
}
.download-icon .csv:hover svg path {
  stroke: #ffffff !important;
}

.downloads {
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.excel-button {
  background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
  padding: 5px !important;
  border: 1px dashed white !important;
  color: white !important;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  cursor: pointer;
  font-size: 0.6rem !important;
}
.excel-button:hover {
  color: white !important;
  background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
  border: 1px dashed white !important;
  opacity: 0.8;
}

.round-button {
  background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
  padding: 5px !important;
  border: 1px dashed white !important;
  color: white !important;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  cursor: pointer;
}
.round-button span,
.round-button a {
  color: white;
  font-size: 0.6rem;
}
.round-button:hover {
  color: white !important;
  background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
  border: 1px dashed white !important;
  opacity: 0.8;
}
.round-button:hover span,
.round-button:hover a {
  color: white;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.small-button {
  font-weight: 400;
  padding: 0.3rem 0.7rem;
  font-size: 0.8rem;
  background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
  color: #FFF;
}
.small-button:hover, .small-button:active, .small-button:focus {
  color: #FFF;
  background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
}

@media screen and (max-width: 767px) {
  .button-smlg {
    width: 100%;
    display: block;
    text-align: center;
  }
}
.button-icon-secondary {
  display: inline-flex;
  align-items: center;
  color: #45F882;
  white-space: nowrap;
}
.button-icon-secondary svg {
  width: 24px;
  margin-right: 8px;
}

.button-icon-primary {
  display: inline-flex;
  align-items: center;
  color: #FFBE18;
  white-space: nowrap;
}
.button-icon-primary svg {
  width: 24px;
  margin-right: 8px;
}

.button-3d-outline {
  background-color: transparent;
  border-radius: 80px;
  color: #FFBE18;
  position: relative;
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid rgba(255, 190, 24, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  cursor: pointer;
}
.button-3d-outline:hover, .button-3d-outline:active, .button-3d-outline:focus {
  color: #000;
  transform: scale(1.1);
  background-color: #FFBE18;
}
.button-3d-outline:disabled {
  opacity: 0.5;
  cursor: none;
}
.button-3d-outline::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.btn-red {
  background-color: rgba(255, 17, 17, 0.5);
  color: #FFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.btn-red svg {
  width: 20px;
}

.button-line {
  position: relative;
  display: inline-flex;
  column-gap: 10px;
}
.button-line svg {
  width: 20px;
}
.button-line::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background: #FFBE18;
  border-radius: 5px;
  transition: 0.5s all ease-in-out;
}
.button-line:hover::before {
  width: 80%;
}

.button-3d-primary {
  background-color: #000;
  border-radius: 80px;
  color: #FFF;
  position: relative;
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  cursor: pointer;
}
.button-3d-primary:hover, .button-3d-primary:active, .button-3d-primary:focus {
  color: #ffffff;
  transform: scale(1.1);
}
.button-3d-primary:disabled {
  opacity: 0.5;
  cursor: none;
}
.button-3d-primary::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.button-3d-secondary {
  background-color: #45F882;
  border-radius: 80px;
  color: #142129;
  position: relative;
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
  overflow: hidden;
  transition: all 0.4s ease;
  cursor: pointer;
}
.button-3d-secondary:hover, .button-3d-secondary:active, .button-3d-secondary:focus {
  color: #fff;
  transform: scale(1.1);
}
.button-3d-secondary:disabled {
  opacity: 0.5;
  cursor: none;
}
.button-3d-secondary::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.icon-input {
  position: relative;
}
.icon-input .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}
.icon-input .icon svg {
  width: 20px;
  cursor: pointer;
  color: #FFBE18;
}
.icon-input .icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.icon-input .icon-right svg {
  width: 20px;
  cursor: pointer;
  color: #FFBE18;
}
.icon-input .toggle-password {
  position: absolute;
  right: 14px;
  top: 16px;
}
.icon-input .toggle-password svg {
  width: 26px;
  color: rgba(255, 255, 255, 0.5);
}
.icon-input .form-control {
  padding-left: 52px !important;
}
.icon-input .form-control.ps-0 {
  padding-left: 14px !important;
}

.form-default label {
  color: #fff;
  margin-bottom: 8px;
}
.form-default .form-group {
  position: relative;
}
.form-default .form-group .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.form-default .form-group .icon.with-label {
  top: 66%;
}
.form-default .form-group .icon svg {
  width: 20px;
  cursor: pointer;
  color: #FFBE18;
}
.form-default .form-control {
  min-height: 60px;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
  padding: 17px 12px;
}
.form-default .form-select {
  min-height: 60px;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
}

.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control::-ms-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.radio-onoff input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}
.radio-onoff label {
  cursor: pointer;
  width: 120px;
  height: 42px;
  background: linear-gradient(270deg, #BF0205 0%, #CB0909 31.72%, #660002 100%);
  display: block;
  border-radius: 100px;
  position: relative;
}
.radio-onoff label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 32px;
  height: 32px;
  box-shadow: inset 7px -9px 13px -4px #505050;
  border-radius: 90px;
  transition: 0.3s;
  background: #fff;
}
.radio-onoff label:before {
  content: "Deactive";
  color: #FFF;
  font-weight: bold;
  right: 12px;
  top: 11px;
  position: absolute;
  font-size: 13px;
}
.radio-onoff input:checked + label:before {
  content: "Active";
  left: 12px;
  right: unset;
  color: #fff;
}
.radio-onoff input:checked + label {
  background: linear-gradient(88deg, #85D74A 0%, #28922F 31.72%, #02612B 100%);
}
.radio-onoff input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 40px;
  display: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000000;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham3 .top {
  stroke-dasharray: 40 130;
}
.ham3 .middle {
  stroke-dasharray: 40 140;
}
.ham3 .bottom {
  stroke-dasharray: 40 205;
}
.ham3.active .top {
  stroke-dasharray: 75 130;
  stroke-dashoffset: -63px;
}
.ham3.active .middle {
  stroke-dashoffset: -102px;
}
.ham3.active .bottom {
  stroke-dasharray: 110 205;
  stroke-dashoffset: -86px;
}

.ham4 .top {
  stroke-dasharray: 40 121;
}
.ham4 .bottom {
  stroke-dasharray: 40 121;
}
.ham4.active .top {
  stroke-dashoffset: -68px;
}
.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

.page-head {
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 12rem;
}
.page-head h1 {
  text-align: center;
  color: #FFF;
  font-family: "Krub";
}

.page-content {
  background-color: #1b1b1a;
  color: #FFF;
  padding: 3rem 0;
}

.modal-theme {
  background-color: rgba(0, 0, 0, 0.44);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: rgba(255, 255, 255, 0.6);
}
.modal-theme.size-md .modal-dialog {
  max-width: 500px;
}
.modal-theme.transparent {
  color: #FFF;
}
.modal-theme.transparent .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #171f27;
}
.modal-theme.transparent .modal-header button[aria-label=Close] {
  display: none;
}
.modal-theme.transparent .modal-header .btn-close {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: auto;
  height: auto;
  padding: 0.25em 0.25em;
  color: #000;
  background: none;
  border: 0;
  border-radius: none;
  opacity: 1;
}
.modal-theme.transparent .modal-header .btn-close svg {
  width: 30px;
  color: #45F882;
}
.modal-theme.transparent .modal-body {
  background: #000;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
}
.modal-theme.transparent .modal-footer {
  padding: 1.2rem 2rem;
  background: #000000;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.modal-theme .modal-header {
  background-color: #000;
  border: none;
  position: relative;
}
.modal-theme .modal-header.header-dark {
  margin: -2px;
}
.modal-theme .modal-header.header-dark .modal-title {
  color: #FFF;
}
.modal-theme .modal-header.header-dark .btn-close {
  filter: invert(100%);
}
.modal-theme .modal-header .modal-title {
  position: relative;
}
.modal-theme .modal-header .h4 {
  font-size: 1rem;
}
.modal-theme.modal-small .modal-dialog {
  max-width: 550px;
}
.modal-theme.modal-lg .modal-dialog {
  max-width: 960px;
}
.modal-theme.modal-thumbnail .modal-dialog iframe {
  width: 100%;
  height: 397px;
  border-radius: 25px;
}
.modal-theme .modal-dialog {
  max-width: 800px;
}
.modal-theme .modal-body img {
  border-radius: 12px;
}
.modal-theme .modal-footer {
  padding: 1.2rem 2rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.bg-img-modal .modal-header {
  min-height: 115px;
  position: relative;
  filter: grayscale(1);
}
.bg-img-modal .modal-header .button-close {
  position: absolute;
  right: 1%;
  top: 3%;
}
.bg-img-modal .modal-header .title {
  position: absolute;
  bottom: 0%;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .bg-img-modal .modal-header .title {
    bottom: 2.5%;
  }
  .modal-theme .modal-body {
    padding: 1.3rem;
  }
  .modal-theme.modal-thumbnail .modal-dialog iframe {
    height: 245px !important;
  }
}
/** Switch

 -------------------------------------*/
.switch-box {
  display: flex;
  align-items: center;
}
.switch-box span {
  margin-left: 12px;
  margin-top: -5px;
}
.switch-box .switch-box-slider {
  position: relative;
  display: inline-block;
  height: 23px;
  width: 50px;
  background: #999;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.switch-box .switch-box-slider:after {
  position: absolute;
  left: 1px;
  top: 1px;
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  content: "";
  transition: all 0.2s ease;
}
.switch-box .switch-box-input {
  display: none;
}
.switch-box .switch-box-input ~ .switch-box-label {
  margin-left: 16px;
}
.switch-box .switch-box-input:checked ~ .switch-box-slider:after {
  left: 28px;
}
.switch-box.is-primary .switch-box-input:checked ~ .switch-box-slider {
  background: #000;
}
.switch-box.is-primary .switch-box-input:checked ~ .switch-box-slider:after {
  background: #FFBE18;
}

@media screen and (max-width: 767px) {
  .switch-box .switch-box-slider {
    width: 40px;
    height: 18px;
  }
  .switch-box .switch-box-slider::after {
    width: 16px;
    height: 16px;
  }
  .switch-box .switch-box-input:checked ~ .switch-box-slider:after {
    left: 23px;
  }
}
.card {
  background-color: #FFF;
  border: none;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  display: block;
}
.card.card_lg {
  padding: 50px 66px;
}
.card .card_label {
  background: rgb(243, 243, 243);
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 12px;
  color: rgb(142, 142, 142);
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
}
.card .card_title {
  font-size: 16px;
  color: #1F1F1F;
}
.card .card_text {
  color: #8E8E8E;
}

.card-gradient {
  padding: 2px;
  border-radius: 20px;
}
.card-gradient.radius-12 {
  border-radius: 12px;
}
.card-gradient.radius-12 .card-inner {
  border-radius: 10px;
}
.card-gradient .card-inner {
  background-color: #FAFAFA;
  border-radius: 18px;
  padding: 40px;
}
.card-gradient .card-inner.p2 {
  padding: 2px;
}
.card-gradient .card-inner .card-inner-header {
  min-height: 70px;
  padding: 20px;
  position: relative;
  font-weight: 500;
  color: #000;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .card .card_title {
    font-size: 14px;
  }
  .card.card_lg {
    padding: 20px 30px;
  }
  .card-gradient .card-inner {
    padding: 15px !important;
  }
  .card-gradient .card-inner.p-0 {
    padding: 0 !important;
  }
}
.table {
  margin-bottom: 0;
}
.table.table-sm th,
.table.table-sm td {
  padding: 4px;
}
.table.table-package td,
.table.table-package th {
  padding: 10px;
}
.table.table-head-primary thead {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
}
.table.table-head-primary thead th {
  color: #FFF;
}
.table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.table thead th {
  padding: 1rem 1rem;
}
.table.large-th thead th {
  padding: 2rem 1rem;
}
.table tbody tr:last-child td {
  border-bottom: none;
}
.table tbody tr.filter-row td {
  padding: 6px;
}
.table tbody tr.filter-row td .form-select {
  box-shadow: transparent !important;
  padding: 0 16px;
  border-radius: 12px;
  border: 1px solid #FFBE18;
  height: 45px;
  color: rgba(0, 0, 0, 0.6) !important;
}
.table tbody tr.filter-row td .form-select:active, .table tbody tr.filter-row td .form-select:focus, .table tbody tr.filter-row td .form-select:target, .table tbody tr.filter-row td .form-select:focus-visible {
  box-shadow: transparent !important;
}
.table tbody tr.filter-row td .check {
  width: 15px !important;
  background-color: #000;
}
.table tbody tr.filter-row td .inputwrap {
  border: 1px solid #FFBE18;
  border-radius: 10px;
  padding: 1px;
}
.table tbody tr.filter-row td input {
  width: 100% !important;
  border: none;
  border-radius: 8px;
  background-color: white;
  min-height: 40px;
}
.table tbody tr.filter-row td input::placeholder {
  font-size: 14px;
}
.table tbody tr.filter-row td input:focus {
  box-shadow: none;
  border-color: transparent !important;
}
.table th {
  font-weight: 600;
}
.table td {
  padding: 0.8rem 1rem;
}
.table td,
.table th {
  border-color: rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  color: #000;
  font-size: 13px;
}
.table .table-total {
  font-size: 16px;
}

.table-theme {
  border-spacing: 0 10px;
  border-collapse: separate;
}
.table-theme thead tr th:first-child {
  border-radius: 10px 0 0 10px;
}
.table-theme thead tr th:last-child {
  border-radius: 0 10px 10px 0;
}
.table-theme tr td:first-child {
  border-radius: 10px 0 0 10px;
}
.table-theme tr td:last-child {
  border-radius: 0 0 10px 10px;
}

.table-ledger th {
  vertical-align: middle;
}

.table-activities {
  position: relative;
}
.table-activities thead {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
}
.table-activities thead tr {
  background-color: transparent !important;
}
.table-activities thead th {
  background-color: transparent;
  color: #FFF;
}
.table-activities tr,
.table-activities th {
  vertical-align: middle;
}
.table-activities th,
.table-activities td {
  border: none;
}
.table-activities tr {
  background-color: #FFF;
}

.kyc-table tbody {
  text-align: center;
  border: 3px solid #d8d8d8 !important;
}
.kyc-table tbody tr {
  border: 3px solid #d8d8d8 !important;
  padding: 15px;
}
.kyc-table tbody tr th {
  border: 3px solid #d8d8d8 !important;
  padding: 0.9rem;
  font-size: 1rem;
  width: 50%;
  font-weight: 700;
}
.kyc-table tbody tr:first-child th {
  font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
  .card-table .card-inner {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .card-table .card-inner .table {
    border-collapse: collapse;
  }
  .card-table .card-inner .table th,
  .card-table .card-inner .table td {
    white-space: nowrap;
  }
  .table-flow {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .table-flow .table {
    border-collapse: collapse;
  }
  .table-flow .table th,
  .table-flow .table td {
    white-space: nowrap;
  }
  .stat-table {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .stat-table .table {
    border-collapse: collapse;
  }
  .stat-table .table th,
  .stat-table .table td {
    white-space: nowrap;
  }
}
.OTP_wrap {
  max-width: 500px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px 10px;
  text-align: center;
  border-radius: 4px;
}
.OTP_wrap.OTP_wrap input {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.OTP_wrap .OTP_head {
  text-align: center;
}
.OTP_wrap .OTP_head span {
  color: #4FC4C4;
  font-size: 4rem;
}
.OTP_wrap h4 {
  margin: 0 0 10px 0;
  font-size: 1.1rem;
}
.OTP_wrap > div {
  justify-content: center;
}
.OTP_wrap input {
  height: 30px;
  width: 30px !important;
  margin: 10px;
  border-radius: 4px;
  border: none;
  border-bottom: 3px solid #4FC4C4;
  text-align: center;
  font-size: 26px;
}

.page-numbers-wrap {
  padding: 2px;
  border-radius: 70px;
}

.pagination .page-item {
  margin: 0 5px;
  padding: 2px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.pagination .page-item:first-child {
  margin-left: 0;
}
.pagination .page-item:last-child {
  margin-right: 0;
}
.pagination .page-item .page-link {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  color: #000;
  border: none;
}
.pagination .page-item .page-link span {
  font-size: 1.5rem;
  line-height: unset;
}
.pagination .page-item .page-link:focus {
  outline: none;
  box-shadow: none;
}
.pagination .page-item.active .page-link {
  border-radius: 8px;
  background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
  color: #ffffff;
  border-color: #FFF2F2;
}

@media screen and (max-width: 767px) {
  .page-numbers-wrap {
    padding: 1px;
    border-radius: 70px;
    margin-top: 13px;
  }
  .pagination-wrap .d-flex {
    justify-content: center;
  }
  .ms-md-2 {
    font-size: 13px !important;
    margin-top: 10px;
  }
  .pagination .page-item .page-link {
    font-size: 13px;
    height: 28px;
    width: 28px;
  }
  .pagination .page-item .page-link span {
    font-size: 1rem;
    line-height: unset;
  }
}
.disable-accordion button {
  pointer-events: none;
}

.no-dropdown-arrow .accordion-button::after {
  display: none;
}

#menu {
  padding: 0;
}

/* Initially hide all submenus */
.submenu {
  display: none;
}

/* Style for active submenu */
.submenu.open {
  display: block;
}

.tabs-theme {
  margin: -2px;
  border: none;
  border-bottom: 2px solid #FFBE18;
}
.tabs-theme.theme-style-1 {
  border-bottom: none;
}
.tabs-theme.theme-style-1 .nav-item {
  min-width: 100%;
}
.tabs-theme.theme-style-1 .nav-item .nav-link {
  color: #000;
  width: 100%;
}
.tabs-theme .nav-item .nav-link {
  color: #FFBE18;
  border: none !important;
  padding: 14px 20px;
  text-align: center;
  border-radius: 18px 18px 0 0;
}
.tabs-theme .nav-item .nav-link.active {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF !important;
}

.tab-content-p .tab-content {
  padding: 30px;
}

.wizard-tabs {
  margin: 0;
  padding: 32px 0;
  list-style: none;
}
.wizard-tabs.tabs-deposit {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.wizard-tabs li {
  position: relative;
  text-align: center;
}
.wizard-tabs li.active .wizard-number-wrap {
  border: 1px solid #000;
}
.wizard-tabs li.active .wizard-number-wrap span {
  background-color: #000;
  color: #FFBE18;
}
.wizard-tabs li.active .wizard-number-wrap span svg {
  stroke: white;
}
.wizard-tabs li.active p {
  color: #FFF;
}
.wizard-tabs li .wizard-number-wrap {
  margin: auto;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1490196078);
  width: 38px;
  height: 38px;
}
.wizard-tabs li .wizard-number-wrap span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-size: 14px;
}
.wizard-tabs li .wizard-number-wrap span svg {
  stroke: #FFBE18;
  width: 20px;
}
.wizard-tabs li p {
  margin-top: 6px;
  font-size: 12px;
  margin-bottom: 0;
}
.wizard-tabs li .line {
  display: none;
  width: 1px;
  height: 49px;
  background: #7B8599;
  margin: 10px auto;
}

.dropdown-gradient {
  border-radius: 12px;
  position: relative;
}
.dropdown-gradient.active button span {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF;
}
.dropdown-gradient.active button i {
  color: #FFF;
}
.dropdown-gradient > button {
  width: 100%;
  border: none;
  text-align: left;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  display: block;
  padding: 1px;
  border-radius: 12px;
}
.dropdown-gradient > button span {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #FAFAFA;
  padding: 14px 20px;
  border-radius: 10px;
  position: relative;
  padding-left: 46px;
}
.dropdown-gradient > button span i {
  line-height: unset;
  margin-right: 10px;
  position: absolute;
  left: 17px;
}
.dropdown-gradient > button i {
  line-height: unset;
  margin-right: 10px;
  position: absolute;
  right: 12px;
  top: 8px;
}
.dropdown-gradient .dropdown-menu {
  width: 100%;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  border-radius: 12px;
  overflow: hidden;
  padding: 1px;
}
.dropdown-gradient .dropdown-menu li {
  background-color: #FAFAFA;
}
.dropdown-gradient .dropdown-menu li .dropdown-item {
  padding-left: 54px;
  background-color: #FAFAFA;
}
.dropdown-gradient .dropdown-menu li .dropdown-item:focus, .dropdown-gradient .dropdown-menu li .dropdown-item:hover {
  background-color: #FFBE18;
  color: #FFF;
}

.dropdown-gradient2 {
  border-radius: 12px;
  position: relative;
}
.dropdown-gradient2:not(.default-light) button[aria-expanded=false] span {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF;
}
.dropdown-gradient2:not(.default-light) button[aria-expanded=true] span {
  background-color: #FAFAFA;
  color: #000;
}
.dropdown-gradient2.default-light button i {
  color: #000;
}
.dropdown-gradient2 > button {
  width: 100%;
  border: none;
  text-align: left;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  display: block;
  padding: 1px;
  border-radius: 12px;
  position: relative;
}
.dropdown-gradient2 > button[aria-expanded=true] i {
  transform: rotateX(180deg);
  color: #000;
}
.dropdown-gradient2 > button span {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 210px;
  padding: 12px 20px;
  border-radius: 10px;
  position: relative;
  background-color: #FAFAFA;
  color: #000;
}
.dropdown-gradient2 > button span i {
  line-height: unset;
  margin-right: 10px;
  position: absolute;
  left: 17px;
}
.dropdown-gradient2 > button i {
  line-height: unset;
  margin-right: 10px;
  position: absolute;
  right: 12px;
  top: 8px;
}
.dropdown-gradient2 .dropdown-menu {
  width: 100%;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  border-radius: 12px;
  overflow: hidden;
  padding: 1px;
}
.dropdown-gradient2 .dropdown-menu li {
  background-color: #FAFAFA;
}
.dropdown-gradient2 .dropdown-menu li:first-child {
  padding-top: 10px;
}
.dropdown-gradient2 .dropdown-menu li:last-child {
  padding-bottom: 10px;
}
.dropdown-gradient2 .dropdown-menu li .dropdown-item {
  background-color: #FAFAFA;
}
.dropdown-gradient2 .dropdown-menu li .dropdown-item:focus, .dropdown-gradient2 .dropdown-menu li .dropdown-item:hover {
  background-color: #FFBE18;
  color: #FFF;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 8px;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 7px;
  height: 7px;
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 0;
  margin-top: 10px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFBE18;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
}

.package-slider {
  margin-top: 20px;
}
.package-slider .slick-list {
  overflow: hidden;
}
.package-slider .slick-list .slick-slide {
  margin: auto;
}
.package-slider .slick-list .slick-slide img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .slick-dots li {
    width: 6px !important;
    height: 20px !important;
    margin: 0 8px !important;
  }
  .slick-dots li button {
    font-size: 0px;
    line-height: 1;
    color: #FFBE18 !important;
    opacity: 5;
  }
  .slick-dots li button:before {
    color: #FFBE18 !important;
    font-size: 12px !important;
  }
  .slick-dots li.slick-active button {
    opacity: 1;
  }
}
.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #FFBE18;
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-moz-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-moz-scrollbar-thumb {
  background-color: #FFBE18;
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar {
  width: 0.6rem;
  border-radius: 20px;
}
.scrollbar::-ms-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.scrollbar::-ms-scrollbar-thumb {
  background-color: #FFBE18;
  border-radius: 20px;
}
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #FFBE18 rgba(0, 0, 0, 0.1);
}

.placeholder {
  position: fixed;
  z-index: 99999999999999 !important;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: flex !important;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: #000 !important;
  opacity: 1 !important;
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.placeholder.loader {
  transform: translate(0, 0);
}
.placeholder.loader svg {
  fill: #050709;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 110vh;
}
.placeholder.hidden {
  animation: fadeOut 3s forwards;
  opacity: 0;
  pointer-events: none;
}
.placeholder .preloader-heading .load-text {
  font-weight: 200;
  letter-spacing: 10px;
  text-transform: uppercase;
}
.placeholder .preloader-heading .load-text span {
  animation: loading 1s infinite alternate;
  color: white;
  font-weight: 400;
  font-size: 20px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes loading {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.assistive-button {
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #FFBE18;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  user-select: none;
  touch-action: none;
  overflow: hidden;
}
.assistive-button img {
  width: 100%;
}

.header-dashboard {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  position: fixed;
  background: black;
  top: 0;
  max-width: 480px;
  width: 100%;
  z-index: 10;
}
.header-dashboard .header-left {
  display: flex;
  align-items: center;
}
.header-dashboard .header-left img {
  border-radius: 50%;
  width: 45px;
}
.header-dashboard .header-left .user-logged-in {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.header-dashboard .header-left .user-logged-in .info h4 {
  color: #FFF;
  font-size: 17px;
  margin: 0;
  text-transform: capitalize;
}
.header-dashboard .header-left .user-logged-in .info p {
  font-size: 13px;
  margin: 0;
}
.header-dashboard .header-right {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header-dashboard .header-right .menu-toggle {
  border: none;
  background-color: transparent;
}
.header-dashboard .header-right .menu-toggle svg {
  width: 40px;
  color: #45F882;
}

.bs-notifications {
  font-family: "Montserrat", sans-serif;
}
.bs-notifications .dropdown-toggle {
  border: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  position: relative;
}
.bs-notifications .dropdown-toggle svg {
  stroke: #45F882;
  width: 25px;
}
.bs-notifications .dropdown-toggle .notifications-icon.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #45F882;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  position: absolute;
  top: 0%;
  right: 15%;
  transform: translate(0, 0);
}
.bs-notifications .dropdown-toggle::after {
  display: none;
}
.bs-notifications svg {
  width: 20px;
}
.bs-notifications .notifications-wrap {
  border-radius: 16px;
  background: #000000;
  box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);
  width: 360px;
  min-width: 260px;
  padding: 0 0 5px 0 !important;
  overflow: hidden;
}
.bs-notifications .noifications-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}
.bs-notifications .noifications-content .notifications_head {
  background: #45F882;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  margin-bottom: 5px;
}
.bs-notifications .noifications-content .notifications_head > label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}
.bs-notifications .noifications-content .notifications_head > ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  align-items: center;
  gap: 20px;
}
.bs-notifications .noifications-content .notifications_head > ul li > div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.bs-notifications .noifications-content .notifications_head > ul li span {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.bs-notifications .noifications-content .notifications_head > ul li svg {
  color: #000;
}
.bs-notifications .noifications-content .notifications_head > ul li:hover span, .bs-notifications .noifications-content .notifications_head > ul li:active span {
  color: #fff;
}
.bs-notifications .noifications-content .notifications_head > ul li:hover svg, .bs-notifications .noifications-content .notifications_head > ul li:active svg {
  stroke: #fff;
}
.bs-notifications .noifications-content .notification-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 75vh;
  overflow-y: scroll;
}
.bs-notifications .noifications-content .notification-list li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px 0px 30px;
  position: relative;
}
.bs-notifications .noifications-content .notification-list li .divider {
  margin-top: 10px;
  width: 90%;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 190, 24, 0) 0%, #FFBE18 50%, rgba(255, 190, 24, 0) 100%);
}
.bs-notifications .noifications-content .notification-list li .massage-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.bs-notifications .noifications-content .notification-list li .massage-content h5 {
  color: #45F882;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
}
.bs-notifications .noifications-content .notification-list li .massage-content p {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
}
.bs-notifications .noifications-content .notification-list li .massage-content span {
  color: rgba(69, 248, 130, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.bs-notifications .noifications-content .notification-list li .indicator.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #45F882;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translate(50%, 0);
}
.bs-notifications .noifications-content .notification-list li > svg {
  cursor: pointer;
  flex-shrink: 0;
  width: 17px;
  z-index: 5;
  color: #ffffff;
}
.bs-notifications .noifications-content .notification-list li:hover {
  border-radius: 15px;
}
.bs-notifications .dropdown-menu {
  padding: 10px;
}
.bs-notifications .dropdown-menu li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bs-notifications .dropdown-menu li a {
  color: #525866;
  transition: 0.3s all;
}
.bs-notifications .dropdown-menu li a svg {
  width: 20px;
  margin-right: 10px;
}
.bs-notifications .dropdown-menu li a:hover {
  color: #45F882;
  transition: width 0.3;
}

.canvas-menu {
  background-color: rgba(0, 0, 0, 0.44);
  backdrop-filter: blur(5px);
  color: rgba(255, 255, 255, 0.6);
}
.canvas-menu .btn-close {
  display: none;
}
.canvas-menu .btn-close-menu {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.canvas-menu .btn-close-menu svg {
  width: 30px;
  color: #45F882;
}
.canvas-menu .canvas-menu-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  border: 1px solid #45F882;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 15px;
}
.canvas-menu .canvas-menu-list li .divider {
  margin-top: 10px;
  width: 90%;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 190, 24, 0) 0%, #FFBE18 50%, rgba(255, 190, 24, 0) 100%);
}
.canvas-menu .canvas-menu-list li a {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  width: 300px;
}
.canvas-menu .canvas-menu-list li a svg {
  width: 24px;
  color: #45F882;
}
.canvas-menu .canvas-menu-list li a span {
  margin-left: 10px;
}

.navbar-website {
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}
.navbar-website.sticky {
  background: #000;
  height: 65px;
}
.navbar-website .navbar-toggler .close {
  display: none;
}
.navbar-website .navbar-toggler .open {
  display: block;
}
.navbar-website .navbar-toggler[aria-expanded=true] .close {
  display: block;
}
.navbar-website .navbar-toggler[aria-expanded=true] .open {
  display: none;
}
.navbar-website .navbar {
  padding: 0;
  padding-top: 4PX;
}
.navbar-website .navbar-brand img {
  width: 155px;
  height: auto;
}
.navbar-website .navbar-nav .nav-item .nav-link {
  padding: 0.6rem 1.6rem;
  color: #FFF;
  text-transform: uppercase;
}
.navbar-website .navbar-nav .nav-item .nav-link:hover, .navbar-website .navbar-nav .nav-item .nav-link:focus {
  color: #FFBE18;
}
.navbar-website .navbar-nav .nav-item .nav-link.button {
  margin-left: 1rem;
  border: 1px solid #FFBE18;
  border-radius: 16px;
}

.mobile_dropdown_language {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-left: 16px;
  text-transform: uppercase;
}
.mobile_dropdown_language .header_user_dropdown .inner {
  padding: 20px;
}
.mobile_dropdown_language .header_user_dropdown .inner ul li img {
  width: 31px;
  margin-right: 10px;
}
.mobile_dropdown_language .header_user_dropdown .inner ul li:last-child {
  margin-bottom: 0px;
}
.mobile_dropdown_language button {
  background-color: transparent !important;
  border: 1px solid #FFBE18 !important;
  padding: 0.6rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 16px;
}
.mobile_dropdown_language button svg path {
  fill: white;
}
.mobile_dropdown_language button span {
  margin-left: 10px;
  color: white !important;
}
.mobile_dropdown_language button::after {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .mobile_dropdown_language {
    margin-top: 10px;
    margin-left: 0px;
  }
  .mobile_dropdown_language button {
    padding: 0.4rem 1.6rem;
    color: #FFF;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 100px;
  }
  .mobile_dropdown_language button svg path {
    fill: white;
  }
  .mobile_dropdown_language button span {
    margin-left: 10px;
    color: white !important;
  }
  .mobile_dropdown_language button::after {
    display: none !important;
  }
  .mobile_dropdown_language .header_user_dropdown.lang-dd {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(-7px, 30.5px, 0px) !important;
  }
  .mobile_dropdown_language .header_user_dropdown.lang-dd::before {
    left: 42% !important;
  }
  .button_center {
    text-align: center;
    margin-top: 10px;
  }
  .button_center .button {
    margin-left: 0px !important;
    margin: 0;
  }
  .button_center .button .nav-item:first-child {
    margin-top: 0px !important;
  }
  .navbar-website .navbar-brand img {
    width: 120px;
    margin-bottom: 5px;
  }
  .navbar-website #navbarSupportedContent {
    border-top: 1px solid #FFBE18;
  }
  .navbar-website .navbar-nav .nav-item .nav-link {
    padding: 0.4rem 1.6rem;
    color: #FFF;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
  }
  .navbar-website .navbar-nav .nav-item .nav-link:hover, .navbar-website .navbar-nav .nav-item .nav-link:focus {
    color: #FFBE18;
  }
  .navbar-website .navbar-nav .nav-item .nav-link.button {
    margin-left: 0rem;
    border: 1px solid #FFBE18;
    border-radius: 16px;
  }
  .navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    border: none;
    border-radius: none;
    transition: var(--bs-navbar-toggler-transition);
  }
  .navbar-website {
    background: linear-gradient(90.03deg, #000000 100%, #000000 100%) !important;
  }
}
#pages-footer {
  background-color: rgb(255, 255, 255) !important;
  color: #000000;
  padding-top: 2rem;
}
#pages-footer .footer-about img {
  width: 135px;
}
#pages-footer .footer-about p {
  color: #000000;
  font-size: 16px;
  margin-top: 2rem;
}
#pages-footer .footer-widget h3 {
  font-size: 18px;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
#pages-footer .footer-widget ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#pages-footer .footer-widget ul li {
  color: #000000;
  line-height: 1.6rem;
}
#pages-footer .footer-widget ul li a {
  color: #000000;
  font-size: 14px;
}
#pages-footer .footer-widget ul li a:hover {
  color: #FFBE18;
}
#pages-footer .copyright {
  padding: 1rem 0;
  text-align: center;
  color: #000000;
}

#home-footer {
  background-color: black;
  color: #FFF;
  padding-top: 2rem;
}
#home-footer .footer-about img {
  width: 135px;
}
#home-footer .footer-about p {
  color: #77838f;
  font-size: 16px;
  margin-top: 2rem;
}
#home-footer .footer-widget h3 {
  font-size: 18px;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
#home-footer .footer-widget ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#home-footer .footer-widget ul li {
  color: #77838f;
  line-height: 1.6rem;
}
#home-footer .footer-widget ul li a {
  color: #77838f;
  font-size: 14px;
}
#home-footer .footer-widget ul li a:hover {
  color: #FFF;
}
#home-footer .copyright {
  padding: 1rem 0;
  text-align: center;
  color: #77838f;
  font-size: 14px;
}

.home-footer-social {
  margin-top: 2rem;
}
.home-footer-social .side_social {
  justify-content: center;
}
.home-footer-social .side_social a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  color: #8c8c8c;
}
.home-footer-social .side_social a span {
  width: 24px;
  height: 24px;
  background-color: #8c8c8c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}
.home-footer-social .side_social a svg {
  fill: #181818;
}
.home-footer-social .side_social a:hover {
  color: #FFF;
  transform: translateY(-5px);
}

.footer-social {
  margin-top: 2rem;
}
.footer-social .side_social {
  justify-content: center;
}
.footer-social .side_social a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  color: #000000;
}
.footer-social .side_social a span {
  width: 24px;
  height: 24px;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}
.footer-social .side_social a svg {
  fill: #ffffff;
}
.footer-social .side_social a:hover {
  color: #616161;
  transform: translateY(-5px);
}

@media screen and (max-width: 767px) {
  footer .footer-widget h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
  footer .footer-widget ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  footer .footer-widget ul li {
    font-size: 14px !important;
    text-align: justify;
  }
  footer .footer-widget ul li a {
    color: #77838f;
    font-size: 14px !important;
  }
  footer .footer-widget ul li a:hover {
    color: #FFF;
  }
  footer .copyright {
    font-size: 12px;
    padding: 1rem 0;
    text-align: center;
    color: #77838f;
  }
}
#sidebar {
  position: fixed;
  left: 0;
  top: 132px;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  transition: all 0.2s ease 0s;
  z-index: 3;
  background-color: #FFF;
}
#sidebar:hover {
  width: 220px !important;
}
#sidebar:hover .side-nav li a span {
  opacity: 1;
  display: block;
}
#sidebar .side-nav-wrap {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, rgb(180, 164, 88) 0%, rgb(231, 232, 231) 100%);
  padding: 1px;
}
#sidebar .side-nav li {
  padding-bottom: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(80deg, rgb(180, 164, 88) 0%, rgb(231, 232, 231) 100%);
}
#sidebar .side-nav li:last-child {
  padding-bottom: 0;
}
#sidebar .side-nav li a {
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  background-color: #FAFAFA;
  padding-left: 25px;
}
#sidebar .side-nav li a.active, #sidebar .side-nav li a:hover {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
}
#sidebar .side-nav li a.active span, #sidebar .side-nav li a:hover span {
  color: #FFF;
}
#sidebar .side-nav li a.active svg path, #sidebar .side-nav li a:hover svg path {
  fill: #FFF;
}
#sidebar .side-nav li a.active i, #sidebar .side-nav li a:hover i {
  color: #FFF;
}
#sidebar .side-nav li a i {
  font-size: 1.4rem;
  width: 38px;
  color: #FFBE18;
}
#sidebar .side-nav li a svg {
  width: 18px;
  height: 18px;
  margin-right: 18px;
}
#sidebar .side-nav li a svg path {
  fill: #FFBE18;
}
#sidebar .side-nav li a span {
  opacity: 0;
  white-space: nowrap;
  color: #FFBE18;
  display: none;
  font-size: 13px;
}
#sidebar.sidebar-collapse {
  width: 72px;
}
#sidebar.sidebar-open {
  width: 220px;
}
#sidebar.sidebar-open .side-nav li a span {
  opacity: 1;
}
#sidebar .side-nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media screen and (max-width: 768px) {
  #sidebar {
    transform: translateX(-100%);
    top: 0;
    color: #FAFAFA;
  }
  #sidebar.sidebar-collapse {
    width: 238px !important;
  }
  #sidebar.sidebar-collapse .side-nav {
    padding: 0 0 0 0px;
  }
  #sidebar.sidebar-collapse .side-nav li {
    background: rgb(180, 164, 88);
    background: linear-gradient(180deg, #b4a458 0%, #e7e8e7 100%);
    margin-bottom: 0.2rem;
    height: 34px;
  }
  #sidebar.sidebar-collapse .side-nav li a {
    height: 35px;
  }
  #sidebar.sidebar-collapse .side-nav li a i {
    font-size: 1.2rem;
    margin-right: 0;
    width: 30px;
  }
  #sidebar.sidebar-collapse .side-nav li a span {
    display: block;
    font-size: 14px;
    opacity: 1;
  }
  #sidebar.sidebar-collapse .side-nav li a::after, #sidebar.sidebar-collapse .side-nav li a::before {
    display: none;
  }
  #sidebar.sidebar-collapse .side-nav li:first-child {
    height: 55px;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    background: white !important;
  }
  #sidebar.sidebar-collapse .side-nav li:first-child a {
    height: 54px;
    background: white !important;
  }
  #sidebar.active {
    transform: translateX(0);
    z-index: 5;
  }
}
.dashboard_footer {
  margin: 1rem 0 2rem 0;
}

.side_social {
  display: flex;
  align-items: center;
}
.side_social a {
  margin-right: 10px;
}
.side_social svg {
  width: 20px;
  fill: #FFBE18;
}

.dashboard_copyright {
  text-align: right;
  font-size: 12px;
  color: black;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .dashboard_footer {
    margin: 1rem 0 1rem 0;
  }
  .dashboard_footer_social {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .dashboard_copyright {
    text-align: center;
    margin-top: 10px;
  }
}
.auth_style_2 {
  min-height: 100vh;
  padding-top: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 40px;
  background-attachment: fixed;
  background-color: black;
  background-size: cover;
}
.auth_style_2 > img {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 256px;
  margin-bottom: 15px;
}
.auth_style_2 .auth_wraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.auth_style_2 .auth_wraper .auth_card {
  width: 500px;
  max-width: 500px;
  margin: auto;
  border-radius: 40px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, rgb(231, 232, 231) 0%, rgb(180, 164, 88) 100%) !important;
}
.auth_style_2 .auth_wraper .auth_card .auth_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF;
  border-radius: 25px 25px 0 0;
  padding: 15px 25px;
}
.auth_style_2 .auth_wraper .auth_card .auth_head h1 {
  font-size: 1.7rem;
  font-weight: 600;
}
.auth_style_2 .auth_wraper .auth_card .auth_head span {
  font-size: 10px;
  font-size: 11px;
}
.auth_style_2 .auth_wraper .auth_card .auth_body {
  padding: 20px 25px;
  background-color: #FAFAFA;
  border-radius: 0 0 25px 25px;
}
.auth_style_2 .auth_wraper .auth_card label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}
.auth_style_2 .auth_wraper .auth_card .inputwrap {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, rgb(231, 232, 231) 0%, rgb(180, 164, 88) 100%) !important;
  border-radius: 10px;
  padding: 2px;
  position: relative;
}
.auth_style_2 .auth_wraper .auth_card .inputwrap .form-control {
  border: none;
  border-radius: 8px;
  background-color: #FAFAFA;
  min-height: 40px;
}

.verify {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.verify .Character {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: #45F882;
  width: 40%;
  min-height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  color: #000000;
  text-shadow: 1px solid black;
  letter-spacing: 0.6rem;
  font-style: italic;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.verify .form-group {
  width: 60%;
}

.lock-icon {
  position: absolute;
  top: 0px;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50px;
  height: 100%;
  cursor: pointer;
}
.lock-icon::before {
  position: absolute;
  content: "";
  background: linear-gradient(0deg, rgb(231, 232, 231) 0%, rgb(180, 164, 88) 100%) !important;
  height: 38px;
  width: 2px;
  top: 3px;
  left: 0;
}

.top50 {
  top: 50px !important;
}

.uncompleted {
  font-size: 12px;
}

.completed {
  color: #FFBE18;
  font-size: 12px;
}

.error-message {
  color: red;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .auth_style_2 {
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 20px;
  }
  .auth_style_2 > img {
    width: 174px;
  }
  .auth_style_2 .auth_wraper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
  .auth_style_2 .auth_card {
    width: 100% !important;
    margin: 0px 15px;
  }
  .auth_style_2 .auth_card .inputwrap .form-control {
    font-size: 16px !important;
  }
  .auth_style_2 .auth_card .auth_body .verify .Character {
    font-size: 13px;
  }
}
.hero {
  position: relative;
}
.hero #banner {
  /* Add zoom-in and zoom-out effect SCSS */
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
.hero #banner .carousel-item.active img.bannerImg {
  animation: zoomInOut 10s infinite; /* Add infinite loop */
}
@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
.hero.page-title {
  background-color: #000000;
  min-height: 236px;
}
.hero.page-title .hero-content .title {
  padding-top: 8rem;
}
.hero.page-title .hero-content .title h1 {
  font-size: 34px;
}
.hero .hero-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.hero .hero-content .container {
  height: 100%;
}
.hero .hero-content .hero-logo {
  position: absolute;
  top: 3rem;
  right: 0;
}
.hero .hero-content .text-right-bottom {
  position: absolute;
  right: 9%;
  bottom: 18%;
  color: #FFF;
  font-size: 2rem;
}
.hero .hero-content .title {
  color: #FFF;
  padding-top: 15rem;
}
.hero .hero-content .title h1 {
  font-weight: bold;
  font-size: 52px;
}
.hero .hero-content .title h1 span.big {
  font-size: 42px;
}
.hero .hero-content .title h1 .vission {
  font-size: 34px;
}
.hero .hero-content .title p {
  max-width: 361px;
  font-size: 16px;
  color: #FFF;
  font-weight: 300;
  text-align: justify;
  margin-top: 200px;
}

.text-gradient {
  background: rgb(191, 154, 65);
  background: linear-gradient(180deg, #D9BE71 0%, #FBF6BA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 767px) {
  .hero {
    padding-top: 41px;
  }
  .hero.page-title {
    min-height: 130px;
  }
  .hero.page-title .hero-content .title {
    padding-top: 5.5rem;
  }
  .hero.page-title .hero-content .title h1 {
    font-size: 24px;
  }
  .hero > img {
    object-fit: cover;
  }
  .hero .hero-content .hero-logo {
    display: none;
  }
  .hero .hero-content .vr-girl {
    display: none;
  }
  .hero .hero-content .text-right-bottom {
    display: none;
  }
  .hero .hero-content .title {
    padding-top: 7rem;
  }
  .hero .hero-content .title h4 {
    font-size: 0.9rem;
  }
  .hero .hero-content .title h1 {
    font-size: 37px;
  }
  .hero .hero-content .title h1 span.big {
    font-size: 2rem;
  }
  .hero .hero-content .title h1 span {
    font-size: 17px;
  }
  .hero .hero-content .title h1 .vission {
    display: flex;
    text-transform: lowercase;
  }
  .hero .hero-content .title h1 .vission span {
    font-size: 23px;
  }
  .hero .hero-content .title h1 .vission span:last-child {
    padding-left: 5px;
  }
  .hero .hero-content .title p {
    font-size: 12px !important;
    margin-top: 175px;
    width: 248px;
    text-align: justify;
    display: none;
  }
  .hero .hero-content .arrow {
    display: none;
  }
}
.blog-section {
  padding: 3rem 0;
}

.blog-item {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
  overflow: hidden;
  margin-bottom: 1rem;
  position: relative;
}
.blog-item img {
  border-radius: 0.4rem;
  width: 100%;
}
.blog-item:hover .overlay {
  transform: translateY(0);
}
.blog-item .overlay {
  transition: all 0.5s ease;
  padding: 2rem;
  border-radius: 0.4rem;
  background-color: rgba(191, 154, 65, 0.8);
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}
.blog-item .overlay h3 {
  font-size: 1.2rem;
  color: #000;
}
.blog-item .overlay a {
  color: #000;
  font-weight: bold;
}
.blog-item:hover .thumb img {
  transform: scale(1.1);
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .blog-item {
    padding: 1.625rem;
  }
}
@media screen and (max-width: 767px) {
  .blog-item {
    margin-bottom: 2rem;
  }
  .blog-item .info h2 {
    font-size: 0.8rem;
  }
  .blog-item .info .button {
    font-size: 0.8rem;
  }
}
.team-section {
  min-height: 700px;
  background-size: cover;
  padding: 0;
}
.team-section .team-item {
  margin-bottom: 2rem;
}
.team-section .team-item img {
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.team-section .team-item .name {
  background-color: #000;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.dashboard-content {
  position: relative;
  padding: 30px 0;
  width: 100%;
}

.dashboard-icons {
  margin-top: 3rem;
  padding: 2rem;
  border-radius: 0.4rem;
}

.stat_box {
  border-radius: 20px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  padding: 2px;
}
.stat_box .head {
  display: flex;
  align-items: center;
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF;
  border-radius: 18px 18px 0 0;
  padding: 3px 25px;
}
.stat_box .head .head-box-title {
  margin: 10px 0;
  font-size: 16px;
}
.stat_box .head span {
  line-height: unset;
  font-size: 2.6rem;
}
.stat_box .head img {
  width: 38px;
  margin: 10px 0;
}
.stat_box .head h4 {
  margin: 0;
  padding-left: 20px;
  font-size: 20px;
}
.stat_box .head a {
  color: #FFF;
  display: block;
  padding-left: 20px;
}
.stat_box .head a p {
  margin: 0;
  font-size: 15px;
  color: #FFF;
  text-transform: uppercase;
}
.stat_box .head a h3 {
  margin: 0;
  font-size: 20px;
}
.stat_box .stat_box_body {
  padding: 25px;
  background-color: #FAFAFA;
  border-radius: 0 0 20px 20px;
}
.stat_box .stat_box_body h2 {
  font-size: 22px;
  font-weight: 600;
}
.stat_box .head_wallet {
  display: flex;
  align-items: center;
  align-items: center;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 0.6rem;
}
.stat_box .head_wallet .icon span {
  line-height: unset;
  color: #FFBE18;
  font-size: 2.6rem;
}
.stat_box .head_wallet .info {
  padding-left: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.stat_box .head_wallet .info p {
  margin: 0;
  font-size: 18px;
  color: #8E8E8E;
}
.stat_box .head_wallet .info h4 {
  margin: 0;
  font-size: 20px;
}
.stat_box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.stat_box ul.action_list li {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 0;
  flex-direction: row-reverse;
  position: relative;
}
.stat_box ul.action_list li::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  position: absolute;
  bottom: 0;
  left: 0;
}
.stat_box ul.action_list li.wallet_heading {
  background-color: #fbefd1;
  padding-left: 20px;
  flex-direction: row;
  padding-bottom: 6px;
}
.stat_box ul.action_list li .icon {
  margin-right: 20px;
  width: 62px;
}
.stat_box ul.action_list li .icon span {
  color: #FFBE18;
  font-size: 3rem;
}
.stat_box ul.action_list li .icon img {
  width: 54px;
}
.stat_box ul.action_list li .icon svg {
  width: 30px;
}
.stat_box ul.action_list li a {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #1F1F1F;
}
.stat_box ul.action_list li a p {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 6px;
  font-weight: 400;
  margin-bottom: 0;
}
.stat_box ul.action_list li .button {
  padding: 0;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.stat_box ul.action_list li .button span {
  line-height: unset;
}
.stat_box ul li {
  margin-bottom: 1.5rem;
}
.stat_box ul li p {
  margin: 0;
  font-size: 14px;
}
.stat_box ul li h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.ref_link {
  padding: 1px;
  border-radius: 8px;
}
.ref_link .inner {
  border-radius: 7px;
  background-color: #FAFAFA;
  padding: 12px;
}

.inline-button-radios {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.inline-button-radios li {
  flex: 1;
  margin: 0.5rem;
}
.inline-button-radios li .button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0.4rem;
  background-color: #B5A666;
  color: #EBDFAD;
}
.inline-button-radios li .button i {
  opacity: 0;
}
.inline-button-radios li .button.active {
  background-color: #FFF;
  color: #FFBE18;
}
.inline-button-radios li .button.active i {
  opacity: 1;
}

.bonus_total {
  position: relative;
}
.progress-bar {
  background-color: #333 !important;
}

.nav-tabs .nav-item .nav-link {
  color: #FFBE18;
}
.nav-tabs .nav-item .nav-link.active {
  color: #253250;
}

.join_date_boxes {
  display: flex;
  justify-content: space-between;
}
.join_date_boxes span {
  width: 55px;
  height: 50px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #FFF;
  background-color: #FFBE18;
}

.choose-photo label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 0.4rem;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  height: 300px;
  cursor: pointer;
}
.choose-photo label i {
  font-size: 5rem;
  color: rgba(0, 0, 0, 0.4);
}
.choose-photo label input {
  position: absolute;
  opacity: 0;
}

.wallet_item {
  display: flex;
  align-items: center;
  border-radius: 22px;
  padding: 1rem;
  margin-top: 1rem;
}
.wallet_item .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet_item .icon i {
  font-size: 2rem;
  color: #FFBE18;
}
.wallet_item .info {
  padding-left: 0.8rem;
}
.wallet_item .info h4 {
  margin: 0;
  font-size: 1.2rem;
}
.wallet_item .info p {
  margin: 0;
  font-size: 0.9rem;
}

.dash-box {
  display: flex;
  align-items: center;
}

.photo_name {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.03);
}

#dashboard-placement {
  position: absolute;
  right: 7px;
  top: 64px;
  z-index: 1;
  width: 300px;
}

.scroll_content_all {
  overflow-x: scroll;
  overflow-x: hidden;
}
.scroll_content_all:hover::-webkit-scrollbar-thumb {
  display: block;
}
.scroll_content_all:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.scroll_content_all::-webkit-scrollbar {
  width: 4px;
}
.scroll_content_all::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 50px;
  display: none;
}

.date-filters {
  display: flex;
  align-items: flex-end;
}
.date-filters .item {
  display: flex;
  align-items: flex-end;
}
.date-filters .item.last {
  margin-left: 50px;
}
.date-filters .item .input {
  width: 250px;
}
.date-filters .item input[type=date]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.date-filters .item .icon {
  margin-left: 10px;
}
.date-filters .item .icon button {
  width: 44px;
  height: 44px;
}
.date-filters .item .icon button span {
  min-width: 44px;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-filters .item .icon button span i {
  color: #FFBE18;
}
.date-filters > .button {
  height: 44px;
  font-weight: normal;
  margin-left: 20px;
}

.filters-right {
  display: flex;
  justify-content: flex-end;
}
.filters-right > .button {
  height: 44px;
  margin-right: 20px;
  font-weight: normal;
}

#overlaymenu {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 2;
  display: none;
}
#overlaymenu.active {
  display: block;
}

.statistical-card {
  padding-bottom: 0 !important;
  height: 170px;
}

@media screen and (max-width: 767px) {
  #ResumeContainer {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
  .PDFPageOne {
    margin-bottom: 25px;
  }
  .PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
  }
  .e-header {
    margin: 10px 0 !important;
  }
  .e-header h4 {
    font-size: 17px;
    padding: 0 !important;
  }
  #overlaymenu {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 3;
    display: none;
    top: 0;
    left: 0;
  }
  #overlaymenu.active {
    display: block;
  }
  .dashboard-content {
    padding: 40px 0 30px 0;
  }
  .date-filters .item .input {
    width: 272px;
  }
  .filters-right {
    justify-content: center;
    margin-bottom: 10px;
  }
  .statistical-card {
    height: 170px;
    padding-bottom: 20px !important;
  }
  .stat_box {
    border-radius: 20px;
    background: rgb(180, 164, 88);
    background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
    padding: 2px;
  }
  .stat_box .head {
    display: flex;
    align-items: center;
    background: rgb(200, 158, 72);
    background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
    color: #FFF;
    border-radius: 18px 18px 0 0;
    padding: 3px 25px;
  }
  .stat_box .head .head-box-title {
    margin: 10px 0;
    font-size: 16px;
  }
  .stat_box .head span {
    line-height: unset;
    font-size: 2.6rem;
  }
  .stat_box .head img {
    width: 30px;
    margin: 10px 0;
  }
  .stat_box .head h4 {
    margin: 0;
    padding-left: 20px;
    font-size: 19px;
  }
  .stat_box .head a {
    color: #FFF;
    display: block;
    padding-left: 20px;
  }
  .stat_box .head a p {
    margin: 0;
    font-size: 15px;
    color: #FFF;
    text-transform: uppercase;
  }
  .stat_box .head a h3 {
    margin: 0;
    font-size: 17px;
  }
  .stat_box .stat_box_body {
    padding: 15px;
    background-color: #FAFAFA;
    border-radius: 0 0 20px 20px;
  }
  .stat_box .stat_box_body h2 {
    font-size: 17px;
    font-weight: 600;
  }
  .stat_box .head_wallet {
    display: flex;
    align-items: center;
    align-items: center;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 0.6rem;
  }
  .stat_box .head_wallet .icon span {
    line-height: unset;
    color: #FFBE18;
    font-size: 2.6rem;
  }
  .stat_box .head_wallet .info {
    padding-left: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .stat_box .head_wallet .info p {
    margin: 0;
    font-size: 18px;
    color: #8E8E8E;
  }
  .stat_box .head_wallet .info h4 {
    margin: 0;
    font-size: 20px;
  }
  .stat_box ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .stat_box ul.action_list li {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin-bottom: 0;
    flex-direction: row-reverse;
    position: relative;
  }
  .stat_box ul.action_list li::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: rgb(180, 164, 88);
    background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .stat_box ul.action_list li.wallet_heading {
    background-color: #fbefd1;
    padding-left: 20px;
    flex-direction: row;
    padding-bottom: 6px;
  }
  .stat_box ul.action_list li .icon {
    margin-right: 0px;
    width: 30px;
  }
  .stat_box ul.action_list li .icon span {
    color: #FFBE18;
    font-size: 3rem;
  }
  .stat_box ul.action_list li .icon img {
    width: 34px;
  }
  .stat_box ul.action_list li .icon svg {
    width: 30px;
  }
  .stat_box ul.action_list li a {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #1F1F1F;
  }
  .stat_box ul.action_list li a p {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 6px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .stat_box ul.action_list li .button {
    padding: 0;
    background-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
  .stat_box ul.action_list li .button span {
    line-height: unset;
  }
  .stat_box ul li {
    margin-bottom: 1.5rem;
  }
  .stat_box ul li p {
    margin: 0;
    font-size: 14px;
  }
  .stat_box ul li h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  .scroll_content_all {
    overflow-x: scroll;
    overflow-y: scroll;
  }
  .ref_link .inner {
    font-size: 13px;
  }
}
.package-item-new {
  margin-bottom: 2rem;
}
.package-item-new .package-box h2 {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  font-size: 22px;
}
.package-item-new .package-box p {
  font-weight: bold;
  color: #87888A;
  font-size: 22px;
}
.package-item-new .action {
  padding-top: 18px;
  position: relative;
}
.package-item-new .action::after {
  content: "";
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 0;
  left: -42px;
}

.package-item {
  position: relative;
}
.package-item.fdays {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.package-item .info {
  display: flex;
  align-items: center;
}
.package-item .info .icon img {
  width: 100px;
}
.package-item .info .icon .remaining_days {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  font-size: 36px;
  top: 33px;
}
.package-item .action .button {
  min-width: 200px;
}
.package-item .package-box {
  position: relative;
  border-radius: 10px;
  margin: 0 0 0 2rem;
}
.package-item .package-box h2 {
  font-size: 1.7rem;
}
.package-item .package-box .stacked_buttons {
  display: flex;
  justify-content: center;
}
.package-item .package-box .stacked_buttons .button.button_repurchase {
  margin-right: -10px;
  position: relative;
}
.package-item .package-box .stacked_buttons .button.cashback {
  background-color: #DEDEDE;
  color: #ABABAB;
}
.package-item .package-box .button:hover {
  opacity: 0.8;
}
.package-item .package-box .button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.package-item .package-box h4 {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.3);
  margin: 0;
}
.package-item .package-box h1 {
  margin: 0;
  font-weight: bold;
  font-size: 55px;
}
.package-item .package-box ul {
  margin: 0 0 0.6rem 0;
  padding: 0;
  list-style-type: none;
}
.package-item .package-box ul li {
  font-size: 0.8rem;
  color: #8F9198;
}

.packages-row .col-md-4:nth-child(1) .bg-box, .packages-row .col-md-4:nth-child(9) .bg-box {
  background-color: rgba(255, 244, 229, 0.8);
}
.packages-row .col-md-4:nth-child(1) .bg-box .bar, .packages-row .col-md-4:nth-child(9) .bg-box .bar {
  background-color: #8D6224;
}
.packages-row .col-md-4:nth-child(1) .package-box h2, .packages-row .col-md-4:nth-child(9) .package-box h2 {
  color: #8D6224;
}
.packages-row .col-md-4:nth-child(1) .package-box .button, .packages-row .col-md-4:nth-child(9) .package-box .button {
  background-color: #8D6224;
  color: #FFF;
}
.packages-row .col-md-4:nth-child(2) .bg-box, .packages-row .col-md-4:nth-child(5) .bg-box {
  background-color: rgba(236, 235, 235, 0.8);
}
.packages-row .col-md-4:nth-child(2) .bg-box .bar, .packages-row .col-md-4:nth-child(5) .bg-box .bar {
  background-color: #A7A2A2;
}
.packages-row .col-md-4:nth-child(2) .package-box h2, .packages-row .col-md-4:nth-child(5) .package-box h2 {
  color: #A7A2A2;
}
.packages-row .col-md-4:nth-child(2) .package-box .button, .packages-row .col-md-4:nth-child(5) .package-box .button {
  background-color: #A7A2A2;
  color: #FFF;
}
.packages-row .col-md-4:nth-child(3) .bg-box, .packages-row .col-md-4:nth-child(8) .bg-box {
  background-color: rgba(236, 196, 64, 0.1);
}
.packages-row .col-md-4:nth-child(3) .bg-box .bar, .packages-row .col-md-4:nth-child(8) .bg-box .bar {
  background-color: #ECC440;
}
.packages-row .col-md-4:nth-child(3) .package-box h2, .packages-row .col-md-4:nth-child(8) .package-box h2 {
  color: #ECC440;
}
.packages-row .col-md-4:nth-child(3) .package-box .button, .packages-row .col-md-4:nth-child(8) .package-box .button {
  background-color: #ECC440;
  color: #FFF;
}
.packages-row .col-md-4:nth-child(4) .bg-box {
  background-color: rgba(223, 242, 254, 0.1);
}
.packages-row .col-md-4:nth-child(4) .bg-box .bar {
  background-color: #016099;
}
.packages-row .col-md-4:nth-child(4) .package-box h2 {
  color: #016099;
}
.packages-row .col-md-4:nth-child(4) .package-box .button {
  background-color: #016099;
  color: #FFF;
}
.packages-row .col-md-4:nth-child(6) .bg-box {
  background-color: rgba(75, 146, 155, 0.1);
}
.packages-row .col-md-4:nth-child(6) .bg-box .bar {
  background-color: #4B929B;
}
.packages-row .col-md-4:nth-child(6) .package-box h2 {
  color: #4B929B;
}
.packages-row .col-md-4:nth-child(6) .package-box .button {
  background-color: #4B929B;
  color: #FFF;
}
.packages-row .col-md-4:nth-child(7) .bg-box {
  background-color: rgba(77, 203, 249, 0.1);
}
.packages-row .col-md-4:nth-child(7) .bg-box .bar {
  background-color: #4DCBF9;
}
.packages-row .col-md-4:nth-child(7) .package-box h2 {
  color: #4DCBF9;
}
.packages-row .col-md-4:nth-child(7) .package-box .button {
  background-color: #4DCBF9;
  color: #FFF;
}

.pp_box {
  background-color: #FAFAFA;
  border-radius: 18px;
}
.pp_box.duration ul li {
  justify-content: center;
}
.pp_box.duration ul li p {
  margin: 0;
  text-align: center;
  padding: 10px;
  font-size: 18px;
}
.pp_box.duration ul li p svg {
  margin-right: 8px;
}
.pp_box.duration ul li button {
  margin: 10px;
}
.pp_box .package_name {
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.pp_box .package_name .photo img {
  max-width: 130px;
}
.pp_box .package_name .info {
  padding-top: 1rem;
}
.pp_box .package_name .info h4 {
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #000;
}
.pp_box .package_name .info h3 {
  color: #8D8D8D;
  font-weight: bold;
}
.pp_box ul {
  margin: 0;
  padding: 0 0 1rem 0;
  list-style-type: none;
}
.pp_box ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
  position: relative;
}
.pp_box ul li::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  position: absolute;
  bottom: 0;
  left: 0;
}
.pp_box ul li:last-child::after {
  display: none;
}

.box_head {
  background-color: #FFF;
  padding: 0.8rem;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  font-weight: bold;
  color: #8E9F9F;
}

.box_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.box_list li {
  background-color: #F5F5F5;
  padding: 0.8rem;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  font-weight: 400;
  color: #8E9F9F;
  margin-top: 10px;
  min-height: 50px;
}

.package-item-v {
  position: relative;
  margin-bottom: 2rem;
}
.package-item-v .bg-box {
  width: 100%;
  height: 200px;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(213, 192, 101, 0.2);
}
.package-item-v .bg-box .bar {
  width: 90%;
  height: 12px;
  position: absolute;
  left: 5%;
  top: 0;
  background-color: #FFBE18;
}
.package-item-v .icon {
  position: relative;
  padding-top: 23px;
}
.package-item-v .icon img {
  width: 130px;
}
.package-item-v .icon .remaining_days {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  font-size: 36px;
  top: 33px;
}
.package-item-v .package-box {
  width: 90%;
  min-height: 204px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  text-align: center;
  padding: 1rem;
  position: relative;
  border-radius: 10px;
  margin: auto;
  background-color: #FFF;
}
.package-item-v .package-box .stacked_buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.package-item-v .package-box .stacked_buttons .button {
  padding: 10px 10px;
  font-size: 13px;
}
.package-item-v .package-box .stacked_buttons .button.button_repurchase {
  margin-right: -10px;
  position: relative;
  background-color: #FFBE18;
}
.package-item-v .package-box .stacked_buttons .button.cashback {
  background-color: #DEDEDE;
  color: #ABABAB;
}
.package-item-v .package-box .button {
  border-radius: 0;
}
.package-item-v .package-box .button:hover {
  opacity: 0.8;
}
.package-item-v .package-box .button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  background-color: transparent;
}
.package-item-v .package-box h4 {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.3);
  margin: 0;
}
.package-item-v .package-box h1 {
  margin: 0;
  font-weight: bold;
  font-size: 55px;
  color: #FFBE18;
}
.package-item-v .package-box ul {
  margin: 0 0 0.6rem 0;
  padding: 0;
  list-style-type: none;
}
.package-item-v .package-box ul li {
  font-size: 0.8rem;
  color: #8F9198;
}

.package_detail {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  padding-left: 100px;
  margin-left: 100px;
}

.fourty_duration h2 {
  color: #4FC4C4;
  text-align: center;
}
.fourty_duration .duration-item {
  background-color: #FFF;
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  padding: 10px 20px;
  margin-bottom: 1rem;
}
.fourty_duration .duration-item .row {
  align-items: center;
}
.fourty_duration .duration-item.brown .count {
  background-color: #D2C383;
}
.fourty_duration .duration-item.brown .d-button {
  color: #D2C383;
  background-color: rgba(210, 195, 131, 0.2);
}
.fourty_duration .duration-item.blue .count {
  background-color: #4FC4C4;
}
.fourty_duration .duration-item.blue .d-button {
  color: #4FC4C4;
  background-color: rgba(79, 196, 196, 0.2);
}
.fourty_duration .duration-item.green .count {
  background-color: #66D545;
}
.fourty_duration .duration-item.green .d-button {
  color: #66D545;
  background-color: rgba(102, 213, 69, 0.2);
}
.fourty_duration .duration-item.cyan .count {
  background-color: #28D7A6;
}
.fourty_duration .duration-item.cyan .d-button {
  color: #28D7A6;
  background-color: rgba(40, 215, 166, 0.2);
}
.fourty_duration .duration-item .count {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #FFF;
  font-weight: bold;
  font-size: 1.4rem;
}
.fourty_duration .duration-item p {
  margin: 0;
}
.fourty_duration .duration-item .d-button {
  display: inline-block;
  background-color: #FAF8F1;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 60px;
}

.ls-item {
  margin-bottom: 1rem;
}
.ls-item .row {
  align-items: center;
}
.ls-item:nth-child(even) .ls-info {
  background-color: rgba(210, 195, 131, 0.2);
}
.ls-item:nth-child(odd) .ls-info {
  background-color: rgba(79, 196, 196, 0.2);
}
.ls-item .ls-label {
  background-color: #FFF;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}
.ls-item .ls-info {
  padding: 10px 10px 10px 30px;
  border-radius: 4px;
}

.d-dates {
  margin: 0 0 1rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}
.d-dates li {
  background-color: #F5F5F5;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  color: #8E9F9F;
  margin: 0 20px;
}
.d-dates li span {
  font-size: 0.8rem;
}
.d-dates li p {
  font-size: 1.3rem;
  margin: 0 0 0 6px;
  font-weight: bold;
}
.d-dates li p sup {
  font-weight: normal;
}

.summary-box {
  border-radius: 18px;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.summary-box .icon {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  align-self: stretch;
  width: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px 0 0 18px;
  flex-shrink: 0;
}
.summary-box .icon i {
  font-size: 40px;
  color: #FFF;
}
.summary-box .icon img {
  width: 40px;
}
.summary-box .info {
  padding: 30px 20px;
}
.summary-box .info p {
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  color: rgb(142, 142, 142);
  margin-bottom: 10px;
  font-weight: bold;
}
.summary-box .info h3 {
  margin: 0;
  color: rgb(31, 31, 31);
  font-size: 18px;
  font-weight: 400;
}

.pp_modal_item {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  padding: 2px;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  overflow: hidden;
}
.pp_modal_item .inner {
  display: flex;
  justify-content: space-between;
  background-color: #FAFAFA;
  width: 100%;
  padding: 10px 10px;
  border-radius: 10px;
}
.pp_modal_item .inner .pp_modal_label {
  color: #000;
  font-weight: bold;
  font-size: 13px;
}
.pp_modal_item .inner .pp_modal_label .form-check-label {
  font-weight: normal;
  color: gray;
}
.pp_modal_item .inner .pp_modal_value {
  color: #8D8D8D;
  font-weight: bold;
  font-size: 14px;
}

.package-image img {
  width: 100%;
  border-radius: 16px;
  height: 500px;
}

.package-commodity .card-package-image {
  border-radius: 12px;
  width: 200px;
  margin: auto;
}
.package-commodity .card-package-image .card-inner {
  padding: 0;
  border-radius: 10px;
}
.package-commodity .card-package-image .card-inner > img {
  width: 100%;
  border-radius: 10px;
  border: 6px solid #FAFAFA;
}
.package-commodity h4 {
  color: #8D8D8D;
}
.package-commodity h5 {
  color: #8D8D8D;
}
.package-commodity button {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .pp_box.duration ul li p {
    font-size: 14px;
  }
  .pp_box .package_name .info h4 {
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
  }
  .pp_box ul li {
    font-size: 14px;
  }
  .pp_modal_item .inner {
    padding: 4px 10px;
    align-items: center;
  }
  .pp_modal_item .inner .pp_modal_label {
    font-weight: 500;
  }
  .pp_modal_item .inner .pp_modal_value {
    font-weight: 500;
  }
  .package-item-new {
    margin-bottom: 2rem;
  }
  .package-item-new .card-gradient {
    max-width: 80%;
    margin: auto;
  }
  .package-item-new .package-box h2 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    font-size: 18px;
  }
  .package-item-new .package-box p {
    font-weight: bold;
    color: #87888A;
    font-size: 18px;
  }
  .package-item-new .action {
    padding-top: 18px;
    padding-bottom: 18px;
    position: relative;
  }
  .package-item-new .action::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
  }
}
.treeview {
  padding-bottom: 80px;
}
.treeview .top_member {
  max-width: 680px;
  margin: auto;
}
.treeview .top_member .tp_top_options {
  margin: 0 0 10px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.treeview .top_member .tp_top_options li {
  font-size: 13px;
}
.treeview .top_member .tp_top_options li span {
  font-weight: 300;
}
.treeview .top_member .tp_top_options li strong {
  color: #FFBE18;
}
.treeview .top_member .tm_center {
  text-align: center;
}
.treeview .top_member .tm_center .img-wrap {
  width: 60px;
  height: 60px;
  margin: 0 auto 3px auto;
}
.treeview .top_member .tm_center .img-wrap > img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
}
.treeview .top_member .tm_center h5 {
  font-size: 13px;
  margin: 0 0 5px 0;
  font-weight: 600;
  color: #000;
}
.treeview .top_member .tm_center h3 {
  font-size: 22px;
  margin: 0;
  font-weight: bold;
}
.treeview .top_member .tm_center p {
  font-size: 13px;
  margin: 12px 0 0 0;
}
.treeview .top_member .tm_side_box .tm_side_box_option {
  padding: 14px 0;
  text-align: center;
  position: relative;
}
.treeview .top_member .tm_side_box .tm_side_box_option::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  width: 100%;
  height: 2px;
  bottom: 0;
}
.treeview .top_member .tm_side_box .tm_side_box_option.b-0 {
  border-bottom: none;
  padding-bottom: 0;
}
.treeview .top_member .tm_side_box .tm_side_box_option.b-0::after {
  display: none;
}
.treeview .top_member .tm_side_box .tm_side_box_option p {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
}
.treeview .top_member .tm_side_box .tm_side_box_option h4 {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}

.tree_border {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tree_border.three_members .bar {
  width: 67%;
}
.tree_border.three_members .bar .caret_left {
  left: -7px;
}
.tree_border.three_members .bar .caret_right {
  right: -6.5px;
}
.tree_border > span {
  background: #FFBE18;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.tree_border .v_line {
  border-left: 1px dashed rgb(31, 31, 31);
  height: 18px;
}
.tree_border .bar {
  border-top: 1px dashed rgb(31, 31, 31);
  border-left: 1px dashed rgb(31, 31, 31);
  border-right: 1px dashed rgb(31, 31, 31);
  border-radius: 5px 5px 0px 0px;
  width: 50%;
  height: 18px;
  position: relative;
}
.tree_border .bar .caret_center {
  position: absolute;
  left: 0;
  bottom: -14px;
  right: 0;
  margin: auto;
  text-align: center;
}
.tree_border .bar .caret_left {
  position: absolute;
  left: -7px;
  bottom: -14px;
}
.tree_border .bar .caret_right {
  position: absolute;
  right: -7px;
  bottom: -14px;
}

.signup_link {
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFBE18;
  margin-top: 20px;
}

.node {
  margin: 10px auto 0 auto;
  text-align: center;
  height: 130px;
}
.node.disabled .avatar {
  opacity: 0.5;
  cursor: not-allowed;
}
.node .avatar {
  width: 30px;
  height: 30px;
  margin: auto;
  cursor: pointer;
}
.node .avatar img {
  width: 100%;
}
.node .lr_members {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.node .lr_members .left {
  margin-right: 10px;
}
.node .lr_members .right {
  margin-left: 10px;
}
.node .lr_members .left,
.node .lr_members .right {
  display: flex;
  flex-direction: column;
  line-height: 18px;
}
.node .lr_members span {
  font-size: 11px;
}

.tree_search_wrap {
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  z-index: 1;
  background-color: #f7f9fc;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.tree_search_wrap li {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tree_search_wrap li .photo {
  width: 40px;
}
.tree_search_wrap li .photo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.tree_search_wrap li .info {
  padding-left: 10px;
}
.tree_search_wrap li .info h4 {
  margin: 0;
  font-size: 1rem;
}
.tree_search_wrap li .info p {
  margin: 0;
  font-size: 0.8rem;
}
.tree_search_wrap li:hover {
  background-color: #FFBE18;
}

.tree_top_filters {
  margin: -4px;
  padding: 0;
  display: flex;
  list-style-type: none;
}
.tree_top_filters li {
  margin-right: 1rem;
}
.tree_top_filters li a,
.tree_top_filters li button {
  color: rgb(0, 0, 0);
  font-size: 15px;
  padding: 18px 16px;
  border-radius: 12px 12px;
  border: none;
  font-weight: 500;
  display: block;
}
.tree_top_filters li a.active,
.tree_top_filters li button.active {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF;
}

.tree_bottom_filters {
  position: fixed;
  bottom: 0px;
  left: 31px;
  right: 0px;
  width: 96%;
  z-index: 2;
}
.tree_bottom_filters .filters_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tree_bottom_filters .filters_wrap .fb_buttons {
  display: flex;
  position: relative;
}
.tree_bottom_filters .filters_wrap .fb_buttons .button {
  margin: 0 10px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.tree_bottom_filters .filters_wrap .fb_buttons .button span {
  padding: 9px 3.3rem;
  font-size: 14px;
}
.tree_bottom_filters .filters_wrap .fb_buttons .button.button-placement span {
  padding: 8px 1.3rem;
  line-height: 1.3;
}
.tree_bottom_filters .filters_wrap .fb_buttons .default_placement_box {
  position: absolute;
  right: 0;
  top: -205px;
  width: 250px;
  padding: 2px;
  border-radius: 12px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
}
.tree_bottom_filters .filters_wrap .fb_buttons .default_placement_box .card {
  border-radius: 10px;
  background-color: #FAFAFA;
  padding: 17px;
}
.tree_bottom_filters .filters_wrap .tree_arrows .button {
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.tree_bottom_filters .filters_wrap .tree_arrows .button span {
  font-size: 1.5rem;
}

.rightborder {
  position: relative;
}
.rightborder::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  background: rgb(180, 164, 88);
  background: linear-gradient(0deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  width: 2px;
  height: 100%;
  bottom: 0px;
}

.tree_volume_wrap {
  max-width: 680px;
  margin: auto;
}
.tree_volume_wrap .tree_volume_item {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  border-radius: 12px;
  padding: 2px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  margin-bottom: 1rem;
}
.tree_volume_wrap .tree_volume_item > div {
  background-color: #FAFAFA;
  border-radius: 8px;
}
.tree_volume_wrap .tree_volume_item.generation.active .date_box {
  background-color: #e9e9e9;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
}
.tree_volume_wrap .tree_volume_item.generation.active .date_box h3 {
  color: #FFBE18;
}
.tree_volume_wrap .tree_volume_item.generation.active .date_box p {
  color: #1F1F1F;
}
.tree_volume_wrap .tree_volume_item.generation .date_box {
  background-color: #e9e9e9;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  color: black;
  justify-content: space-around;
}
.tree_volume_wrap .tree_volume_item.generation .date_box h3 {
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}
.tree_volume_wrap .tree_volume_item.generation .date_box p {
  margin: 0;
  color: black;
}
.tree_volume_wrap .tree_volume_item .date_box {
  background-color: #e9e9e9;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  color: #FFBE18;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 8px;
}
.tree_volume_wrap .tree_volume_item h4 {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 8px;
  margin-top: 10px;
}
.tree_volume_wrap .tree_volume_item .balance_wrap {
  display: flex;
  justify-content: space-around;
}
.tree_volume_wrap .tree_volume_item .balance_wrap .b_option {
  text-align: center;
}
.tree_volume_wrap .tree_volume_item .balance_wrap .b_option span {
  font-weight: normal;
  font-size: 12px;
  color: rgb(142, 142, 142);
  margin-bottom: 4px;
  opacity: 0.7;
}
.tree_volume_wrap .tree_volume_item .balance_wrap .b_option p {
  font-size: 15px;
  margin: 0;
  font-weight: 600;
}

.uni_level_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uni_level_item .left,
.uni_level_item .right {
  display: flex;
  align-items: center;
}
.uni_level_item .left .icon img,
.uni_level_item .right .icon img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.uni_level_item .left .info,
.uni_level_item .right .info {
  padding-left: 1.2rem;
}
.uni_level_item .left .info h4,
.uni_level_item .right .info h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
}
.uni_level_item .left .info p,
.uni_level_item .right .info p {
  margin: 6px 0 0 0;
  color: rgb(90, 90, 90);
  font-size: 12px;
}
.uni_level_item .center_border {
  width: 60%;
  height: 1px;
  border-bottom: 1px dashed;
}
.modal-tree-detail .modal-content {
  border-radius: 20px;
}
.modal-tree-detail .modal-dialog {
  max-width: 359px;
}
.modal-tree-detail .modal-info-wrap {
  position: relative;
}
.modal-tree-detail .modal-info-wrap .modal-profile-head {
  min-height: 120px;
  border-radius: 20px;
  position: relative;
}
.modal-tree-detail .modal-info-wrap .modal-profile-head > .photo {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -60px;
  border: 3px solid #E0D6C7;
  overflow: hidden;
}
.modal-tree-detail .modal-info-wrap .modal-profile-head > .photo img {
  width: 100%;
}
.modal-tree-detail .modal-info-wrap .modal-profile-head > .photo .name {
  background-color: rgba(255, 255, 255, 0.8);
  color: #6C5D56;
  text-align: center;
  position: absolute;
  bottom: -22px;
  width: 100%;
  padding-top: 6px;
  height: 70px;
}
.modal-tree-detail .modal-info-wrap .modal-profile-head > .photo .name span {
  max-width: 70px;
  display: block;
  margin: auto;
  line-height: 18px;
  text-align: center;
}
.modal-tree-detail .modal-info-wrap .modal-info-body {
  border-radius: 20px;
  padding: 4.4rem 2rem 2rem 2rem;
}
.modal-tree-detail .modal-info-wrap .modal-info-body h3 {
  text-align: center;
  margin-top: 1.2rem;
  color: #4FC4C4;
  margin-bottom: 0;
}
.modal-tree-detail .modal-info-wrap .modal-info-body .inv_balance {
  text-align: center;
  margin-top: 2rem;
}
.modal-tree-detail .modal-info-wrap .modal-info-body .inv_balance p {
  margin: 0;
}
.modal-tree-detail .modal-info-wrap .modal-info-body .inv_balance h4 {
  margin: 0;
  font-weight: bold;
  color: #FFF;
  font-size: 4rem;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.05);
}
.modal-tree-detail .modal-info-wrap .modal-info-body .country {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-tree-detail .modal-info-wrap .modal-info-body .country span {
  color: #4FC4C4;
  font-weight: bold;
  font-size: 1.2rem;
}
.modal-tree-detail .modal-info-wrap .modal-info-body .country img {
  width: 20px;
  margin-right: 10px;
}

.p-packages {
  padding: 1rem 4rem;
  border-radius: 0.4rem;
}
.p-packages .p-package-item {
  display: flex;
  align-items: center;
}
.p-packages .p-package-item img {
  width: 50px;
}
.p-packages .p-package-item h4 {
  margin: 0 0 0 0.6rem;
  font-size: 1.3rem;
}

.tree-indicators {
  margin: 30px 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}
.tree-indicators li {
  display: flex;
  align-items: center;
  margin: 10px 20px;
}
.tree-indicators li .indicator {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
}
.tree-indicators li .indicator.active {
  background-color: #FFBE18;
}
.tree-indicators li .indicator.paid {
  background-color: #212121;
}
.tree-indicators li .indicator.inactive {
  background-color: #838383;
}
.tree-indicators li .indicator.block {
  background-color: #C70F01;
}

@media screen and (max-width: 767px) {
  .rightborder::after {
    display: none;
  }
  .tree_volume_wrap .tree_volume_item {
    box-shadow: none;
  }
  .tree_volume_wrap .tree_volume_item.generation.active .date_box {
    box-shadow: none;
  }
  .tree_volume_wrap .tree_volume_item.generation .date_box {
    box-shadow: none;
  }
  .tree_volume_wrap .tree_volume_item .date_box {
    box-shadow: none;
  }
  .tree-mobile-wrap {
    overflow-x: scroll;
  }
  .tree-mobile-wrap .tree-mobile-container {
    width: 800px;
  }
  .node .lr_members {
    margin-top: 0;
  }
  .node .lr_members span {
    font-size: 7px;
    line-height: 13px;
  }
  .node .lr_members strong {
    font-size: 12px;
  }
  span.name {
    font-size: 10px;
  }
  .treeview .top_member .tm_center .img-wrap {
    width: 60px;
    height: 60px;
  }
  .treeview .top_member .tm_center h3 {
    font-size: 12px;
  }
  .treeview .top_member .tm_center h5 {
    font-size: 11px;
    font-weight: normal;
  }
  .treeview .top_member .tm_side_box .tm_side_box_option h4 {
    font-size: 13px;
  }
  .treeview .top_member .tp_top_options {
    display: block;
  }
  .treeview .top_member .tp_top_options li {
    display: flex;
    justify-content: space-between;
  }
  .tree_volume_wrap .tree_volume_item.generation .balance_wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 12px;
  }
  .tree_volume_wrap .tree_volume_item.generation .balance_wrap .b_option {
    text-align: center;
    margin: 5px 10px;
  }
  .tree_volume_wrap .tree_volume_item .balance_wrap {
    display: flex;
    justify-content: space-around;
  }
  .tree_volume_wrap .tree_volume_item .balance_wrap .b_option {
    margin-bottom: 13px;
  }
  .tree_volume_wrap .tree_volume_item .balance_wrap .b_option span {
    font-weight: normal;
    font-size: 12px;
    color: rgb(142, 142, 142);
    margin-bottom: 4px;
    opacity: 0.7;
  }
  .tree_volume_wrap .tree_volume_item .balance_wrap .b_option p {
    font-weight: 500;
  }
  .tree_top_filters {
    display: block;
    margin-bottom: 15px;
  }
  .tree_top_filters li {
    margin-right: 0.2rem;
    display: inline-block;
  }
  .tree_top_filters li a,
  .tree_top_filters li button {
    font-size: 13px;
    padding: 6px 10px;
  }
  .tree-indicators {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
  }
  .tree-indicators li {
    margin: 8px;
    font-size: 12px;
  }
  .tree-indicators li .indicator {
    width: 12px;
    height: 12px;
  }
  .tree_bottom_filters {
    position: relative;
    left: unset;
    width: 100%;
    z-index: 0;
  }
  .tree_bottom_filters .filters_wrap {
    display: block;
  }
  .tree_bottom_filters .filters_wrap .tree_arrows {
    text-align: center;
  }
  .tree_bottom_filters .filters_wrap .fb_buttons {
    flex-direction: column;
  }
  .tree_bottom_filters .filters_wrap .fb_buttons .button {
    margin: 10px 0;
  }
  .tree_bottom_filters .filters_wrap .fb_buttons .default_placement_box {
    top: 54px;
    left: 2%;
  }
}
.card-bg-addmoney {
  position: relative;
  padding: 10px;
}

.deposit-wrap .payment-modes {
  flex-direction: column;
  gap: 20px;
}
.deposit-wrap .payment-modes li {
  width: 100%;
}
.deposit-wrap .payment-modes li a {
  padding: 6px 23px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
}
.deposit-wrap .payment-modes li a .county-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.deposit-wrap .payment-modes li a .county-info img {
  margin: 0;
}
.deposit-wrap .payment-modes li a .price {
  text-align: end;
}
.deposit-wrap .payment-modes li a .price h6 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.deposit-wrap .payment-modes li a .price span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.5px;
}

.copy-ticket {
  border-radius: 16px;
  background: #F2F2F2;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copy-ticket .ticket-qr {
  height: auto;
  margin: 0 auto;
  padding: 20px;
  max-width: 204;
  width: 100%;
}
.copy-ticket ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.copy-ticket ul li {
  font-size: 12px;
  color: #525866;
}
.copy-ticket ul li span {
  font-weight: 400;
}
.copy-ticket ul li h6 {
  font-weight: 600;
}

.ticket-instruction h5 {
  color: #525866;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
}
.ticket-instruction ol {
  margin: 0;
}
.ticket-instruction ol li {
  color: #525866;
  font-size: 12px;
  line-height: 150%;
}

.upload-img {
  border-radius: 12px;
  border: 2px dashed rgba(255, 0, 43, 0.5);
  padding: 0;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 402px;
}
.upload-img svg {
  width: 20px;
}
.upload-img .upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(45, 45, 45);
  padding: 0;
  flex-direction: column;
}
.upload-img .upload-box input {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.upload-img .upload-box .thumnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.upload-img .upload-box .thumnail svg {
  margin-bottom: 10px;
}
.upload-img .upload-box .thumnail p {
  margin: 0;
}
.upload-img.white {
  background: #FFF;
  height: 220px;
}
.upload-img.pink {
  height: 456px;
  background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
}
.upload-img.blue {
  height: 220px;
  background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
}
.upload-img.gray {
  width: 480px;
  background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
}
.upload-img.gray {
  width: 480px;
  background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
}
.upload-img .delete {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 15px;
}
.upload-img .delete svg {
  stroke: #FFBE18;
}

.bt_box {
  border-radius: 0.4rem;
}
.bt_box h1 {
  text-align: center;
  color: #ffffff;
  font-size: 26px;
  margin-top: 30px;
}
.bt_box p {
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.bt_box p strong {
  color: #FFBE18;
  font-size: 20px;
  display: block;
  margin-top: 10px;
  font-weight: 600;
}
.bt_box h5 {
  font-size: 14px;
  text-align: center;
}
.bt_box .bt_input_group {
  margin-top: 2rem;
  position: relative;
}
.bt_box .bt_input_group .form-control {
  height: 60px;
  font-size: 1.3rem;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
  padding-left: 50px;
}
.bt_box .bt_input_group .form-control:focus {
  outline: none;
}
.bt_box .bt_input_group .icon-left {
  position: absolute;
  left: 10px;
  top: 16px;
}
.bt_box .bt_input_group .icon-left svg {
  width: 24px;
  height: 24px;
}
.bt_box .bt_input_group .icon-right {
  position: absolute;
  right: 10px;
  top: 16px;
}
.bt_box .bt_input_group .icon-right span {
  cursor: pointer;
}
.bt_box .bt_input_group .icon-right svg {
  width: 24px;
  height: 24px;
}
.bt_box .bt_input_group .button {
  display: inline-flex;
  align-items: center;
  color: #E61110;
  padding: 0 1rem;
  background-color: transparent;
}

.payment-modes {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: column;
}
.payment-modes li {
  width: 100%;
}
.payment-modes li a {
  display: block;
  border-radius: 16px;
  padding: 14px 16px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
}
.payment-modes li a.active {
  background: rgba(255, 190, 24, 0.1);
  border-color: #FFBE18;
}
.payment-modes li a span {
  margin-right: 10px;
}
.payment-modes li a img {
  width: 34px;
  margin-right: 10px;
}

.bt_amounts {
  margin: 1rem auto 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 8px;
}
.bt_amounts li {
  margin: 0 5px;
}
.bt_amounts li span {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFBE18;
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.5s ease;
  padding: 0 10px;
  white-space: nowrap;
  color: #000;
}
.bt_amounts li span:hover {
  border-color: #FFBE18;
}
.bt_amounts li span.clear {
  color: #dc3545;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.payment_modes {
  margin: 0 0 2rem 0;
  padding: 0;
  list-style-type: none;
}
.payment_modes li {
  width: 100%;
  margin: 8px;
}
.payment_modes li button {
  background-color: #02020C;
  color: #FFF;
  width: 100%;
}
.payment_modes li a {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  padding: 12px;
  gap: 16px;
  margin: 0;
}
.payment_modes li a img {
  margin: 0;
}

.currency_list {
  margin: 1rem 0 0 0;
  padding: 0;
  list-style-type: none;
}
.currency_list li {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.currency_list li img {
  width: 50px;
  margin-right: 20px;
}

.crypto_order .crypto_address {
  display: flex;
  align-items: center;
  justify-content: center;
}
.crypto_order .crypto_address .address-wrap {
  width: 100%;
}
.crypto_order .crypto_address .address {
  border-radius: 12px;
  background-color: #161E26;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #FFF;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.crypto_order .crypto_address .address > div svg {
  margin-right: 10px;
}
.crypto_order .crypto_address .address svg {
  width: 24px;
}
.crypto_order .crypto_address .address button {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 13px;
  right: 0;
  transform: translate(-13px, 0);
}
.crypto_order .crypto_address .address button svg {
  color: #FFBE18;
}
.crypto_order .crypto_address .button {
  padding: 0.4rem 0.8rem;
  margin-left: 12px;
  margin-top: 19px;
}
.crypto_order .crypto_address .button i {
  line-height: unset;
}

.deposit-info-box {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  align-items: center;
  position: relative;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.deposit-info-box.bg {
  background: rgba(0, 0, 0, 0.05);
}
.deposit-info-box > div {
  display: flex;
  align-items: center;
}
.deposit-info-box > div svg,
.deposit-info-box > div img {
  width: 24px;
  margin-right: 8px;
}
.deposit-info-box.small {
  padding: 11px 24px;
}
.deposit-info-box.small h4 {
  font-size: 14px;
}
.deposit-info-box.small p {
  font-size: 14px;
}
.deposit-info-box h4 {
  font-size: 13px;
  margin: 0;
  display: flex;
  align-items: center;
}
.deposit-info-box h4 img {
  width: 30px;
  margin-right: 10px;
}
.deposit-info-box p {
  font-size: 16px;
  color: #8D8D8D;
  margin: 0;
}

.crypto_order {
  padding-top: 40px;
}
.crypto_order h2 {
  text-align: center;
  font-size: 20px;
}
.crypto_order h2 span {
  color: #FFBE18;
}
.crypto_order .sub-heading {
  max-width: 400px;
  margin: auto;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
}

.toggle-exchange-currency {
  position: absolute;
  left: 15px;
  top: 54px;
  background-color: #fafafa;
  padding: 10px;
}
.toggle-exchange-currency h4 {
  cursor: pointer;
}

.instructions {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: #8D8D8D;
  font-size: 14px;
}
.instructions li {
  line-height: 20px;
}

/*
@media screen and (max-width: 767px) {
    .crypto_order {
        .crypto_address {
            display: flex;
            align-items: center;
            justify-content: center;


            .address-wrap {
                width: 100%;

                .card-gradient {
                    border-radius: 10px;
                    width: 269px;
                    overflow: hidden;

                    .card-inner {
                        border-radius: 8px;
                        width: 288px;
                        padding: 2px !important;
                    }
                }
            }

            .address {

                margin: 0.9rem;
                overflow: hidden;
                width: 95%;
            }

            .button {
                padding: .4rem .8rem;
                margin-left: 12px;
                margin-top: 19px;

                i {
                    line-height: unset;
                }
            }
        }
    }

    .deposit-info-box {
        // padding: 6px 10px;

        h4 {
            font-size: 13px;
        }

        p {
            font-size: 13px;
        }
    }

    .bt_box {
        h1 {
            font-size: 18px;
        }

        p {
            font-size: 12px;
            margin-bottom: 0;

            strong {
                font-size: 16px;
            }
        }

        h5 {
            font-size: 14px;
        }
    }

    .bt_amounts {


        li {
            span {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                height: 40px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
                font-weight: 500;
                transition: all 0.5s ease;
                padding: 0 10px;
                white-space: nowrap;
                color: #000;

            }

            &:last-child {
                margin-top: 0;
            }
        }
    }



    .payment_modes {
        align-items: center;

        li {
            .button {
                min-width: 200px;
            }
        }
    }

    .payment-modes {
        gap: 10px;

        li {
            width: 100%;

            a {
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border-radius: 16px;
                padding: 12px;
                gap: 16px;
                margin: 0;

                img {
                    margin: 0;
                }
            }
        }
    }

    .payment_modes {
        flex-direction: column;

        li {
            a {
                display: block;

                span {
                    margin-right: 10px;
                }
            }
        }
    }

}*/
.referrals_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.referrals_list li {
  width: 170px;
  flex-shrink: 0;
  padding: 16px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.03);
  margin: 14px;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.referrals_list li:hover {
  border-color: #4FC4C4;
}
.referrals_list li .ref_photo {
  position: relative;
  text-align: center;
}
.referrals_list li .ref_photo img {
  width: 100px;
  height: 100px;
  margin: auto;
  border: 3px solid #FFBE18;
  border-radius: 50%;
  cursor: pointer;
}
.referrals_list li .ref_photo img:hover {
  border-color: #4FC4C4;
}
.referrals_list li .ref_photo .verified {
  display: none;
}
.referrals_list li .ref_photo.Investor .verified {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.referrals_list li h4 {
  text-align: center;
  color: #FFBE18;
  margin: 10px;
  font-size: 1.3rem;
}
.referrals_list li .count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 4px;
  line-height: 28px;
  margin: 15px 0;
}
.referrals_list li .count span {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}
.referrals_list li .count p {
  font-weight: 700;
  font-size: 30px;
  color: #FFBE18;
  margin: 0;
}
.referrals_list li .button {
  width: 100%;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.ref_tree_income {
  padding: 20px;
  border-radius: 10px;
}
.ref_tree_income h3 {
  color: #4FC4C4;
  font-size: 2rem;
}

.circle_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.circle_list li {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
}
.circle_list li:nth-child(odd) span {
  background-color: #DBC27D;
}
.circle_list li:nth-child(odd) p {
  color: #DBC27D;
}
.circle_list li:nth-child(even) span {
  background-color: #cccccc;
}
.circle_list li:nth-child(even) p {
  color: #cccccc;
}
.circle_list li span {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  margin-right: 10px;
}
.circle_list li p {
  margin: 0;
  font-weight: 500;
}

.left_members {
  padding: 20px;
}

.right_members {
  padding: 20px;
}

.directs_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.directs_list.left {
  justify-content: flex-end;
}
.directs_list li {
  flex-shrink: 0;
  padding: 6px;
  background-color: #FFF;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.03);
  margin: 5px;
  flex-basis: 18%;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.directs_list li .ref_photo {
  position: relative;
  text-align: center;
}
.directs_list li .ref_photo img {
  width: 50px;
  height: 50px;
  margin: auto;
  border-radius: 50%;
  cursor: pointer;
}
.directs_list li .ref_photo img:hover {
  border-color: #4FC4C4;
}
.directs_list li .ref_photo .verified {
  display: none;
}
.directs_list li .ref_photo.Investor .verified {
  display: block;
  position: absolute;
  right: 15px;
  bottom: 0;
}
.directs_list li h4 {
  text-align: center;
  color: #1F1F1F;
  margin: 6px 0;
  font-size: 10px;
  font-weight: normal;
}
.directs_list li .count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 4px;
  line-height: 28px;
  margin: 15px 0;
}
.directs_list li .count span {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}
.directs_list li .count p {
  font-weight: 700;
  font-size: 30px;
  color: #FFBE18;
  margin: 0;
}
.directs_list li .button {
  width: 100%;
  text-transform: uppercase;
  font-size: 0.9rem;
}

@media screen and (max-width: 767px) {
  .directs_list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
  }
  .directs_list.left {
    justify-content: center;
  }
  .directs_list li {
    flex-shrink: 0;
    padding: 6px;
    background-color: #FFF;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.03);
    margin: 5px;
    flex-basis: 18%;
    border-radius: 8px;
    min-width: 124px;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  .directs_list li .ref_photo {
    position: relative;
    text-align: center;
  }
  .directs_list li .ref_photo img {
    width: 50px;
    height: 50px;
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
  }
  .directs_list li .ref_photo img:hover {
    border-color: #4FC4C4;
  }
  .directs_list li .ref_photo .verified {
    display: none;
  }
  .directs_list li .ref_photo.Investor .verified {
    display: block;
    position: absolute;
    right: 22px;
    top: 0;
  }
  .directs_list li h4 {
    text-align: center;
    color: #1F1F1F;
    margin: 10px;
    font-size: 13px;
    font-weight: normal;
  }
  .directs_list li .count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 4px;
    line-height: 28px;
    margin: 15px 0;
  }
  .directs_list li .count span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
  .directs_list li .count p {
    font-weight: 700;
    font-size: 30px;
    color: #FFBE18;
    margin: 0;
  }
  .directs_list li .button {
    width: 100%;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
}
body.theme-light .matrix_box .matrix_content {
  background-color: #F5F5F5;
}
body.theme-light .matrix_box .matrix_content .matrix_progress {
  background-color: #FFF;
}
body.theme-light .matrix_box .matrix_content ul li > p {
  color: rgba(0, 0, 0, 0.5);
}
body.theme-dark .matrix_box .matrix_content {
  background-color: #253250;
}

.matrix_box {
  border-radius: 12px;
}
.matrix_box.golden {
  background: rgb(195, 183, 135);
  background: linear-gradient(90deg, rgb(195, 183, 135) 0%, rgb(230, 217, 161) 100%);
}
.matrix_box.golden .matrix_content .matrix_status .matrix_status_box {
  background: rgb(195, 183, 135);
  background: linear-gradient(90deg, rgb(195, 183, 135) 0%, rgb(230, 217, 161) 100%);
}
.matrix_box.golden .matrix_content .matrix_status .matrix_status_box span {
  color: #FFBE18;
  border-color: #FFBE18;
}
.matrix_box.cyan {
  background: rgb(125, 191, 191);
  background: linear-gradient(90deg, rgb(125, 191, 191) 0%, rgb(159, 230, 230) 100%);
}
.matrix_box.cyan .matrix_content .matrix_status .matrix_status_box {
  background: rgb(125, 191, 191);
  background: linear-gradient(90deg, rgb(125, 191, 191) 0%, rgb(159, 230, 230) 100%);
}
.matrix_box.cyan .matrix_content .matrix_status .matrix_status_box span {
  color: #4FC4C4;
  border-color: #4FC4C4;
}
.matrix_box .matrix_title {
  text-align: center;
  color: #FFF;
  font-weight: bold;
  padding: 1.5rem;
  font-size: 1.4rem;
}
.matrix_box .matrix_content {
  border-radius: 12px;
  padding: 2rem 2rem 0 2rem;
}
.matrix_box .matrix_content .matrix_status {
  text-align: center;
}
.matrix_box .matrix_content .matrix_status h5 {
  color: #95A4A4;
  font-size: 1rem;
}
.matrix_box .matrix_content .matrix_status .matrix_status_box {
  padding: 0 0.3rem;
  border-radius: 12px;
}
.matrix_box .matrix_content .matrix_status .matrix_status_box span {
  display: block;
  background-color: #FFF0F5;
  font-weight: bold;
  font-size: 2rem;
  border-radius: 12px;
  padding: 0.7rem;
  border: 2px dashed transparent;
}
.matrix_box .matrix_content .matrix_progress {
  border-radius: 12px;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.matrix_box .matrix_content .matrix_progress h3 {
  margin: 0;
  font-size: 1.2rem;
}
.matrix_box .matrix_content .matrix_progress p {
  margin: 0;
  color: #95A4A4;
}
.matrix_box .matrix_content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.matrix_box .matrix_content ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.7rem 0;
}
.matrix_box .matrix_content ul li:last-child {
  border-bottom: none;
}
.matrix_box .matrix_content ul li .matrix_option_item {
  display: flex;
  justify-content: space-between;
}
.matrix_box .matrix_content ul li .matrix_option_item h4 {
  font-size: 1.2rem;
  color: #707070;
  margin: 0;
}
.matrix_box .matrix_content ul li > p {
  font-size: 0.9rem;
  line-height: 1.1rem;
}

.ranks_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ranks_list li {
  position: relative;
  margin-bottom: 2rem;
}
.ranks_list li .rank_content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px 20px 20px;
  color: #FFF;
}
.ranks_list li .rank_content .rank_title {
  padding: 0 30px;
  position: relative;
}
.ranks_list li .rank_content .rank_title > img {
  width: 120px;
  position: absolute;
  right: 20px;
  top: -36px;
}
.ranks_list li .rank_details {
  padding: 30px;
  border-radius: 12px;
  border: 1px dashed #FFF;
  background-color: rgba(191, 154, 65, 0.5);
}
.ranks_list li .rank_details .rank_option p {
  margin: 0 0 6px 0;
}

.requirement_box {
  position: relative;
  display: flex;
  align-items: center;
}
.requirement_box .left {
  width: 50px;
}
.requirement_box .right {
  width: 100px;
  text-align: center;
}
.requirement_box .right .a_label .wrap {
  display: flex;
  justify-content: space-between;
}

.available-balance {
  text-align: center;
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  padding: 12px 20px;
  margin: 10px 0;
  border-radius: 10px;
}
.available-balance span {
  font-weight: bold;
  color: #FFBE18;
}

.withdraw_methods {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.withdraw_methods li {
  background-color: #FFF;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 6px;
  margin: 20px;
  border: 3px solid transparent;
  cursor: pointer;
}
.withdraw_methods li.active {
  border-color: #4FC4C4;
}
.withdraw_methods li img {
  width: 60px;
  margin-bottom: 10px;
}

.pool_rewards > h1 {
  color: #FFBE18;
  font-weight: bold;
}

body.theme-light .pool_cards_wrap .pool_card {
  background-color: #FFF;
}

.pool_cards_wrap .pool_card {
  margin-top: 20px;
  border-radius: 10px;
  padding: 30px;
}
.pool_cards_wrap .pool_card.pool_vertical {
  display: flex;
  justify-content: space-between;
}
.pool_cards_wrap .pool_card.pool_vertical .pool {
  flex: 1;
}
.pool_cards_wrap .pool_card.pool_vertical .pool_left {
  padding: 0;
  margin-bottom: 1rem;
  min-height: 88px;
}
.pool_cards_wrap .pool_card.pool_vertical .pool_members ul li {
  width: 100px;
}
.pool_cards_wrap .pool_card .row {
  margin-bottom: 10px;
  align-items: center;
}
.pool_cards_wrap .pool_card .row.pool1 .pool_left h2 {
  color: #7BD3D3;
}
.pool_cards_wrap .pool_card .row.pool1 .pool_members ul li {
  background-color: #7BD3D3;
}
.pool_cards_wrap .pool_card .row.pool2 .pool_left h2 {
  color: #5EE1BC;
}
.pool_cards_wrap .pool_card .row.pool2 .pool_members ul li {
  background-color: #5EE1BC;
}
.pool_cards_wrap .pool_card .row.pool3 .pool_left h2 {
  color: #8CDF73;
}
.pool_cards_wrap .pool_card .row.pool3 .pool_members ul li {
  background-color: #8CDF73;
}
.pool_cards_wrap .pool_card .row.pool4 .pool_left h2 {
  color: #DDD2A2;
}
.pool_cards_wrap .pool_card .row.pool4 .pool_members ul li {
  background-color: #DDD2A2;
}
.pool_cards_wrap .pool_card .pool_left {
  padding: 16px;
}
.pool_cards_wrap .pool_card .pool_left h2 {
  font-weight: bold;
  margin: 0;
  font-size: 1.7rem;
}
.pool_cards_wrap .pool_card .pool_left p {
  margin: 0;
  font-size: 1rem;
}
.pool_cards_wrap .pool_card .pool_members {
  height: 100%;
}
.pool_cards_wrap .pool_card .pool_members.vertical ul {
  flex-direction: column;
}
.pool_cards_wrap .pool_card .pool_members.vertical ul li:first-child {
  border-radius: 6px 6px 0 0;
}
.pool_cards_wrap .pool_card .pool_members.vertical ul li:last-child {
  border-radius: 0 0 6px 6px;
}
.pool_cards_wrap .pool_card .pool_members ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.pool_cards_wrap .pool_card .pool_members ul li {
  padding: 5px;
  width: 8%;
  background-color: #7BD3D3;
}
.pool_cards_wrap .pool_card .pool_members ul li:first-child {
  border-radius: 6px 0 0 6px;
}
.pool_cards_wrap .pool_card .pool_members ul li:last-child {
  border-radius: 0 6px 6px 0;
}
.pool_cards_wrap .pool_card .pool_members ul li.pool_more div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.04);
  word-spacing: -6px;
}
.pool_cards_wrap .pool_card .pool_members ul li div {
  margin: 1%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: relative;
}
.pool_cards_wrap .pool_card .pool_members ul li span {
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  bottom: 4px;
  font-size: 0.6rem;
}
.pool_cards_wrap .pool_card .pool_members ul li img {
  width: 100%;
  border-radius: 50%;
}

.investus_pool_card h1 {
  color: #FFBE18;
  font-weight: bold;
}
.investus_pool_card .investus_pool_row {
  display: flex;
  justify-content: space-between;
}
.investus_pool_card .investus_pool_row .investus_pool_item {
  flex: 1;
  margin: 1%;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool1 .pool_head {
  background-color: #B1E5E5;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool1 .pool_body ul li img {
  border-color: #B1E5E5;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool2 .pool_head {
  background-color: #A0EDD8;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool2 .pool_body ul li img {
  border-color: #A0EDD8;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool3 .pool_head {
  background-color: #BCEDAD;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool3 .pool_body ul li img {
  border-color: #BCEDAD;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool4 .pool_head {
  background-color: #EBE5C8;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool4 .pool_body ul li img {
  border-color: #EBE5C8;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool5 .pool_head {
  background-color: #FDEAC7;
}
.investus_pool_card .investus_pool_row .investus_pool_item.pool5 .pool_body ul li img {
  border-color: #FDEAC7;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_head {
  min-height: 176px;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  text-align: center;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_head h4 {
  font-weight: bold;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_head p {
  margin: 0;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_body {
  background-color: #FFF;
  border-radius: 0 0 10px 10px;
  padding: 20px;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_body ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_body ul li {
  margin-bottom: 0.8rem;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_body ul li.pool_more div {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 1.3rem;
  background-color: rgba(0, 0, 0, 0.04);
  word-spacing: -6px;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_body ul li div {
  width: 90px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: relative;
  margin: auto;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_body ul li span {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 0;
  font-size: 1rem;
}
.investus_pool_card .investus_pool_row .investus_pool_item .pool_body ul li img {
  width: 100%;
  border-radius: 50%;
  border: 4px solid;
}

@media screen and (max-width: 767px) {
  .pool_cards_wrap .pool_card .pool_members ul li {
    width: 50%;
  }
  .investus_pool_card .investus_pool_row {
    display: block;
  }
}
.left_icon_menu {
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.left_icon_menu li {
  position: relative;
}
.left_icon_menu li:last-child {
  margin-bottom: 0;
}
.left_icon_menu li:last-child::after {
  display: none;
}
.left_icon_menu li::after {
  content: "";
  display: block;
  width: 70%;
  height: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  position: absolute;
  bottom: 0;
  left: 0;
}
.left_icon_menu li a {
  display: flex;
  align-items: center;
  color: #1F1F1F;
  padding: 16px 20px;
}
.left_icon_menu li a:hover strong, .left_icon_menu li a.active strong {
  color: #FFBE18;
}
.left_icon_menu li a:hover svg path, .left_icon_menu li a.active svg path {
  fill: #FFBE18;
}
.left_icon_menu li a > span {
  padding-left: 14px;
}
.left_icon_menu li a > span strong {
  font-size: 1rem;
}
.left_icon_menu li a > span span {
  display: block;
  font-size: 0.7rem;
  font-weight: 300;
}

.account_header {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}
.account_header .profile_photo {
  position: relative;
}
.account_header .profile_photo button {
  position: absolute;
  right: -10px;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid;
}
.account_header .info {
  padding-left: 3rem;
}
.account_header .info h3 {
  margin: 0;
  font-size: 20px;
}
.account_header .info p {
  font-size: 17px;
  margin: 4px 0 0 0;
  color: rgba(0, 0, 0, 0.7);
}

.account-page-heading {
  color: #909090;
  font-size: 16px;
  font-weight: bold;
}

.p_options {
  margin: 0;
  padding: 2rem;
  list-style-type: none;
}
.p_options.p_small {
  padding: 0.5rem 2rem;
}
.p_options li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.8rem;
}
.p_options li:last-child {
  margin-bottom: 0;
}
.p_options li .option_left h3 {
  color: #1F1F1F;
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 5px 0;
}
.p_options li .option_left h4 {
  font-weight: 500;
  font-size: 14px;
  color: rgb(142, 142, 142);
  margin: 0;
}
.p_options li .option_left p {
  font-size: 14px;
  color: rgb(31, 31, 31);
  margin: 0;
}
.p_options li .option_right .button {
  min-width: 150px;
}

.saved_wallets .wallet_name {
  background-color: rgba(191, 154, 65, 0.1);
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.saved_wallets .wallet_name i {
  margin-right: 10px;
  color: #FFBE18;
}
.saved_wallets .wallet_address {
  background-color: rgba(191, 154, 65, 0.1);
  padding: 10px;
  border-radius: 6px;
}

.canvas-content-middel {
  padding-top: 100px;
}

.change_email_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.change_email_label span {
  color: rgb(90, 90, 90);
  font-weight: 700;
}
.change_email_label .button {
  color: rgb(78, 141, 107);
  background-color: transparent;
  padding: 0;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .change_email_label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .left_icon_menu {
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
  }
  .left_icon_menu li {
    position: relative;
  }
  .left_icon_menu li:last-child {
    margin-bottom: 0;
  }
  .left_icon_menu li:last-child::after {
    display: none;
  }
  .left_icon_menu li::after {
    content: "";
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 12px;
  }
  .account_header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
  }
  .account_header img {
    width: 55px !important;
    height: 55px !important;
  }
  .account_header .profile_photo {
    position: relative;
  }
  .account_header .profile_photo button {
    right: -1px;
    top: -5px;
    width: 20px;
    height: 20px;
  }
  .account_header .profile_photo button span {
    font-size: 15px;
  }
  .account_header .info h3 {
    margin: 0;
  }
}
.bonus_page_head h3 {
  font-weight: 600;
  font-size: 24px;
  color: #FFF;
}
.bonus_page_head p {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.bonus_total h6 {
  font-size: 12px;
  font-weight: bold;
  color: rgb(142, 142, 142);
  margin-bottom: 8px;
  text-transform: uppercase;
}
.bonus_total h2 {
  font-size: 34px;
  font-weight: 700;
}

.tier-item {
  margin-top: 1.3rem;
}
.tier-item h4 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}
.tier-item .card-gradient .card-inner {
  padding: 20px 40px;
}
.tier-item .tier_packages p {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.tier-item .tier_pool {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  padding: 2px;
  border-radius: 12px;
}
.tier-item .tier_pool .inner {
  background-color: #FAFAFA;
  border-radius: 10px;
  padding: 10px;
  min-height: 72px;
}
.tier-item .tier_pool .inner.status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tier-item .tier_pool .inner.status span {
  color: #ffffff;
  background-color: #000;
  border-radius: 50px;
  font-size: 30px;
}
.tier-item .tier_pool p {
  margin: 0;
  font-size: 13px;
}
.tier-item .tier_pool h3 {
  margin: 10px 0 0 0;
  font-size: 20px;
  font-weight: 600;
}

.table_matrix_levels th {
  text-align: center;
}
.table_matrix_levels td {
  width: 33%;
  text-align: center;
  padding: 9px;
  vertical-align: middle;
}
.table_matrix_levels td span {
  line-height: unset;
}
.table_matrix_levels td span.yes {
  color: #FFF;
  background-color: #000;
}
.table_matrix_levels td span.no {
  color: #D61D1D;
}

.bonus_page_head {
  text-align: justify;
}

.wallet_card {
  padding: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  border-radius: 20px;
}
.wallet_card .wallet_inner {
  background-color: #FAFAFA;
  border-radius: 18px;
  overflow: hidden;
}
.wallet_card .wallet_head {
  text-align: center;
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  padding: 14px 10px;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  color: #FFF;
  border-radius: 18px 18px 0 0;
}
.wallet_card .wallet_head > a {
  position: absolute;
  right: 18px;
  font-weight: normal;
  font-size: 14px;
}
.wallet_card .wallet_body {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet_card .wallet_body h2 {
  margin: 0;
  color: #FFBE18;
  font-weight: bold;
}
.wallet_card .wallet_footer .accordion .accordion-item {
  border-radius: 0 0 20px 20px;
  background-color: #FAFAFA;
}
.wallet_card .wallet_footer .accordion .accordion-item .accordion-header {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  padding: 2px;
  border-radius: 0 0 20px 20px;
  margin: -3px;
}
.wallet_card .wallet_footer .accordion .accordion-item .accordion-header .accordion-button {
  border-radius: 0 0 20px 20px;
  background-color: #FAFAFA;
  min-height: 65px;
}
.wallet_card .wallet_footer .accordion .accordion-item .accordion-header .accordion-button:focus {
  outline: none;
  box-shadow: none !important;
  z-index: 0;
}
.wallet_card .wallet_footer .accordion-button:not(.collapsed) {
  color: #1F1F1F;
  background-color: transparent;
  box-shadow: none;
}
.wallet_card .wallet_footer .wallet_footer_form {
  padding: 2rem;
}

.button-addmax {
  position: absolute;
  right: 18px;
  top: 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFBE18;
}
.button-addmax span {
  margin-right: 5px;
}

.Resend {
  font-size: 14px;
}
.Resend span {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .Resend {
    font-size: 12px;
  }
  .Resend span {
    font-size: 12px;
  }
  .wallet_card .wallet_footer .wallet_footer_form {
    padding: 5px;
  }
}
.earning_left {
  padding-right: 60px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 100vh;
}

.title-icon {
  display: flex;
  align-items: center;
}
.title-icon i {
  margin-right: 0.7rem;
}
.title-icon span {
  font-weight: bold;
}

.earning_box {
  display: block;
  background-color: rgba(191, 154, 65, 0.1);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  color: #1F1F1F;
}
.earning_box:hover {
  color: #FFBE18;
}
.earning_box h4 {
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}
.earning_box h3 {
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0 0 0;
}
.earning_box .today {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
}
.earning_box .today span {
  margin: 0 5px 0 0;
}
.earning_box .earning_box_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}
.earning_box .earning_box_options > div > a,
.earning_box .earning_box_options > div button {
  padding: 0;
  background-color: transparent;
  margin-right: 20px;
  font-size: 13px;
  color: #1F1F1F;
  text-transform: uppercase;
}

.earning_search_input {
  position: relative;
}
.earning_search_input .form-control {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  border-radius: 8px;
  height: 48px;
  background: rgb(255, 255, 255);
  padding: 0px 20px 0 60px;
  font-weight: 400;
  font-size: 14px;
  border: none;
}
.earning_search_input i {
  position: absolute;
  top: 12px;
  left: 12px;
}

.earning_filter_buttons {
  display: flex;
  justify-content: space-around;
}
.earning_filter_buttons button {
  padding: 0;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FFBE18;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
.earning_filter_buttons button span {
  line-height: unset;
}

.filter_lowhigh {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  color: rgb(31, 31, 31);
}
.filter_lowhigh li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 6px;
  cursor: pointer;
}
.filter_lowhigh li span {
  margin-left: 10px;
  line-height: unset;
}

.filter_pills {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.filter_pills li {
  display: inline-flex;
  margin: 5px;
  background: rgb(255, 255, 255);
  border-radius: 100px;
  padding: 0px 16px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}
.filter_pills li.active {
  background-color: #FFBE18;
}
.filter_pills li.active a,
.filter_pills li.active .button {
  color: #FFF;
}
.filter_pills li a,
.filter_pills li .button {
  color: #1F1F1F;
}
.filter_pills li .button {
  background-color: transparent;
  padding: 0;
}

.pills_round {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.pills_round li {
  margin: 5px;
}
.pills_round li a,
.pills_round li button {
  display: inline-flex;
  background: rgb(255, 255, 255);
  border-radius: 100px;
  padding: 0px 16px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}
.pills_round li a.active, .pills_round li a:focus,
.pills_round li button.active,
.pills_round li button:focus {
  background-color: #FFBE18;
  color: #FFF;
}

@media screen and (max-width: 767px) {
  .pills_round {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
  }
  .pills_round li {
    margin: 5px;
  }
  .pills_round li a,
  .pills_round li button {
    display: inline-flex;
    background: rgb(255, 255, 255);
    border-radius: 100px;
    padding: 0px 10px;
    height: 32px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
  }
  .pills_round li a.active, .pills_round li a:focus,
  .pills_round li button.active,
  .pills_round li button:focus {
    background-color: #FFBE18;
    color: #FFF;
  }
}
.copy_wrap.space-small .copy_item {
  padding: 8px 20px;
  font-size: 13px;
}
.copy_wrap .copy_item {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  position: relative;
}
.copy_wrap .copy_item::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.copy_wrap .copy_item .option {
  display: flex;
  align-items: center;
}
.copy_wrap .copy_item .option i {
  margin-right: 6px;
}
.copy_wrap .copy_item .action button {
  display: inline-flex;
  align-items: center;
  color: #FFBE18;
}

.bt-wrap {
  background: linear-gradient(180deg, #FFFFFF 0%, #E6E6E6 100%);
  text-align: center;
  padding: 0 30px 30px 30px;
  border-radius: 20px;
}
.bt-wrap p {
  font-weight: 500;
}
.bt-wrap p span {
  color: #FFBE18;
}

.bt-success-img {
  margin-top: -100px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .bt-success-img {
    margin-top: 0;
  }
}
#notifications .notifications_header {
  border-bottom: 1px solid #FFBE18;
  padding: 32px 50px 10px 50px;
  position: relative;
}
#notifications .notifications_header::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #FFBE18 transparent;
  top: -16px;
  right: 247px;
}
#notifications ul li {
  position: relative;
  padding: 1px;
  border-radius: 20px;
  margin-bottom: 0.6rem;
}
#notifications ul li .inner {
  background: #FAFAFA;
  padding: 1rem;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
#notifications ul li .inner .notification {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#notifications ul li .inner .notification svg {
  width: 25px;
  height: 25px;
}
#notifications ul li .inner .notification .massage {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
#notifications ul li .inner .notification .deleted {
  font-size: 24px;
  margin-top: 5px;
  cursor: pointer;
}
#notifications ul li .inner .notification .deleted:hover {
  color: #FFBE18;
}
#notifications ul li:hover {
  border-color: #FFBE18;
}
#notifications ul li .close,
#notifications ul li .spinner-border {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  font-size: 1.5rem;
}
#notifications ul li .date {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}
#notifications ul li .date i {
  margin-right: 5px;
}
#notifications ul li .title {
  font-size: 1.1rem;
  font-weight: 500;
  padding-right: 100px;
  line-height: 1.4rem;
  margin-bottom: 0.5rem;
}
#notifications ul li p {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.1rem;
  margin: 0 0 8px 0;
  width: 90%;
}

@media screen and (max-width: 767px) {
  #notifications .notifications_header {
    padding: 12px;
  }
  #notifications ul li p strong {
    font-weight: 500;
  }
}
.promotions-wrap .promotion-item {
  position: relative;
  margin-bottom: 1rem;
}
.promotions-wrap .promotion-item img {
  width: 100%;
}
.promotions-wrap .promotion-item a {
  position: absolute;
  right: 67px;
  top: 40%;
}
.promotions-wrap .promotion-item .promo-title {
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promotions-wrap .promotion-item .promo-title h2 {
  color: #FFF;
  font-weight: bold;
  font-size: 70px;
  margin: 0;
}

.promo-bar-title {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.promo-progress {
  height: 12px;
  background-color: #B2B2B2;
  border-radius: 20px;
  position: relative;
  margin-bottom: 0;
}
.promo-progress > .bar {
  display: block;
  height: 12px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #000;
  border-radius: 20px;
}

.promo-label {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -6px;
  position: relative;
}

.promo-timer {
  margin-top: 50px;
}
.promo-timer p {
  font-size: 18px;
  font-weight: 600;
}

.promo-users {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}
.promo-users li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}
.promo-users li span {
  font-size: 13px;
}
.promo-users li svg {
  width: 60px;
  height: 60px;
}

.progress_wrapper_card input {
  margin-bottom: 10px;
}
.progress_wrapper_card .card {
  height: 360px;
  width: 326px;
  font-family: Roboto;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.progress_wrapper_card .card__chart-wrapper {
  margin-top: 20px;
  position: relative;
}
.progress_wrapper_card .card__chart-wrapper__value {
  height: 86px;
  width: 86px;
  transform: translate(-50%, -50%);
  color: #212121;
  left: 50%;
  top: 50%;
  border: 4px solid #FFBE18;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  font-size: 27px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
.progress_wrapper_card .card__chart-wrapper svg {
  margin: auto;
  display: block;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.progress_wrapper_card .card__chart-wrapper svg .progress {
  stroke-dasharray: 559 559;
  stroke-dashoffset: 279.6;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
}
.progress_wrapper_card .card__info {
  margin-top: 30px;
}
.progress_wrapper_card .card__info ul {
  list-style: none;
  display: flex;
}
.progress_wrapper_card .card__info ul li::before {
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  top: 2px;
  position: relative;
}
.progress_wrapper_card .card__info ul li:not(:last-child) {
  margin-right: 10px;
}
.progress_wrapper_card .card__info ul li.restless::before {
  background: #000000;
}
.progress_wrapper_card .card__info ul li.awake::before {
  background: #969696;
}

@media screen and (max-width: 767px) {
  .promo-bar-title {
    margin-top: 30px;
    padding: 0 20px;
  }
  .promo-label {
    margin-left: 14px;
  }
  .promo-progress {
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
    height: 8px;
  }
  .promo-progress > .bar {
    height: 8px;
  }
  .promotions-wrap .promotion-item img {
    width: 100%;
    height: 155px;
  }
  .promotions-wrap .promotion-item a {
    position: relative;
    right: 0px;
    left: 0;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .promotions-wrap .promotion-item .promo-title h2 {
    font-size: 26px;
    margin-bottom: 38px;
  }
  .promo-users {
    margin-bottom: 40px;
  }
  .promo-users li {
    margin: 0 10px;
  }
  .promo-users li svg {
    width: 46px;
    height: 36px;
  }
  .promo-users li span {
    font-size: 10px;
    text-align: center;
    line-height: 13px;
  }
  .promo-timer {
    text-align: start;
    margin-top: 20px;
    margin-bottom: 150px;
  }
  .promo-timer p {
    font-size: 13px;
  }
}
.support-wrap {
  background-color: rgba(0, 0, 0, 0.05);
  min-height: 100vh;
  padding-top: 2rem;
}

.upload-img-model .modal-header {
  border-bottom: none;
}
.upload-img-model .modal-header .h4 {
  font-size: 1rem;
}
.upload-img-model .modal-body .modal-footer {
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 17px 0px;
  padding-bottom: 0;
}
.upload-img-model .upload-img {
  width: auto !important;
}
.upload-img-model .upload-img.model-pink {
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #45F882 inset;
  border: transparent;
}
.upload-img-model .upload-img.model-pink .thumnail {
  color: #FFF;
}

.support_left_menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.support_left_menu li a {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: relative;
}
.support_left_menu li a i {
  margin-right: 0.8rem;
  font-size: 1.3rem;
}
.support_left_menu li a:hover, .support_left_menu li a.active {
  background-color: #FFBE18;
  color: #FFF;
}
.support_left_menu li a .count {
  position: absolute;
  right: 10px;
  display: inline-block;
  padding: 0.3rem 0.6rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}
.support_left_menu li a .count.waiting {
  background-color: #FFCD4E;
}
.support_left_menu li:last-child a {
  border-bottom: none;
}

.attachment-link {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.attachment-link.active svg {
  stroke: #FFBE18 !important;
}
.attachment-link.active span {
  font-weight: 600 !important;
}
.attachment-link svg {
  stroke: #000;
  width: 20px;
}
.attachment-link span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.table_tickets {
  font-size: 0.9rem;
}
.table_tickets .ticket_id {
  width: 120px;
}
.table_tickets .ticket_subject {
  width: 150px;
}
.table_tickets th,
.table_tickets td {
  padding: 0.3rem;
  vertical-align: middle;
}
.table_tickets .count {
  display: inline-block;
  padding: 0.2rem 0.6rem;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
}
.table_tickets .subject {
  margin: 0;
  max-width: 300px;
  height: 20px;
  overflow: hidden;
}
.table_tickets .button-detail {
  display: inline-flex;
  align-items: center;
  color: #45F882;
  text-transform: uppercase;
}
.table_tickets .button-detail span {
  margin-left: 0.5rem;
  background-color: #45F882;
  color: #FFF;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.table_tickets .button-detail:hover {
  color: #FFBE18;
}
.table_tickets .button-detail:hover span {
  background-color: #FFBE18;
}

.support-action {
  color: #FFBE18 !important;
  transition: color 0.3s ease;
  display: inline-flex !important;
  align-items: center !important;
}
.support-action span {
  background-color: #FFBE18 !important;
  transition: background-color 0.3s ease;
  margin-left: 0.5rem;
  background-color: #1E1E24;
  color: #FFF;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.support-action span i {
  color: white !important;
}
.support-action span .material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.support-action:hover {
  color: black !important;
}
.support-action:hover span {
  background-color: black !important;
}
.support-action:hover span i {
  color: white !important;
}

.new-ticket-wrap .col-form-label {
  text-align: right;
}

.gradient-border {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid transparent;
  color: #000000;
  background: #ffffff;
  background-clip: padding-box;
  border-radius: 10px;
}
.gradient-border p {
  margin-bottom: 0px;
  padding: 10px;
}
.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background-image: linear-gradient(to right, #b4a458, #ffffff);
}

.gradient-borderr {
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  border-radius: 10px;
  font-size: 1rem;
  color: gray;
  width: 90%;
  margin: auto;
  padding: 30% 2em;
  box-sizing: border-box;
}
.gradient-borderr p {
  margin: 0;
  padding: 10px;
}
.gradient-borderr:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit !important;
  margin: -2px !important;
  background: linear-gradient(to right, #b4a458, #ffffff);
}

.nav-support {
  background-color: #F0F2F7 !important;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}
.nav-support .nav-item .nav-link {
  color: #525866;
  font-size: 15px;
  padding: 12px 16px;
  border-radius: 12px 12px 0 0;
  border: none;
  font-weight: 500;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.nav-support .nav-item .nav-link p {
  margin: 6px 0 0 0;
  font-size: 13px;
  font-weight: 500;
}
.nav-support .nav-item .nav-link span {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  right: 15px;
  min-width: 15px;
  padding: 1px;
  height: 15px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #FFBE18;
  border-radius: 5px;
  color: white;
}
.nav-support .nav-item .nav-link svg {
  width: 26px;
}
.nav-support .nav-item .nav-link:hover {
  transition: width 0.3;
}
.nav-support .nav-item .nav-link:hover::before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 20px;
  height: 3px;
  background: #FFBE18;
  border-bottom: 1px solid #FFBE18;
  border-radius: 5px;
}
.nav-support .nav-item .nav-link.active {
  color: #FFBE18;
  background-color: transparent;
}
.nav-support .nav-item .nav-link.active::before {
  position: absolute;
  content: "";
  bottom: 0;
  width: 20px;
  height: 3px;
  background: #FFBE18;
  border-bottom: 1px solid #FFBE18;
  border-radius: 5px;
}
.nav-support .nav-item .nav-link.active svg {
  color: #FFBE18 !important;
}
.nav-support .nav-item .nav-link.active svg path {
  stroke: #FFBE18 !important;
}

.inbox-item {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  position: relative;
  padding: 12px 16px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inbox-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.inbox-item a {
  position: absolute;
  width: 100%;
  height: 100%;
}
.inbox-item span {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  background: #525866;
  color: #FFF;
}
.inbox-item .inbox-menu p {
  margin: 6px 0 0 0;
  font-size: 14px;
  font-weight: bold;
}
.inbox-item .inbox-menu svg {
  width: 24px;
}

.list-support-tickets {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list-support-tickets li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
  font-size: 14px;
}
.list-support-tickets li h2 {
  margin-bottom: 0;
  font-size: 18px;
}
.list-support-tickets li .ticket-id {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.list-support-tickets li .button {
  font-size: 13px;
}

.tickte-detail-head {
  display: flex;
  align-items: center;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.tickte-detail-head > div {
  display: flex;
  align-items: center;
}
.tickte-detail-head h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0 10px;
}
.tickte-detail-head button {
  display: block;
  background-color: transparent;
  width: 30px;
  border: none;
}
.tickte-detail-head span {
  font-size: 12px;
}

.type-date {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 10px 0;
}
.type-date .date {
  color: gray;
}

.feedback_list {
  list-style: none;
  padding: 0;
}
.feedback_list li {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 15px 0;
}
.feedback_list li label {
  cursor: pointer;
}
.feedback_list li label input {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  position: absolute;
}
.feedback_list li label span {
  margin-left: 15px;
  font-weight: 500;
  font-size: 15px;
}
.feedback_list li label.active svg path#golden-color {
  fill: #FFCF48;
}
.feedback_list li label.active svg path#red {
  fill: #ff0000;
}
.feedback_list li:first-child {
  margin: 0;
}
.feedback_list li:last-child {
  margin: 0;
}

.admin-support-modal .modal-dialog {
  max-width: 70%;
  min-width: 70%;
  width: auto;
  height: auto;
}
.admin-support-modal .modal-dialog .modal-body {
  text-align: center;
}
.admin-support-modal .modal-dialog .modal-body img {
  min-width: 100%;
}

.submit_button {
  background-color: rgb(217, 217, 217);
  padding: 0.6rem;
  display: inline-block;
  border-radius: 6px;
  border: transparent;
  cursor: pointer;
  width: 100%;
}
.submit_button span {
  color: black;
}

.tickhide {
  display: none;
}

@media screen and (max-width: 767px) {
  .tickshow {
    top: -12px;
    right: -2px;
  }
  .admin-support-modal .modal-dialog {
    max-width: 96%;
    min-width: 96%;
  }
  .support-cyan {
    padding: 2px;
    overflow: hidden;
    height: 76px;
    margin-bottom: 1px;
  }
  .support-cyan .white-cyan {
    background-color: #FAFAFA !important;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    list-style: none;
    height: 71px;
    padding: 4px !important;
  }
  .support-cyan .white-cyan .support-item {
    align-items: center;
  }
  .support-cyan .white-cyan .support-item .support-link {
    color: rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    font-size: 12px;
    padding: 2px;
    border-radius: 12px 12px 0 0;
    border: none;
    font-weight: 500;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .support-cyan .white-cyan .support-item .support-link span {
    margin-left: 0px;
  }
  .support-cyan .white-cyan .support-item .support-link svg {
    width: 20px;
    height: 20px;
  }
  .support-cyan .white-cyan .support-item .support-link.active {
    color: #FFBE18;
  }
  .support-cyan .white-cyan .support-item .support-link.active svg path {
    fill: #FFBE18 !important;
  }
  .new-ticket-wrap .col-form-label {
    text-align: left;
  }
}
/*thread start*/
.topic-comments .post-new {
  padding: 32px 20px;
  border: 1px solid #dcdcdc;
  box-shadow: 2px 2px 13px 3px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
}

.input-post-group {
  position: relative;
}
.input-post-group .form-control {
  border-radius: 30px;
  padding-left: 20px;
  background-color: #f1f2f4;
  border: none;
  min-height: 60px;
}
.input-post-group .input-group-append {
  position: absolute;
  top: 0;
  right: 0;
}
.input-post-group .input-group-append .button {
  padding: 17px 30px;
  min-width: 180px;
  border-radius: 54px;
}

.post-new-actions {
  margin: 30px 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.post-new-actions.in-reply {
  margin: 0 20px 0 0;
}
.post-new-actions.in-reply li {
  margin: 12px;
}
.post-new-actions.in-reply li i {
  font-size: 22px;
}
.post-new-actions li {
  margin-right: 60px;
  display: flex;
  align-items: center;
}
.post-new-actions li i {
  font-size: 30px;
}
.post-new-actions li span {
  font-size: 22px;
  color: #5a5d67;
  display: inline-block;
  margin-left: 15px;
}

.comment-posts .post-item {
  border: 1px solid transparent;
  position: relative;
}
.comment-posts .post-item .ticket-type {
  position: absolute;
  right: 34px;
  top: 48px;
}
.comment-posts .post-item .ticket-type p {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}
.comment-posts .post-item .ticket-type p strong {
  color: #45F882;
}
.comment-posts .post-item .user-info {
  display: flex;
  align-items: center;
}
.comment-posts .post-item .user-info .thumb {
  width: 80px;
}
.comment-posts .post-item .user-info .thumb img {
  max-width: 100%;
  max-height: 86px;
  border-radius: 50%;
}
.comment-posts .post-item .user-info .info {
  padding-left: 12px;
}
.comment-posts .post-item .user-info .info h4 {
  color: #040d25;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.comment-posts .post-item .user-info .info p {
  color: #838289;
  font-size: 14px;
  margin: 0;
}
.comment-posts .post-item .user-info .info p img {
  width: 20px;
  margin-left: 6px;
}
.comment-posts .post-item .post-info {
  max-width: 800px;
}
.comment-posts .post-item .post-info h2 {
  color: #040d25;
  font-size: 26px;
  font-weight: 600;
  max-width: 687px;
}
.comment-posts .post-item .post-info p {
  color: #515151;
  font-size: 16px;
}
.comment-posts .post-item .post-info img {
  max-width: 100%;
}
.comment-posts .post-item .post-comments-count {
  margin: 14px 0;
}
.comment-posts .post-item .post-comments-count a {
  color: #08a0d2;
  font-size: 16px;
  text-decoration: underline;
}

.post-info-actions {
  margin: 0;
  padding: 1.5rem 1.5rem;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: rgba(255, 14, 13, 0.1215686275);
}
.post-info-actions li {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.post-info-actions li.mobile-view {
  display: none;
}
.post-info-actions li i {
  margin-right: 0.5rem;
  color: #FFBE18;
}
.post-info-actions li:first-child {
  padding-left: 0;
}
.post-info-actions li:last-child {
  padding-right: 0;
  border-right: 0;
}
.post-info-actions.action-comments {
  background-color: transparent;
  padding: 10px 0 0 0;
}
.post-info-actions.action-comments li {
  margin: 0;
}

.bs-comment-post {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bs-comment-post .post-info-actions {
  text-align: start;
}
.bs-comment-post .user-reply {
  margin: 0 !important;
}
.bs-comment-post .user-reply .comment-item {
  margin: 0px !important;
  gap: 5px;
}
.bs-comment-post .user-reply .comment-item .thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto !important;
  margin: 0 !important;
}
.bs-comment-post .user-reply .comment-item .thumb span {
  width: 21px !important;
  height: 24px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
}
.bs-comment-post .user-reply .comment-item .comment .comment-message .inner {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 8px;
  background: rgba(255, 21, 0, 0.1);
}
.bs-comment-post .user-reply .comment-item .comment .comment-message .inner p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: 0;
}
.bs-comment-post .user-reply .comment-item .comment .comment-message .inner span {
  color: #525866;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.bs-comment-post .user-reply .comment-item .comment .comment-message .comment-actions svg {
  width: 15px;
  cursor: pointer;
}
.bs-comment-post .admin-reply {
  margin: 0 !important;
}
.bs-comment-post .admin-reply .comment-item {
  margin: 0px !important;
  gap: 5px;
}
.bs-comment-post .admin-reply .comment-item .thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto !important;
  margin: 0 !important;
}
.bs-comment-post .admin-reply .comment-item .thumb span {
  width: 21px !important;
  height: 24px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
}
.bs-comment-post .admin-reply .comment .comment-message .inner {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 8px !important;
  background: rgba(123, 133, 153, 0.1);
}
.bs-comment-post .admin-reply .comment .comment-message .inner .comment-actions {
  margin: 0 !important;
}
.bs-comment-post .admin-reply .comment .comment-message .inner p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: 0;
}
.bs-comment-post .admin-reply .comment .comment-message .inner span {
  color: #525866;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.bs-comment-post .admin-reply .comment .comment-message .comment-actions svg {
  width: 15px;
  cursor: pointer;
}
.bs-comment-post .reply .attachment-icon .active svg {
  stroke: #FFBE18;
}

.post-comments {
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.post-comments li {
  margin: 2rem 0;
}
.post-comments li.user-reply .comment-item {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: 30px;
}
.post-comments li.user-reply .comment-item .thumb {
  margin-right: 0;
  margin-left: 10px;
}
.post-comments li.user-reply .comment-item .thumb span {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFBE18;
  color: #FFF;
}
.post-comments li.user-reply .comment-item .comment {
  text-align: end;
}
.post-comments li.user-reply .comment-item .comment .comment-message .inner {
  background: rgba(255, 21, 0, 0.1);
}
.post-comments li.user-reply .comment-item .comment .comment-actions {
  justify-content: flex-end;
}
.post-comments li.admin-reply .inner {
  background-color: #ededed !important;
}
.post-comments li.admin-reply .comment-actions {
  justify-content: flex-start !important;
  margin-left: 10px !important;
  margin-right: 0;
}
.post-comments li .comment-item {
  margin-left: 30px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.post-comments li .comment-item .thumb {
  width: 60px;
  margin-right: 10px;
}
.post-comments li .comment-item .thumb span {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7B8599;
  color: #FFF;
}
.post-comments li .comment-item .thumb img {
  border-radius: 50%;
  object-fit: cover;
  width: 50px;
  height: 50px;
}
.post-comments li .comment-item .comment {
  max-width: 89%;
  display: inline-block;
}
.post-comments li .comment-item .comment .comment-message {
  padding: 2px;
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.post-comments li .comment-item .comment .comment-message .inner {
  border-radius: 15px;
  padding: 8px 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.post-comments li .comment-item .comment .comment-message .inner p {
  margin: 0;
  font-size: 15px;
  color: #515151;
}
.post-comments li .comment-item .comment .comment-message .inner h5 {
  flex-basis: 100%;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  color: #040d25;
}
.post-comments li .comment-item .comment .comment-message .inner div {
  font-size: 14px;
}
.post-comments li .comment-item .comment .comment-actions {
  padding: 6px 0;
  display: flex;
  align-items: flex-end;
}
.post-comments li .comment-item .comment .comment-actions .button-view-attachment {
  padding: 4px 20px;
  margin-right: 10px;
  background-color: transparent;
  border-color: black;
  font-size: 0.9rem;
  color: black;
}
.post-comments li .comment-item .comment .comment-actions .button-view-attachment:hover {
  background-color: black;
  color: #FFF;
}
.post-comments li .comment-item .comment .comment-actions a {
  margin-right: 10px;
  display: inline-block;
}
.post-comments li .comment-item .comment .comment-actions .lik {
  color: #08a0d2;
  text-decoration: underline;
}
.post-comments li .comment-item .comment .comment-actions .reply {
  color: #868686;
  font-size: 0.8rem;
}
.post-comments li .comment-item .comment .comment-actions .date {
  color: #868686;
  font-size: 0.8rem;
}
.post-comments li > ul {
  margin: 0 0 20px 0;
  padding: 0 0 0 100px;
  list-style-type: none;
  position: relative;
}
.post-comments li > ul::before {
  content: "";
  display: block;
  background-image: url(../images/arrow-reply.png);
  width: 50px;
  position: absolute;
  height: 50px;
  left: 48px;
}

.Attachment {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}
.Attachment li {
  position: relative;
}
.Attachment li .attachment_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Attachment li .attachment_img svg {
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
}
.Attachment li .attachment_img svg path {
  fill: #FFBE18 !important;
}
.Attachment li .attachment_img svg::before {
  content: "";
  position: absolute;
  top: -7px;
  right: -17px;
  height: 37px;
  width: 3px;
  background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
}
.Attachment li .attachment_img span {
  margin-left: 10px;
  width: 100%;
  overflow: hidden;
}
.Attachment li::before {
  content: "";
  position: absolute;
  top: -7px;
  right: -17px;
  height: 37px;
  width: 3px;
  background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
}
.Attachment li:first-child {
  width: 80vw;
}
.Attachment li:last-child::before {
  content: "";
  position: absolute;
  top: -7px;
  right: -17px;
  height: 37px;
  width: 3px;
  display: none;
  background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
}
.Attachment .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 23px;
  width: 20vw;
}
.Attachment .heading .lable {
  font-weight: 500;
  width: 240px;
  margin-left: 10px;
}
.Attachment .heading .form-select {
  display: inline-block;
  border: none;
  width: 100%;
}

.reply {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 12px 16px;
  position: relative;
}
.reply .button-reply {
  display: inline-block;
  border: none;
  background-color: transparent;
}
.reply .button-reply svg {
  width: 24px;
  color: #000;
}
.reply .attachment-icon {
  position: relative;
}
.reply .attachment-icon svg {
  cursor: pointer;
  width: 24px;
}
.reply .submit {
  position: relative;
}
.reply .submit svg {
  cursor: pointer;
}
.reply .submit svg path {
  fill: #FFBE18;
}
.reply .submit svg path:first-child {
  stroke: #FFBE18;
  fill: none;
}
.reply .form-control {
  outline: none;
  border: none;
  min-height: 46px;
}
.reply .form-control:active, .reply .form-control:focus, .reply .form-control:target, .reply .form-control:focus-visible {
  box-shadow: none;
}
.reply .form-control::after {
  content: "";
  position: absolute;
  top: -7px;
  left: -17px;
  height: 37px;
  width: 3px;
  z-index: 1;
  background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
}

@media screen and (max-width: 767px) {
  .comment-posts .post-item {
    border: none;
  }
  .reply {
    min-height: 100%;
    display: block;
    padding-top: 5px;
  }
  .reply .attachment-icon {
    margin-bottom: 0px;
  }
  .reply .attachment-icon::after {
    top: -15px;
    right: -11px;
    height: 61px;
  }
  .reply .simile {
    position: relative;
  }
  .reply .simile::before {
    top: -17px;
    right: 0;
    height: 3px;
    width: 100%;
    z-index: 1;
    background: linear-gradient(90deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
  }
  .reply .simile svg {
    cursor: pointer;
  }
  .reply .simile svg path {
    fill: #FFBE18;
  }
  .reply .simile svg path:first-child {
    stroke: #FFBE18;
    fill: none;
  }
  .reply .button {
    margin: 18px 0;
  }
  .post-info-actions {
    padding: 0.7rem;
    flex-wrap: wrap;
  }
  .post-info-actions li {
    display: flex;
    align-items: center;
    padding: 0.3rem 0;
    padding-left: 0;
    padding-right: 0;
    border-right: 0;
  }
  .post-info-actions li.laptop-view {
    display: none;
  }
  .post-info-actions li.mobile-view {
    display: inline-flex;
  }
  .post-info-actions.action-comments {
    background-color: transparent;
    padding: 10px 0 0 0;
  }
  .post-info-actions.action-comments li {
    margin: 0;
  }
  .post-comments {
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 600px;
    overflow: scroll;
  }
  .post-comments li {
    margin: 1.2rem 0;
  }
  .post-comments li .comment-item {
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    margin-right: 10px !important;
  }
  .post-comments li .comment-item .thumb {
    width: 50px;
    margin-right: 5px;
    display: none;
  }
  .post-comments li .comment-item .thumb img {
    width: 40px;
    height: 40px;
  }
  .post-comments li .comment-item .comment .comment-message {
    width: 97%;
  }
  .post-comments li .comment-item .comment .comment-message .inner {
    padding: 8px;
    width: 100%;
  }
  .post-comments li .comment-item .comment .comment-actions {
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 21px;
  }
  .post-comments li .comment-item .comment .comment-actions .button-view-attachment {
    padding: 0;
    margin-right: 10px;
    background-color: transparent;
    border-color: transparent;
    font-size: 0.9rem;
  }
  .post-comments li .comment-item .comment .comment-actions .button-view-attachment:hover {
    background-color: transparent;
    color: #FFBE18;
  }
  .post-comments li .comment-item .comment .comment-actions .button-view-attachment span:hover, .post-comments li .comment-item .comment .comment-actions .button-view-attachment span:active, .post-comments li .comment-item .comment .comment-actions .button-view-attachment span:focus {
    background-color: transparent;
    color: #FFBE18;
  }
  .post-comments li .comment-item .comment .comment-actions a {
    margin-right: 10px;
    display: inline-block;
  }
  .post-comments li .comment-item .comment .comment-actions .lik {
    color: #08a0d2;
    text-decoration: underline;
  }
  .post-comments li .comment-item .comment .comment-actions .reply {
    color: #868686;
    font-size: 0.8rem;
  }
  .post-comments li .comment-item .comment .comment-actions .date {
    color: #868686;
    font-size: 0.8rem;
  }
  .post-comments li > ul {
    margin: 0 0 20px 0;
    padding: 0 0 0 100px;
    list-style-type: none;
    position: relative;
  }
  .post-comments li > ul::before {
    content: "";
    display: block;
    background-image: url(../images/arrow-reply.png);
    width: 50px;
    position: absolute;
    height: 50px;
    left: 48px;
  }
  .Attachment {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .Attachment li {
    position: relative;
    padding: 10px;
  }
  .Attachment li .attachment_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Attachment li .attachment_img svg {
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
  }
  .Attachment li .attachment_img svg path {
    fill: #FFBE18 !important;
  }
  .Attachment li .attachment_img svg::before {
    content: "";
    position: absolute;
    top: -7px;
    right: -17px;
    height: 37px;
    width: 3px;
    background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
  }
  .Attachment li .attachment_img span {
    margin-left: 10px;
    width: 100%;
    overflow: hidden;
  }
  .Attachment li::before {
    content: "";
    position: absolute;
    top: 47px;
    right: 39px;
    height: 4px;
    width: 101%;
    background: linear-gradient(90deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
  }
  .Attachment li:first-child {
    width: 100vw;
  }
  .Attachment li:last-child::before {
    display: none;
  }
  .Attachment .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 29px;
    width: 100vw;
  }
  .Attachment .heading .lable {
    font-weight: 500;
    width: 200px;
    margin-left: 10px;
  }
  .Attachment .heading .lable::before {
    content: "";
    position: absolute;
    top: 6px;
    right: 255px;
    height: 46px;
    width: 4px;
    background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
  }
  .Attachment .heading .form-select {
    display: inline-block;
    border: none;
    width: 100%;
  }
  .input-post-group .input-group-append .btn {
    min-width: auto;
  }
  .post-new-actions li {
    margin-right: 14px;
  }
  .post-new-actions li i {
    font-size: 15px;
  }
  .post-new-actions li span {
    font-size: 10px;
    margin-left: 6px;
  }
}
@media screen and (max-width: 480px) {
  .topic-details .topic-title {
    font-size: 25px;
  }
  .topic-comments {
    margin-top: 1em;
  }
  .topic-comments .post-new {
    padding: 20px 20px;
  }
}
/*thread end*/
.tick {
  color: #ffffff;
  background-color: #D61D1D;
  border-radius: 50px;
  font-size: 20px;
  margin-left: 15px !important;
}
.tick.yes {
  background-color: #000;
}

.cash-back {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.cash-back .user-icon {
  padding: 0px 20px;
}
.cash-back .user-icon .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F3F3F3;
  cursor: pointer;
  padding: 0px 5px;
}
.cash-back .user-icon p {
  font-size: 12px;
  margin: 0;
}

.cashback-bonus h2 {
  font-weight: 600;
  font-size: 34px;
  margin: 0;
}
.cashback-bonus p {
  color: #8E8E8E;
}

.five_partners {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.five_partners li {
  text-align: center;
  margin: 10px;
}
.five_partners li .avatar_cb {
  width: 50px;
  height: 50px;
  margin: auto;
}
.five_partners li p {
  margin: 10px 0 0 0;
  font-size: 12px;
}

.clock_cashback.clock_small #clockdiv > div {
  line-height: 1.1;
  min-width: 70px;
}
.clock_cashback.clock_small #clockdiv > div span {
  font-size: 18px;
}
.clock_cashback.clock_small #clockdiv > div .smalltext {
  font-size: 12px;
}
.clock_cashback #clockdiv {
  display: flex;
  justify-content: end;
  gap: 25px;
}
.clock_cashback #clockdiv .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}
.clock_cashback #clockdiv .wrapper::before {
  content: ":";
  position: absolute;
  top: 0;
  right: -15px;
  border-radius: 50px;
  color: #B69541;
  font-size: 26px;
  font-weight: 700;
}
.clock_cashback #clockdiv .wrapper:last-child::before {
  display: none;
}
.clock_cashback #clockdiv .wrapper .time {
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  background: #b69541;
  height: 40px;
  width: 60px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.clock_cashback #clockdiv .wrapper .time span {
  background-color: #f4f1f1;
  height: 38px;
  width: 56px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  color: #FFBE18;
  font-weight: 600;
  font-size: 23px;
}
.clock_cashback #clockdiv .wrapper .smalltext {
  color: #a5a5a5;
  font-weight: 500;
  font-size: 12px;
}
.clock_cashback #clockdiv .smalltext {
  color: #a5a5a5;
  font-weight: 600;
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .Cashback {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .five_partners {
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .five_partners li {
    text-align: center;
    margin: 8px;
  }
  .five_partners li .avatar_cb {
    width: 45px;
    height: 45px;
  }
  .clock_cashback {
    padding: 0 10px;
    margin-top: 20px;
  }
  .clock_cashback #clockdiv {
    display: flex;
    justify-content: space-between;
  }
  .clock_cashback #clockdiv > div {
    text-align: center;
    min-width: 60px;
  }
  .clock_cashback #clockdiv > div .smalltext {
    color: #a5a5a5;
    font-weight: 600;
    font-size: 15px;
  }
  .clock_cashback #clockdiv > div span {
    font-weight: bold;
    color: #FFBE18;
    font-size: 22px;
  }
}
.contact-form .form-control {
  background-color: #242424;
  border: none;
  min-height: 50px;
}

.landing-page {
  background-color: #000;
  color: rgba(255, 255, 255, 0.8);
}
.landing-page p {
  font-weight: 300;
}

.terms-wrap h1 {
  font-size: 22px;
}

.video-item {
  text-align: center;
  max-width: 290px;
  margin: auto;
  cursor: pointer;
}
.video-item img {
  max-width: 100%;
}
.video-item h4 {
  font-size: 1rem;
  margin-top: 1.5rem;
}

.fb-section h3 {
  font-size: 26px;
  text-transform: uppercase;
}
.fb-section p {
  font-size: 1rem;
}

.stats {
  padding: 2rem 0;
  background-color: #181818;
}
.stats .stat-item {
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stats .stat-item .count {
  font-size: 5rem;
  font-family: "Montserrat", sans-serif;
  margin-right: 0.6rem;
}
.stats .stat-item .count span {
  color: #4FC4C4;
}
.stats .stat-item .stat-label {
  text-transform: uppercase;
  line-height: 1.6rem;
  font-size: 1.5rem;
}

.find-us-box {
  text-align: center;
  background-color: #FFF;
  padding: 2rem 1rem;
  border-radius: 10px;
}
.find-us-box.small {
  max-width: 300px;
  margin: auto;
}
.find-us-box.bg-gold {
  background: rgb(207, 171, 79);
  background: linear-gradient(90deg, rgb(207, 171, 79) 0%, rgb(250, 208, 127) 50%, rgb(221, 184, 89) 100%);
}
.find-us-box.bg-gold h3 {
  color: #FFF;
}
.find-us-box.bg-gold p {
  color: #FFF;
}
.find-us-box h3 {
  text-transform: uppercase;
  font-weight: bold;
  color: #FFBE18;
  font-size: 1.3rem;
}
.find-us-box p {
  color: #bab3b3;
  font-size: 0.9rem;
  font-weight: normal;
  max-width: 300px;
  margin: auto;
}

.leader-item {
  text-align: center;
  margin-top: 2rem;
}
.leader-item img {
  max-width: 90%;
  margin-bottom: 1rem;
}
.leader-item h3 {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.cta {
  padding: 4rem;
  border-radius: 20px;
  background: rgb(91, 82, 52);
  background: linear-gradient(90deg, rgb(91, 82, 52) 0%, rgba(36, 35, 33, 0) 63%);
  position: relative;
  overflow: hidden;
}
.cta h2 {
  text-transform: uppercase;
  font-size: 30px;
}
.cta p.home-detail {
  text-align: justify;
  font-size: 14px;
}
.cta > img {
  position: absolute;
  right: -30px;
  top: -30px;
  width: 400px;
}

.cta2 {
  padding: 4rem;
  border-radius: 20px;
  background: rgb(36, 35, 33);
  background: linear-gradient(90deg, rgba(36, 35, 33, 0) 22%, rgb(91, 82, 52) 100%);
  position: relative;
  overflow: hidden;
}
.cta2 h2 {
  text-transform: uppercase;
  font-size: 2rem;
}
.cta2 p {
  font-size: 15px;
  margin-top: 13px;
}
.cta2 > img {
  position: absolute;
  left: -30px;
  top: -30px;
  width: 500px;
}

.container.terms-wrap {
  font-size: 14px;
}
.container.terms-wrap h2 {
  font-size: 18px;
}
.container.terms-wrap p {
  text-align: justify;
}

.rank-ist {
  text-align: center;
}
.rank-ist img {
  width: 150px !important;
  margin: auto;
}
.rank-ist h4 {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
}

.subscribe-form {
  max-width: 350px;
  margin: 1rem 0;
}
.subscribe-form .input-subscribe {
  width: 100%;
  background-color: transparent;
  height: 50px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #FFF;
  color: #FFF;
}
.subscribe-form .input-subscribe:focus {
  outline: none;
  border-bottom-color: #FFBE18;
}
.subscribe-form .input-subscribe::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.subscribe-form button {
  display: block;
  width: 100%;
  margin-top: 1rem;
  background: linear-gradient(89.93deg, #D6BB6E 0.83%, #D5B972 47.33%, #FBF6BB 101.6%);
  border-radius: 15px;
  color: #000;
  font-weight: 500;
}

.capitals-list {
  margin: 30px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.capitals-list li {
  margin-top: 1rem;
  font-size: 1.2rem;
}
.capitals-list li strong {
  display: block;
  color: #FFF;
  font-weight: 500;
}
.capitals-list li p {
  font-size: 16px;
}

.testimonials {
  padding: 3rem 0;
}
.testimonials .testimonial-item {
  margin: 3rem 0;
  text-align: center;
}
.testimonials .testimonial-item .testi-user {
  margin-top: 1rem;
}
.testimonials .testimonial-item .testi-user h4 {
  color: #FFBE18;
  margin: 0;
}
.testimonials .testimonial-item .testi-user p {
  margin: 0;
  color: #FFF;
}
.testimonials .testimonial-item p {
  margin-top: 1rem;
  color: #77838f;
}

.leader-slider {
  display: none;
}

.news-slider {
  display: none;
}

.revolution {
  display: block;
  margin-left: 0;
  margin-top: 0px;
}

#timer {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
}
#timer > img {
  width: 140px;
  margin-bottom: 30px;
}
#timer h3 {
  color: #FFF;
}

#launch-time {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
#launch-time #clockdiv {
  display: flex;
  justify-content: center;
}
#launch-time #clockdiv > div {
  text-align: center;
  display: flex;
  position: relative;
  margin: 0 20px;
  flex-direction: column-reverse;
}
#launch-time #clockdiv > div::after {
  content: ":";
  color: #FFBE18;
  position: absolute;
  right: -25px;
  top: 18%;
  font-size: 40px;
  font-weight: bold;
}
#launch-time #clockdiv > div:last-child::after {
  content: "";
}
#launch-time #clockdiv > div span {
  font-size: 50px;
  border-radius: 4px;
  font-weight: bold;
  width: 88px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFBE18;
  margin-bottom: 6px;
  padding: 16px 0 0 0;
}

@media screen and (max-width: 767px) {
  .revolution {
    display: inline-block;
    margin-left: 5px;
    margin-top: 0px;
  }
  .rank-ist img {
    width: 160px !important;
  }
  .globe {
    max-width: 80%;
  }
  #education .col-md-4 {
    order: 2;
  }
  #education .col-md-6 {
    order: 1;
  }
  .video-item {
    margin: 0 auto 60px auto;
  }
  #timer h3 {
    font-size: 18px;
  }
  #launch-time {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  #launch-time #clockdiv {
    display: flex;
    justify-content: center;
  }
  #launch-time #clockdiv > div {
    font-size: 12px;
    margin: 0 5px;
    min-width: 70px;
  }
  #launch-time #clockdiv > div::after {
    display: none;
  }
  #launch-time #clockdiv > div span {
    font-size: 30px;
    width: 64px;
    height: 73px;
  }
  .news-slider {
    display: block;
  }
  .leader-desktop {
    display: none;
  }
  .desktop-news {
    display: none;
  }
  .leader-slider {
    display: block;
  }
  .capitals-list li p {
    font-size: 14px;
    text-align: justify;
  }
  .capitals-list li p strong {
    font-size: 400 !important;
  }
  .leader-item img {
    max-width: 69%;
  }
  .cta {
    padding: 2rem;
  }
  .cta .row {
    padding-top: 215px;
  }
  .cta .row h2 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .cta h2 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .cta2 {
    padding: 2rem;
  }
  .cta2 .row {
    padding-top: 25px;
  }
  .cta2 .row h2 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .cta2 h2 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .landing-page p {
    font-size: 14px !important;
    text-align: justify;
  }
  .fb-section h3 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .fb-section p {
    font-size: 14px !important;
    text-align: justify;
  }
  .ceo {
    display: block;
    margin-top: -18px;
  }
  .ceo-profile {
    max-width: 90%;
  }
  .home-detail {
    font-size: 13px;
    text-align: justify;
  }
  .icon-bullet {
    font-size: 13px;
    text-align: justify;
  }
  .find-us-box {
    margin-bottom: 1rem;
  }
  .find-us-box.small {
    max-width: 100%;
    margin-bottom: 1rem;
  }
  .section-trade {
    padding: 0;
  }
  .section-about {
    padding: 0;
  }
  .challanges .challanges-wrap {
    min-height: auto;
  }
  .challanges .challanges-wrap h2 {
    font-size: 2.4rem;
  }
  .section-trade .asset-box {
    margin-bottom: 140px;
  }
  .features-section {
    min-height: auto;
    display: none;
  }
  .section-tutorials .tutorial-box {
    margin-bottom: 2rem;
  }
  .slick-initialized .slick-slide {
    margin: auto;
    text-align: center;
  }
}
.bt-wrap h3 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.5rem;
}
.bt-wrap .form-control {
  min-height: 56px;
}

.bt-user {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  flex-direction: column;
  text-align: center;
}
.bt-user .bt-photo {
  width: 70px;
}
.bt-user .bt-photo img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.bt-user h3 {
  color: #FFBE18;
  font-family: "Barlow", sans-serif;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}
.bt-user p {
  margin: 0;
  font-size: 0.9rem;
}

.uc_wrap {
  background-color: #1b1b1a;
  color: #FFF;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uc_wrap .logo {
  margin-bottom: 2rem;
}
.uc_wrap .logo img {
  width: 120px;
}
.uc_wrap h2 {
  color: #FFBE18;
  font-family: "Bebas";
  font-size: 4rem;
}

.kyc_left_menu {
  padding: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  width: 100%;
  border-radius: 20px;
}
.kyc_left_menu .inner {
  padding-top: 50px;
  background-color: #FAFAFA;
  height: 80vh;
  border-radius: 18px;
}
.kyc_left_menu .logo {
  text-align: center;
}
.kyc_left_menu ul {
  margin: 60px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.kyc_left_menu ul li {
  text-align: center;
  position: relative;
}
.kyc_left_menu ul li:first-child::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.kyc_left_menu ul li::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(180, 164, 88);
  background: linear-gradient(270deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
  position: absolute;
  bottom: 0;
  left: 0;
}
.kyc_left_menu ul li a {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  padding: 12px 0;
  display: block;
}
.kyc_left_menu ul li a.active {
  font-weight: 600;
  color: #1F1F1F;
}

.kyc_wrap {
  background-color: #FFF;
  max-width: 900px;
  margin: auto;
}
.kyc_wrap .kyc_row {
  display: block;
}
.kyc_wrap .kyc_row .kycleft {
  flex-basis: 18%;
}
.kyc_wrap .kyc_row .kycleft .kyc_terms {
  margin-top: 50px;
  text-align: center;
}
.kyc_wrap .kyc_row .kycright {
  flex-basis: 82%;
}
.kyc_wrap .kyc_row .kycright .kyc_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kyc_wrap .kyc_terms {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.kyc_wrap .kyc_terms a {
  display: block;
  margin-bottom: 6px;
  color: #1F1F1F;
  font-size: 13px;
}

.agreement_wrap {
  max-width: 900px;
}
.agreement_wrap .agreement_head {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 0;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
}
.agreement_wrap .agreement_footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 0;
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
}
.agreement_wrap .agreement_footer .form-check {
  margin-bottom: 1rem;
}
.agreement_wrap .agreement_footer .form-check label {
  font-size: 16px;
  font-weight: 400;
  color: rgb(90, 90, 90);
}
.agreement_wrap p {
  text-align: justify;
}

.kyc_1 h2 {
  font-weight: 600;
  font-size: 34px;
}
.kyc_1 p {
  font-weight: 400;
  font-size: 18px;
  color: rgb(142, 142, 142);
}

.kyc_title h3 {
  font-weight: 600;
  font-size: 20px;
  color: #1F1F1F;
}
.kyc_title p {
  font-weight: 400;
  font-size: 16px;
  color: rgb(142, 142, 142);
  margin-bottom: 40px;
  max-width: 600px;
}

.offcanvas_country {
  width: 100%;
  height: 600px;
}
.offcanvas_country .offcanvas-title {
  width: 100%;
  text-align: center;
}

.card_list_of_countries {
  position: absolute;
  max-height: 300px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 74px;
  z-index: 1;
  overflow-x: scroll;
  overflow-x: hidden;
}
.card_list_of_countries:hover::-webkit-scrollbar-thumb {
  display: block;
}
.card_list_of_countries:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.card_list_of_countries::-webkit-scrollbar {
  width: 4px;
}
.card_list_of_countries::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 50px;
  display: none;
}

.countries_list_offcanvas {
  margin: 0;
  padding: 8px;
  list-style-type: none;
  background-color: #1F1F1F;
}
.countries_list_offcanvas li {
  font-size: 1rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.countries_list_offcanvas li img {
  width: 22px;
  margin-right: 8px;
}
.countries_list_offcanvas li:hover {
  color: #FFBE18;
}

.cam_wrap video {
  width: 100% !important;
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: rgb(45, 45, 45);
  padding: 40px;
  flex-direction: column;
}
.upload-box label {
  text-align: center;
}
.upload-box h4 {
  font-weight: 500;
  font-size: 16px;
}
.upload-box p {
  font-size: 12px;
  margin-bottom: 20px;
  color: rgb(181, 168, 168);
}
.upload-box input {
  position: absolute;
  opacity: 0;
  left: 0;
}

.webcam_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 400px;
  overflow: hidden;
  margin: auto;
  border-radius: 50%;
  border: 3px dashed gray;
}

.button-capture {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .kyc_box {
    margin-top: 60px;
  }
  .kyc_title h3 {
    font-size: 16px;
  }
  .kyc_title p {
    font-size: 14px;
  }
}
.page {
  width: 21cm;
  min-height: 28cm;
  padding: 0.5cm 1cm;
  margin: auto;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

@page {
  size: A4;
  margin: 0;
}
.agreement-print {
  background: #FFFDEA;
  text-align: justify;
  box-shadow: none;
  font-size: 13px;
}
.agreement-print > img {
  width: 100%;
  margin-bottom: 20pt;
}
.agreement-print .agreement-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10pt 0;
  gap: 10pt;
}
.agreement-print .agreement-footer .left,
.agreement-print .agreement-footer .right {
  width: 48%;
  position: relative;
}
.agreement-print .agreement-footer .lable {
  font-weight: 700;
}
.agreement-print .agreement-footer .line {
  border-bottom: 1px solid black;
}
.agreement-print .agreement-footer .line p {
  margin: 10px 0px 2px 0;
  font-weight: 800;
}
.agreement-print .agreement-footer .sign {
  border-bottom: 1px solid black;
}
.agreement-print .agreement-footer .stamp {
  position: absolute;
  top: -155px;
  right: 25px;
  width: 150px;
  rotate: 34deg;
}

.invoice-head {
  background: rgb(200, 158, 72);
  background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
  color: #FFF;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  border-radius: 20px;
}

.invoice-data {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.invoice-data li {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 500;
}
.invoice-data li:last-child {
  border-bottom: none;
}

.invoice-address h4 {
  font-size: 14px;
  color: #FFBE18;
  margin: 0;
}
.invoice-address p {
  font-size: 12px;
}

.address-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.address-item i {
  color: #FFBE18;
  font-size: 14px;
}
.address-item span {
  font-size: 13px;
  margin-left: 6px;
}

.invoice-title {
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  position: relative;
  text-transform: capitalize;
}
.invoice-title::after {
  content: "";
  height: 3px;
  width: 50px;
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #FFBE18;
}

.invoice-heading {
  display: flex;
  justify-content: flex-start;
}
.invoice-heading label {
  position: relative;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 6px;
  color: #FFBE18;
}
.invoice-heading label::after {
  content: "";
  height: 3px;
  width: 20px;
  position: absolute;
  left: 0;
  bottom: -5px;
  background-color: #FFBE18;
}
.invoice-heading span {
  font-weight: 400;
  color: #000;
}

.invoice-sign {
  margin-top: 10px;
  border-top: 0.8px solid rgba(0, 0, 0, 0.6);
  width: 100%;
}
.invoice-sign label {
  font-weight: 600;
  margin-top: 10px;
}

.inv-box {
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.6);
  padding: 20px;
  min-height: 114px;
}
.inv-box.border-dashed {
  border-style: dashed;
}
.inv-box p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.inv-box h3 {
  color: #000;
  font-size: 18px;
  margin: 10px 0 0 0;
  font-weight: bold;
}
.inv-box h4 {
  font-size: 14px;
}
.inv-box h4 span {
  color: rgba(0, 0, 0, 0.5);
}

.inv-date {
  font-size: 13px;
}
.inv-date span {
  color: rgba(0, 0, 0, 0.5);
}

.inv-bordered-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.inv-bordered-items.column {
  flex-direction: column;
  align-items: flex-start;
}
.inv-bordered-items.column li {
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
}
.inv-bordered-items.column li:last-child {
  border-bottom: none;
}
.inv-bordered-items li {
  flex: 1 auto;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  padding: 0 20px;
}
.inv-bordered-items li:last-child {
  border-right: 0;
}
.inv-bordered-items li span {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  white-space: nowrap;
}
.inv-bordered-items li p {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

#inv-footer {
  position: relative;
  border-top: 1px solid #D1D1D1;
  margin: 20px 0;
  padding-top: 20px;
}

.qr-wrap {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
}
.qr-wrap img {
  width: 100%;
}

.invoice-list-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.invoice-list-items li {
  margin-left: 30px;
  border-left: 2px solid #FFBE18;
  padding-left: 8px;
}
.invoice-list-items li p {
  margin: 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.invoice-list-items li h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.wallet-box {
  background-color: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 144px;
  border-radius: 10px 0px 0px 10px;
}
.wallet-box p {
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.wallet-box h4 {
  margin: 5px 0 0 0;
  font-size: 17px;
  font-weight: 500;
}

.wallet-amount-box {
  border-radius: 0px 10px 10px 0px;
  border: 0.5px dashed rgba(0, 0, 0, 0.5);
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet-amount-box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
}
.wallet-amount-box ul li {
  display: flex;
}
.wallet-amount-box ul li .w-label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  width: 45%;
  text-align: right;
}
.wallet-amount-box ul li .w-divider {
  width: 10%;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}
.wallet-amount-box ul li .w-amount {
  font-weight: 500;
}

.footer-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.footer-info p {
  font-size: 12px;
  color: #6d6d6d;
  margin: 0;
}
.footer-info span {
  font-size: 20px;
  color: #6d6d6d;
}
.footer-info h5 {
  font-size: 13px;
  margin: 0;
}

.cash-collection-print .collection-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.cash-collection-print .collection-head img {
  width: 250px;
}
.cash-collection-print .collection-head .collection-text {
  background-color: #FFBE18;
  height: 45px;
  padding: 10px 30px 10px 50px;
  position: relative;
}
.cash-collection-print .collection-head .collection-text span {
  color: white;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
}
.cash-collection-print .collection-head .collection-text::after {
  content: "";
  width: 45px;
  height: 45px;
  rotate: 45deg;
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  transform: translate(-50%, 50%);
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
  .invoice-title {
    -webkit-print-color-adjust: exact !important;
  }
}
@media screen and (max-width: 767px) {
  .page {
    margin-top: 30% !important;
  }
}
.rs_country_item {
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;
  border-radius: 20px;
}
.rs_country_item img {
  width: 100%;
  transition: all 0.5s ease;
}
.rs_country_item:hover img {
  transform: scale(1.1);
}
.rs_country_item .button {
  position: absolute;
  right: 37px;
  bottom: 31px;
  padding: 4px 20px;
  min-width: 100px;
}

#main {
  max-width: 100%;
  margin: auto;
  background-image: url("../images/dashboard/login-bg.png");
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  color: rgba(255, 255, 255, 0.6);
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
  position: relative;
}

.business-content {
  padding-top: 80px;
}

.login_box {
  padding: 20px;
  background-color: #000;
}
.login_box .login_box_head {
  text-align: center;
  margin-top: 30px;
}
.login_box .login_box_head h4 {
  font-size: 14px;
  font-weight: normal;
}
.login_box .login_box_head img {
  width: 207px;
  margin-bottom: 20px;
}
.login_box .login_box_body {
  background-color: #000;
  padding: 20px 20px;
  border-radius: 6px;
  margin-top: 50px;
}
.login_box .login_box_body .verify {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.login_box .login_box_body .verify .Character {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: #45F882;
  width: 40%;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-shadow: 1px solid black;
  letter-spacing: 0.4rem;
  font-style: italic;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.login_box .login_box_body .verify .icon-input {
  width: 60%;
}
.login_box .login_box_body .verify .icon-input .form-control {
  padding: 17px 12px !important;
}

.wallet-card {
  min-height: 208px;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1019607843), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 24px;
  font-family: "Montserrat", sans-serif;
}
.wallet-card button {
  white-space: nowrap;
}

.action-icon {
  width: 20px;
}

.mag-wallet-actions .red {
  padding: 0 32px;
}
.mag-wallet-actions h4 {
  color: #FFBE18;
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}
.mag-wallet-actions .wallet-info {
  max-width: 80%;
}
.mag-wallet-actions .wallet-info li {
  position: relative;
  padding-right: 40px;
}
.mag-wallet-actions .wallet-info li svg {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.mag-wallet-actions .wallet-buttons {
  margin-left: 13px;
}

.wallet-buttons {
  margin-right: 80px;
  gap: 20px;
  display: flex;
  align-items: center;
}

.wallet-head-icon {
  display: flex;
  gap: 10px;
}
.wallet-head-icon .icon {
  width: 56px;
  height: 56px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 64, 128, 0.05);
  border-radius: 10px;
}
.wallet-head-icon .icon.red {
  background-color: rgba(255, 0, 43, 0.1);
}
.wallet-head-icon .icon.orange {
  background-color: rgba(255, 96, 64, 0.1);
}
.wallet-head-icon .icon.yellow {
  background-color: rgba(255, 143, 64, 0.1);
}
.wallet-head-icon .icon svg {
  width: 32px;
}

.wallet-head p {
  margin: 0;
  color: #525866;
  font-size: 16px;
  font-weight: 500;
}
.wallet-head h4 {
  margin: 0;
  font-weight: 400;
  font-size: 26px;
}

.wallet-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 400px;
  padding-top: 13px;
  margin-top: 13px;
}
.wallet-info li {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.wallet-info li:last-child {
  border-right: none;
}
.wallet-info li > a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.wallet-info li label {
  color: #525866;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}
.wallet-info li span {
  font-size: 18px;
  white-space: nowrap;
}

.wallet-wrap {
  padding: 30px;
}
.wallet-wrap .wallet-head {
  text-align: center;
}
.wallet-wrap .wallet-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px 0 10px 0;
  margin-bottom: 10px;
}
.wallet-wrap .wallet-body .code {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.wallet-wrap .wallet-body .balance {
  display: flex;
  color: #7B8599;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  gap: 10px;
}
.wallet-wrap .wallet-body .balance span {
  font-weight: 700;
}
.wallet-wrap .wallet-footer {
  display: flex;
  gap: 20px;
  padding-top: 10px;
}

[data-bs-theme=dark] .wallet-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #000000;
  box-shadow: none;
}
[data-bs-theme=dark] .wallet-info {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
[data-bs-theme=dark] .wallet-info li {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.mag-wallet {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .mag-wallet {
    display: block;
  }
  .wallet-card {
    background-image: none;
  }
  .mag-wallet-actions .wallet-info {
    max-width: 100%;
  }
  .wallet-buttons {
    display: block;
    margin-right: 0;
  }
  .wallet-buttons a {
    margin-top: 10px;
    display: block;
  }
  .wallet-info {
    flex-wrap: wrap;
  }
  .wallet-info li {
    border-right: none;
  }
}
.package-detail-section {
  padding: 20px;
}

.responsive-package-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.responsive-package-list > li.active {
  background: linear-gradient(180deg, #FFBE18 0%, #FFBE18 29.98%, #FFF 29.98%, #FFF 100%);
}

.package-detail-card .image img {
  border-radius: 12px;
  width: 100%;
  height: auto;
}
.package-detail-card .image::after {
  content: "";
  position: relative;
  width: 313px;
  height: 3px;
  flex-shrink: 0;
  background: linear-gradient(90deg, rgba(255, 190, 24, 0) 0%, #FFBE18 50%, rgba(255, 190, 24, 0) 100%);
}
.package-detail-card svg {
  width: 100%;
}
.package-detail-card .package-header label {
  color: #525866;
  font-size: 16px;
  text-transform: uppercase;
}
.package-detail-card .package-header h4 {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.54px;
  letter-spacing: 0.1px;
}
.package-detail-card .detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
}
.package-detail-card .detail span {
  font-size: 20px;
  font-weight: 800;
  line-height: 27.54px;
  letter-spacing: 0.1px;
  font-weight: 600;
  color: #FFBE18;
}

.package-card-view-button > div {
  background: linear-gradient(0deg, #F5F5F7 0%, #F5F5F7 100%), #FFF;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 400px;
}
.package-card-view-button > div .package-card {
  align-items: center;
  position: relative;
  box-shadow: none;
}

.package-card-view {
  margin-bottom: 24px;
  padding: 8px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.package-card-view .package-card {
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.package-card-view .package-card .image {
  border-radius: 12px;
  min-width: 100%;
}
.package-card-view .package-card .image img {
  border-radius: 12px;
  width: 100%;
  height: auto;
}
.package-card-view .package-card .content .ribbon .ribbon-text {
  color: white;
}

.package-icon {
  position: absolute;
  top: 50px;
  right: 15px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.package-icon span {
  color: #FFBE18;
  font-size: 50px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
}
.package-icon span small {
  font-size: 20px;
}
.package-icon label {
  color: #FFBE18;
  font-size: 13px;
  position: relative;
  margin-top: -7px;
}
.package-icon label::before {
  bottom: -3px;
  right: 0;
}

.package-card {
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 26px;
  padding: 26px;
  position: relative;
}
.package-card.secondary {
  box-shadow: 0px 1px 4px 2px #45F882 inset;
}
.package-card.secondary .icon-secondary label {
  color: #45F882;
}
.package-card.secondary .icon-secondary label::before, .package-card.secondary .icon-secondary label::after {
  background-color: #45F882;
}
.package-card.secondary .icon-secondary span {
  color: #45F882;
}
.package-card.secondary .secondary-text {
  color: #45F882 !important;
}
.package-card.blue {
  box-shadow: 0px 1px 4px 2px #48C7FF inset;
}
.package-card.blue .icon-blue label {
  color: #48C7FF;
}
.package-card.blue .icon-blue span {
  color: #48C7FF;
}
.package-card.blue .blue-text {
  color: #48C7FF !important;
}
.package-card.blue .button-blue {
  background-color: #48C7FF !important;
  box-shadow: -1px -1px 11px 2px rgba(72, 200, 255, 0.51) !important;
}
.package-card.red {
  box-shadow: 0px 1px 4px 2px #FF1662 inset;
}
.package-card.red .red-text {
  color: #FF1662 !important;
}
.package-card.red .icon-red label {
  color: #FF1662;
}
.package-card.red .icon-red span {
  color: #FF1662;
}
.package-card.red .button-red {
  background-color: #FF1662 !important;
  box-shadow: -1px -1px 11px 2px rgba(255, 22, 100, 0.51) !important;
}
.package-card .primary-text {
  color: #FFBE18;
}
.package-card .secondary-text {
  color: #45F882 !important;
}
.package-card .blue-text {
  color: #48C7FF !important;
}
.package-card .red-text {
  color: #FF1662 !important;
}
.package-card .content .package-header label {
  color: #525866;
  font-size: 16px;
  text-transform: uppercase;
}
.package-card .content .package-header h4 {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.54px;
  letter-spacing: 0.1px;
}
.package-card .content .detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
}
.package-card .content .detail .price {
  font-size: 20px;
  font-weight: 800;
  line-height: 27.54px;
  letter-spacing: 0.1px;
  font-weight: 600;
  color: #FFBE18;
}
.package-card .content .detail .price.secondary {
  color: #45F882;
}
.package-card .content .list {
  padding: 0;
  list-style-type: none;
  text-align: left;
  margin: 0;
  gap: 1px;
  width: 100%;
}
.package-card .content .list li {
  display: flex;
  justify-content: space-between;
  text-align: center;
  position: relative;
  padding: 4px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.package-card .content .list li label {
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
}
.package-card .content .list li span {
  color: #FFF;
}
.package-card .content .list li:last-child {
  border-bottom: none;
}
.package-card .content .list span {
  font-weight: 600;
}
.package-card .content .package-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  column-gap: 15px;
  margin-left: 10px;
}
.package-card .content .package-footer a {
  color: #525966;
  font-size: 12px;
  font-weight: 700;
}
.package-card .completed {
  flex-direction: column;
  text-align: center;
  margin: 0 !important;
}
.package-card .completed svg {
  width: 150px !important;
}
.package-card .icon {
  color: #000;
}

.active-package-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 494px;
}
.active-package-list li {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  width: 100%;
}
.active-package-list li:first-child {
  font-weight: 600;
}
.active-package-list li:first-child .sr {
  background-color: transparent;
  color: #000;
}
.active-package-list li .sr {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #FFBE18;
  border-radius: 50%;
  color: white;
  flex-shrink: 0;
}
.active-package-list li .package {
  width: 100%;
}
.active-package-list li .values {
  width: 120px;
}
.active-package-list li.active {
  background: #F5F7FC;
}
.active-package-list li .package h1 {
  font-size: 16px;
  margin: 0;
}
.active-package-list li .package label {
  font-size: 13px;
}
.active-package-list li .package h6 {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}
.active-package-list li .values {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.active-package-list li .values p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.active-package-list li .values span {
  color: #FF002B;
  font-size: 12px;
}
.active-package-list li .values.paid {
  align-items: flex-end;
}
.active-package-list li .values.pending {
  align-items: center;
}
.active-package-list li .values.pending span {
  color: #7B8599;
}

.properte-card {
  position: relative;
  font-family: "Montserrat", sans-serif;
}
.properte-card .ribbon {
  position: absolute;
  margin: 22px 10px 0px -15px;
  background: #FFBE18;
}
.properte-card .content {
  display: flex;
  min-width: 232px;
  padding: 15px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  color: #000;
}
.properte-card .content .package-header {
  position: relative;
  width: 100%;
  margin-top: 45px;
}
.properte-card .content .package-header label {
  color: #525866;
  font-size: 16px;
  text-transform: uppercase;
}
.properte-card .content .package-header h4 {
  text-align: left;
  font-size: 21px;
  font-style: italic;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
.properte-card .content > ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin: 0;
  margin-top: 10px;
  list-style-type: none;
  width: 100%;
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 10px;
  row-gap: 10px;
}
.properte-card .content > ul li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.properte-card .content > ul li label {
  color: #525866;
  font-size: 14px;
  line-height: 16px;
}
.properte-card .content > ul li span {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.properte-card .content .princing li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.properte-card .content .princing span {
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
}
.properte-card .content .package-footer {
  width: 100%;
}

.color-chose {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0;
  list-style-type: none;
}
.color-chose li {
  display: inline-block;
}
.color-chose li input[type=radio] {
  display: none;
}
.color-chose li label {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.3s;
}
.color-chose li label:hover {
  box-shadow: inset 0 0 4px 0px rgba(0, 0, 0, 0.9);
}
.color-chose li input[type=radio]:checked + label {
  box-shadow: inset 0 0 4px 0px rgba(0, 0, 0, 0.9);
}

.opportunity-icon-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  gap: 10px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}
.opportunity-icon-list .info {
  display: flex;
  gap: 10px;
  width: 100%;
}
.opportunity-icon-list .user-card {
  width: 100%;
}
.opportunity-icon-list .user-card .front,
.opportunity-icon-list .user-card .back {
  border-radius: 10px;
}
.opportunity-icon-list .user-card img {
  border-radius: 10px;
}
.opportunity-icon-list h1 {
  font-family: "Montserrat", sans-serif;
  color: #FFBE18;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  margin: 0;
}
.opportunity-icon-list > span {
  font-size: 14px;
  line-height: 16px;
}
.opportunity-icon-list li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 8px;
  gap: 10px;
}
.opportunity-icon-list li:last-child {
  border-bottom: none;
}
.opportunity-icon-list li.close svg {
  color: rgb(123, 133, 153);
}
.opportunity-icon-list li svg {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  font-size: 1rem;
  color: #FFBE18;
}
.opportunity-icon-list li p {
  font-size: 14px;
  margin: 0;
  text-wrap: nowrap;
  color: #fff;
}

.product-feature {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
  background: #FFF;
  padding: 40px 20px 10px 20px;
}
.product-feature ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  list-style-type: none;
}
.product-feature ul li {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
}
.product-feature ul li a {
  color: #000;
}
.product-feature ul li:hover a {
  color: #FFBE18;
}
.product-feature ul li:last-child {
  border-bottom: none !important;
  padding-bottom: 0;
}
.product-feature ul li .product {
  width: 30%;
  font-size: 14px;
  font-weight: 700;
}
.product-feature ul li .product-info {
  width: 70%;
}

.purchase-now-card {
  border-radius: 16px;
}
.purchase-now-card .modal-content {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.purchase-now-card .modal-header {
  height: 64px;
  flex-shrink: 0;
  background-color: rgb(0, 0, 0);
  justify-content: center;
  border-bottom: 0;
  border-radius: 16px 16px 0 0;
}
.purchase-now-card .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  background-color: rgb(0, 0, 0);
}
.purchase-now-card .btn-close {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 1;
  background: transparent;
}
.purchase-now-card .btn-close svg {
  width: 30px;
  color: #45F882;
}
.purchase-now-card .verify {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.purchase-now-card .verify .icon-input {
  width: 100%;
}
.purchase-now-card .verify .button {
  width: 100%;
}
.purchase-now-card .form-check {
  cursor: pointer;
}
.purchase-now-card .form-check .form-check-label {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.4px;
}
.purchase-now-card .form-check .link {
  margin-left: 10px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  text-transform: uppercase;
}
.purchase-now-card .help {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  width: 100%;
}
.purchase-now-card .help label {
  color: #ffffff;
  font-size: 16px;
}
.purchase-now-card .condition {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.purchase-now-card .condition label {
  font-size: 0.9rem;
  color: #fff;
}
.purchase-now-card .condition .select {
  width: 100%;
}
.purchase-now-card .balance {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
}
.purchase-now-card .balance label {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.purchase-now-card .balance span {
  color: #FFBE18;
  font-size: 28px;
  font-weight: 600;
}
.purchase-now-card .modal-footer {
  background-color: rgb(0, 0, 0);
  padding: 1rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

.calc-result {
  background-size: cover;
  border-radius: 16px;
  box-shadow: 0px 16px 24px -16px #CC7A96;
  padding: 32px;
}

.calc-title {
  font-size: 16px;
  font-weight: 600;
}

.calc-progress {
  height: 10px;
  background: #F2E6E8;
  border-radius: 5px;
  margin-top: 20px;
}
.calc-progress > div {
  height: 10px;
  border-radius: 5px;
  background-color: #FFBE18;
}

.calc-item {
  margin-top: 20px;
  padding-right: 40px;
  /* Apply custom track styles */
  /* Apply custom thumb styles */
  /* Adjust thumb position for Firefox */
}
.calc-item .range-list {
  margin: 10px 0;
  padding: 0;
  height: 10px;
  background: #F2E6E8;
  border-radius: 5px;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
}
.calc-item .range-list li {
  margin-top: -8px;
}
.calc-item .range-list li button {
  width: 28px;
  height: 28px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #efefef;
}
.calc-item .range-list li button.active {
  border: 4px solid #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  background: #FFBE18;
}
.calc-item p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
}
.calc-item input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin: 10px 0;
}
.calc-item input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: #F2E6E8;
  border-radius: 5px;
}
.calc-item input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: #F2E6E8;
  border-radius: 5px;
}
.calc-item input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background: #FFBE18;
  border-radius: 50%;
  border: 4px solid #FFF;
  margin-top: -10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.calc-item input[type=range]::-moz-range-thumb {
  width: 28px;
  height: 28px;
  cursor: pointer;
  background: #FFBE18;
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
.calc-item input[type=range]::-moz-range-progress {
  background-color: #FFBE18;
}

.calc-detail-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.calc-detail-list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.mobile-container {
  padding: 0 26px;
}

.theme-card {
  background-color: rgba(2, 2, 12, 0.5);
  border-radius: 10px;
}
.theme-card .theme-card-action svg {
  width: 34px;
  position: absolute;
  right: 0;
  top: 0;
  color: #45F882;
}
.theme-card.bg-safe {
  background-image: url("../images/safebg.jpg");
  background-size: cover;
  padding: 30px 0;
}

.main-bg-safe {
  display: flex;
  align-items: flex-end;
  padding: 10px 0 0 0 !important;
}
.main-bg-safe h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: #45F882;
}
.main-bg-safe span {
  color: #fff;
}
.main-bg-safe .music-wrap {
  display: flex;
  gap: -50px;
}
.main-bg-safe .right-music {
  margin-left: -148px;
}
.main-bg-safe .music {
  width: 200px;
  height: 70px;
  overflow: hidden;
  margin-bottom: -10px;
}
.main-bg-safe .balance {
  width: 56.5%;
  position: relative;
}
.main-bg-safe .balance h6 {
  margin-top: 6px;
}
.main-bg-safe .balance h2 {
  font-size: 32px !important;
  color: #FFBE18;
}
.main-bg-safe .balance h2 span {
  color: #FFBE18;
}
.main-bg-safe .balance::before {
  content: "";
  right: 0;
  position: absolute;
  width: 2px;
  height: 90%;
  background: linear-gradient(94deg, rgba(69, 248, 129, 0.4) 0%, #45F882 50%, rgba(69, 248, 129, 0.4) 100%);
}
.main-bg-safe .right {
  width: 43.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}
.main-bg-safe .right .brand-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.main-bg-safe .right img {
  width: 25px;
}
.main-bg-safe .right h3 {
  color: #fff;
  font-size: 24px;
  margin: 0;
}
.main-bg-safe .right p {
  font-weight: 600;
  font-size: 26px;
  color: #FFBE18;
  margin: 0;
}
.main-bg-safe .right .coin {
  width: 40px;
}

.buy-sale-section {
  overflow: hidden;
  margin-top: 20px;
  background-image: url("../images/dashboard/login-bg.png");
  background-size: cover;
  background-attachment: fixed;
}
.buy-sale-section .top-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buy-sale-section .top-card .left {
  width: 55%;
}
.buy-sale-section .top-card .left svg {
  width: 25px;
  height: 25px;
  background: linear-gradient(180deg, #FF9813 0%, #FEF458 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.buy-sale-section .top-card .right {
  width: 40%;
}
.buy-sale-section .top-card .right img {
  width: 25px;
  height: 25px;
}
.buy-sale-section .top-card .left,
.buy-sale-section .top-card .right {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1.2px solid #FF9712;
}
.buy-sale-section .top-card .left span,
.buy-sale-section .top-card .right span {
  color: #FFF659;
  font-size: 17px;
  font-weight: 500;
  text-wrap: nowrap;
}
.buy-sale-section .buy-sale-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  border-radius: 10px;
  border: 1px solid #9F7E1E;
  padding: 10px 20px 10px 0;
}
.buy-sale-section .buy-sale-card .left {
  width: 40%;
  padding: 0 10px;
}
.buy-sale-section .buy-sale-card .left img {
  width: 100%;
}
.buy-sale-section .buy-sale-card .right {
  width: 60%;
}
.buy-sale-section .buy-sale-card .right a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  border-radius: 6px;
  background: linear-gradient(90deg, #FF1662 0%, #F9982C 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.buy-sale-section .buy-sale-card .right a:last-child {
  background: linear-gradient(90deg, #48C7FF 0%, #EE91FF 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
}
.buy-sale-section .buy-sale-card .right a .label {
  display: flex;
  align-items: center;
  gap: 2px;
}
.buy-sale-section .buy-sale-card .right a .label svg {
  color: #fff;
  width: 18px;
  height: 18px;
}
.buy-sale-section .buy-sale-card .right a .label p {
  margin: 0;
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 700;
}
.buy-sale-section .buy-sale-card .right a .amount {
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
}
.buy-sale-section .buy-sale-card > span,
.buy-sale-section .buy-sale-card > p {
  color: #FFF;
  font-weight: 600;
  margin: 0;
}

.bic-view-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}
.bic-view-list li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #000;
  position: relative;
  border-radius: 15px;
  padding: 10px;
  width: 31%;
  gap: 0;
  min-height: 105px;
}
.bic-view-list li a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bic-view-list li:nth-child(1) {
  width: 100%;
  border-radius: 19px;
  border: 1px solid #F5C147;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}
.bic-view-list li:nth-child(1) .left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.bic-view-list li:nth-child(1) .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bic-view-list li:nth-child(1) .content strong {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 500;
}
.bic-view-list li:nth-child(1) .content span {
  color: #FFF;
  font-size: 25px;
  font-weight: 700;
}
.bic-view-list li:nth-child(1) img {
  width: 70px;
}
.bic-view-list li:nth-child(1) svg {
  width: 20px;
  color: #fff;
}
.bic-view-list li:nth-child(2) {
  border: 1px solid #FF9712;
}
.bic-view-list li:nth-child(2) svg {
  width: 23px;
}
.bic-view-list li:nth-child(2) strong {
  color: #FFF659;
}
.bic-view-list li:nth-child(2) span {
  color: #FFF659;
}
.bic-view-list li:nth-child(3) {
  border: 1px solid #FF1662;
}
.bic-view-list li:nth-child(3) svg {
  width: 23px;
}
.bic-view-list li:nth-child(3) strong {
  color: #FF1662;
}
.bic-view-list li:nth-child(3) span {
  color: #FF1662;
}
.bic-view-list li:nth-child(4) {
  border: 1px solid #48C7FF;
}
.bic-view-list li:nth-child(4) svg {
  width: 20px;
}
.bic-view-list li:nth-child(4) strong {
  color: #48C7FF;
}
.bic-view-list li:nth-child(4) span {
  color: #48C7FF;
}
.bic-view-list li strong {
  font-weight: 400;
  font-size: 15px;
}
.bic-view-list li span {
  font-size: 14px;
  font-weight: 700;
}

.bar-background {
  background: #373737;
  border-radius: 5px;
  padding: 10px;
}

.dash-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}
.dash-header .menu {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
}
.dash-header .menu .menu-link {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  height: 33px;
  overflow: hidden;
  border-radius: 25px;
  border: 1px solid #45F882;
  margin: 0;
  width: 100%;
}
.dash-header .menu .menu-link li {
  position: relative;
  flex: 1;
}
.dash-header .menu .menu-link li a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50px;
  font-size: 11px;
  font-weight: 500;
  padding: 7px 10px;
  color: #ffffff;
  cursor: pointer;
  text-wrap: nowrap;
}
.dash-header .menu .menu-link li a.active {
  background: #45F882;
  color: #000;
  font-weight: 600;
}
.dash-header .menu .menu-link li .menu-date {
  visibility: hidden;
  width: 0;
  max-width: 0;
  min-width: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.dash-header .menu .menu-link li > button {
  background: transparent;
  border: none;
  padding: 10px 15px;
  padding-right: 10px;
}
.dash-header .menu .menu-link li svg {
  width: 20px;
  color: #fff;
}
.dash-header .menu .menu-icon {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  height: 33px;
  overflow: hidden;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0;
}
.dash-header .menu .menu-icon li {
  position: relative;
}
.dash-header .menu .menu-icon li a {
  padding: 10px 15px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.dash-header .menu .menu-icon li a.active {
  background: rgba(255, 255, 255, 0.05);
  color: #FFBE18;
}
.dash-header .menu .menu-icon li svg {
  width: 20px;
}

.user-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 20px;
  margin-top: 15px;
}
.user-history li {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 50%;
  text-align: center;
}
.user-history li svg {
  width: 30px;
  color: #FFBE18;
}
.user-history li:first-child {
  align-items: flex-start;
}
.user-history li:last-child {
  align-items: flex-end;
}

.future-safe {
  background-image: url("../images/futurebg.jpg");
  background-size: cover;
  padding: 42px 26px;
}
.future-safe .future-safe-balance p {
  font-size: 18px;
  margin: 0;
}
.future-safe .future-safe-balance h3 {
  margin: 0 0 20px 0;
  font-size: 38px;
  font-weight: bold;
  color: #FFF;
  font-family: "Barlow", sans-serif;
}
.future-safe .buy-more-wrap a {
  display: flex;
  flex-direction: column;
}
.future-safe .buy-more-wrap a svg {
  width: 18px;
}
.future-safe .buy-more-wrap a strong {
  font-size: 30px;
}
.future-safe .buy-more-wrap a span {
  color: rgba(255, 255, 255, 0.8);
}

.rewards-card {
  padding: 26px;
}
.rewards-card.secondary .reward-title h2 {
  color: #45F882;
}
.rewards-card.secondary .reward-list li {
  box-shadow: 2px 1px 5px 0px rgba(69, 248, 130, 0.79);
  -webkit-box-shadow: 2px 1px 5px 0px rgba(69, 248, 130, 0.79);
  -moz-box-shadow: 2px 1px 5px 0px rgba(69, 248, 130, 0.79);
}
.rewards-card.secondary .reward-list li .left .count {
  border-color: #45F882;
}
.rewards-card .reward-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rewards-card .reward-title p {
  margin: 0;
  color: #FFF;
}
.rewards-card .reward-title h2 {
  margin: 0;
  color: #FFBE18;
  font-size: 30px;
}
.rewards-card .reward-head {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
.rewards-card .reward-head p {
  margin: 0px;
}
.rewards-card .reward-list {
  margin: 10px 0 0 0;
  padding: 0;
  list-style-type: none;
}
.rewards-card .reward-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 1px 5px 0px rgba(255, 190, 24, 0.79);
  -webkit-box-shadow: 2px 1px 5px 0px rgba(255, 190, 24, 0.79);
  -moz-box-shadow: 2px 1px 5px 0px rgba(255, 190, 24, 0.79);
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 12px;
  position: relative;
}
.rewards-card .reward-list li > a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.rewards-card .reward-list li .left {
  display: flex;
  align-items: center;
}
.rewards-card .reward-list li .left .count {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #FFBE18;
  color: #FFF;
  margin-right: 10px;
  font-family: "Barlow", sans-serif;
  font-weight: bold;
}
.rewards-card .reward-list li .left h3 {
  margin: 0;
  color: #FFF;
}
.rewards-card .reward-list li h4 {
  margin: 0;
}

.inline-buttons {
  display: flex;
  justify-content: space-between;
  gap: 18px;
}
.inline-buttons a {
  flex: 1;
}
.inline-buttons a img {
  width: 100%;
  display: inline-block;
}

.balance-card {
  text-align: center;
}
.balance-card .balance h2 {
  color: #FFBE18;
  font-family: "Barlow", sans-serif;
  font-size: 40px;
  margin: 0;
}
.balance-card .balance p {
  font-size: 26px;
  margin: 0;
  color: #FFF;
}

.inv-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.inv-info .icon img {
  width: 62px;
}
.inv-info .info {
  padding-left: 14px;
}
.inv-info .info p {
  color: #FFF;
  font-size: 18px;
  margin: 0 0 4px 0;
}
.inv-info .info h3 {
  font-weight: bold;
  color: #FFBE18;
  font-family: "Barlow", sans-serif;
  font-size: 32px;
  margin: 0;
}
.inv-info .info.profit h3 {
  color: #45F882;
}

.card-shadow {
  min-height: 200px;
  background-color: rgba(2, 2, 12, 0.01);
  border-radius: 10px;
  overflow: hidden;
}
.card-shadow.primary {
  box-shadow: 0px 1px 2px 3px rgb(255, 190, 24) inset;
  -webkit-box-shadow: 0px 1px 2px 3px rgb(255, 190, 24) inset;
  -moz-box-shadow: 0px 1px 2px 3px rgb(255, 190, 24) inset;
}
.card-shadow.secondary {
  box-shadow: 0px 1px 2px 3px rgb(69, 248, 130) inset;
  -webkit-box-shadow: 0px 1px 2px 3px rgb(69, 248, 130) inset;
  -moz-box-shadow: 0px 1px 2px 3px rgb(69, 248, 130) inset;
}

.subscribers-box {
  height: 350px;
  overflow: hidden;
  background: #000;
}

.theme-shadow-title {
  font-size: 20px;
  font-weight: bold;
}
.theme-shadow-title.primary {
  color: #FFBE18;
}
.theme-shadow-title.secondary {
  color: #45F882;
}

.recent-team-members .member-item {
  display: flex;
  justify-content: center;
}
.recent-team-members .member-item .photo {
  margin-right: 10px;
}
.recent-team-members .member-item .photo img {
  width: 40px;
}
.recent-team-members .member-item .content p {
  color: #FFF;
  margin: 0;
  font-size: 12px;
}
.recent-team-members .member-item .content h4 {
  margin: 0;
  font-size: 17px;
  font-family: "Barlow", sans-serif;
  color: #FFBE18;
}

.team-count {
  margin: 0 auto;
  padding: 26px;
  list-style-type: none;
  max-width: 480px;
}
.team-count li {
  position: relative;
  margin-bottom: 12px;
}
.team-count li img {
  width: 100%;
}
.team-count li:nth-child(even) .count-content {
  flex-direction: row-reverse;
}
.team-count li:nth-child(even) .count-content p {
  margin-left: 10px;
  margin-right: 0;
}
.team-count li .count-content {
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-count li .count-content p {
  margin: 0 10px 0 0;
  font-size: 18px;
  color: #FFF;
}
.team-count li .count-content h3 {
  color: #FFBE18;
  margin: 0;
  font-size: 30px;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  z-index: 10;
  padding: 0 16px;
  list-style-type: none;
  display: flex;
  background-color: #13181D;
  height: 70px;
  justify-content: space-between;
}
.bottom-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bottom-menu ul li a {
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-top: 6px;
  border-top: 2px solid transparent;
  opacity: 0.7;
}
.bottom-menu ul li a .home-icon path {
  fill: rgba(255, 255, 255, 0.7);
}
.bottom-menu ul li a svg {
  width: 24px;
}
.bottom-menu ul li a.active {
  opacity: 1;
  border-top-color: #45F882;
  color: #45F882;
}
.bottom-menu ul li a.active .home-icon path {
  fill: #45F882;
}

.wallets-wrap {
  padding: 40px 0;
}
.wallets-wrap ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  width: 100%;
  overflow-x: scroll;
}
.wallets-wrap ul li {
  position: relative;
  margin-right: 16px;
}
.wallets-wrap ul li .wallet-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 34px;
}
.wallets-wrap ul li .wallet-content p {
  color: #FFF;
  margin: 0;
  font-size: 18px;
}
.wallets-wrap ul li .wallet-content h2 {
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  color: #FFBE18;
  font-family: "Barlow", sans-serif;
}

.recent-members-list {
  list-style-type: none;
  flex-direction: column;
  margin: 0;
}
.recent-members-list li {
  position: relative;
}
.recent-members-list li .member-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.recent-members-list li .member-info .photo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background: #000;
  border: 1px solid #45F882;
  width: 50px;
  height: 50px;
  flex-shrink: 1;
}
.recent-members-list li .member-info .photo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.recent-members-list li .member-info .photo .avatar {
  color: #ffffff;
  font-weight: 700;
}
.recent-members-list li .member-info .user-info {
  display: flex;
  align-items: center;
}
.recent-members-list li .member-info .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}
.recent-members-list li .member-info .content span {
  font-size: 14px;
}
.recent-members-list li .member-info .content p {
  color: #FFF;
  margin: 0;
  font-size: 17px;
}
.recent-members-list li .member-info h4 {
  font-size: 20px;
  font-family: "Barlow", sans-serif;
  color: #45F882;
  margin: 0;
}
.recent-members-list li .divider {
  margin: 10px 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(69, 248, 129, 0.4) 0%, #45F882 50%, rgba(69, 248, 129, 0.4) 100%);
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.slider-marquee {
  --grid-gap: 15px;
  --animation-duration: 5s;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 15px 0;
  overflow: hidden;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
  /* New class for top-to-bottom marquee */
  /* New class for bottom-to-top marquee */
}
.slider-marquee__track {
  display: flex;
  animation: marquee var(--animation-duration) linear infinite;
  animation-play-state: running;
}
.slider-marquee.reverse-marquee .slider-marquee__track {
  animation: reversemarquee var(--animation-duration) linear infinite;
  animation-play-state: running;
}
.slider-marquee.reverse-marquee:hover .slider-marquee__track {
  animation-play-state: paused;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes reversemarquee {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.slider-marquee__grid {
  display: flex;
  gap: var(--grid-gap);
  list-style: none;
  padding: 0;
  margin: 0;
}
.slider-marquee.vertical-marquee .slider-marquee__track {
  display: block;
  animation: marquee-vertical 35s linear infinite;
  animation-play-state: running;
}
.slider-marquee.vertical-marquee:hover .slider-marquee__track {
  animation-play-state: paused;
}
@keyframes marquee-vertical {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
.slider-marquee.reverse-vertical-marquee .slider-marquee__track {
  display: block;
  animation: reversemarquee-vertical var(--animation-duration) linear infinite;
  animation-play-state: running;
}
.slider-marquee.reverse-vertical-marquee:hover .slider-marquee__track {
  animation-play-state: paused;
}
@keyframes reversemarquee-vertical {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.recent-withdrawals {
  padding-bottom: 20px;
}
.recent-withdrawals .recent-withdrawals-slider .member-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  background-color: #000;
  opacity: 1;
}
.recent-withdrawals .recent-withdrawals-slider .member-info .photo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(45deg, #FFBE18, #45F882);
  width: 50px;
  height: 50px;
  flex-shrink: 1;
}
.recent-withdrawals .recent-withdrawals-slider .member-info .photo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.recent-withdrawals .recent-withdrawals-slider .member-info .photo .avatar {
  color: #000000;
  font-weight: 700;
}
.recent-withdrawals .recent-withdrawals-slider .member-info p {
  color: #FFF;
  margin: 0;
  font-size: 17px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.recent-withdrawals .recent-withdrawals-slider .member-info .content h4 {
  font-size: 17px;
  font-family: "Barlow", sans-serif;
  color: #45F882;
  margin: 0;
}
.recent-withdrawals .recent-withdrawals-slider .member-info .divider {
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(69, 248, 129, 0.4) 0%, #45F882 50%, rgba(69, 248, 129, 0.4) 100%);
}
@keyframes zoomInAndHide {
  0% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(1) {
  animation-delay: 0s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(2) {
  animation-delay: 9s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(3) {
  animation-delay: 18s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(4) {
  animation-delay: 27s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(5) {
  animation-delay: 36s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(6) {
  animation-delay: 45s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(7) {
  animation-delay: 54s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(8) {
  animation-delay: 63s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(9) {
  animation-delay: 72s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(10) {
  animation-delay: 81s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(11) {
  animation-delay: 90s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(12) {
  animation-delay: 99s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(13) {
  animation-delay: 108s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(14) {
  animation-delay: 117s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(15) {
  animation-delay: 126s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(16) {
  animation-delay: 135s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(17) {
  animation-delay: 144s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(18) {
  animation-delay: 153s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(19) {
  animation-delay: 162s;
}
.recent-withdrawals .recent-withdrawals-slider .slick-slide:nth-child(20) {
  animation-delay: 171s;
}

.list-transactions {
  margin: 10px;
  padding: 0;
  list-style-type: none;
}
.list-transactions li {
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
}
.list-transactions li > div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px 0;
}
.list-transactions li > div:last-child {
  border-bottom: none;
}
.list-transactions li > div span {
  font-size: 13px;
}
.list-transactions li > div span.value {
  color: #FFF;
}

.button-arrow-back {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.button-arrow-back svg {
  width: 26px;
}

.nav-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
.nav-menu li a {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  color: #FFF;
  font-size: 18px;
}
.nav-menu li a.active {
  color: #FFBE18;
}
.nav-menu li a svg {
  width: 24px;
  margin-right: 6px;
}

.server-time {
  text-align: center;
  margin-top: 12px;
}
.server-time p {
  margin: 0;
}
.server-time h4 {
  margin: 0;
  color: #FFF;
  font-size: 18px;
}

.advertisement {
  height: 100px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-top: 20px;
}

.timer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #45F882;
  border-radius: 10px;
}
.timer-box .time-item p {
  color: #FFF;
  font-size: 13px;
  margin: 0;
}
.timer-box .time-item h4 {
  font-size: 22px;
  font-family: "Barlow", sans-serif;
  color: #45F882;
  margin-bottom: 0;
}
.timer-box .time-item h4.red {
  color: #FF5231;
}

.ref-link {
  padding: 30px;
  margin-top: 30px;
}
.ref-link .ref-link-box {
  display: flex;
  background: #000;
  padding: 10px 20px 10px 40px;
  border-radius: 33px;
  font-size: 13px;
  overflow: hidden;
  position: relative;
}
.ref-link .ref-link-box svg {
  width: 18px;
  color: #45F882;
  position: absolute;
  left: 14px;
}

.dash-icon-box {
  background: rgb(244, 143, 255);
  background: linear-gradient(0deg, rgb(244, 143, 255) 0%, rgb(64, 201, 255) 100%);
  text-align: center;
  border-radius: 10px;
  padding: 12px;
  display: block;
}
.dash-icon-box.yellow {
  background: rgb(255, 249, 91);
  background: linear-gradient(0deg, rgb(255, 249, 91) 0%, rgb(255, 147, 15) 100%);
}
.dash-icon-box.red {
  background: rgb(248, 155, 41);
  background: linear-gradient(0deg, rgb(248, 155, 41) 0%, rgb(255, 15, 123) 100%);
}
.dash-icon-box .icon {
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dash-icon-box .icon svg {
  width: 26px;
  color: rgba(255, 255, 255, 0.6);
}
.dash-icon-box p {
  margin: 10px 0 2px 0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.1;
  font-weight: 500;
}
.dash-icon-box h3 {
  margin: 0px;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
}

.new-members {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.new-members h3 {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.1), 0px -1px 2px rgba(255, 255, 255, 0.1);
  background: linear-gradient(35deg, rgb(0, 195, 255), red, rgb(255, 0, 255), rgb(255, 238, 0));
  background-clip: text;
  -webkit-background-clip: text;
  font-family: "Barlow", sans-serif;
  color: transparent;
  width: fit-content;
}
@keyframes rotate {
  to {
    --angle: 360deg;
  }
}
@property --angle {
  .new-members {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
}

.new-members {
  background-color: #02020C;
  padding: 26px 40px;
  margin-top: 2rem;
  text-align: center;
}
.new-members > div {
  margin: auto;
}
.new-members p {
  margin-top: 20px;
  margin-bottom: 0;
}

.total-earnings {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-image: url("../images/earningbg.png");
  background-size: cover;
  border-image: fill 0 linear-gradient(rgba(0, 0, 0, 0.2), #000);
}
.total-earnings .info {
  text-align: center;
}
.total-earnings .info p {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #45F882;
}
.total-earnings .icon {
  display: flex;
  align-items: center;
  gap: 20px;
}
.total-earnings .icon h4 {
  margin: 0;
  color: #FFBE18;
  font-weight: bold;
  font-size: 36px;
  font-family: "Barlow", sans-serif;
}

.task {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 34px;
}
.task b {
  font-size: 20px;
  font-family: "Barlow", sans-serif;
  color: #FFF;
}
.task .rank-progress {
  height: 18px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.task .rank-progress .bar {
  border-radius: 54px;
  height: 18px;
  background: linear-gradient(35deg, rgb(0, 195, 255), red, rgb(255, 0, 255), rgb(255, 238, 0));
  position: relative;
  z-index: 1;
  width: var(--progress-width);
  animation: wave 5s linear infinite;
  background-size: 200% 100%;
}
.task .rank-progress .bar-bg {
  background-color: #000000;
  border-radius: 80px;
  height: 18px;
  width: 100%;
  margin-top: -18px;
}

@keyframes wave {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200% 0;
  }
}
.promotion {
  padding-bottom: 50px;
}
.promotion h1 {
  margin-top: 0;
}

#clockdiv {
  display: flex;
  justify-content: center;
  text-align: center;
}
#clockdiv .wrapper {
  padding: 0 14px;
  line-height: 1.3;
}
#clockdiv .wrapper .time {
  color: #FFBE18;
  font-size: 30px;
  font-weight: bold;
}

.dash-countdown .countdown-timer {
  background: rgb(69, 248, 130);
  background: linear-gradient(180deg, rgb(69, 248, 130) 0%, rgb(10, 142, 55) 100%);
  border-radius: 50px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 38px;
}
.dash-countdown .countdown-timer.short-timer {
  width: 210px;
}
.dash-countdown .countdown-timer .wrapper {
  padding: 2px 2.5px !important;
  line-height: 1.3 !important;
  display: flex;
  align-items: center;
}
.dash-countdown .countdown-timer .wrapper .time {
  color: #FFF !important;
  font-size: 25px !important;
  font-weight: bold !important;
}
.dash-countdown .countdown-timer .wrapper .smalltext {
  color: #FFF !important;
  font-size: 0 !important;
  font-weight: bold !important;
  margin-right: 7px;
}
.dash-countdown .countdown-timer .wrapper .smalltext::first-letter {
  font-size: initial !important;
  font-size: 25px !important;
}
.dash-countdown .countdown-timer .wrapper .smalltext::after {
  content: ":";
  font-size: 25px;
  color: #FFF;
  font-weight: bold !important;
  margin-left: 7px;
  font-weight: bold !important;
}
.dash-countdown .countdown-timer .wrapper:last-child .smalltext::after {
  display: none;
}

.partner-table {
  overflow-y: hidden;
  overflow-x: scroll;
  border-radius: 16px;
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  box-shadow: none;
  padding-top: 12px;
}
.partner-table .table-wrap {
  position: relative;
  width: 100%;
  padding: 0 15px;
}
.partner-table .table-wrap::after, .partner-table .table-wrap::before {
  content: "";
  width: 20%;
  height: 45px;
  background: #F8FAFF;
  position: absolute;
  top: 0%;
  display: none;
}
.partner-table .table-wrap::after {
  left: 0%;
  transform: translate(-50%, 0%);
}
.partner-table .table-wrap::before {
  right: 0%;
  transform: translate(0%, 0%);
}
.partner-table .table {
  z-index: 1;
  position: relative;
}
.partner-table .table thead {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.partner-table .table thead tr {
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: block;
  margin-bottom: 1rem;
  box-shadow: rgba(26, 108, 225, 0.1) 0px 0px 25px;
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  border-radius: 10px;
}
.partner-table .table thead tr th {
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
  display: block;
  text-align: right;
  color: #fff;
}
.partner-table .table thead tr th .form-check-input {
  float: right;
}
.partner-table .table thead tr th::before {
  content: attr(data-label);
  float: left;
  color: rgba(255, 255, 255, 0.6) !important;
}
.partner-table .table tbody tr {
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  display: block;
  text-align: right;
  color: #fff;
  background-color: #000;
}
.partner-table .table tbody tr .form-check-input {
  float: right;
}
.partner-table .table tbody tr td {
  text-wrap: nowrap;
  min-height: 31.5938px;
  font-size: 14px;
  font-weight: 700;
  display: block;
  text-align: right;
  color: #fff;
  position: relative;
}
.partner-table .table tbody tr td .total {
  color: #525866;
  font-size: 16px;
  font-weight: 700;
}
.partner-table .table tbody tr td.else {
  text-align: center;
}
.partner-table .table tbody tr td.else svg {
  width: 40px;
  stroke: #FFBE18;
  margin-bottom: 10px;
}
.partner-table .table tbody tr td.else h4 {
  font-size: 14px;
  margin: 0;
}
.partner-table .table tbody tr td .form-check-input {
  float: right;
}
.partner-table .table tbody tr td:last-child {
  border: transparent !important;
}
.partner-table .table tr {
  display: block;
  margin-bottom: 1rem;
  padding: 10px;
  border-radius: 5px;
}
.partner-table .table td {
  display: block;
  text-align: right;
  padding: 0.3rem 0.1rem !important;
  z-index: 1;
}
.partner-table .table td::before {
  content: attr(data-label);
  float: left;
  color: #7e7e7e;
}
.partner-table .table td::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 190, 24, 0) 0%, #FFBE18 50%, rgba(255, 190, 24, 0) 100%);
  display: flex;
  bottom: -5%;
}
.partner-table .table td:last-child {
  border-bottom: 0;
}
.partner-table .table td:last-child::after {
  display: none;
}

.table-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  gap: 20px;
}
.table-footer .page-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.table-footer .page-info label {
  color: rgba(255, 255, 255, 0.42);
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.table-footer .page-info label span {
  color: rgba(255, 255, 255, 0.87);
}
.table-footer .page-info .number-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.table-footer .page-info .number-select .form-control {
  width: 71px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.table-footer .page-info .number-select .form-control:focus-visible, .table-footer .page-info .number-select .form-control:focus, .table-footer .page-info .number-select .form-control:active {
  border: 1px solid #FFBE18;
  outline: #FFBE18;
}
.table-footer .page-info .number-select .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.bs-pagination-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.bs-pagination-wrap .arrows svg {
  width: 20px;
  stroke: #ffffff;
  stroke-width: 1.5px;
}
.bs-pagination-wrap .arrows.disabled svg {
  stroke: rgba(255, 255, 255, 0.15);
}

.bs-pagination {
  display: flex;
  list-style-type: none;
  padding: 2px;
  align-items: center;
  gap: 1px;
  border-radius: 8px;
  border: 1px solid transparent;
  margin: 0;
  padding: 0;
}
.bs-pagination .page-item {
  margin: 0 5px;
  width: 44px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bs-pagination .page-item svg {
  stroke: #FFBE18;
}
.bs-pagination .page-item:first-child {
  margin-left: 0;
}
.bs-pagination .page-item:last-child {
  margin-right: 0;
}
.bs-pagination .page-item .page-link {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFBE18;
  border: none;
}
.bs-pagination .page-item .page-link span {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: unset;
}
.bs-pagination .page-item .page-link:focus {
  outline: none;
  box-shadow: none;
}
.bs-pagination .page-item.active .page-link {
  border-radius: 20px;
  background: #FFBE18;
  width: 44px;
  height: 44px;
  color: #000;
}
.bs-pagination .page-item.active .page-link::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

[data-bs-theme=dark] .partner-table {
  border: 1px solid #303030;
  background-color: #000000;
}
[data-bs-theme=dark] .partner-table .table-wrap {
  position: relative;
  width: 100%;
  padding: 0 15px;
}
[data-bs-theme=dark] .partner-table .table-wrap::after, [data-bs-theme=dark] .partner-table .table-wrap::before {
  background: #303030;
}
[data-bs-theme=dark] .partner-table .table thead {
  background-color: #303030;
}
[data-bs-theme=dark] .partner-table .table thead tr th {
  border-right: 1px solid #303030;
  border-bottom: 1px solid #303030;
  color: white;
}
[data-bs-theme=dark] .partner-table .table tbody tr td {
  border-color: #303030;
  color: white;
}
[data-bs-theme=dark] .partner-table .table tbody tr td.else h4 {
  color: white;
}
[data-bs-theme=dark] .partner-table .table tbody tr:last-child {
  border-bottom: 1px solid #303030 !important;
}
[data-bs-theme=dark] .table-footer .page-info label {
  color: rgba(255, 255, 255, 0.42);
}
[data-bs-theme=dark] .table-footer .page-info label span {
  color: rgba(255, 255, 255, 0.87);
}
[data-bs-theme=dark] .table-footer .page-info .number-select input {
  border: 1px solid rgba(245, 245, 245, 0.15);
}
[data-bs-theme=dark] .table-footer .page-info .number-select input::placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}
[data-bs-theme=dark] .bs-pagination-wrap .arrows svg {
  stroke: #ffffff;
}
[data-bs-theme=dark] .bs-pagination-wrap .arrows.disabled svg {
  stroke: rgba(255, 255, 255, 0.5);
}
[data-bs-theme=dark] .bs-pagination {
  border: 1px solid rgba(251, 251, 251, 0.5);
}
[data-bs-theme=dark] .bs-pagination .page-item .page-link {
  color: #ffffff;
}
[data-bs-theme=dark] .bs-pagination .page-item.active .page-link {
  color: #000000;
}

.else-emoji {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.else-emoji svg {
  width: 40px;
  stroke: #FFBE18;
  margin-bottom: 10px;
}
.else-emoji h4 {
  font-size: 14px;
  margin: 0;
}

.partner-card {
  background-color: #fafafa;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  overflow: hidden;
}
.partner-card label {
  margin-bottom: 5px;
}
.partner-card .form-select {
  border: 1px solid #f0f0f0;
}
.partner-card option {
  background-color: #fff;
}

[data-bs-theme=dark] .partner-card {
  background-color: #303030;
  border: 1px solid #303030;
}
[data-bs-theme=dark] .partner-card .form-select {
  border: 1px solid #303030;
  background-color: black;
  color: white;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .partner-card option {
  background-color: black;
  color: white;
}

.theme-icons {
  position: fixed;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.theme-icons .iocn {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 32px;
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
}
.theme-icons .iocn .sun {
  stroke: #FFBE18;
}
.theme-icons .iocn svg {
  width: 24px;
}

.support-pannel .else {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10%;
}
.support-pannel .else h4 {
  color: #515151;
  margin: 0;
  font-size: 15px;
}
.support-pannel .else svg {
  width: 100px;
  color: #e9e9e9;
}

.support-icons {
  position: fixed;
  bottom: 80px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  flex-shrink: 0;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 56px;
  z-index: 500;
}
.support-icons img {
  width: 100%;
}

[data-bs-theme=dark] .support-pannel-offcanvas {
  background-color: #17181a;
}
[data-bs-theme=dark] .support-pannel-offcanvas .offcanvas-title {
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .btn-close {
  width: 25px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/contain no-repeat;
  margin: 0;
  padding: 0;
}
[data-bs-theme=dark] .support-pannel-offcanvas .nav-support {
  background-color: #000000 !important;
  border-bottom: none;
}
[data-bs-theme=dark] .support-pannel-offcanvas .nav-support svg path {
  stroke: #525866;
}
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .icon-input input,
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .icon-input .form-select,
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .icon-input textarea {
  background: #000;
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .icon-input input::placeholder,
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .icon-input .form-select::placeholder,
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .icon-input textarea::placeholder {
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .attachment-link span {
  color: rgba(255, 255, 255, 0.6);
}
[data-bs-theme=dark] .support-pannel-offcanvas .new-ticket-wrap .attachment-link svg {
  stroke: #fff;
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item {
  background: #000;
  border: 1px solid rgba(255, 255, 255, 0.15);
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item .inbox-menu svg {
  stroke: #525866;
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item .inbox-menu p {
  color: #525866;
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item > span {
  background: #525866;
  color: #fff;
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item:hover {
  border: 1px solid #FFBE18;
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item:hover .inbox-menu svg {
  stroke: #FFBE18;
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item:hover .inbox-menu p {
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .inbox-item:hover > span {
  background: #FFBE18;
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .tickte-detail-head svg {
  stroke: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .tickte-detail-head h3 {
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .list-support-tickets li h2,
[data-bs-theme=dark] .support-pannel-offcanvas .list-support-tickets li > div {
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .list-support-tickets li .ticket-id {
  color: #FFBE18;
}
[data-bs-theme=dark] .support-pannel-offcanvas .list-support-tickets li .button {
  background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
  color: #FFF;
  border: none;
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .post-info-actions .attachment-link span {
  color: rgba(255, 255, 255, 0.6);
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .post-info-actions .attachment-link svg {
  stroke: #FFBE18;
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .user-reply .comment-message .inner {
  background: rgba(255, 16, 9, 0.47) !important;
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .user-reply .comment-message .inner p {
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .user-reply .comment-message .inner .comment-actions .date {
  color: #a1a1a1;
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .admin-reply .comment-message .inner {
  background: rgba(0, 0, 0, 0.47) !important;
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .admin-reply .comment-message .inner p {
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .bs-comment-post .admin-reply .comment-message .inner .comment-actions .date {
  color: #a1a1a1;
}
[data-bs-theme=dark] .support-pannel-offcanvas .reply {
  background: #000;
}
[data-bs-theme=dark] .support-pannel-offcanvas .reply svg {
  stroke: #FFBE18;
}
[data-bs-theme=dark] .support-pannel-offcanvas .reply .form-control {
  background: #17181a;
  color: white;
}
[data-bs-theme=dark] .support-pannel-offcanvas .else {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10%;
}
[data-bs-theme=dark] .support-pannel-offcanvas .else h4 {
  color: #515151;
  margin: 0;
  font-size: 15px;
}
[data-bs-theme=dark] .support-pannel-offcanvas .else svg {
  width: 100px;
  color: #e9e9e9;
}
[data-bs-theme=dark] .upload-img-model .modal-content {
  background-color: #17181a;
}
[data-bs-theme=dark] .upload-img-model .modal-title {
  color: white;
}
[data-bs-theme=dark] .upload-img-model .btn-close {
  width: 25px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/contain no-repeat;
  padding: 0;
  margin: 0;
}
[data-bs-theme=dark] .upload-img-model .model-pink {
  background: #000000;
}
[data-bs-theme=dark] .upload-img-model .thumnail svg,
[data-bs-theme=dark] .upload-img-model .thumnail h6 {
  color: white;
}
[data-bs-theme=dark] .theme-icons .iocn {
  background: #000000;
  box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);
}
[data-bs-theme=dark] .theme-icons .iocn svg {
  fill: #FFBE18;
  stroke: #FFBE18;
}
[data-bs-theme=dark] .support-icons .main-iocn,
[data-bs-theme=dark] .support-icons .open-icon {
  background: #000000;
  box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);
}
[data-bs-theme=dark] .support-icons .main-iocn svg path,
[data-bs-theme=dark] .support-icons .open-icon svg path {
  fill: #FFBE18;
}

.kyc-section {
  padding: 0 20px;
}
.kyc-section svg {
  width: 20px;
}
.kyc-section .kyc-info-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
}
.kyc-section .kyc-info-list b {
  font-size: 16px;
  font-weight: 600;
}
.kyc-section .kyc-info-list span {
  font-size: 16px;
}
.kyc-section .kyc-info-list p {
  margin: 0;
  font-size: 12px;
  color: #999999;
}
.kyc-section .examle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.kyc-section .examle label {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.kyc-section .examle span {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}
.kyc-section .kyc-upload {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
}
.kyc-section .kyc-upload li {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.kyc-section .kyc-img-card {
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
  padding: 10px;
  position: relative;
  width: 100%;
}
.kyc-section .kyc-img-card.franchise-kyc-card {
  width: 100%;
}
.kyc-section .kyc-img-card .upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(45, 45, 45);
  padding: 0;
  flex-direction: column;
}
.kyc-section .kyc-img-card .upload-box input {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.kyc-section .kyc-img-card .upload-box .thumnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.kyc-section .kyc-img-card .upload-box .thumnail svg {
  margin-bottom: 10px;
}
.kyc-section .kyc-img-card .upload-box .thumnail p {
  margin: 0;
}
.kyc-section .kyc-img-card.white {
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #fff inset;
  min-height: 220px;
}
.kyc-section .kyc-img-card.pink {
  min-height: 456px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
}
.kyc-section .kyc-img-card.blue {
  min-height: 220px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #45F882 inset;
}
.kyc-section .kyc-img-card .delete {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 15px;
}
.kyc-section .kyc-img-card .delete svg {
  stroke: #FFBE18;
}
.kyc-section .kyc-img-card.cursor_none {
  cursor: not-allowed;
}
.kyc-section .kyc-img-card.cursor_none .thumnail,
.kyc-section .kyc-img-card.cursor_none p {
  cursor: not-allowed;
}

.example-model img {
  width: 100%;
}

.kyc-submitted {
  padding: 20px;
  font-family: "Montserrat", sans-serif;
}
.kyc-submitted .pink-bg {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
  text-align: center;
}
.kyc-submitted .pink-bg h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  color: #FFBE18;
}
.kyc-submitted .pink-bg p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #fff;
}
.kyc-submitted .verification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
  padding: 8px 16px;
}
.kyc-submitted .verification .content h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.kyc-submitted .verification .content span {
  color: #525866;
  font-size: 14px;
  line-height: 16px;
}
.kyc-submitted .accordion-section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px;
}

.kyc-user-info {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}
.kyc-user-info li {
  width: 48%;
  position: relative;
}
.kyc-user-info li span {
  color: #FFBE18;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.kyc-user-info li h5 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}
.kyc-user-info li input {
  border: none;
  border-bottom: 1px solid #bababa;
  display: block;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 0;
  width: 85%;
  padding: 0;
}
.kyc-user-info li input:focus-visible, .kyc-user-info li input:focus, .kyc-user-info li input:active {
  outline: none;
  box-shadow: none;
}
.kyc-user-info li input:disabled {
  background: transparent;
}
.kyc-user-info li input::placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.kyc-user-info li > svg {
  width: 20px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.kyc-user-info li > svg:hover {
  color: #FFBE18;
}
.kyc-user-info li > button {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 10px;
  padding: 10px 20px;
}
.kyc-user-info li > button svg {
  width: 20px;
}
.kyc-user-info li:last-child {
  width: 100%;
}
.kyc-user-info li:nth-last-child(2), .kyc-user-info li:nth-last-child(3) {
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
}
.kyc-user-info li > button {
  padding: 7px;
}
.kyc-user-info li:last-child {
  width: 100%;
}

.border-free-accordion .accordion-item {
  border: none;
  background-color: transparent;
}
.border-free-accordion .accordion-item .accordion-header .accordion-button {
  background: transparent;
  padding: 10px 0;
  color: #FFBE18;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.border-free-accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}
.border-free-accordion .accordion-item .accordion-header .accordion-button::after {
  width: 16px;
  height: 16px;
  filter: invert(1);
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
.border-free-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  filter: invert(1);
}
.border-free-accordion .accordion-item .accordion-body {
  background-color: transparent;
  border: none;
  padding: 20px 0;
}

.passport-image img {
  width: 100%;
}

[data-bs-theme=dark] .kyc-section svg {
  width: 20px;
}
[data-bs-theme=dark] .kyc-section .kyc-img-card .upload-box {
  color: #17181a;
}
[data-bs-theme=dark] .kyc-section .kyc-img-card .upload-box .thumnail p {
  color: white;
}
[data-bs-theme=dark] .kyc-section .kyc-img-card .upload-box .thumnail svg {
  stroke: white;
}
[data-bs-theme=dark] .kyc-section .kyc-img-card.white {
  background: #17181a;
}
[data-bs-theme=dark] .kyc-section .kyc-img-card.pink {
  background-blend-mode: color, normal;
}
[data-bs-theme=dark] .kyc-section .kyc-img-card.blue {
  background: #000;
}
[data-bs-theme=dark] .kyc-section .kyc-img-card .delete {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #000000;
}
[data-bs-theme=dark] .kyc-submitted {
  font-family: "Montserrat", sans-serif;
}
[data-bs-theme=dark] .kyc-submitted .pink-bg {
  background: #17181a;
}
[data-bs-theme=dark] .kyc-submitted .pink-bg h5 {
  color: #FFBE18;
}
[data-bs-theme=dark] .kyc-submitted .pink-bg p {
  color: #525866;
}
[data-bs-theme=dark] .kyc-submitted .verification {
  background: #17181a;
}
[data-bs-theme=dark] .kyc-submitted .verification .content h4 {
  color: #FFBE18;
}
[data-bs-theme=dark] .kyc-submitted .verification .content span {
  color: #525866;
}
[data-bs-theme=dark] .kyc-submitted .opportunity-icon-list p {
  color: #525866;
}
[data-bs-theme=dark] .kyc-submitted .accordion-section {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
[data-bs-theme=dark] .kyc-submitted .accordion-section .accordion-item .accordion-header .accordion-button {
  color: #ffffff;
}
[data-bs-theme=dark] .kyc-submitted .accordion-section .accordion-item .accordion-header .accordion-button::after {
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e") center/contain no-repeat;
}
[data-bs-theme=dark] .kyc-submitted .accordion-section .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e") center/contain no-repeat;
}
[data-bs-theme=dark] .kyc-submitted .kyc-user-info li span {
  color: #525866;
}
[data-bs-theme=dark] .kyc-submitted .kyc-user-info li h5 {
  color: #ffffff;
}
[data-bs-theme=dark] .kyc-submitted .kyc-user-info li input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
  background: transparent;
}
[data-bs-theme=dark] .kyc-submitted .kyc-user-info li input::placeholder {
  color: #ffffff;
}
[data-bs-theme=dark] .kyc-submitted .kyc-user-info li svg {
  color: white;
}
[data-bs-theme=dark] .kyc-submitted .kyc-user-info li svg:hover {
  color: #FFBE18;
}
[data-bs-theme=dark] .border-free-accordion .accordion-item .accordion-header .accordion-button {
  color: #ffffff;
}
[data-bs-theme=dark] .border-free-accordion .accordion-item .accordion-header .accordion-button::after {
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FF0000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .border-free-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FF0000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

@media screen and (max-width: 767px) {
  .kyc-section {
    padding: 20px;
  }
  .kyc-section svg {
    width: 20px;
  }
  .kyc-section .kyc-upload {
    flex-direction: column;
    align-items: center;
  }
  .kyc-section .kyc-upload li {
    width: 100%;
  }
  .kyc-section .kyc-img-card {
    width: 100%;
  }
  .kyc-submitted .pink-bg {
    gap: 5px;
  }
  .kyc-submitted .pink-bg h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }
  .kyc-submitted .pink-bg p {
    font-size: 13px;
    line-height: normal;
  }
  .kyc-submitted .verification .content h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
  }
  .kyc-submitted .front,
  .kyc-submitted .back {
    width: 100%;
  }
}
.active-link-menu {
  width: 100%;
  text-wrap: nowrap;
  display: flex;
}
.active-link-menu > .nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: #000;
  border-radius: 0;
  column-gap: 14px;
  width: 100%;
  overflow-x: scroll;
}
.active-link-menu > .nav .nav-item {
  order: 1;
  position: relative;
}
.active-link-menu > .nav .nav-item:last-child .nav-link::after {
  left: -25px;
}
.active-link-menu > .nav .nav-item .nav-link {
  cursor: pointer;
  text-wrap: nowrap;
}
.active-link-menu > .nav .nav-item .nav-link span {
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.54px;
  letter-spacing: 0.1px;
  text-wrap: nowrap;
}
.active-link-menu > .nav .nav-item .nav-link.active {
  position: relative;
}
.active-link-menu > .nav .nav-item .nav-link.active::after {
  content: "";
  width: 39px;
  height: 1px;
  transform: rotate(-90deg) translate(0, 50%);
  background: linear-gradient(90deg, rgba(255, 190, 24, 0) 0%, #FFBE18 50%, rgba(255, 190, 24, 0) 100%);
  position: absolute;
  top: 50%;
  right: -25px;
}
.active-link-menu > .nav .nav-item .nav-link.active span {
  color: #FFBE18;
  text-wrap: nowrap;
}
.active-link-menu > .nav .nav-item .nav-link.active svg {
  stroke: #000;
}
.active-link-menu > .nav .nav-item.active {
  order: 0;
}

.menu-option {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #FFBE18;
  list-style: none;
  gap: 5px;
  margin: 23px;
  margin-top: 0;
  padding: 0;
}
.menu-option li .nav-link {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 16px;
  border: transparent;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
  cursor: pointer;
}
.menu-option li .nav-link label {
  cursor: pointer;
}
.menu-option li .nav-link svg {
  width: 20px;
}
.menu-option li .nav-link.active {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  color: #000 !important;
  background: #FFBE18;
}
.menu-option li .nav-link.active svg {
  color: #000 !important;
}

.verification-wrap {
  padding: 20px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}
.verification-wrap .tab-content {
  background: #f8faff;
  padding: 50px 0;
}
.verification-wrap .verification-info {
  width: 50%;
  margin-left: 25%;
}
.verification-wrap .verification-info > button {
  margin-top: 10px;
}
.verification-wrap .verification {
  display: flex;
  align-items: center;
  gap: 16px;
}
.verification-wrap .verification .icon-input {
  margin-bottom: 0;
  width: 80%;
}
.verification-wrap .alert-content {
  padding-top: 10px;
  text-align: start;
}
.verification-wrap .alert-content p {
  margin: 0;
  padding: 0;
}
.verification-wrap .transfer-header {
  background: #F8FAFF;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
}
.verification-wrap .transfer-header svg {
  width: 20px;
}
.verification-wrap .transfer-header span {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.verification-wrap .transfer-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px 0 10px 0;
  margin-bottom: 10px;
}
.verification-wrap .transfer-body .add-condition {
  margin: 0;
  color: #7B8599;
}
.verification-wrap .transfer-body .available-amout {
  color: #7B8599;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.verification-wrap .transfer-body .available-amout span {
  font-weight: 700;
}
.verification-wrap .transfer-body .code {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.verification-wrap .transfer-body .code .Resend p {
  color: #7B8599;
  font-size: 14px;
  line-height: 16px;
}
.verification-wrap .transfer-body .code .Resend p span {
  color: #7B8599;
}
.verification-wrap .transfer-body .code .Resend p b {
  color: #000;
}
.verification-wrap .transfer-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 10px;
}
.verification-wrap .transfer-footer button {
  width: 480%;
}

.fake-input {
  width: 100%;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(0, 0, 0, 0.05);
  height: 56px;
  font-family: "Montserrat", sans-serif;
}
.fake-input label {
  font-size: 16px;
  margin: 0;
}
.fake-input span {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}

ul.add-condition {
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
}

.verification-section > div {
  text-align: center;
}

[data-bs-theme=dark] .active-link-menu > .nav {
  background: #000000;
}
[data-bs-theme=dark] .active-link-menu > .nav .nav-item .nav-link svg {
  stroke: white;
}
[data-bs-theme=dark] .active-link-menu > .nav .nav-item .nav-link span {
  color: white;
}
[data-bs-theme=dark] .active-link-menu > .nav .nav-item .nav-link.active span {
  color: #FFBE18;
}
[data-bs-theme=dark] .active-link-menu > .nav .nav-item .nav-link.active svg {
  stroke: #FFBE18;
}
[data-bs-theme=dark] .menu-option {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
[data-bs-theme=dark] .menu-option li .nav-link {
  color: white;
}
[data-bs-theme=dark] .menu-option li .nav-link.active {
  color: #FFBE18;
  background: rgba(255, 26, 64, 0.23);
}

.print-footer > div {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.print-footer > div svg {
  width: 20px;
}
.print-footer > div img {
  width: 20px;
}
.print-footer > div p {
  margin: 0;
  font-size: 12px;
  padding-left: 18px;
}

.inv-bordered-items li > div {
  text-transform: uppercase;
  border: 1px solid green;
  border-radius: 33px;
  font-size: 16px;
  font-weight: 800;
  padding: 8px 14px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.inv-bordered-items li > div span {
  color: green;
}
.inv-bordered-items li > div svg {
  width: 20px;
}
.inv-bordered-items li > div svg path {
  color: #334e0e;
}

.download {
  width: 20px;
  cursor: pointer;
}
.download:hover {
  color: #FFBE18;
}

.dashboard_wallets .button {
  padding: 0.6rem 1rem;
}

.withdraw-first-step {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;
}
.withdraw-first-step svg {
  width: 40px;
  stroke: #c5c5c5;
}
.withdraw-first-step p {
  margin: 0;
}

.wallet-head .menu-option {
  display: flex;
  margin: 0;
  margin-bottom: 10px;
  border: none;
  justify-content: center;
}
.wallet-head .menu-option li .nav-link {
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wallet-head .menu-option li .nav-link.active {
  background: #FFBE18;
}

@media screen and (max-width: 767px) {
  .verification-wrap .verification-info {
    width: 100%;
    margin-left: 0;
  }
  .verification-wrap .verification-info .verification {
    flex-wrap: wrap;
  }
  .verification-wrap .verification-info .verification .icon-input {
    width: 100%;
  }
  .verification-wrap .verification-info .verification > button {
    width: 100%;
  }
  .verification-wrap .tab-content {
    padding: 25px 12px;
  }
  .menu-option li .nav-link {
    text-wrap: nowrap;
  }
}
.network-list .list-item {
  margin-bottom: 20px;
}
.network-list ul {
  list-style: none;
  padding: 0;
}
.network-list ul li.level-2 {
  padding-left: 5%;
}
.network-list ul li.level-2 .tree-card-wrap {
  box-shadow: 0px 1px 4px 2px #45F882 inset;
}
.network-list ul li.level-2 .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount h5 {
  color: #45F882 !important;
}
.network-list ul li.level-2 .tree-card-wrap .tree-card-right ul li .level span {
  background-color: #45F882;
  box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
}
.network-list ul li.level-2 .profile h5 {
  color: #45F882 !important;
}
.network-list ul li.level-3 {
  padding-left: 10%;
}
.network-list ul li.level-3 .tree-card-wrap {
  box-shadow: 0px 1px 4px 2px #135EE7 inset;
}
.network-list ul li.level-3 .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount h5 {
  color: #135EE7 !important;
}
.network-list ul li.level-3 .tree-card-wrap .tree-card-right ul li .level span {
  background-color: #135EE7;
  box-shadow: -1px -1px 11px 2px rgba(19, 94, 231, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(19, 94, 231, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(19, 94, 231, 0.51);
  color: #fff;
}
.network-list ul li.level-3 .tree-card-wrap .profile h5 {
  color: #135EE7 !important;
}
.network-list ul li .tree-card-wrap {
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
  display: flex;
  width: 90%;
  margin: auto;
  min-height: 184px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex-shrink: 0;
  position: relative;
  display: block;
}
.network-list ul li .tree-card-wrap .tree-card-right {
  position: absolute;
  right: 0;
  height: 100%;
  position: relative;
  width: 100%;
}
.network-list ul li .tree-card-wrap .tree-card-right ul {
  margin: 0;
  padding: 10px 0 0 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li {
  flex: none;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li.total_members .tree-icon-info {
  justify-content: flex-start;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-info {
  display: flex;
  gap: 20px;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-info h2 {
  color: #FFF;
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.54px;
  letter-spacing: 0.1px;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-info p {
  margin: 0;
  font-size: 0.9rem;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info p {
  margin: 0;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info span {
  color: #FFBE18;
  margin: 0 10px;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount:last-child {
  border-bottom: none;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount h5 {
  margin: 0;
  font-weight: 500;
  font-size: 1.1rem;
  white-space: nowrap;
  font-size: 20px;
  color: #FFBE18;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .tree-icon-info .amount p {
  font-size: 0.9rem;
  color: #7c7c7c;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .level {
  position: absolute;
  top: -80px;
  right: 0;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .level p {
  margin: 0;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .level span {
  background-color: #FFBE18;
  border-radius: 80px;
  color: #142129;
  position: relative;
  padding: 10px 10px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid rgba(255, 255, 255, 0.5);
  box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.network-list ul li .tree-card-wrap .tree-card-right ul li .level span::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: -24px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
}
.network-list ul li .tree-card-wrap .profile {
  display: flex;
  align-items: center;
  min-width: 25%;
  width: 100%;
}
.network-list ul li .tree-card-wrap .profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.network-list ul li .tree-card-wrap .profile .info {
  margin-left: 10px;
}
.network-list ul li .tree-card-wrap .profile .info h5 {
  margin: 0;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.25rem;
  margin-bottom: 2px;
  text-transform: capitalize;
  color: #FFBE18;
}
.network-list ul li .tree-card-wrap .profile .info p {
  margin: 0;
  font-weight: 300;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
}

.progress-wrap {
  margin-top: -4px;
}

.statement-table {
  font-family: "Montserrat", sans-serif;
}
.statement-table .partner-table {
  padding-top: 0;
}
.statement-table .partner-table .table-wrap::after, .statement-table .partner-table .table-wrap::before {
  height: 90px;
}
.statement-table .partner-table .table-wrap .table tr:first-child th {
  font-weight: 500;
}
.statement-table .filter-head p {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  padding: 4px 12px;
  height: 32px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.statement-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 20px 0;
  padding: 0;
  gap: 10px;
}
.statement-info li {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 30px 10px 10px;
}
.statement-info li:first-child {
  padding-left: 0;
}
.statement-info li:last-child {
  border-right: none;
}
.statement-info li p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
}
.statement-info li span {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.white-input {
  width: 100%;
}
.white-input input {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  display: flex;
  width: 100%;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}
.white-input input[type=date] {
  padding-right: 16px;
  display: block;
  width: 100%;
}

[data-bs-theme=dark] .statement-table .top-row {
  background-color: #303030;
}
[data-bs-theme=dark] .statement-table .top-row h4 {
  color: #717171;
}
[data-bs-theme=dark] .statement-table .top-row .blance {
  color: #717171;
}
[data-bs-theme=dark] .statement-table .filter-head p {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #000000;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .top-row {
    padding: 25px 10;
  }
  .top-row h4 {
    font-size: 15px;
    white-space: nowrap;
  }
  .top-row .blance {
    font-size: 13px;
    white-space: nowrap;
  }
}
.profile_header {
  text-align: center;
  display: inline-block;
}
.profile_header .profile_photo {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: #F0F2F7;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.profile_header .profile_photo img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}
.profile_header .profile_photo .profile_edit {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  display: none;
}
.profile_header .profile_photo .profile_edit svg {
  width: 20px;
}
.profile_header .profile_photo:hover .profile_edit {
  display: flex;
  background-color: rgba(0, 0, 0, 0.15);
}
.profile_header span {
  color: #525866;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.profile-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.profile-info svg {
  width: 20px;
}
.profile-info .content h4 {
  color: #7B8599;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0;
}
.profile-info .content span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.profile-info-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  list-style-type: none;
  padding: 0;
}
.profile-info-list li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.profile-info-list li .icon-form-group {
  width: 100%;
}
.profile-info-list li .icon-input {
  width: 100%;
}
.profile-info-list li button {
  text-wrap: nowrap;
}
.profile-info-list li .icon-input {
  margin: 0;
}

.kyc-box {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
  box-shadow: 0px 1px 4px 2px #FFBE18 inset;
  position: relative;
}
.kyc-box h1 {
  color: #FFF;
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.54px;
  letter-spacing: 0.1px;
  margin: 0;
}
.kyc-box p {
  margin: 0;
}

.Resend {
  font-size: 14px;
}
.Resend p {
  margin: 0;
  margin-top: 5px;
}
.Resend span {
  font-size: 14px;
}

.profile-bg-card {
  padding: 20px;
}

[data-bs-theme=dark] .profile-bg-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #000000;
  box-shadow: 0px 0px 1px 0px rgba(255, 255, 255, 0.1), 0px 0px 5px 0px rgba(255, 255, 255, 0.1);
}
[data-bs-theme=dark] .kyc-box {
  border: 1px solid rgba(255, 0, 43, 0.1);
  background: #1c0301;
  box-shadow: 0px 4px 16px 0px rgba(255, 0, 43, 0.1);
}
[data-bs-theme=dark] .kyc-box .ribbon::after {
  background: #1c0301;
}
[data-bs-theme=dark] .kyc-box p {
  margin: 0;
  margin-top: 40px;
}
[data-bs-theme=dark] .kyc-box a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 16px;
  gap: 8px;
  color: #000;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .profile-info-list li {
    flex-wrap: wrap;
  }
  .profile-info-list li button {
    width: 100%;
  }
}
.Toastify__toast-container {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  min-height: 200px;
  width: 90vw !important;
}

.Toastify__toast {
  position: relative;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  font-family: "Montserrat", sans-serif !important;
  border-radius: 20px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.Toastify__toast.Toastify__toast-theme--light {
  background: #000000;
}
.Toastify__toast .Toastify__toast-body {
  flex-direction: column;
  align-items: center !important;
  text-align: center;
  gap: 15px;
  height: 100%;
}
.Toastify__toast .Toastify__toast-body > div {
  margin: 10px 0;
  color: #ffffff;
}
.Toastify__toast .Toastify__toast-body .Toastify__toast-icon {
  display: none;
}
.Toastify__toast .Toastify__close-button {
  display: none;
}
.Toastify__toast .Toastify__progress-bar {
  bottom: 10px !important;
  left: 15px !important;
  width: 90% !important;
  height: 7px !important;
  opacity: 1 !important;
  transform-origin: left !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.Toastify__toast .Toastify__progress-bar.Toastify__progress-bar--success {
  background: #45F882 !important;
}
.Toastify__toast .Toastify__progress-bar.Toastify__progress-bar--error {
  background: #FF002B !important;
}
.Toastify__toast .Toastify__progress-bar.Toastify__progress-bar--error::before {
  content: "";
  background-color: #fff;
  height: 10px;
  width: 90%;
  border-radius: 10px !important;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.Toastify__toast--success {
  background-image: url("../images/dashboard/tosat/success-bg.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.Toastify__toast--success::before {
  content: url("../images/dashboard/tosat/successToast.svg");
  position: relative;
  z-index: 100000;
  top: 10px;
  animation: zoomInOut 2s infinite;
}
.Toastify__toast--success::after {
  content: url("../images/dashboard/tosat/closeToast.svg");
  position: absolute;
  right: 15px;
  top: 15px;
}

.Toastify__toast--error {
  background-image: url("../images/dashboard/tosat/error-bg.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: #fff !important;
}
.Toastify__toast--error::before {
  content: url("../images/dashboard/tosat/errorToast.svg");
  position: relative;
  z-index: 100000;
  top: 10px;
  animation: zoomInOut 2s infinite;
}
.Toastify__toast--error::after {
  content: url("../images/dashboard/tosat/closeToast.svg");
  position: absolute;
  right: 15px;
  top: 15px;
}

[data-bs-theme=dark] .Toastify__toast {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #000000;
  box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16prgba 255, 255, 255, 0.02;
}
[data-bs-theme=dark] .Toastify__toast .Toastify__toast-body > div {
  color: #ffffff;
}
[data-bs-theme=dark] .Toastify__toast--success::before {
  content: url("../images/dashboard/tosat/successToastDark.svg");
}
[data-bs-theme=dark] .Toastify__toast--error::before {
  content: url("../images/dashboard/tosat/errorToastDark.svg");
}

.reward-card {
  display: flex;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  font-family: "Montserrat", sans-serif;
}
.reward-card.active svg {
  border-radius: 50px;
  border: 1px solid #FFBE18;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  padding: 5px;
  width: 30px !important;
  height: 30px;
}
.reward-card.last-reward-card {
  flex-direction: row;
}
.reward-card.last-reward-card .content,
.reward-card.last-reward-card .thumnail {
  width: 50%;
}
.reward-card.last-reward-card .thumnail {
  text-align: end;
}
.reward-card.last-reward-card .thumnail img {
  width: 512px;
  height: 320px;
}
.reward-card .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  position: relative;
}
.reward-card .content svg {
  width: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.reward-card .content .rank-head {
  display: flex;
  align-items: flex-end;
  gap: 52px;
  align-self: stretch;
  position: relative;
}
.reward-card .content .rank-head .rank-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.reward-card .content .rank-head .rank-info span {
  color: #525866;
  font-size: 18px;
  text-transform: uppercase;
}
.reward-card .content .rank-head .rank-info h2 {
  margin: 0;
  color: #000;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
}
.reward-card .content .rank-head .criteria {
  width: 100%;
}
.reward-card .content .rank-head .criteria > span {
  color: #525866;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.reward-card .content .rank-head .criteria ul {
  margin: 0;
  width: 100%;
}
.reward-card .content .rank-head .criteria ul > span {
  color: #7B8599;
  font-size: 16px;
}
.reward-card .content .rank-head .criteria ul li {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.reward-card .content .rank-head .criteria ul li span {
  width: 20%;
  white-space: nowrap;
}
.reward-card .content .rank-head .criteria ul li label {
  width: 80%;
  white-space: nowrap;
}
.reward-card .content .border {
  position: relative;
  height: 0px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.reward-card .content .border img {
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translate(-50%, 0);
}
.reward-card .content .price {
  width: 100%;
}
.reward-card .content .price label {
  color: #525866;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  display: block;
}
.reward-card .content .price h3 {
  margin: 0;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  background: linear-gradient(92deg, #FF002B 47.98%, #FF4060 97.98%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.reward-card .content .price h3 span {
  font-size: 20px;
  font-weight: 400;
}
.reward-card .thumnail {
  border-radius: 16px;
  width: 100%;
  height: auto;
  flex-shrink: 0;
}
.reward-card .thumnail img {
  border-radius: 16px;
  width: 100%;
  height: auto;
}

[data-bs-theme=dark] .reward-card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #000000;
  box-shadow: 0px 4px 12px 0px rgba(255, 255, 255, 0.05);
}
[data-bs-theme=dark] .reward-card .content .rank-head .rank-info h2 {
  color: #fdfdfd;
}
[data-bs-theme=dark] .svg-ellipse-chart .svg-ellipse-chart-circle {
  fill: #141414;
}
[data-bs-theme=dark] .svg-ellipse-chart .svg-ellipse-chart-background {
  stroke: #141414;
}
[data-bs-theme=dark] .svg-ellipse-chart .svg-ellipse-chart-info .svg-ellipse-chart-percent,
[data-bs-theme=dark] .svg-ellipse-chart .svg-ellipse-chart-info .svg-ellipse-chart-subline {
  fill: #fff;
}

@media screen and (max-width: 767px) {
  .reward-card {
    padding: 20px;
    gap: 20px;
  }
  .reward-card.last-reward-card {
    flex-direction: column;
  }
  .reward-card.last-reward-card .content,
  .reward-card.last-reward-card .thumnail {
    width: 100%;
  }
  .reward-card.last-reward-card .thumnail img {
    width: 100%;
    height: auto;
  }
  .reward-card .content {
    gap: 32px;
  }
  .reward-card .content .rank-head {
    gap: 10px;
    justify-content: space-between;
  }
  .reward-card .content .rank-head .rank-info span {
    font-size: 12px;
    text-wrap: nowrap;
  }
  .reward-card .content .rank-head .rank-info h2 {
    font-size: 18px;
    text-wrap: nowrap;
  }
  .reward-card .content .rank-head .criteria ul {
    padding-left: 10px;
  }
  .reward-card .content .rank-head .criteria ul li {
    font-size: 14px;
  }
  .reward-card .content .rank-head .criteria ul li span {
    width: 30%;
    white-space: nowrap;
  }
  .reward-card .content .rank-head .criteria ul li label {
    width: 70%;
    white-space: nowrap;
  }
  .reward-card .content .price h3 {
    font-size: 20px;
  }
  .reward-card .content .price h3 span {
    font-size: 15px;
  }
}
.invoice-page {
  background-color: #000;
  font-family: "Montserrat", sans-serif;
}
.invoice-page .invoice-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  position: relative;
}
.invoice-page .invoice-top img {
  width: 100px;
  padding-left: 20px;
}
.invoice-page .invoice-top .title {
  color: #000;
  background: #45F882;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
  padding: 2px 20px;
}
.invoice-page .invoice-top > span {
  color: #ffffff;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: auto;
}
.invoice-page .invoice-top .qr-code-wrap {
  position: absolute;
  top: 0;
  right: 22px;
  background-color: #FFF;
  padding: 10px;
}
.invoice-page .invoice-top .qr-code-wrap svg {
  width: 90px;
  height: 90px;
}
.invoice-page .invoice-content {
  margin-top: 15px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.25);
}
.invoice-page .invoice-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.invoice-page .invoice-content ul li {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}
.invoice-page .invoice-content ul li span {
  width: 50%;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.invoice-page .invoice-content ul li label {
  width: 50%;
  color: #ffffff;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.invoice-page .invoice-content ul li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(69, 248, 129, 0.4) 0%, #45F882 50%, rgba(69, 248, 129, 0.4) 100%);
  bottom: 0;
  right: 0;
}
.invoice-page .invoice-content ul li:last-child {
  border-bottom: none;
}
.invoice-page .invoice-content ul li:last-child::after {
  display: none;
}

.invoice-footer {
  background: #000000;
  width: 500px;
  padding: 20px 20px 20px 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.invoice-parent-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: rgb(19, 19, 19);
  min-height: 100vh;
}
.invoice-parent-page .invoice-page {
  width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 767px) {
  .invoice-parent-page .invoice-page {
    width: 95%;
  }
  .invoice-footer {
    width: 95%;
  }
}
.target-section {
  font-family: "Montserrat", sans-serif;
  padding-bottom: 80px;
}
.target-section .target-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
  margin-bottom: 70px;
}
.target-section .target-header .target-info {
  width: 45%;
}
.target-section .target-header .target-info.left {
  text-align: right;
}
.target-section .target-header .target-info.right {
  text-align: left;
}
.target-section .target-header .target-info p {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}
.target-section .target-header .target-info h4 {
  color: #FFBE18;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
.target-section .polygon {
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  width: 60px;
  height: 60px;
  background-color: #000;
  position: relative;
  margin: auto;
}
.target-section .polygon img {
  position: absolute;
  transform: translate(-50%, 30%);
  width: 40px;
}
.target-section .arrow-target {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: transparent;
}
.target-section .arrow-target svg {
  cursor: pointer;
}
.target-section .arrow-target span {
  font-size: 12px;
  opacity: 0.5;
  cursor: pointer;
}
.target-section .content-bg {
  border-radius: 125px;
  background: #FFF3F2;
  width: 100%;
  height: 155px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
}
.target-section .content-info {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  width: 40%;
}
.target-section .content-info.right {
  justify-content: start;
  text-align: start;
}
.target-section .content-info.left {
  justify-content: end;
  text-align: end;
}
.target-section .content-info span {
  color: #FE1C3B;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 6px;
}
.target-section .content-info p {
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 0;
}
.target-section .content-info h3 {
  font-size: 17px;
  font-weight: 700;
  margin: 0;
}
.target-section .content-info label {
  font-size: 12px;
  opacity: 0.5;
}
.target-section .target-graph {
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  width: 250px;
}
.target-section .target-graph .svg-ellipse-chart {
  filter: none;
}
.target-section .target-graph .svg-ellipse-chart .svg-ellipse-chart-background {
  stroke: #FFF3F2;
}
.target-section .target-graph .svg-ellipse-chart .bottom-line {
  dominant-baseline: middle;
}
@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}
@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-bs-theme=dark] .target-section .polygon {
  background-color: #17181a;
}
[data-bs-theme=dark] .target-section .content-bg {
  background: #17181a;
}
[data-bs-theme=dark] .target-section .target-graph {
  background-color: #000000;
}
[data-bs-theme=dark] .target-section .target-graph .svg-ellipse-chart .svg-ellipse-chart-background {
  stroke: #17181a;
}
[data-bs-theme=dark] .target-section .target-graph .svg-ellipse-chart .svg-ellipse-chart-circle {
  fill: #000;
}
[data-bs-theme=dark] .target-section .target-graph .svg-ellipse-chart .top-line,
[data-bs-theme=dark] .target-section .target-graph .svg-ellipse-chart .bottom-line {
  fill: #fff;
}

@media screen and (max-width: 767px) {
  .target-section .target-header {
    flex-direction: column;
    gap: 15px;
    margin: 20px 0 30px 0;
  }
  .target-section .target-header .target-info {
    width: 100%;
  }
  .target-section .target-header .target-info p {
    font-size: 13px;
  }
  .target-section .target-header .target-info h4 {
    font-size: 17px;
  }
  .target-section .target-header .target-info.left, .target-section .target-header .target-info.right {
    text-align: center;
  }
  .target-section .content-bg {
    border-radius: 25px;
    height: 100%;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
  }
  .target-section .arrow-target {
    margin: auto;
  }
  .target-section .content-info {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .target-section .content-info.right, .target-section .content-info.left {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .target-section .content-info h3 {
    text-wrap: nowrap;
    font-size: 17px;
  }
  .target-section .content-info p {
    font-size: 14px;
  }
  .target-section .content-info p span {
    font-size: 14px;
    padding: 4px;
  }
  .target-section .target-graph {
    width: 210px;
  }
  .target-section .target-graph .svg-ellipse-chart .top-line {
    font-size: 2.2px;
    transform: translateY(-0.3px);
  }
  .target-section .target-graph .svg-ellipse-chart .center-line {
    font-size: 2.8px;
  }
  .target-section .target-graph .svg-ellipse-chart .bottom-line {
    font-size: 2.2px;
    transform: translateY(0.3px);
    dominant-baseline: central;
  }
  .target-section .target-graph .svg-ellipse-chart .bottom-line tspan {
    font-size: 2.2px;
  }
}


