.support-wrap {
    background-color: rgba(0, 0, 0, 0.05);
    min-height: 100vh;
    padding-top: 2rem;
}

.upload-img-model {
    .modal-header {
        border-bottom: none;
        // padding-bottom: 0;

        .h4 {
            font-size: 1rem;
        }
    }

    .modal-body {
        .modal-footer {
            border-top: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            padding: 17px 0px;
            padding-bottom: 0;
        }
    }

    .upload-img {
        width: auto !important;

        &.model-pink {
            background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
            box-shadow: 0px 1px 4px 2px $secondary inset;
            border: transparent;
            .thumnail{
                color: #FFF;
            }
        }
    }
}

.support_left_menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        a {
            display: flex;
            align-items: center;
            padding: 1rem 1rem;
            color: #000;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            position: relative;

            i {
                margin-right: 0.8rem;
                font-size: 1.3rem;
            }

            &:hover,
            &.active {
                background-color: $primary;
                color: #FFF;
            }

            .count {
                position: absolute;
                right: 10px;
                display: inline-block;
                padding: .3rem .6rem;
                background-color: rgba(0, 0, 0, 0.08);
                border-radius: 20px;

                &.waiting {
                    background-color: $warning;
                }
            }
        }

        &:last-child {
            a {
                border-bottom: none;
            }
        }
    }


}

.attachment-link {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &.active {
        svg {
            stroke: $primary !important;
        }

        span {
            font-weight: 600 !important;
        }
    }

    svg {
        stroke: #000;
        width: 20px;
    }

    span {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
}

.table_tickets {
    font-size: .9rem;

    .ticket_id {
        width: 120px;
    }

    .ticket_subject {
        width: 150px;
    }

    th,
    td {
        padding: .3rem;
        vertical-align: middle;
    }

    .count {
        display: inline-block;
        padding: .2rem .6rem;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.05);
    }

    .subject {
        margin: 0;
        max-width: 300px;
        height: 20px;
        overflow: hidden;
    }

    .button-detail {
        display: inline-flex;
        align-items: center;
        color: $secondary;
        text-transform: uppercase;

        span {
            margin-left: 0.5rem;
            background-color: $secondary;
            color: #FFF;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            color: $primary;

            span {
                background-color: $primary;
            }
        }
    }
}

.support-action {
    color: $primary !important;
    transition: color 0.3s ease;
    display: inline-flex !important;
    align-items: center !important;
    // text-transform: uppercase !important;

    span {
        background-color: $primary !important;
        transition: background-color 0.3s ease;
        margin-left: 0.5rem;
        background-color: #1E1E24;
        color: #FFF;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        i {
            color: white !important;
        }

        .material-symbols-outlined {
            font-family: 'Material Symbols Outlined';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
    }

    &:hover {
        color: black !important;

        span {
            background-color: black !important;

            i {
                color: white !important;
            }
        }
    }
}

.new-ticket-wrap {
    .col-form-label {
        text-align: right;
    }
}

.gradient-border {
    display: flex;
    align-items: center;
    width: 90%;
    margin: auto;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid transparent;
    color: #000000;
    background: #ffffff;
    background-clip: padding-box;
    border-radius: 10px;

    p {
        margin-bottom: 0px;
        padding: 10px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: inherit;
        background-image: linear-gradient(to right, #b4a458, #ffffff);
    }
}

.gradient-borderr {
    position: relative;
    display: inline-block;
    border: 2px solid transparent;
    border-radius: 10px;
    font-size: 1rem;
    color: gray;
    width: 90%;
    margin: auto;
    padding: 30% 2em;
    box-sizing: border-box;

    p {
        margin: 0;
        padding: 10px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        border-radius: inherit !important;
        margin: -2px !important;
        background: linear-gradient(to right, #b4a458, #ffffff);

    }
}

.nav-support {
    background-color: #F0F2F7 !important;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;

    .nav-item {

        .nav-link {
            color: #525866;
            font-size: 15px;
            padding: 12px 16px;
            border-radius: 12px 12px 0 0;
            border: none;
            font-weight: 500;
            align-items: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            p {
                margin: 6px 0 0 0;
                font-size: 13px;
                font-weight: 500;
            }

            span {
                display: block;
                position: absolute;
                content: '';
                top: 5px;
                right: 15px;
                min-width: 15px;
                padding: 1px;
                height: 15px;
                font-size: 11px;
                @include flexcenter;
                background: $primary;
                border-radius: 5px;
                color: $white;
            }

            svg {
                width: 26px;
            }

            &:hover {
                transition: width .3;

                &::before {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    width: 20px;
                    height: 3px;
                    background: $primary;
                    border-bottom: 1px solid $primary;
                    border-radius: 5px;
                }
            }

            &.active {
                color: $primary;
                background-color: transparent;

                &::before {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    width: 20px;
                    height: 3px;
                    background: $primary;
                    border-bottom: 1px solid $primary;
                    border-radius: 5px;
                }

                svg {
                    color: $primary !important;

                    path {
                        stroke: $primary !important;
                    }
                }

            }
        }
    }
}

.inbox-item {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    position: relative;
    padding: 12px 16px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    a {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    span {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        border-radius: 4px;
        background: #525866;
        color: #FFF;
    }

    .inbox-menu {

        p {
            margin: 6px 0 0 0;
            font-size: 14px;
            font-weight: bold;

        }

        svg {
            width: 24px;
        }
    }
}


.list-support-tickets {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 10px;
        font-size: 14px;

        h2 {
            margin-bottom: 0;
            font-size: 18px;
        }

        .ticket-id {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
        }

        .button {
            font-size: 13px;
        }
    }
}

.tickte-detail-head {
    display: flex;
    align-items: center;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    >div {
        display: flex;
        align-items: center;
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 0 10px;

    }

    button {
        display: block;
        background-color: transparent;
        width: 30px;
        border: none;
    }

    span {
        font-size: 12px;
    }
}

.type-date {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin: 10px 0;

    .date {
        color: gray;
    }
}

.feedback_list {
    list-style: none;
    padding: 0;

    li {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 15px 0;

        label {
            cursor: pointer;

            input {
                opacity: 0;
                z-index: -1;
                visibility: hidden;
                position: absolute;
            }

            span {
                margin-left: 15px;
                font-weight: 500;
                font-size: 15px;
            }

            &.active {
                svg {
                    path#golden-color {
                        fill: #FFCF48;
                    }

                    path#red {
                        fill: #ff0000;
                    }
                }
            }
        }

        &:first-child {
            margin: 0;
        }

        &:last-child {
            margin: 0;
        }
    }
}

.admin-support-modal {
    .modal-dialog {
        max-width: 70%;
        min-width: 70%;
        width: auto;
        height: auto;

        .modal-body {
            text-align: center;

            img {
                min-width: 100%;
            }
        }
    }
}

.submit_button {
    background-color: rgb(217, 217, 217);
    padding: 0.6rem;
    display: inline-block;
    border-radius: 6px;
    border: transparent;
    cursor: pointer;
    width: 100%;

    span {
        color: black;
    }
}



.tickhide {
    display: none;
}

@media screen and (max-width: 767px) {
    .tickshow {
        top: -12px;
        right: -2px;
    }

    .admin-support-modal {
        .modal-dialog {
            max-width: 96%;
            min-width: 96%;
        }
    }

    .support-cyan {
        padding: 2px;
        overflow: hidden;
        height: 76px;
        margin-bottom: 1px;

        .white-cyan {
            background-color: #FAFAFA !important;
            border-radius: 12px 12px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            list-style: none;
            height: 71px;
            padding: 4px !important;

            .support-item {

                align-items: center;



                .support-link {
                    color: rgba(0, 0, 0, 1);
                    color: rgb(0, 0, 0);
                    font-size: 12px;
                    padding: 2px;
                    border-radius: 12px 12px 0 0;
                    border: none;
                    font-weight: 500;
                    align-items: center;
                    display: flex;
                    flex-direction: column;

                    span {
                        margin-left: 0px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }

                    &.active {
                        color: $primary;

                        svg {
                            path {
                                fill: $primary !important;
                            }

                        }

                    }
                }
            }
        }
    }

    .new-ticket-wrap {
        .col-form-label {
            text-align: left;
        }
    }
}