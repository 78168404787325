#sidebar {

    position: fixed;
    left: 0;
    top: 132px;
    height: 100vh;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
    transition: all 0.2s ease 0s;
    z-index: 3;
    background-color: #FFF;

    // height: calc(100vh - 125px);
    // overflow-y: scroll;

    // &::-webkit-scrollbar {
    //     width: 5px;
    // }

    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: darkgrey;
    //     outline: 1px solid slategrey;
    // }
 

    &:hover {
        width: 220px !important;

        .side-nav {
            li {
                a {

                    span {
                        opacity: 1;
                        display: block;
                    }
                }
            }
        }
    }


    .side-nav-wrap {
        background: rgb(180, 164, 88);
        background: linear-gradient(270deg, rgba(180, 164, 88, 1) 0%, rgba(231, 232, 231, 1) 100%);
        padding: 1px;
        // position: sticky;
        // top: 115px;
    }

    .side-nav {


        li {

            padding-bottom: 2px;
            background: rgb(180, 164, 88);
            background: linear-gradient(80deg, rgba(180, 164, 88, 1) 0%, rgba(231, 232, 231, 1) 100%);

            &:last-child {
                padding-bottom: 0;
            }

            a {
                display: flex;
                align-items: center;
                position: relative;
                height: 40px;
                background-color: $lightGray;
                padding-left: 25px;

                &.active,
                &:hover {
                    @include gradientDark();

                    span {
                        color: #FFF;
                    }

                    svg {
                        path {
                            fill: #FFF;
                        }
                    }

                    i {
                        color: #FFF;
                    }
                }

                i {
                    font-size: 1.4rem;
                    width: 38px;
                    color: $primary;
                }

                svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 18px;

                    path {
                        fill: $primary;
                    }
                }

                span {
                    opacity: 0;
                    white-space: nowrap;
                    color: $primary;
                    display: none;
                    font-size: 13px;
                }

            }
        }
    }


    &.sidebar-collapse {
        width: 72px;
    }

    &.sidebar-open {
        width: 220px;

        .side-nav {
            li {
                a {
                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .side-nav {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }


}

@media screen and (max-width: 768px) {
    #sidebar {
        transform: translateX(-100%);
        top: 0;
        color: #FAFAFA;

        &.sidebar-collapse {
            width: 238px !important;

            .side-nav {
                padding: 0 0 0 0px;

                li {
                    background: rgb(180, 164, 88);
                    background: linear-gradient(180deg, #b4a458 0%, #e7e8e7 100%);
                    margin-bottom: .2rem;
                    height: 34px;

                    a {

                        height: 35px;

                        i {
                            font-size: 1.2rem;
                            margin-right: 0;
                            width: 30px;
                        }

                        span {
                            display: block;
                            font-size: 14px;
                            // font-weight: 600;
                            opacity: 1;
                        }

                        &::after,
                        &::before {
                            display: none;
                        }
                    }

                    &:first-child {
                        height: 55px;
                        display: flex !important;
                        justify-content: flex-start;
                        align-items: center;
                        background: white !important;
                        a {
                            height: 54px;
                            background: white !important;
                        }
                    }
                }
            }
        }

        &.active {
            transform: translateX(0);
            z-index: 5;
        }
    }
}