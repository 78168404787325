.table {
    // font-size: 15px !important;
    margin-bottom: 0;

    &.table-sm {

        th,
        td {
            padding: 4px;
        }
    }

    &.table-package {

        td,
        th {
            padding: 10px;
        }
    }

    &.table-head-primary {
        thead {
            @include gradientDark();

            th {
                color: #FFF;
            }
        }
    }

    thead {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);

        th {
            padding: 1rem 1rem;
        }
    }


    &.large-th {
        thead {
            th {
                padding: 2rem 1rem;
            }
        }
    }

    // tr {


    //     &:nth-child(even) {
    //         background-color: #f7f9fc;
    //     }
    //     &:nth-child(odd) {
    //         background-color: #FFF;
    //     }


    // }

    tbody {
        tr {
            // &:not(.filter-row):hover {
            //     background-color: #edf1f7 !important;
            // }

            &:last-child {
                td {
                    border-bottom: none;
                }
            }

            &.filter-row {

                td {

                    .form-select {
                        box-shadow: transparent !important;
                        padding: 0 16px;
                        border-radius: 12px;
                        border: 1px solid $primary;
                        height: 45px;
                        color: rgba(0, 0, 0, 0.6) !important;

                        &:active,
                        &:focus,
                        &:target,
                        &:focus-visible {
                            box-shadow: transparent !important;
                        }
                    }

                    padding: 6px;

                    // border-color: transparent;
                    .check {
                        width: 15px !important;
                        background-color: #000
                    }

                    .inputwrap {
                        border: 1px solid $primary;
                        // background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                        border-radius: 10px;
                        padding: 1px;


                    }

                    input {

                        width: 100% !important;
                        border: none;
                        border-radius: 8px;
                        background-color: $white;
                        min-height: 40px;


                        &::placeholder {
                            font-size: 14px;
                        }

                        &:focus {
                            box-shadow: none;
                            border-color: transparent !important;
                        }
                    }
                }
            }
        }
    }

    th {
        font-weight: 600;

    }

    td {
        padding: 0.8rem 1rem;
    }

    td,
    th {
        border-color: rgba(0, 0, 0, 0.3);

        vertical-align: middle;
        color: #000;
        font-size: 13px;
    }

    .table-total {
        font-size: 16px;
    }

}

.table-theme {
    border-spacing: 0 10px;
    border-collapse: separate;

    thead {
        tr {
            th {
                &:first-child {
                    border-radius: 10px 0 0 10px;
                }

                &:last-child {
                    border-radius: 0 10px 10px 0;
                }
            }
        }
    }

    tr {
        td {
            &:first-child {
                border-radius: 10px 0 0 10px;
            }

            &:last-child {
                border-radius: 0 0 10px 10px;
            }
        }
    }
}

.table-ledger {
    td {
        //border: 1px solid rgba(0,0,0,0.2);
    }

    th {
        vertical-align: middle;
    }
}

.table-activities {
    position: relative;

    thead {
        @include gradientDark();
        // position: sticky;
        // top: 0;

        tr {
            background-color: transparent !important;
        }

        th {
            background-color: transparent;
            color: #FFF;

        }
    }

    tr,
    th {
        vertical-align: middle;
    }

    th,
    td {
        border: none;
    }

    tr {
        background-color: #FFF;
    }
}

.kyc-table {

    tbody {
        text-align: center;
        border: 3px solid #d8d8d8 !important;

        tr {
            border: 3px solid #d8d8d8 !important;
            padding: 15px;

            th {
                border: 3px solid #d8d8d8 !important;
                padding: .9rem;
                font-size: 1rem;
                width: 50%;
                font-weight: 700;
            }

            &:first-child {
                th {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .card-table {
        .card-inner {
            overflow-x: scroll;
            overflow-y: hidden;

            .table {
                border-collapse: collapse;

                th,
                td {
                    white-space: nowrap;
                }
            }
        }
    }

    .table-flow {
        overflow-x: scroll;
        overflow-y: hidden;

        .table {
            border-collapse: collapse;

            th,
            td {
                white-space: nowrap;
            }
        }
    }

    .stat-table {
        overflow-x: scroll;
        overflow-y: hidden;

        .table {
            border-collapse: collapse;

            th,
            td {
                white-space: nowrap;
            }
        }
    }
}