.ranks_list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        position: relative;
        margin-bottom: 2rem;
        .rank_content {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 0 20px 20px 20px;
            color: #FFF;
            .rank_title {
                padding: 0 30px;
                position: relative;

                > img {
                    width: 120px;
                    position: absolute;
                    right: 20px;
                    top: -36px;
                }
            }
        }
        .rank_details {
            padding: 30px;
            border-radius: 12px;
            border: 1px dashed #FFF;
            @include bgPrimary(0.5);

            .rank_option {
                p {
                    margin: 0 0 6px 0;
                }
            }
        }
    }
}

.requirement_box {
    position: relative;
    display: flex;
    align-items: center;
    .left {
        width: 50px;
    }
    .right {
        width: 100px;
        text-align: center;
        .a_label {
            .wrap {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}