.copy_wrap {

    &.space-small {
        .copy_item {
            padding: 8px 20px;
            font-size: 13px;
        }
    }
    .copy_item {
        display: flex;
        justify-content: space-between;
        padding: 20px 30px;
        position: relative;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            @include gradientReverse();
            position: absolute;
            top: 0;
            left: 0;
        }

       
        
        

        .option {
            display: flex;
            align-items: center;
            
            i {
                margin-right: 6px;
            }
        }
        .action {
            button {
                display: inline-flex;
                align-items: center;
                color: $primary;
            }
        }
    }
}

.bt-wrap {
    background: linear-gradient(180deg, #FFFFFF 0%, #E6E6E6 100%);
    text-align: center;
    padding: 0 30px 30px 30px;
    border-radius: 20px;

    p {
        font-weight: 500;
        span {
            color: $primary;
        }
    }
    
}

.bt-success-img {
    margin-top: -100px;
    position: relative;
}

@media screen and (max-width: 767px){
    .bt-success-img {
        margin-top: 0;
    }
}