.reward-card {
    display: flex;
    width: 100%;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    font-family: $font-primary;

    &.active {
        svg {
            border-radius: 50px;
            border: 1px solid $primary;
            @include flexcenter;
            flex-shrink: 0;
            padding: 5px;
            width: 30px !important;
            height: 30px;
        }
    }

    &.last-reward-card {
        flex-direction: row;

        .content,
        .thumnail {
            width: 50%;
        }

        .thumnail {
            text-align: end;

            img {
                width: 512px;
                height: 320px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        position: relative;

        svg {
            width: 20px;
            position: absolute;
            top: 0px;
            right: 0px;
        }

        .rank-head {
            display: flex;
            align-items: flex-end;
            gap: 52px;
            align-self: stretch;
            position: relative;

            .rank-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;

                span {
                    color: #525866;
                    font-size: 18px;
                    text-transform: uppercase;
                }

                h2 {
                    margin: 0;
                    color: #000;
                    text-align: center;
                    font-family: $font-primary;
                    font-size: 24px;
                    font-style: italic;
                    font-weight: 500;
                }
            }

            .criteria {
                width: 100%;

                >span {
                    color: #525866;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }

                ul {
                    margin: 0;
                    width: 100%;

                    >span {
                        color: #7B8599;
                        font-size: 16px;
                    }


                    li {
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        span {
                            width: 20%;
                            white-space: nowrap;
                        }

                        label {
                            width: 80%;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .border {
            position: relative;
            height: 0px;
            width: 100%;
            background-color: rgba(255, 255, 255, 1);

            img {
                position: absolute;
                bottom: -11px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        .price {
            width: 100%;

            label {
                color: #525866;
                font-size: 12px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-align: center;
                display: block;
            }

            h3 {
                margin: 0;
                text-align: center;
                font-size: 32px;
                font-style: normal;
                background: linear-gradient(92deg, #FF002B 47.98%, #FF4060 97.98%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                span {
                    font-size: 20px;
                    font-weight: 400;
                }
            }
        }


    }

    .thumnail {
        img {
            border-radius: 16px;
            width: 100%;
            height: auto;
        }

        border-radius: 16px;
        width: 100%;
        height: auto;
        flex-shrink: 0;
    }
}

[data-bs-theme="dark"] {
    .reward-card {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #000000;
        box-shadow: 0px 4px 12px 0px rgba(255, 255, 255, 0.05);

        .content {
            .rank-head {
                .rank-info {
                    h2 {
                        color: #fdfdfd;
                    }
                }
            }
        }
    }

    .svg-ellipse-chart {


        .svg-ellipse-chart-circle {
            fill: #141414;

        }

        .svg-ellipse-chart-background {
            stroke: #141414;
        }


        .svg-ellipse-chart-info {

            .svg-ellipse-chart-percent,
            .svg-ellipse-chart-subline {
                fill: #fff;
            }
        }
    }

}



@media screen and (max-width:767px) {

    .reward-card {
        padding: 20px;
        gap: 20px;

        &.last-reward-card {
            flex-direction: column;

            .content,
            .thumnail {
                width: 100%;
            }

            .thumnail {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .content {
            gap: 32px;

            .rank-head {
                gap: 10px;
                justify-content: space-between;

                .rank-info {

                    span {
                        font-size: 12px;
                        text-wrap: nowrap;
                    }

                    h2 {
                        font-size: 18px;
                        text-wrap: nowrap;
                    }
                }

                .criteria {
                    ul {
                        padding-left: 10px;
                        li {
                            font-size: 14px;
                            span {
                                width: 30%;
                                white-space: nowrap;
                            }
    
                            label {
                                width: 70%;
                                white-space: nowrap;
                            }
                        }
                       
                    }
                }
            }

            .price {

                h3 {
                    font-size: 20px;

                    span {
                        font-size: 15px;
                    }
                }
            }

        }

    }
}