.pool_rewards {
    >h1 {
        color: $primary;
        font-weight: bold;
    }
}

body {
    &.theme-light {
        .pool_cards_wrap {
            .pool_card {
                background-color: #FFF;
            }
        }
    }
}

.pool_cards_wrap {
    .pool_card {
        margin-top: 20px;
        border-radius: 10px;
        padding: 30px;

        &.pool_vertical {
            display: flex;
            justify-content: space-between;

            .pool {
                flex: 1;
            }

            .pool_left {
                padding: 0;
                margin-bottom: 1rem;
                min-height: 88px;
            }

            .pool_members {
                ul {
                    li {
                        width: 100px;

                    }
                }
            }
        }

        .row {
            margin-bottom: 10px;
            align-items: center;

            &.pool1 {
                .pool_left {
                    h2 {
                        color: #7BD3D3;
                    }
                }

                .pool_members {
                    ul {
                        li {
                            background-color: #7BD3D3;
                        }
                    }
                }

            }

            &.pool2 {
                .pool_left {
                    h2 {
                        color: #5EE1BC;
                    }
                }

                .pool_members {
                    ul {
                        li {
                            background-color: #5EE1BC;
                        }
                    }
                }

            }

            &.pool3 {
                .pool_left {
                    h2 {
                        color: #8CDF73;
                    }
                }

                .pool_members {
                    ul {
                        li {
                            background-color: #8CDF73;
                        }
                    }
                }

            }

            &.pool4 {
                .pool_left {
                    h2 {
                        color: #DDD2A2;
                    }
                }

                .pool_members {
                    ul {
                        li {
                            background-color: #DDD2A2;
                        }
                    }
                }

            }

        }

        .pool_left {
            padding: 16px;

            h2 {
                font-weight: bold;
                margin: 0;
                font-size: 1.7rem;
            }

            p {
                margin: 0;
                font-size: 1rem;
            }
        }

        .pool_members {


            height: 100%;
            // border-radius: 0 10px 10px 0;

            &.vertical {
                ul {
                    flex-direction: column;

                    li {
                        &:first-child {
                            border-radius: 6px 6px 0 0;
                        }

                        &:last-child {
                            border-radius: 0 0 6px 6px;
                        }
                    }
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;

                li {


                    padding: 5px;
                    width: 8%;
                    background-color: #7BD3D3;

                    &:first-child {
                        border-radius: 6px 0 0 6px;
                    }

                    &:last-child {
                        border-radius: 0 6px 6px 0;
                    }

                    &.pool_more {
                        div {
                           
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 2px solid rgba(0,0,0,0.1);
                            color: rgba(0,0,0,0.5);
                            font-size: 1.2rem;
                            font-weight: 600;
                            background-color: rgba(0,0,0,0.04);
                            word-spacing: -6px;
                        }
                    }


                    div {
                        margin: 1%;
                        border: 2px solid rgba(255, 255, 255, 0.3);
                        border-radius: 50%;
                        position: relative;
                    }

                    span {
                        position: absolute;
                        display: block;
                        width: 100%;
                        text-align: center;
                        bottom: 4px;
                        font-size: .6rem;
                    }

                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.investus_pool_card {
    h1 {
        color: $primary;
        font-weight: bold;
    }

    .investus_pool_row {
        display: flex;
        justify-content: space-between;

        .investus_pool_item {
            flex: 1;
            margin: 1%;

            &.pool1 {
                .pool_head {
                    background-color: #B1E5E5;
                }

                .pool_body {
                    ul {
                        li {
                            img {
                                border-color: #B1E5E5;
                            }
                        }
                    }
                }
            }

            &.pool2 {
                .pool_head {
                    background-color: #A0EDD8;
                }

                .pool_body {
                    ul {
                        li {
                            img {
                                border-color: #A0EDD8;
                            }
                        }
                    }
                }
            }

            &.pool3 {
                .pool_head {
                    background-color: #BCEDAD;
                }

                .pool_body {
                    ul {
                        li {
                            img {
                                border-color: #BCEDAD;
                            }
                        }
                    }
                }
            }

            &.pool4 {
                .pool_head {
                    background-color: #EBE5C8;
                }

                .pool_body {
                    ul {
                        li {
                            img {
                                border-color: #EBE5C8;
                            }
                        }
                    }
                }
            }

            &.pool5 {
                .pool_head {
                    background-color: #FDEAC7;
                }

                .pool_body {
                    ul {
                        li {
                            img {
                                border-color: #FDEAC7;
                            }
                        }
                    }
                }
            }

            .pool_head {
                min-height: 176px;
                border-radius: 10px 10px 0 0;
                padding: 20px;
                text-align: center;

                h4 {
                    font-weight: bold;
                }

                p {
                    margin: 0;
                }
            }

            .pool_body {
                background-color: #FFF;
                border-radius: 0 0 10px 10px;
                padding: 20px;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    li {
                        margin-bottom: .8rem;

                        &.pool_more {
                            div {
                                height: 90px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 2px solid rgba(0,0,0,0.1);
                                color: rgba(0,0,0,0.5);
                                font-weight: 600;
                                font-size: 1.3rem;
                                background-color: rgba(0,0,0,0.04);
                                word-spacing: -6px;
                            }
                        }

                        div {
                            width: 90px;
                            
                            border: 2px solid rgba(255, 255, 255, 0.3);
                            border-radius: 50%;
                            position: relative;
                            margin: auto;
                        }

                        span {

                            display: block;
                            width: 100%;
                            text-align: center;
                            margin-top: 0;
                            font-size: 1rem;
                        }

                        img {
                            width: 100%;
                            border-radius: 50%;
                            border: 4px solid;
                        }
                    }
                }
            }
        }


    }
}

@media screen and (max-width: 767px) {
    .pool_cards_wrap {
        .pool_card {
            .pool_members {
                ul {
                    li {
                        width: 50%;
                    }
                }
            }
        }
    }
    .investus_pool_card {
        .investus_pool_row {
            display: block;
        }
    }
}