.promotions-wrap {
    .promotion-item {
        position: relative;
        margin-bottom: 1rem;

        img {
            width: 100%;
            // border-radius: 20px;
        }

        a {
            position: absolute;
            right: 67px;
            top: 40%;
        }

        .promo-title {
            position: absolute;
            left: 0;
            text-align: center;
            width: 100%;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                color: #FFF;
                font-weight: bold;
                font-size: 70px;
                margin: 0;
            }

        }
    }
}


.promo-bar-title {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.promo-progress {
    height: 12px;
    background-color: #B2B2B2;
    border-radius: 20px;
    position: relative;
    margin-bottom: 0;

    >.bar {
        display: block;
        height: 12px;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #000;
        border-radius: 20px;
    }
}

.promo-label {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -6px;
    position: relative;
}

.promo-timer {
    margin-top: 50px;

    p {
        font-size: 18px;
        font-weight: 600;
    }
}

.promo-users {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        span {
            font-size: 13px;
        }

        svg {
            width: 60px;
            height: 60px;
        }
    }
}


.progress_wrapper_card {

    input {
        margin-bottom: 10px;
    }

    .card {
        height: 360px;
        width: 326px;
        font-family: Roboto;
        background: #fff;
        border-radius: 10px;
        padding: 30px;
        display: flex;
        flex-direction: column;


        &__chart-wrapper {
            margin-top: 20px;
            position: relative;

            &__value {
                height: 86px;
                width: 86px;
                transform: translate(-50%, -50%);
                color: #212121;
                left: 50%;
                top: 50%;
                border: 4px solid $primary;
                border-right-color:transparent ;
                border-radius: 50%;
                position: absolute;
                font-size: 27px !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 40px;
            }

            svg {
                margin: auto;
                display: block;
                transform: rotate(-90deg);
                transform-origin: 50% 50%;

                .progress {
                    stroke-dasharray: 559 559;
                    stroke-dashoffset: 279.6;
                    stroke-linecap: round;
                    transition: stroke-dashoffset 0.3s;
                }
            }
        }

        &__info {
            margin-top: 30px;

            ul {
                list-style: none;
                display: flex;

                li {
                    &::before {
                        content: "";
                        height: 14px;
                        width: 14px;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 6px;
                        top: 2px;
                        position: relative;
                    }

                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    &.restless::before {
                        background: #000000;
                    }

                    &.awake::before {
                        background: #969696;
                    }
                }
            }
        }
    }

}



@media screen and (max-width:767px) {
    .promo-bar-title {
        margin-top: 30px;
        padding: 0 20px;
    }

    .promo-label {
        margin-left: 14px;
    }

    .promo-progress {
        margin-bottom: 0;
        margin-left: 20px;
        margin-right: 20px;
        height: 8px;
        >.bar {
            height: 8px;
        }
    }

    .promotions-wrap {
        .promotion-item {

            img {
                width: 100%;
                // border-radius: 20px;
                height: 155px;
            }

            a {
                position: relative;
                right: 0px;
                left: 0;
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }

            .promo-title {

                h2 {
                    font-size: 26px;
                    margin-bottom: 38px;
                }

            }
        }
    }

    .promo-users {
        margin-bottom: 40px;

        li {
            margin: 0 10px;

            svg {
                width: 46px;
                height: 36px;
            }

            span {
                font-size: 10px;
                text-align: center;
                line-height: 13px;

            }
        }
    }

    .promo-timer {
        text-align: start;
        margin-top: 20px;
        margin-bottom: 150px;

        p {
            font-size: 13px;
        }
    }
}