.OTP_wrap {
   
    max-width: 500px;
    margin: auto;
    background-color: rgba(0,0,0,0.03);
    padding: 20px 10px;
    text-align: center;
    border-radius: 4px;

    &.OTP_wrap {
        input {
            border: 1px solid rgba(0,0,0,0.4);
        }
    }

    .OTP_head {
        text-align: center;
        span {
            color: $cyan;
            font-size: 4rem;
        }
    }
   
    h4 {
        margin: 0 0 10px 0;
        font-size: 1.1rem;
    }
    > div {
        justify-content: center;
    }

    input {
        height: 30px;
        width: 30px !important;
        margin: 10px;
        border-radius: 4px;
        border: none;
        border-bottom: 3px solid $cyan;
        text-align: center;
        font-size: 26px;
    }
}