.assistive-button {
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $primary;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    user-select: none;
    touch-action: none;
    overflow: hidden;

    img {
        width: 100%;
    }
}