/*thread start*/
.topic-comments {
    .post-new {
        padding: 32px 20px;
        border: 1px solid #dcdcdc;
        box-shadow: 2px 2px 13px 3px rgba(0, 0, 0, 0.03);
        border-radius: 12px;
    }
}


.input-post-group {
    position: relative;

    .form-control {
        border-radius: 30px;
        padding-left: 20px;
        background-color: #f1f2f4;
        border: none;
        min-height: 60px;

    }

    .input-group-append {
        position: absolute;
        top: 0;
        right: 0;

        .button {
            padding: 17px 30px;
            min-width: 180px;
            border-radius: 54px;
        }
    }
}

.post-new-actions {
    margin: 30px 0 0 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;

    &.in-reply {
        margin: 0 20px 0 0;

        li {
            margin: 12px;

            i {
                font-size: 22px;
            }
        }
    }

    li {
        margin-right: 60px;
        display: flex;
        align-items: center;

        i {
            font-size: 30px;
        }

        span {
            font-size: 22px;
            color: #5a5d67;
            display: inline-block;
            margin-left: 15px;
        }
    }
}

.comment-posts {
    .post-item {
        // padding: 0;
        border: 1px solid transparent;
        // box-shadow: 2px 2px 13px 3px rgba(0, 0, 0, 0.03);
        position: relative;

        .ticket-type {
            position: absolute;
            right: 34px;
            top: 48px;

            p {
                margin: 0;
                color: rgba(0, 0, 0, 0.5);

                strong {
                    color: $secondary;
                }
            }
        }

        .user-info {
            display: flex;
            align-items: center;

            .thumb {
                width: 80px;

                img {
                    max-width: 100%;
                    max-height: 86px;
                    border-radius: 50%;
                }
            }

            .info {
                padding-left: 12px;

                h4 {
                    color: #040d25;
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0;
                }

                p {
                    color: #838289;
                    font-size: 14px;
                    margin: 0;

                    img {
                        width: 20px;
                        margin-left: 6px;
                    }
                }
            }
        }

        .post-info {
            max-width: 800px;

            h2 {
                color: #040d25;
                font-size: 26px;
                font-weight: 600;
                max-width: 687px;
            }

            p {
                color: #515151;
                font-size: 16px;
            }

            img {
                max-width: 100%;
            }
        }


        .post-comments-count {
            margin: 14px 0;

            a {
                color: #08a0d2;
                font-size: 16px;
                text-decoration: underline;
            }
        }
    }
}

.post-info-actions {
    margin: 0;
    padding: 1.5rem 1.5rem;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #ff0e0d1f;


    li {
        display: flex;
        align-items: center;

        padding: 0 1rem;

        &.mobile-view {
            display: none;
        }

        i {
            margin-right: 0.5rem;
            color: $primary;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            border-right: 0;
        }
    }

    &.action-comments {
        background-color: transparent;
        padding: 10px 0 0 0;

        li {
            margin: 0;
        }
    }
}

.bs-comment-post {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .post-info-actions {
        text-align: start;
    }

    .user-reply {
        margin: 0 !important;

        .comment-item {
            margin: 0px !important;
            gap: 5px;

            .thumb {
                @include flexcenter;
                width: auto !important;
                margin: 0 !important;

                span {
                    width: 21px !important;
                    height: 24px !important;
                    font-size: 14px !important;
                    font-style: normal !important;
                    font-weight: 500 !important;
                    line-height: 16px !important;
                    letter-spacing: 0.5px !important;
                }
            }

            .comment {
                .comment-message {
                    .inner {
                        display: flex;
                        padding: 8px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 6px;
                        border-radius: 8px;
                        background: rgba(255, 21, 0, 0.10);

                        p {
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 24px;
                            letter-spacing: 0.5px;
                            margin: 0;
                        }

                        span {
                            color: #525866;
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                        }
                    }

                    .comment-actions {
                        svg {
                            width: 15px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .admin-reply {
        margin: 0 !important;

        .comment-item {
            margin: 0px !important;
            gap: 5px;

            .thumb {
                @include flexcenter;
                width: auto !important;
                margin: 0 !important;

                span {
                    width: 21px !important;
                    height: 24px !important;
                    font-size: 14px !important;
                    font-style: normal !important;
                    font-weight: 500 !important;
                    line-height: 16px !important;
                    letter-spacing: 0.5px !important;
                }
            }
        }

        .comment {
            .comment-message {
                .inner {
                    display: flex;
                    padding: 8px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 6px;
                    border-radius: 8px !important;
                    background: rgba(123, 133, 153, 0.10);

                    .comment-actions {
                        margin: 0 !important;
                    }

                    p {
                        color: #000;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        margin: 0;
                    }

                    span {
                        color: #525866;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                    }
                }

                .comment-actions {
                    svg {
                        width: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .reply {
        .attachment-icon {
            .active {
                svg {
                    stroke: $primary;
                }
            }
        }
    }
}

.post-comments {
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;

    li {
        margin: 2rem 0;

        &.user-reply {
            .comment-item {
                flex-direction: row-reverse;
                margin-left: 0;
                margin-right: 30px;

                .thumb {
                    margin-right: 0;
                    margin-left: 10px;

                    span {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $primary;
                        color: #FFF;
                    }
                }

                .comment {
                    text-align: end;

                    .comment-message {
                        .inner {
                            background: rgba(255, 21, 0, 0.10);

                        }
                    }

                    .comment-actions {
                        justify-content: flex-end;
                    }
                }
            }
        }

        &.admin-reply {
            .inner {
                background-color: #ededed !important;
            }

            .comment-actions {
                justify-content: flex-start !important;
                margin-left: 10px !important;
                margin-right: 0;
            }
        }

        .comment-item {
            margin-left: 30px;
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;

            .thumb {
                width: 60px;
                margin-right: 10px;

                span {
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #7B8599;
                    color: #FFF;
                }

                img {
                    border-radius: 50%;
                    object-fit: cover;
                    width: 50px;
                    height: 50px;
                }

            }

            .comment {

                max-width: 89%;
                display: inline-block;

                .comment-message {
                    padding: 2px;
                    border-radius: 15px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;

                    .inner {
                        // background-color: #ffffff;
                        border-radius: 15px;
                        padding: 8px 20px;
                        border-top-left-radius: 0px;
                        border-top-right-radius: 0px;

                        p {
                            margin: 0;
                            font-size: 15px;
                            color: #515151;

                        }

                        h5 {
                            flex-basis: 100%;
                            margin: 0;
                            font-weight: bold;
                            font-size: 16px;
                            color: #040d25;
                            // word-break: break-all;
                        }

                        div {
                            font-size: 14px;
                        }

                        // >div {
                        //     word-break: break-all;
                        // }

                    }


                }

                .comment-actions {
                    padding: 6px 0;
                    display: flex;
                    align-items: flex-end;

                    .button-view-attachment {
                        padding: 4px 20px;
                        margin-right: 10px;
                        background-color: transparent;
                        border-color: black;
                        font-size: .9rem;
                        color: black;

                        &:hover {
                            background-color: black;
                            color: #FFF;
                        }
                    }

                    a {
                        margin-right: 10px;
                        display: inline-block;
                    }

                    .lik {
                        color: #08a0d2;
                        text-decoration: underline;
                    }

                    .reply {
                        color: #868686;
                        font-size: .8rem;
                    }

                    .date {
                        color: #868686;
                        font-size: .8rem;
                    }
                }
            }
        }

        >ul {
            margin: 0 0 20px 0;
            padding: 0 0 0 100px;
            list-style-type: none;
            position: relative;

            &::before {
                content: "";
                display: block;
                background-image: url(../images/arrow-reply.png);
                width: 50px;
                position: absolute;
                height: 50px;
                left: 48px;
            }
        }
    }
}

.Attachment {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;


    li {
        position: relative;

        .attachment_img {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                text-align: center;
                margin-left: 10px;
                cursor: pointer;

                path {
                    fill: $primary !important;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: -7px;
                    right: -17px;
                    height: 37px;
                    width: 3px;
                    background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
                }

            }

            span {
                margin-left: 10px;
                width: 100%;
                overflow: hidden;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: -7px;
            right: -17px;
            height: 37px;
            width: 3px;
            background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
        }

        &:first-child {
            width: 80vw;
        }


        &:last-child {

            &::before {
                content: "";
                position: absolute;
                top: -7px;
                right: -17px;
                height: 37px;
                width: 3px;
                display: none;
                background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
            }
        }
    }



    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 23px;
        width: 20vw;

        .lable {
            font-weight: 500;
            width: 240px;
            margin-left: 10px;

        }

        .form-select {
            display: inline-block;
            border: none;
            width: 100%;

        }
    }
}

.reply {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    padding: 12px 16px;
    position: relative;

    .button-reply {
        display: inline-block;
        border: none;
        background-color: transparent;

        svg {
            width: 24px;
            color: #000;
        }
    }


    .attachment-icon {
        position: relative;

        svg {
            cursor: pointer;
            width: 24px;
        }
    }

    .submit {
        position: relative;

        svg {
            cursor: pointer;

            path {
                fill: $primary;

                &:first-child {
                    stroke: $primary;
                    fill: none;
                }
            }
        }
    }

    .form-control {
        outline: none;
        border: none;
        min-height: 46px;

        &:active,
        &:focus,
        &:target,
        &:focus-visible {
            box-shadow: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: -7px;
            left: -17px;
            height: 37px;
            width: 3px;
            z-index: 1;
            background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
        }

    }

}

@media screen and (max-width: 767px) {
    .comment-posts {
        .post-item {
            border: none;
        }
    }


    .reply {
        min-height: 100%;
        display: block;
        padding-top: 5px;

        .attachment-icon {
            margin-bottom: 0px;

            &::after {
                top: -15px;
                right: -11px;
                height: 61px;
            }
        }

        .simile {
            position: relative;


            &::before {
                top: -17px;
                right: 0;
                height: 3px;
                width: 100%;
                z-index: 1;
                background: linear-gradient(90deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
            }


            svg {
                cursor: pointer;

                path {
                    fill: $primary;

                    &:first-child {
                        stroke: $primary;
                        fill: none;
                    }
                }
            }
        }

        .button {
            margin: 18px 0;

        }

    }

    .post-info-actions {

        padding: .7rem;
        flex-wrap: wrap;



        li {
            display: flex;
            align-items: center;
            padding: .3rem 0;
            padding-left: 0;
            padding-right: 0;
            border-right: 0;

            &.laptop-view {
                display: none;
            }

            &.mobile-view {
                display: inline-flex;
            }
        }

        &.action-comments {
            background-color: transparent;
            padding: 10px 0 0 0;

            li {
                margin: 0;
            }
        }
    }


    .post-comments {
        margin: 0;
        padding: 0;
        list-style-type: none;
        height: 600px;
        overflow: scroll;

        li {
            margin: 1.2rem 0;

            .comment-item {
                margin-left: 10px;
                display: flex;
                align-items: flex-start;
                margin-bottom: 0;
                margin-right: 10px !important;

                .thumb {
                    width: 50px;
                    margin-right: 5px;
                    display: none;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }

                .comment {

                    .comment-message {
                        width: 97%;

                        .inner {
                            padding: 8px;
                            width: 100%;



                        }


                    }

                    .comment-actions {
                        padding: 6px 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-right: 21px;

                        .button-view-attachment {
                            padding: 0;
                            margin-right: 10px;
                            background-color: transparent;
                            border-color: transparent;
                            font-size: .9rem;

                            &:hover {
                                background-color: transparent;
                                color: $primary;
                            }

                            span {

                                &:hover,
                                &:active,
                                &:focus {
                                    background-color: transparent;
                                    color: $primary;
                                }
                            }
                        }

                        a {
                            margin-right: 10px;
                            display: inline-block;
                        }

                        .lik {
                            color: #08a0d2;
                            text-decoration: underline;
                        }

                        .reply {
                            color: #868686;
                            font-size: .8rem;
                        }

                        .date {
                            color: #868686;
                            font-size: .8rem;
                        }
                    }
                }
            }

            >ul {
                margin: 0 0 20px 0;
                padding: 0 0 0 100px;
                list-style-type: none;
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    background-image: url(../images/arrow-reply.png);
                    width: 50px;
                    position: absolute;
                    height: 50px;
                    left: 48px;
                }
            }
        }
    }

    .Attachment {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;


        li {
            position: relative;
            padding: 10px;

            .attachment_img {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    text-align: center;
                    margin-left: 10px;
                    cursor: pointer;

                    path {
                        fill: $primary !important;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: -7px;
                        right: -17px;
                        height: 37px;
                        width: 3px;
                        background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
                    }

                }

                span {
                    margin-left: 10px;
                    width: 100%;
                    overflow: hidden;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 47px;
                right: 39px;
                height: 4px;
                width: 101%;
                background: linear-gradient(90deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
            }

            &:first-child {
                width: 100vw;
            }


            &:last-child {

                &::before {

                    display: none;
                }
            }
        }



        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 29px;
            width: 100vw;

            .lable {
                font-weight: 500;
                width: 200px;
                margin-left: 10px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 6px;
                    right: 255px;
                    height: 46px;
                    width: 4px;
                    background: linear-gradient(180deg, rgb(180, 166, 102) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
                }
            }

            .form-select {
                display: inline-block;
                border: none;
                width: 100%;

            }
        }
    }

    .input-post-group {

        .input-group-append {


            .btn {

                min-width: auto;
            }
        }
    }

    .post-new-actions {

        li {
            margin-right: 14px;

            i {
                font-size: 15px;
            }

            span {
                font-size: 10px;
                margin-left: 6px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .topic-details {
        .topic-title {
            font-size: 25px;
        }
    }

    .topic-comments {
        margin-top: 1em;

        .post-new {
            padding: 20px 20px;
        }
    }
}

/*thread end*/