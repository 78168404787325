#notifications {


    .notifications_header {
        border-bottom: 1px solid $primary;
        padding: 32px 50px 10px 50px;
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 15px 15px 15px;
            border-color: transparent transparent $primary transparent;
            top: -16px;
            right: 247px;
        }
    }

    ul {


        li {
            position: relative;
            padding: 1px;
            border-radius: 20px;
            margin-bottom: .6rem;

            .inner {
                background: $lightGray;
                padding: 1rem;
                border: 1px solid transparent;

                transition: all 0.3s ease;
                border-radius: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .notification {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;

                    svg {
                        width: 25px;
                        height: 25px;
                    }

                    .massage {
                        width: 22px;
                        height: 22px;
                        cursor: pointer;
                    }

                    .deleted {
                        font-size: 24px;
                        margin-top: 5px;
                        cursor: pointer;

                        &:hover {
                            color: $primary;
                        }
                    }
                }

            }

            &:hover {
                border-color: $primary;
            }

            .close,
            .spinner-border {
                position: absolute;
                right: 1rem;
                top: .6rem;
                font-size: 1.5rem;
            }

            .date {

                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, .7);

                i {
                    margin-right: 5px;
                }
            }

            .title {
                font-size: 1.1rem;
                font-weight: 500;
                padding-right: 100px;
                line-height: 1.4rem;
                margin-bottom: 0.5rem;
            }

            p {
                font-size: .8rem;
                color: rgba(0, 0, 0, .7);
                line-height: 1.1rem;
                margin: 0 0 8px 0;
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #notifications {
        .notifications_header {
            padding: 12px;
        }

        ul {

            li {
                p {
                    strong {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}