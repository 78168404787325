.left_icon_menu {
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;

    li {
        position: relative;

        &:last-child {
            margin-bottom: 0;

            &::after {
                display: none;
            }
        }

        &::after {
            content: "";
            display: block;
            width: 70%;
            height: 2px;
            @include gradientReverse();
            position: absolute;
            bottom: 0;
            left: 0;
        }


        a {
            display: flex;
            align-items: center;
            color: $black;
            padding: 16px 20px;

            &:hover,
            &.active {
                strong {
                    color: $primary;
                }

                svg {
                    path {
                        fill: $primary;
                    }
                }

            }



            >span {
                padding-left: 14px;

                strong {
                    font-size: 1rem;
                }

                span {
                    display: block;
                    font-size: .7rem;
                    font-weight: 300;
                }
            }
        }
    }
}

.account_header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;

    .profile_photo {
        position: relative;

        button {
            position: absolute;
            right: -10px;
            top: 0;
            width: 30px;
            height: 30px;
            background-color: #FFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            border: 1px solid;
        }
    }

    .info {
        padding-left: 3rem;

        h3 {
            margin: 0;
            font-size: 20px;
        }

        p {
            font-size: 17px;
            margin: 4px 0 0 0;
            color: rgba(0, 0, 0, 0.7);
        }
    }
}

.account-page-heading {
    color: #909090;
    font-size: 16px;
    font-weight: bold;
}

.p_options {
    margin: 0;
    padding: 2rem;
    list-style-type: none;

    &.p_small {
        padding: .5rem 2rem;
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.8rem;

        &:last-child {
            margin-bottom: 0;
        }

        .option_left {
            h3 {
                color: $black;
                font-weight: 600;
                font-size: 16px;
                margin: 0 0 5px 0;
            }

            h4 {
                font-weight: 500;
                font-size: 14px;
                color: rgb(142, 142, 142);
                margin: 0;
            }

            p {
                font-size: 14px;
                color: rgb(31, 31, 31);
                margin: 0;
            }
        }

        .option_right {
            .button {
                min-width: 150px;
            }
        }
    }
}

.saved_wallets {
    .wallet_name {
        @include bgPrimary(0.1);
        padding: 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;

        i {
            margin-right: 10px;
            color: $primary;
        }
    }

    .wallet_address {
        @include bgPrimary(0.1);
        padding: 10px;
        border-radius: 6px;
    }
}

.canvas-content-middel {
    padding-top: 100px;
}

.change_email_label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        color: rgb(90, 90, 90);
        font-weight: 700;
    }

    .button {
        color: rgb(78, 141, 107);
        background-color: transparent;
        padding: 0;
        font-weight: 700;
    }
}

@media screen and (max-width:767px) {
    .change_email_label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

    }

    .left_icon_menu {
        margin: 0;
        padding: 1rem 0;
        list-style-type: none;

        li {
            position: relative;

            &:last-child {
                margin-bottom: 0;

                &::after {
                    display: none;
                }
            }

            &::after {
                content: "";
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 12px;
            }

        }
    }

    .account_header {
        display: flex;
        align-items: center;
        padding: 1rem 2rem;

        img {
            width: 55px !important;
            height: 55px !important;
        }

        .profile_photo {
            position: relative;

            button {
                right: -1px;
                top: -5px;
                width: 20px;
                height: 20px;

                span {
                    font-size: 15px;
                }
            }
        }
        .info {
    
            h3 {
                margin: 0;
            }
    
            p {
            }
        }

    }

}