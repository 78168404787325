.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 0;
    margin-left: 15%;
    list-style: none;

    [data-bs-target] {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 7px;
        height: 7px;
        border: 1px solid transparent;
        border-radius: 50%;
        padding: 0;
        margin-top: 10px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: $primary;
        background-clip: padding-box;
        border: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: 1;
        transition: opacity .6s ease;
    }



}

.package-slider {
    margin-top: 20px;

    .slick-list {
        overflow: hidden;
        .slick-slide {
            margin: auto;

            img {
                width: 100%;
            }
        }
    }
}

// .slick-dots {
//     display: flex !important;
//     justify-content: center;
//     margin: 15px 0;

//     li {
//         position: relative;
//         display: inline-block;
//         width: 10px;
//         height: 0px;
//         margin: 7px;
//         padding: 0;
//         cursor: pointer;

//         button {
//             font-size: 18px; // Adjust the font size to increase dot size
//             line-height: 1;
//             color: $primary !important;
//             opacity: 5;

//             &:before {
//                 color: $primary !important;
//                 font-size: 12px !important;
//             }
//         }

//         &.slick-active button {
//             opacity: 1;
//         }
//     }
// }

@media screen and (max-width:767px) {

    .slick-dots {


        li {
          
            width: 6px !important;
            height: 20px !important;
            margin: 0 8px !important;
            button {
                font-size: 0px; // Adjust the font size to increase dot size
                line-height: 1;
                color: $primary !important;
                opacity: 5;

                &:before {
                    color: $primary !important;
                    font-size: 12px !important;
                }
            }

            &.slick-active button {
                opacity: 1;
            }
        }
    }
}