.card {
    background-color: #FFF;
    border: none;
    padding: 0;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;

    display: block;

    &.card_lg {
        padding: 50px 66px;
    }

    .card_label {
        background: rgb(243, 243, 243);
        border-radius: 12px;
        padding: 10px;
        margin-bottom: 12px;
        color: rgb(142, 142, 142);
        text-transform: uppercase;
        font-size: 12px;
        display: inline-block;

    }

    .card_title {
        font-size: 16px;
        color: $black;
    }

    .card_text {
        color: #8E8E8E;
    }
}



.card-gradient {

    padding: 2px;
    border-radius: 20px;

    &.radius-12 {
        border-radius: 12px;

        .card-inner {
            border-radius: 10px;
        }
    }

    .card-inner {
        background-color: #FAFAFA;
        border-radius: 18px;
        padding: 40px;

        &.p2 {
            padding: 2px;
        }

        .card-inner-header {
            min-height: 70px;
            padding: 20px;
            position: relative;
            font-weight: 500;
            color: #000;
            font-size: 18px;


        }
    }
}

@media screen and (max-width: 767px) {

    .card {
        .card_title {
            font-size: 14px;
        }
    }

  

    .card {
        &.card_lg {
            padding: 20px 30px;
        }

    }

    .card-gradient {
        .card-inner {
            padding: 15px !important;

            &.p-0 {
                padding: 0 !important;
            }
        }
    }
}