.button {
	display: inline-block;
	vertical-align: middle;
	padding: .6rem 1.3rem;
	border: none;
	text-align: center;
	transition: all 0.4s ease-in-out;
	border: 1px solid transparent;
	font-size: 1rem;
	border-radius: 20px;
	font-weight: normal;
	//font-family: $font-secondary;
	color: #000;
	font-weight: 700;

	&.hover-on-dark {
		&:hover {
			color: #FFF;
			border-color: #FFF;
		}
	}

	&.button-sm {
		padding: .4rem .8rem;
		font-size: .8rem;
		font-weight: normal;
	}

	&.disabled {
		pointer-events: none;
		opacity: .4;
		cursor: not-allowed;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: .4;
	}
}

.btn-img {
	border: none;
	background-color: transparent;

	img {
		display: block;
	}
}

.button-close {
	width: 40px;
	height: 40px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	background-color: transparent;
	border-radius: $radius;

	&:hover,
	&:focus {
		@include bgPrimary(0.05);
	}
}

.copy-link {
	border: none;
	padding: 0.2rem 0.5rem;
	font-size: 0.8rem;
}

.button-long {
	width: 100%;
}

.button-dark {
	background-color: $themeDark;
	color: #FFF;

	&:hover {
		background-color: transparent;
		border-color: $themeDark;
		color: $themeDark;
	}
}


.button-bs {
	border-radius: 20px;
	background: $primary;
	color: #000;
	font-weight: 700;

	&:hover,
	&:active,
	&:focus {
		opacity: .8;
	}
}

.white-button {
	border-radius: 24px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	background: #FFF;

	&:hover,
	&:active,
	&:focus {
		color: #000000;
		background: rgb(128, 128, 128) !important;
	}
}

.button-outline-bs {
	border-color: $primary;
	color: $primary;
	background-color: transparent;
	border-radius: 80px;

	&:hover {
		color: #FFF;
		background: linear-gradient(91deg, $primary 0%, #FF002B 49.48%, #FF4060 98.97%);
	}
}

.input-height {
	height: 56px;
}

.input-radius {
	border-radius: .375rem;
}

.button-icon-bs {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	svg {
		width: 20px;
	}

	&:hover,
	&.active {
		color: #000000;
		background-color: $primary;
	}
}

.button-black {
	background-color: $black;
	color: #FFF;

	&:hover {
		background-color: transparent;
		border-color: $black;
		color: $black;
	}
}


.button-icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.6rem 0.6rem 0.6rem 1.3rem;

	&:has(svg) {
		gap: 20px;
		svg {
			width: 30px;
		}
	}

	span {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
	}

	&.icon-right {
		span {
			margin-left: 0.8rem;
		}
	}

	&.icon-left {
		flex-direction: row-reverse;
		justify-content: center;
		padding: 0.6rem 0.6rem 0.6rem 0.6rem;

		span,
		i {
			margin-left: 0;
			margin-right: 0.4rem;
		}
	}

	&.button-dark {
		span {
			background-color: $themeLight;
			color: $themeDark;
		}
	}
}

.button-light {
	background-color: #E8E8E8;
	color: #65676B;

	&:hover {
		background-color: transparent;
		border-color: #65676B;
		color: #65676B;
	}
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

.button-gradient {
	@include gradientReverse();
	color: #000;
	padding: 1px;
	border-radius: 8px;
	border: none;
	font-weight: 500;

	span {
		background-color: $lightGray;
		padding: .6rem 1.3rem;
		display: inline-block;
		border-radius: 6px;
		min-width: 130px;
		width: 100%;
	}
}

.two-buttons {
	margin-top: 20px;

	.button {
		width: 100%;
		min-height: 70px;

		&.left {
			border-radius: 0 0 0 18px;
		}

		&.right {
			border-radius: 0 0 18px 0;
		}

		span {
			line-height: unset;
		}
	}
}

.button-primary {
	background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
	color: #FFF;
	font-weight: 700;

	&:hover,
	&:active,
	&:focus {
		color: #FFF;
		background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
	}


}


.button-secondary {
	background-color: $cyan;
	color: $secondary !important;
	font-weight: bold;

	&:hover {
		background-color: transparent;
		border-color: $cyan;
		color: $cyan !important;
		opacity: 1;
	}
}


.button-outline-secondary {
	border-color: $black;
	color: $black;
	background-color: transparent;

	&:hover {
		color: #FFF;
		background-color: $black;
	}
}

.button-outline-secondary {
	border-color: $secondary;
	color: $secondary;
	background-color: transparent;

	&:hover {
		color: #FFF;
		background-color: $secondary;
	}
}

.button-outline-gray {
	border-color: $lightGray;
	color: $lightGray;
	background-color: transparent;

	&:hover {
		color: #FFF;
		background-color: $lightGray;
	}
}


.button-outline-primary {
	border-color: $primary;
	color: $primary;
	background-color: transparent;

	&:hover {
		color: #FFF;
		background-color: $primary;
	}
}



.button-fb {
	background-color: #3C5A9A;
	color: #FFF;

	font-weight: bold;

	&:hover {
		background-color: transparent;
		border-color: #3C5A9A;
		color: #3C5A9A;
		opacity: 1;
	}
}

.button-green {
	background-color: $green;
	color: #FFF;

	&:hover {
		color: #FFF;
		opacity: .8;
	}
}


.button-danger {
	background-color: $red;
	color: #FFF;

	&:hover {
		color: #FFF;
		opacity: .8;
	}
}

.button-round {
	border-radius: $radius;
}


.button-block {
	width: 100%;
	display: block;
}


.button-gray {
	background-color: $smgray;
	color: $black !important;
	font-weight: 500;

	&:hover {
		background-color: transparent;
		border-color: $gray;
		color: $gray !important;
		opacity: 1;
	}
}


.button-outline-gray {
	background-color: #F5F5F5;
	border: 1px solid #707070;
	color: #292724;
	font-weight: bold;

	&:hover {
		color: #292724;
		background-color: #FFF;
	}
}

.download-icon {
	display: flex;
	gap: 20px;
	cursor: pointer;

	li {
		padding: 5px !important;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		display: inline-flex !important;
		justify-content: center;
		align-items: center;
		text-align: center !important;
	}

	.excel {
		background: green;

		&:hover {
			svg {
				path {
					stroke: #ffffff !important;
				}
			}
		}
	}

	.pdf {
		// background: red;
		border: 1px solid red;

		svg {
			path {
				stroke: #f50000 !important;
			}
		}

		&:hover {
			background: red;

			svg {
				path {
					stroke: rgb(255, 255, 255) !important;
				}
			}
		}
	}

	.csv {
		background: blue;

		&:hover {
			svg {
				path {
					stroke: #ffffff !important;
				}
			}
		}
	}

}

.downloads {
	display: flex;
	gap: 10px;
	margin: 0;
	padding: 0;
}

.excel-button {
	background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
	padding: 5px !important;
	border: 1px dashed $white !important;
	color: $white !important;
	font-weight: bold;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: inline-flex !important;
	justify-content: center;
	align-items: center;
	text-align: center !important;
	cursor: pointer;
	font-size: .6rem !important;

	&:hover {
		color: $white !important;
		background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
		border: 1px dashed $white !important;
		opacity: .8;
	}
}

.round-button {
	background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
	padding: 5px !important;
	border: 1px dashed $white !important;
	color: $white !important;
	font-weight: bold;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: inline-flex !important;
	justify-content: center;
	align-items: center;
	text-align: center !important;
	cursor: pointer;



	span,
	a {
		color: $white;
		font-size: .6rem;
	}

	&:hover {
		color: $white !important;
		background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
		border: 1px dashed $white !important;
		opacity: .8;

		span,
		a {
			color: $white;
		}
	}
}

.button-row {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 20px;
}

.small-button {
	font-weight: 400;
	padding: 0.3rem .7rem;
	font-size: .8rem;
	background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
	color: #FFF;

	&:hover,
	&:active,
	&:focus {
		color: #FFF;
		background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
	}
}

@media screen and (max-width:767px) {
	.button-smlg {
		width: 100%;
		display: block;
		text-align: center;
	}
}

.button-icon-secondary {
	display: inline-flex;
	align-items: center;
	color: $secondary;
	white-space: nowrap;

	svg {
		width: 24px;
		margin-right: 8px;
	}
}

.button-icon-primary {
	display: inline-flex;
	align-items: center;
	color: $primary;
	white-space: nowrap;

	svg {
		width: 24px;
		margin-right: 8px;
	}
}

.button-3d-outline {
	background-color: transparent;
	border-radius: 80px;
	color: $primary;
	position: relative;
	padding: 10px 30px;
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	border: 3px solid rgba(255, 190, 24, 0.5);
	box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	overflow: hidden;
	transition: all 0.4s ease;
	cursor: pointer;


	&:hover,
	&:active,
	&:focus {
		color: #000;
		transform: scale(1.1);
		background-color: $primary
	}

	&:disabled {
		opacity: .5;
		cursor: none;
	}

	&::after {
		content: "";
		width: 100%;
		position: absolute;
		height: 100%;
		bottom: -24px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.15);

	}
}

.btn-red {
	background-color: rgba(255, 17, 17, .5);
	color: #FFF;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	svg {
		width: 20px;

	}
}

.button-line {
	position: relative;
	display: inline-flex;
	column-gap: 10px;

	svg {
		width: 20px;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: -4px;
		left: 0;
		width: 0;
		height: 2px;
		background: $primary;
		border-radius: 5px;
		transition: .5s all ease-in-out;
	}

	&:hover {
		&::before {
			width: 80%;
		}
	}
}



.button-3d-primary {
	background-color: #000;
	border-radius: 80px;
	color: #FFF;
	position: relative;
	padding: 10px 30px;
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	border: 2px solid rgba(255, 255, 255, 0.5);
	box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	-moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
	overflow: hidden;
	transition: all 0.4s ease;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		color: #ffffff;
		transform: scale(1.1);
	}

	&:disabled {
		opacity: .5;
		cursor: none;
	}

	&::after {
		content: "";
		width: 100%;
		position: absolute;
		height: 100%;
		bottom: -24px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.15);

	}
}

.button-3d-secondary {
	background-color: $secondary;
	border-radius: 80px;
	color: #142129;
	position: relative;
	padding: 10px 30px;
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	border: 3px solid rgba(255, 255, 255, 0.5);
	box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
	-webkit-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
	-moz-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
	overflow: hidden;
	transition: all 0.4s ease;
	cursor: pointer;


	&:hover,
	&:active,
	&:focus {
		color: #fff;
		transform: scale(1.1);
	}

	&:disabled {
		opacity: .5;
		cursor: none;
	}

	&::after {
		content: "";
		width: 100%;
		position: absolute;
		height: 100%;
		bottom: -24px;
		left: 0;
		background-color: rgba(0, 0, 0, 0.15);

	}
}