.partner-table {
    overflow-y: hidden;
    overflow-x: scroll;
    border-radius: 16px;
    border: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    box-shadow: none;
    padding-top: 12px;

    .table-wrap {
        position: relative;
        width: 100%;
        padding: 0 15px;

        &::after,
        &::before {
            content: "";
            width: 20%;
            height: 45px;
            background: #F8FAFF;
            position: absolute;
            top: 0%;
            display: none;

        }

        &::after {
            left: 0%;
            transform: translate(-50%, 0%);
        }

        &::before {
            right: 0%;
            transform: translate(0%, 0%);
        }
    }

    .table {

        z-index: 1;
        position: relative;

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;

            tr {
                border: 1px solid rgba(255, 255, 255, 0.3);
                display: block;
                margin-bottom: 1rem;
                box-shadow: rgba(26, 108, 225, 0.1) 0px 0px 25px;
                background-color: #ffffff00;
                padding: 20px;
                border-radius: 10px;

                th {
                    text-wrap: nowrap;
                    font-size: 14px;
                    font-weight: 700;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
                    display: block;
                    text-align: right;
                    color: #fff;

                    .form-check-input {
                        float: right;
                    }

                    &::before {
                        content: attr(data-label);
                        float: left;
                        color: rgba(255, 255, 255, 0.6) !important;
                    }
                }
            }
        }


        tbody {
            tr {
                text-wrap: nowrap;
                font-size: 14px;
                font-weight: 700;
                display: block;
                text-align: right;
                color: #fff;
                background-color: #000;

                .form-check-input {
                    float: right;
                }

                td {
                    text-wrap: nowrap;
                    min-height: 31.5938px;
                    font-size: 14px;
                    font-weight: 700;
                    display: block;
                    text-align: right;
                    color: #fff;
                    position: relative;

                    .total {
                        color: #525866;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    &.else {
                        text-align: center;

                        svg {
                            width: 40px;
                            stroke: $primary;
                            margin-bottom: 10px;
                        }

                        h4 {
                            font-size: 14px;
                            margin: 0;
                        }
                    }


                    .form-check-input {
                        float: right;
                    }

                    &:last-child {
                        border: transparent !important;
                    }
                }
            }
        }
    }

    .table {

        tr {
            display: block;
            margin-bottom: 1rem;
            padding: 10px;
            border-radius: 5px;
        }

        td {

            display: block;
            text-align: right;
            padding: .3rem .1rem !important;
            z-index: 1;

            &::before {
                content: attr(data-label);
                float: left;
                color: #7e7e7e;
            }

            &::after {
                content: '';
                position: absolute;
                width: 90%;
                height: 1px;
                background: linear-gradient(90deg, rgba(255, 190, 24, 0) 0%, #FFBE18 50%, rgba(255, 190, 24, 0) 100%);
                display: flex;
                bottom: -5%;
            }

            &:last-child {
                border-bottom: 0;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.table-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    gap: 20px;

    .page-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        label {
            color: rgba(255, 255, 255, 0.42);
            font-size: 12px;
            letter-spacing: 0.5px;
            text-transform: uppercase;

            span {
                color: rgba(255, 255, 255, 0.87);
            }
        }

        .number-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .form-control {
                width: 71px;
                height: 38px;
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.15);

                @include flexcenter;

                &:focus-visible,
                &:focus,
                &:active {
                    border: 1px solid $primary;
                    outline: $primary;
                }

                &::placeholder {
                    color: rgba(255, 255, 255, 0.6) !important;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                }
            }
        }

    }
}

.bs-pagination-wrap {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .arrows {
        svg {
            width: 20px;
            stroke: #ffffff;
            stroke-width: 1.5px;
        }

        &.disabled {
            svg {
                stroke: rgba(255, 255, 255, 0.15);
            }
        }
    }


}

.bs-pagination {

    display: flex;
    list-style-type: none;
    padding: 2px;
    align-items: center;
    gap: 1px;
    border-radius: 8px;
    border: 1px solid transparent;
    margin: 0;
    padding: 0;


    .page-item {
        margin: 0 5px;
        width: 44px;
        height: 32px;
        @include flexcenter;

        svg {
            stroke: $primary;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .page-link {
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary;
            border: none;

            span {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: unset;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &.active {
            .page-link {
                border-radius: 20px;
                background: $primary;
                width: 44px;
                height: 44px;
                color: #000;

                &::after {
                    content: "";
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    bottom: -24px;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.15);

                }
            }
        }
    }
}

[data-bs-theme="dark"] {
    .partner-table {
        border: 1px solid #303030;
        background-color: #000000;

        .table-wrap {
            position: relative;
            width: 100%;
            padding: 0 15px;

            &::after,
            &::before {
                background: #303030;
            }

        }

        .table {
            thead {
                background-color: #303030;

                tr {

                    th {
                        border-right: 1px solid #303030;
                        border-bottom: 1px solid #303030;
                        color: white;
                    }
                }
            }

            tbody {
                tr {

                    td {
                        border-color: #303030;
                        color: $white;

                        &.else {
                            h4 {
                                color: $white;
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: 1px solid#303030 !important;
                    }
                }
            }
        }
    }

    .table-footer {

        .page-info {
            label {
                color: rgba(255, 255, 255, 0.42);

                span {
                    color: rgba(255, 255, 255, 0.87);
                }
            }

            .number-select {
                input {
                    border: 1px solid rgba(245, 245, 245, 0.15);

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.6) !important;
                    }
                }
            }

        }
    }

    .bs-pagination-wrap {
        .arrows {
            svg {
                stroke: #ffffff;
            }

            &.disabled {
                svg {
                    stroke: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }


    .bs-pagination {
        border: 1px solid rgba(251, 251, 251, 0.5);

        .page-item {
            .page-link {
                color: #ffffff;
            }

            &.active {
                .page-link {
                    color: #000000;
                }
            }
        }
    }
}

.else-emoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    svg {
        width: 40px;
        stroke: $primary;
        margin-bottom: 10px;
    }

    h4 {
        font-size: 14px;
        margin: 0;
    }
}