.package-detail-section {
    padding: 20px;
}


.responsive-package-list {

    padding: 0;
    margin: 0;
    list-style-type: none;

    >li {

        &.active {
            background: linear-gradient(180deg, $primary 0%, $primary 29.98%, #FFF 29.98%, #FFF 100%);
        }
    }
}

.package-detail-card {
    .image {
        img {
            border-radius: 12px;
            width: 100%;
            height: auto;
        }

        &::after {
            content: '';
            position: relative;
            width: 313px;
            height: 3px;
            flex-shrink: 0;
            background: linear-gradient(90deg, rgba(255, 190, 24, 0.00) 0%, #FFBE18 50%, rgba(255, 190, 24, 0.00) 100%);
        }
    }

    svg {

        width: 100%;
    }

    .package-header {

        label {
            color: #525866;
            font-size: 16px;
            text-transform: uppercase;
        }

        h4 {
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 27.54px;
            letter-spacing: 0.1px;
        }
    }

    .detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        position: relative;

        span {
            font-size: 20px;
            font-weight: 800;
            line-height: 27.54px;
            letter-spacing: 0.1px;
            font-weight: 600;
            color: $primary;
        }
    }
}

.package-card-view-button {
    >div {
        background: linear-gradient(0deg, #F5F5F7 0%, #F5F5F7 100%), #FFF;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        min-height: 400px;

        .package-card {
            align-items: center;
            position: relative;
            box-shadow: none;
        }
    }


}

.package-card-view {

    margin-bottom: 24px;
    padding: 8px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .package-card {

        border-radius: 10px;
        background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
        box-shadow: 0px 1px 4px 2px #FFBE18 inset;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .image {
            border-radius: 12px;
            min-width: 100%;


            img {
                border-radius: 12px;
                width: 100%;
                height: auto;
            }

        }

        .content {

            .ribbon {
                .ribbon-text {
                    color: $white;
                }
            }
        }


    }
}

.package-icon {
    position: absolute;
    top: 50px;
    right: 15px;
    flex-direction: column;
    @include flexcenter;

    span {
        color: $primary;
        font-size: 50px;
        font-weight: bold;
        font-family: $font-secondary;

        small {
            font-size: 20px;
        }
    }

    label {
        color: $primary;
        font-size: 13px;
        position: relative;
        margin-top: -7px;

        // &::after,
        // &::before {
        //     content: '';
        //     position: absolute;
        //     height: 2px;
        //     width: 100%;
        //     background-color: $primary;
        // }

        // &::after {
        //     top: -3px;
        //     left: 0;
        // }


        &::before {
            bottom: -3px;
            right: 0;
        }
    }
}

.package-card {

    border-radius: 10px;
    background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
    box-shadow: 0px 1px 4px 2px #FFBE18 inset;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 26px;
    padding: 26px;
    position: relative;


    &.secondary {
        box-shadow: 0px 1px 4px 2px #45F882 inset;

        .icon-secondary {
            label {
                color: $secondary;

                &::before,
                &::after {
                    background-color: $secondary;
                }
            }

            span {
                color: $secondary;
            }
        }

        .secondary-text {
            color: $secondary !important;
        }
    }

    &.blue {
        box-shadow: 0px 1px 4px 2px #48C7FF inset;

        .icon-blue {
            label {
                color: #48C7FF;
            }

            span {
                color: #48C7FF;
            }
        }

        .blue-text {
            color: #48C7FF !important;
        }

        .button-blue {
            background-color: #48C7FF !important;
            box-shadow: -1px -1px 11px 2px rgba(72, 200, 255, 0.51) !important;
        }
    }

    &.red {
        box-shadow: 0px 1px 4px 2px #FF1662 inset;

        .red-text {
            color: #FF1662 !important;
        }

        .icon-red {
            label {
                color: #FF1662;
            }

            span {
                color: #FF1662;
            }
        }

        .button-red {
            background-color: #FF1662 !important;
            box-shadow: -1px -1px 11px 2px rgba(255, 22, 100, 0.51) !important;
        }
    }

    .primary-text {
        color: $primary;
    }

    .secondary-text {
        color: $secondary !important;
    }


    .blue-text {
        color: #48C7FF !important;
    }




    .red-text {
        color: #FF1662 !important;
    }


    .content {


        .package-header {

            label {
                color: #525866;
                font-size: 16px;
                text-transform: uppercase;
            }

            h4 {
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 27.54px;
                letter-spacing: 0.1px;
            }
        }

        .detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            position: relative;

            .price {
                font-size: 20px;
                font-weight: 800;
                line-height: 27.54px;
                letter-spacing: 0.1px;
                font-weight: 600;
                color: $primary;

                &.secondary {
                    color: $secondary;
                }
            }
        }

        .list {
            padding: 0;
            list-style-type: none;
            text-align: left;
            margin: 0;
            gap: 1px;
            width: 100%;

            li {
                display: flex;
                justify-content: space-between;
                text-align: center;
                position: relative;
                padding: 4px 0;
                border-bottom: 1px solid rgba(255, 255, 255, .5);


                label {
                    margin: 0;
                    color: rgba(255, 255, 255, .6);
                }

                span {
                    color: #FFF;
                }

                &:last-child {
                    border-bottom: none;
                }


            }

            span {
                font-weight: 600;
            }
        }

        .package-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 10px;
            column-gap: 15px;
            margin-left: 10px;

            a {
                color: #525966;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
    .completed {
        flex-direction: column;
        text-align: center;
        margin: 0 !important;
        svg {
            width: 150px !important;
        }
    }
    
  
    
    .icon {
        color: #000;
    }
}

.active-package-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 494px;

    li {
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        padding: 10px;
        width: 100%;

        &:first-child {
            font-weight: 600;

            .sr {
                background-color: transparent;
                color: #000;
            }
        }

        .sr {
            width: 30px;
            height: 30px;
            @include flexcenter;
            background-color: $primary;
            border-radius: 50%;
            color: $white;
            flex-shrink: 0;
        }

        .package {
            width: 100%;
        }

        .values {
            width: 120px;
        }

        &.active {
            background: #F5F7FC;
        }

        .package {
            h1 {
                font-size: 16px;
                margin: 0;
            }

            label {
                font-size: 13px;
            }

            h6 {
                font-size: 13px;
                font-weight: 600;
                margin: 0;
            }
        }

        .values {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                margin: 0;
            }

            span {
                color: #FF002B;
                font-size: 12px;
            }

            &.paid {
                align-items: flex-end;
            }

            &.pending {
                align-items: center;

                span {

                    color: #7B8599;
                }
            }
        }
    }
}

.properte-card {
    position: relative;
    font-family: $font-primary;

    .ribbon {
        position: absolute;
        margin: 22px 10px 0px -15px;
        background: $primary;
    }

    .content {
        display: flex;
        min-width: 232px;
        padding: 15px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        color: #000;



        .package-header {

            position: relative;
            width: 100%;
            margin-top: 45px;

            label {
                color: #525866;
                font-size: 16px;
                text-transform: uppercase;
            }

            h4 {
                text-align: left;
                font-size: 21px;
                font-style: italic;
                font-weight: 500;
                font-family: $font-primary;
                margin: 0;
            }
        }


        >ul {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: left;
            margin: 0;
            margin-top: 10px;
            list-style-type: none;
            width: 100%;
            padding: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            padding-top: 10px;
            row-gap: 10px;

            li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                label {
                    color: #525866;
                    font-size: 14px;
                    line-height: 16px;
                }

                span {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .princing {
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;

            }
        }








        .package-footer {
            width: 100%;
        }
    }
}

.color-chose {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0;
    list-style-type: none;


    li {
        display: inline-block;

        input[type="radio"] {
            display: none;
        }

        label {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            cursor: pointer;
            transition: box-shadow 0.3s;

            &:hover {
                box-shadow: inset 0 0 4px 0px rgba(0, 0, 0, 0.9);
            }
        }

        input[type="radio"]:checked+label {
            box-shadow: inset 0 0 4px 0px rgba(0, 0, 0, 0.9);
        }
    }
}



.opportunity-icon-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    gap: 10px;
    width: 100%;
    font-family: $font-primary;

    .info {
        display: flex;
        gap: 10px;
        width: 100%;
    }

    .user-card {
        width: 100%;

        .front,
        .back {
            border-radius: 10px;
        }

        img {
            border-radius: 10px;
        }
    }

    h1 {
        font-family: $font-primary;
        color: $primary;
        font-size: 24px;
        font-style: italic;
        font-weight: 500;
        margin: 0;
    }

    >span {
        font-size: 14px;
        line-height: 16px;
    }

    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding-bottom: 8px;
        gap: 10px;

        &:last-child {
            border-bottom: none;
        }

        &.close {
            svg {
                color: rgba(123, 133, 153, 1);
            }
        }

        svg {
            width: 22px;
            height: 22px;
            @include flexcenter;
            border-radius: 50%;
            font-size: 1rem;
            color: $primary;
        }



        p {

            font-size: 14px;
            margin: 0;
            text-wrap: nowrap;
            color: #fff;
        }
    }
}

.product-feature {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    font-family: $font-primary;
    // background: linear-gradient(90deg, #F8FAFF 0%, #F8FAFF 15%, #FFF 15%, rgba(255, 255, 255, 0.00) 50%), #FFF;
    background: #FFF;
    padding: 40px 20px 10px 20px;

    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        list-style-type: none;

        li {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 20px;

            a {
                color: #000;
            }

            &:hover {
                a {
                    color: $primary;
                }
            }

            &:last-child {
                border-bottom: none !important;
                padding-bottom: 0;
            }

            .product {
                width: 30%;
                font-size: 14px;
                font-weight: 700;
            }

            .product-info {
                width: 70%;
            }
        }
    }
}

.purchase-now-card {
    border-radius: 16px;

    .modal-content {

        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);

    }



    .modal-header {
        height: 64px;
        flex-shrink: 0;
        background-color: rgb(0, 0, 0);
        justify-content: center;
        border-bottom: 0;
        border-radius: 16px 16px 0 0;
    }

    .modal-body {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        justify-content: center;
        background-color: rgb(0, 0, 0);

    }

    .btn-close {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        opacity: 1;
        background: transparent;

        svg {
            width: 30px;
            color: $secondary;
        }
    }

    .verify {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;

        .icon-input {
            width: 100%;
        }

        .button {
            width: 100%;
        }
    }

    .form-check {
        cursor: pointer;

        .form-check-label {
            color: #ffffff;
            font-size: 14px;
            letter-spacing: 0.4px;
        }

        .link {
            margin-left: 10px;
            color: #fff;
            font-size: 12px;
            letter-spacing: 0.4px;
            text-decoration-line: underline;
            text-transform: uppercase;
        }
    }

    .help {
        @include flexcenter;
        gap: 10px;
        width: 100%;

        label {
            color: #ffffff;
            font-size: 16px;
        }
    }


    .condition {
        width: 100%;

        label {
            font-size: .9rem;
            color: #fff;
        }

        margin:0;
        display: flex;
        flex-direction: column;
        gap: 14px;



        .select {
            width: 100%;
        }
    }

    .balance {
        @include flexcenter;
        flex-direction: column;
        width: 100%;

        label {
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
        }

        span {
            color: $primary;
            font-size: 28px;
            font-weight: 600;

        }

    }

    .modal-footer {
        background-color: rgb(0, 0, 0);
        padding: 1rem 2rem;
        border: 1px solid rgba(0, 0, 0, 0.10);
        box-shadow: none;

    }
}


.calc-result {
    background-size: cover;
    border-radius: 16px;
    box-shadow: 0px 16px 24px -16px #CC7A96;
    padding: 32px;
}

.calc-title {
    font-size: 16px;
    font-weight: 600;
}

.calc-progress {
    height: 10px;
    background: #F2E6E8;
    border-radius: 5px;
    margin-top: 20px;

    >div {
        height: 10px;
        border-radius: 5px;
        background-color: $primary;
    }
}

.calc-item {
    margin-top: 20px;
    padding-right: 40px;

    .range-list {
        margin: 10px 0;
        padding: 0;
        height: 10px;

        background: #F2E6E8;
        border-radius: 5px;
        list-style-type: none;
        display: flex;
        justify-content: space-around;

        li {
            margin-top: -8px;

            button {
                width: 28px;
                height: 28px;
                cursor: pointer;
                border: none;
                border-radius: 50%;
                background-color: #efefef;


                &.active {
                    border: 4px solid #FFF;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
                    background: $primary;
                }
            }
        }
    }

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
    }

    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        margin: 10px 0;
    }

    /* Apply custom track styles */
    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: #F2E6E8;
        border-radius: 5px;
    }

    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: #F2E6E8;
        border-radius: 5px;
    }

    /* Apply custom thumb styles */
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 28px;
        height: 28px;
        cursor: pointer;
        background: $primary;
        border-radius: 50%;
        border: 4px solid #FFF;
        margin-top: -10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }

    input[type="range"]::-moz-range-thumb {
        width: 28px;
        height: 28px;
        cursor: pointer;
        background: $primary;
        border-radius: 50%;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }

    /* Adjust thumb position for Firefox */
    input[type="range"]::-moz-range-progress {
        background-color: $primary;
    }
}

.calc-detail-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }
}


// @media screen and (max-width:767px) {


//     .calc-item {
//         padding-right: 0;
//         margin-bottom: 20px;
//     }

//     .package-image {
//         img {
//             height: auto;
//         }
//     }

//     .package-section {
//         .active-package-list {
//             height: auto !important;
//         }

//         .dash-bg-card {
//             margin-top: 30px;
//         }

//         .demo-bg {
//             height: auto !important;

//             .heading {
//                 position: absolute;
//                 top: 2%;
//                 left: 4%;
//                 color: $white;
//             }

//             .demo {
//                 padding: 50px 10px;

//                 .slick-slider {
//                     svg {
//                         display: none !important;
//                     }
//                 }

//                 .slick-dots {
//                     li {
//                         margin-right: 8px !important;
//                     }
//                 }
//             }
//         }

//         .package-card-view {
//             .package-card {
//                 flex-direction: column;

//                 .content {
//                     width: 98%;
//                 }
//             }
//         }

//         .active-package-list {
//             min-height: 100%;
//         }
//     }

//     .responsive-package-list {
//         justify-content: center;
//     }

//     .purchase-now-card {

//         .modal-content {
//             width: 100%;
//         }

//         .package-card-view {
//             width: 100%;

//             .package-card {
//                 flex-direction: column;
//             }
//         }
//     }



//     .package-card-view-button {
//         >div {
//             padding: 20px;
//             min-height: auto;
//             border-radius: 16px;

//             .package-card {
//                 width: 100% !important;
//             }
//         }


//     }

//     .product-feature {
//         background: linear-gradient(90deg, #F8FAFF 0%, #F8FAFF 30%, #FFF 30%, rgba(255, 255, 255, 0.00) 50%), #FFF;
//         padding: 40px 10px 10px 10px;
//     }

//     [data-bs-theme="dark"] {

//         .product-feature {
//             background: linear-gradient(90deg, #141414 0%, #141414 30%, #000 30%, rgba(255, 255, 255, 0) 50%), #000;
//         }
//     }
// }