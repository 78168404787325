.partner-card {
    background-color: #fafafa;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    overflow: hidden;

    label {
        margin-bottom: 5px;
    }

    .form-select {
        border: 1px solid #f0f0f0;
    }

    option {
        background-color: #fff;
    }
}

[data-bs-theme="dark"] {
    .partner-card {
        background-color: #303030;
        border: 1px solid #303030;

        .form-select {
            border: 1px solid #303030;
            background-color: black;
            color: white;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        }

        option {
            background-color: black;
            color: white;
        }
    }
}