.rs_country_item {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
    border-radius: 20px;
    img {
        width: 100%;
        transition: all .5s ease;
    }

    &:hover {
        img {
            transform: scale(1.1);
            
        }
    }

    .button {
        position: absolute;
        right: 37px;
        bottom: 31px;
        padding: 4px 20px;
        min-width: 100px;
    }
}