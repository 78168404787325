.disable-accordion {
    button {
        pointer-events: none;
    }
}

.no-dropdown-arrow {
    .accordion-button::after {
        display: none;
    }
}

#menu {
    padding: 0;
}

// custom accordion 

/* Initially hide all submenus */
.submenu {
    display: none;
}

/* Style for active submenu */
.submenu.open {
    display: block;
}
