// this code is writen by fahad.

.tick {
	color: #ffffff;
	background-color: #D61D1D;
	border-radius: 50px;
	font-size: 20px;
	margin-left: 15px !important;

	&.yes {
		background-color: #000;
	}
}

.cash-back {
	display: flex;
	flex-direction: row;
	justify-content: end;

	.user-icon {
		padding: 0px 20px;

		.avatar {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #F3F3F3;
			cursor: pointer;
			padding: 0px 5px;
		}

		p {
			font-size: 12px;
			margin: 0;
		}

	}
}

.cashback-bonus {
	h2 {
		font-weight: 600;
		font-size: 34px;
		margin: 0;
	}

	p {
		color: #8E8E8E;

	}
}

.five_partners {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		text-align: center;
		margin: 10px;

		.avatar_cb {
			width: 50px;
			height: 50px;
			margin: auto;
		}

		p {
			margin: 10px 0 0 0;
			font-size: 12px;
		}
	}
}

.clock_cashback {
	&.clock_small {
		#clockdiv {
			>div {
				line-height: 1.1;
				min-width: 70px;

				span {
					font-size: 18px;
				}

				.smalltext {
					font-size: 12px;
				}
			}
		}
	}

	#clockdiv {
		display: flex;
		justify-content: end;
		gap: 25px;

		.wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			position: relative;


			&::before {
				content: ":";
				position: absolute;
				top: 0;
				right: -15px;
				border-radius: 50px;
				color: #B69541;
				font-size: 26px;
				font-weight: 700;
			}

			&:last-child {
				&::before {
					display: none;
				}
			}

			.time {
				@include gradientReverse();
				background: #b69541;
				height: 40px;
				width: 60px;
				border-radius: 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;

				span {
					background-color: #f4f1f1;
					height: 38px;
					width: 56px;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					border-radius: 5px;
					color: $primary;
					font-weight: 600;
					font-size: 23px;

				}
			}

			.smalltext {
				color: #a5a5a5;
				font-weight: 500;
				font-size: 12px;
			}


		}

		.smalltext {
			color: #a5a5a5;
			font-weight: 600;
			font-size: 18px;
		}

		span {}
	}
}

@media screen and (max-width: 767px) {
	.Cashback {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.five_partners {
		justify-content: space-evenly;
		align-items: flex-start;
		flex-wrap: wrap;

		li {
			text-align: center;
			margin: 8px;

			.avatar_cb {
				width: 45px;
				height: 45px;
			}
		}
	}

	.clock_cashback {
		padding: 0 10px;
		margin-top: 20px;

		&.clock_small {}

		#clockdiv {
			display: flex;
			justify-content: space-between;

			>div {
				text-align: center;
				min-width: 60px;

				.smalltext {
					color: #a5a5a5;
					font-weight: 600;
					font-size: 15px;
				}

				span {
					font-weight: bold;
					color: $primary;
					font-size: 22px;


				}
			}
		}
	}
}