.mobile-container {
    padding: 0 26px;
}

.theme-card {
    background-color: rgba(2, 2, 12, .5);
    border-radius: 10px;

    .theme-card-action svg {
        width: 34px;
        position: absolute;
        right: 0;
        top: 0;
        color: $secondary;
    }

    &.bg-safe {
        background-image: url("../images/safebg.jpg");
        background-size: cover;
        padding: 30px 0;
    }

}

.main-bg-safe {
    display: flex;
    align-items: flex-end;
    padding: 10px 0 0 0 !important;

    h6 {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        color: $secondary;
    }

    span {
        color: #fff;
    }

    .music-wrap {
        display: flex;
        gap: -50px;
    }


    .right-music {
        margin-left: -148px;
    }


    .music {
        width: 200px;
        height: 70px;
        overflow: hidden;
        margin-bottom: -10px;
    }

    .balance {
        width: 56.5%;
        position: relative;

        h6 {
            margin-top: 6px;
        }

        h2 {
            font-size: 32px !important;
            color: $primary;

            span {
                color: $primary;
            }
        }

        &::before {
            content: "";
            right: 0;
            position: absolute;
            width: 2px;
            height: 90%;
            background: linear-gradient(94deg, rgba(69, 248, 129, 0.4) 0%, #45F882 50%, rgba(69, 248, 129, 0.4) 100%);
        }
    }

    .right {
        width: 43.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;

        .brand-icon {
            display: flex;
            align-items: center;
            gap: 5px;

        }

        img {
            width: 25px;
        }

        h3 {
            color: #fff;
            font-size: 24px;
            margin: 0;
        }

        p {
            font-weight: 600;
            font-size: 26px;
            color: $primary;
            margin: 0;
        }


        .coin {
            width: 40px;
        }
    }
}

.buy-sale-section {
    overflow: hidden;
    margin-top: 20px;
    background-image: url("../images/dashboard/login-bg.png");
    background-size: cover;
    background-attachment: fixed;

    .top-card {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            width: 55%;

            svg {
                width: 25px;
                height: 25px;
                background: linear-gradient(180deg, #FF9813 0%, #FEF458 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .right {
            width: 40%;

            img {
                width: 25px;
                height: 25px;
            }
        }

        .left,
        .right {
            display: flex;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 5px;
            border: 1.2px solid #FF9712;

            span {
                color: #FFF659;
                font-size: 17px;
                font-weight: 500;
                text-wrap: nowrap;
            }


        }
    }

    .buy-sale-card {

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 23px;
        border-radius: 10px;
        border: 1px solid #9F7E1E;
        padding: 10px 20px 10px 0;

        .left {
            width: 40%;
            padding: 0 10px;

            img {
                width: 100%;
            }
        }

        .right {

            width: 60%;

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 7px 10px;
                border-radius: 6px;

                &:last-child {

                    background: linear-gradient(90deg, #48C7FF 0%, #EE91FF 100%);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    margin-top: 10px;
                }


                background: linear-gradient(90deg, #FF1662 0%, #F9982C 100%);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                .label {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    svg {
                        color: #fff;
                        width: 18px;
                        height: 18px;

                    }

                    p {
                        margin: 0;
                        color: #FFF;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        font-size: 16px;
                        font-weight: 700;

                    }
                }

                .amount {
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }

        >span,
        >p {
            color: #FFF;
            font-weight: 600;
            margin: 0;
        }
    }

}

.bic-view-list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    margin: 0;
    margin-top: 20px;

    li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        color: #000;
        position: relative;
        border-radius: 15px;
        padding: 10px;
        width: 31%;
        gap: 0;
        min-height: 105px;

        a {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }


        

        &:nth-child(1) {
            width: 100%;
            border-radius: 19px;
            border: 1px solid #F5C147;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 20px;

            .left {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 20px;
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                strong {
                    color: rgba(255, 255, 255, 0.60);
                    font-size: 18px;
                    font-weight: 500;
                }

                span {
                    color: #FFF;
                    font-size: 25px;
                    font-weight: 700;
                }
            }

            img {
                width: 70px;
            }

            svg {
                width: 20px;
                color: #fff;
            }
           
        }

        &:nth-child(2) {
            border: 1px solid #FF9712;

            svg {
                width: 23px;
            }

            strong {
                color: #FFF659;
            }

            span {
                color: #FFF659;
            }
        }

        &:nth-child(3) {
            border: 1px solid #FF1662;

            svg {
                width: 23px;
            }

            strong {
                color: #FF1662;
            }

            span {
                color: #FF1662;
            }

        }

        &:nth-child(4) {
            border: 1px solid #48C7FF;

            svg {
                width: 20px;
            }

            strong {
                color: #48C7FF;
            }

            span {
                color: #48C7FF;
            }
        }


        strong {
            font-weight: 400;
            font-size: 15px;
        }

        span {
            font-size: 14px;
            font-weight: 700;
        }
    }
}

.bar-background {
    background: #373737;
    border-radius: 5px;
    padding: 10px;
}

.dash-header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;


    .menu {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 12px;

        .menu-link {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            height: 33px;
            overflow: hidden;
            border-radius: 25px;
            border: 1px solid $secondary;
            margin: 0;
            width: 100%;

            li {
                position: relative;
                flex: 1;

                a {
                    @include flexcenter;
                    border-radius: 50px;
                    font-size: 11px;
                    font-weight: 500;
                    padding: 7px 10px;
                    color: #ffffff;
                    cursor: pointer;
                    text-wrap: nowrap;

                    &.active {
                        background: $secondary;
                        color: #000;
                        font-weight: 600;
                    }

                }

                .menu-date {
                    visibility: hidden;
                    width: 0;
                    max-width: 0;
                    min-width: 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    cursor: pointer;
                }

                >button {
                    background: transparent;
                    border: none;
                    padding: 10px 15px;
                    padding-right: 10px;
                }

                svg {
                    width: 20px;
                    color: #fff;
                }

            }
        }

        .menu-icon {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            height: 33px;
            overflow: hidden;
            border-radius: 25px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            margin: 0;

            li {
                position: relative;

                a {
                    padding: 10px 15px;
                    border-radius: 50px;
                    font-size: 12px;
                    font-weight: 600;
                    @include flexcenter;
                    color: #ffffff;
                    cursor: pointer;

                    &.active {
                        background: rgba(255, 255, 255, 0.05);
                        color: $primary;
                    }
                }

                svg {
                    width: 20px;
                }
            }
        }
    }
}

.user-history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 20px;
    margin-top: 15px;

    li {
        color: #fff;
        @include flexcenter;
        flex-direction: column;
        width: 50%;
        text-align: center;

        svg {
            width: 30px;
            color: $primary;
        }



        &:first-child {
            align-items: flex-start;
        }

        &:last-child {
            align-items: flex-end;
        }
    }
}

.future-safe {
    background-image: url("../images/futurebg.jpg");
    background-size: cover;
    padding: 42px 26px;

    .future-safe-balance {
        p {
            font-size: 18px;
            margin: 0;
        }

        h3 {
            margin: 0 0 20px 0;
            font-size: 38px;
            font-weight: bold;
            color: #FFF;
            font-family: $font-secondary;
        }
    }

    .buy-more-wrap {
        a {
            display: flex;
            flex-direction: column;

            svg {
                width: 18px;
            }

            strong {
                font-size: 30px;
            }

            span {
                color: rgba(255, 255, 255, .8);
            }
        }
    }

}

.rewards-card {
    padding: 26px;


    &.secondary {
        .reward-title {
            h2 {
                color: $secondary;
            }
        }

        .reward-list {
            li {
                box-shadow: 2px 1px 5px 0px rgba(69, 248, 130, 0.79);
                -webkit-box-shadow: 2px 1px 5px 0px rgba(69, 248, 130, 0.79);
                -moz-box-shadow: 2px 1px 5px 0px rgba(69, 248, 130, 0.79);

                .left {
                    .count {
                        border-color: $secondary;
                    }
                }
            }
        }
    }

    .reward-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            color: #FFF;
        }

        h2 {
            margin: 0;
            color: $primary;
            font-size: 30px;
        }
    }

    .reward-head {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;

        p {

            margin: 0px;
        }
    }

    .reward-list {
        margin: 10px 0 0 0;
        padding: 0;
        list-style-type: none;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 2px 1px 5px 0px rgba(255, 190, 24, 0.79);
            -webkit-box-shadow: 2px 1px 5px 0px rgba(255, 190, 24, 0.79);
            -moz-box-shadow: 2px 1px 5px 0px rgba(255, 190, 24, 0.79);
            border-radius: 10px;
            padding: 12px;
            margin-bottom: 12px;
            position: relative;

            >a {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .left {
                display: flex;
                align-items: center;

                .count {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px solid $primary;
                    color: #FFF;
                    margin-right: 10px;
                    font-family: $font-secondary;
                    font-weight: bold;
                }

                h3 {
                    margin: 0;
                    color: #FFF;
                }

            }

            h4 {
                margin: 0;
            }
        }
    }
}

.inline-buttons {
    display: flex;
    justify-content: space-between;
    gap: 18px;

    a {
        flex: 1;

        img {
            width: 100%;
            display: inline-block;
        }
    }
}

.balance-card {
    text-align: center;

    .balance {
        h2 {
            color: $primary;
            font-family: $font-secondary;
            font-size: 40px;
            margin: 0;
        }

        p {
            font-size: 26px;
            margin: 0;
            color: #FFF;

        }
    }
}

.inv-info {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon {
        img {
            width: 62px;
        }
    }

    .info {
        padding-left: 14px;

        p {
            color: #FFF;
            font-size: 18px;
            margin: 0 0 4px 0;
        }

        h3 {
            font-weight: bold;
            color: $primary;
            font-family: $font-secondary;
            font-size: 32px;
            margin: 0;
        }

        &.profit {
            h3 {
                color: $secondary;
            }
        }
    }
}


.card-shadow {
    min-height: 200px;
    background-color: rgba(2, 2, 12, .01);
    border-radius: 10px;
    overflow: hidden;

    &.primary {
        box-shadow: 0px 1px 2px 3px rgba(255, 190, 24, 1) inset;
        -webkit-box-shadow: 0px 1px 2px 3px rgba(255, 190, 24, 1) inset;
        -moz-box-shadow: 0px 1px 2px 3px rgba(255, 190, 24, 1) inset;
    }

    &.secondary {
        box-shadow: 0px 1px 2px 3px rgba(69, 248, 130, 1) inset;
        -webkit-box-shadow: 0px 1px 2px 3px rgba(69, 248, 130, 1) inset;
        -moz-box-shadow: 0px 1px 2px 3px rgba(69, 248, 130, 1) inset;
    }
}

.subscribers-box {
    height: 350px;
    overflow: hidden;
    background: #000;
}

.theme-shadow-title {
    font-size: 20px;
    font-weight: bold;

    &.primary {
        color: $primary;
    }

    &.secondary {
        color: $secondary;
    }
}

.recent-team-members {




    .member-item {
        display: flex;
        justify-content: center;

        .photo {
            margin-right: 10px;

            img {
                width: 40px;
            }
        }

        .content {


            p {
                color: #FFF;
                margin: 0;
                font-size: 12px;
            }

            h4 {
                margin: 0;
                font-size: 17px;
                font-family: $font-secondary;
                color: $primary;
            }
        }
    }

}

.team-count {
    margin: 0 auto;
    padding: 26px;
    list-style-type: none;
    max-width: 480px;

    li {
        position: relative;
        margin-bottom: 12px;

        img {
            width: 100%;
        }

        &:nth-child(even) {
            .count-content {
                flex-direction: row-reverse;

                p {
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }

        .count-content {
            position: absolute;
            top: -4px;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                margin: 0 10px 0 0;
                font-size: 18px;
                color: #FFF;
            }

            h3 {
                color: $primary;
                margin: 0;
                font-size: 30px;
            }
        }
    }
}

.bottom-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 480px;
    z-index: 10;
    padding: 0 16px;
    list-style-type: none;
    display: flex;
    background-color: #13181D;
    height: 70px;
    // -webkit-box-shadow: inset 0px 4px 8px 0px rgba(0, 0, 0, 0.44);
    // -moz-box-shadow: inset 0px 4px 8px 0px rgba(0, 0, 0, 0.44);
    // box-shadow: inset 0px 4px 8px 0px rgba(0, 0, 0, 0.44);
    justify-content: space-between;

    // #middleMenu {
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: -22px;
    //     margin: auto;
    //     width: 42px;
    //     height: 42px;
    //     border-radius: 50%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background-color: #121B24;
    //     border: 1px solid $primary;

    //     svg {
    //         width: 22px;
    //     }
    // }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 100%;
        display: flex;
        justify-content: space-between;

        li {
            a {
                color: rgba(255, 255, 255, .7);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                padding-top: 6px;
                border-top: 2px solid transparent;

                .home-icon {

                    path {
                        fill: rgba(255, 255, 255, .7);
                    }
                }

                svg {
                    width: 24px;

                }

                opacity: .7;

                &.active {
                    opacity: 1;
                    border-top-color: $secondary;
                    color: $secondary;

                    .home-icon {
                        path {
                            fill: $secondary;
                        }
                    }
                }
            }
        }
    }
}

.wallets-wrap {
    padding: 40px 0;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        width: 100%;
        overflow-x: scroll;

        li {
            position: relative;
            margin-right: 16px;

            .wallet-content {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 34px;

                p {
                    color: #FFF;
                    margin: 0;
                    font-size: 18px;
                }

                h2 {
                    margin: 0;
                    font-size: 30px;
                    font-weight: bold;
                    color: $primary;
                    font-family: $font-secondary;
                }
            }
        }
    }
}

.recent-members-list {
    list-style-type: none;
    flex-direction: column;
    margin: 0;

    li {
        position: relative;

        .member-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            .photo {
                @include flexcenter;
                border-radius: 50%;
                background: #000;
                border: 1px solid $secondary;
                width: 50px;
                height: 50px;
                flex-shrink: 1;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }

                .avatar {
                    color: #ffffff;
                    font-weight: 700;
                }
            }

            .user-info {
                display: flex;
                align-items: center;
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 10px;

                span {
                    font-size: 14px;
                }

                p {
                    color: #FFF;
                    margin: 0;
                    font-size: 17px;
                }
            }

            h4 {
                font-size: 20px;
                font-family: $font-secondary;
                color: $secondary;
                margin: 0;
            }
        }

        .divider {
            margin: 10px 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, rgba(69, 248, 129, 40%) 0%, $secondary 50%, rgba(69, 248, 129, 40%) 100%);
        }
    }
}

@keyframes marquee {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

.slider-marquee {
    --grid-gap: 15px;
    --animation-duration: 5s; // Adjust the duration as needed
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding: 15px 0;
    overflow: hidden;
    background: transparent;
    font-size: 100%;
    vertical-align: baseline;

    &__track {
        display: flex;
        animation: marquee var(--animation-duration) linear infinite;
        animation-play-state: running;
    }

    &.reverse-marquee &__track {
        animation: reversemarquee var(--animation-duration) linear infinite;
        animation-play-state: running;
    }

    &.reverse-marquee:hover &__track {
        animation-play-state: paused;
    }

    @keyframes marquee {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes reversemarquee {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    &__grid {
        display: flex;
        gap: var(--grid-gap);
        list-style: none;
        padding: 0;
        margin: 0;
    }

    /* New class for top-to-bottom marquee */
    &.vertical-marquee &__track {
        display: block;
        animation: marquee-vertical 35s linear infinite;
        animation-play-state: running;
    }

    &.vertical-marquee:hover &__track {
        animation-play-state: paused;
    }

    @keyframes marquee-vertical {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-100%);
        }
    }

    /* New class for bottom-to-top marquee */
    &.reverse-vertical-marquee &__track {
        display: block;
        animation: reversemarquee-vertical var(--animation-duration) linear infinite;
        animation-play-state: running;
    }

    &.reverse-vertical-marquee:hover &__track {
        animation-play-state: paused;
    }

    @keyframes reversemarquee-vertical {
        0% {
            transform: translateY(-100%);
        }

        100% {
            transform: translateY(0);
        }
    }
}



$animation-duration: 4.5s;
$num-items: 20;

.recent-withdrawals {
    padding-bottom: 20px;

    .recent-withdrawals-slider {
        .member-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: center;
            width: 100%;
            background-color: #000;
            opacity: 1;

            .photo {
                @include flexcenter;
                border-radius: 50%;
                background: linear-gradient(45deg, #FFBE18, #45F882);
                width: 50px;
                height: 50px;
                flex-shrink: 1;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }

                .avatar {
                    color: #000000;
                    font-weight: 700;
                }
            }

            p {
                color: #FFF;
                margin: 0;
                font-size: 17px;
                text-overflow: ellipsis;
                text-wrap: nowrap;
            }

            .content {
                h4 {
                    font-size: 17px;
                    font-family: $font-secondary;
                    color: $secondary;
                    margin: 0;
                }
            }



            .divider {
                margin-top: 10px;
                width: 100%;
                height: 1px;
                background: linear-gradient(90deg, rgba(69, 248, 129, 40%) 0%, $secondary 50%, rgba(69, 248, 129, 40%) 100%);
            }
        }
    }


    @keyframes zoomInAndHide {
        0% {
            transform: scale(0.5);
        }

        30% {
            transform: scale(1);

        }

        80% {
            transform: scale(1);

        }

        100% {
            transform: scale(0);
        }
    }

    @for $i from 1 through $num-items {
        .recent-withdrawals-slider .slick-slide:nth-child(#{$i}) {
            animation-delay: ($i - 1) * $animation-duration * 2;
        }
    }

}


.list-transactions {
    margin: 10px;
    padding: 0;
    list-style-type: none;

    li {
        border: 1px solid rgba(255, 255, 255, .5);
        padding: 20px;
        border-radius: 10px;

        >div {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255, 255, 255, .3);
            padding: 5px 0;

            &:last-child {
                border-bottom: none;
            }

            span {
                font-size: 13px;

                &.value {
                    color: #FFF;
                }
            }
        }
    }
}

.button-arrow-back {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    svg {
        width: 26px;
    }
}

.nav-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;

    li {
        a {
            display: flex;
            align-items: center;
            padding: 10px 30px;
            color: #FFF;
            font-size: 18px;

            &.active {
                color: $primary;
            }

            svg {
                width: 24px;
                margin-right: 6px;
            }
        }
    }
}

.server-time {
    text-align: center;
    margin-top: 12px;

    p {
        margin: 0;
    }

    h4 {
        margin: 0;
        color: #FFF;
        font-size: 18px;
    }
}

.advertisement {
    height: 100px;
    color: rgba(255, 255, 255, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    margin-top: 20px;
}

.timer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid $secondary;
    border-radius: 10px;

    .time-item {
        p {
            color: #FFF;
            font-size: 13px;
            margin: 0;
        }

        h4 {
            font-size: 22px;
            font-family: $font-secondary;
            color: $secondary;
            margin-bottom: 0;

            &.red {
                color: #FF5231;
            }
        }
    }
}

.ref-link {
    padding: 30px;
    margin-top: 30px;

    .ref-link-box {
        display: flex;
        background: #000;
        padding: 10px 20px 10px 40px;
        border-radius: 33px;
        font-size: 13px;
        overflow: hidden;
        position: relative;

        svg {
            width: 18px;
            color: $secondary;
            position: absolute;
            left: 14px;
        }
    }
}

.dash-icon-box {

    background: rgb(244, 143, 255);
    background: linear-gradient(0deg, rgba(244, 143, 255, 1) 0%, rgba(64, 201, 255, 1) 100%);
    text-align: center;
    border-radius: 10px;
    padding: 12px;
    display: block;


    &.yellow {
        background: rgb(255, 249, 91);
        background: linear-gradient(0deg, rgba(255, 249, 91, 1) 0%, rgba(255, 147, 15, 1) 100%);
    }

    &.red {
        background: rgb(248, 155, 41);
        background: linear-gradient(0deg, rgba(248, 155, 41, 1) 0%, rgba(255, 15, 123, 1) 100%);
    }

    .icon {
        width: 40px;
        height: 40px;
        margin: auto;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 26px;
            color: rgba(255, 255, 255, .6);
        }
    }

    p {
        margin: 10px 0 2px 0;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 1.1;
        font-weight: 500;
    }


    h3 {
        margin: 0px;
        color: #000000;
        font-weight: bold;
        font-size: 16px;
        font-family: $font-secondary;
    }
}


.new-members {


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        text-transform: uppercase;
        text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.1), 0px -1px 2px rgba(255, 255, 255, 0.1);
        background: linear-gradient(35deg, rgb(0, 195, 255), red, rgb(255, 0, 255), rgb(255, 238, 0));
        background-clip: text;
        -webkit-background-clip: text;
        font-family: $font-secondary;
        color: transparent;
        width: fit-content;
    }

    @keyframes rotate {
        to {
            --angle: 360deg;
        }
    }

    @property --angle {
        syntax: "<angle>";
        initial-value: 0deg;
        inherits: false;
    }
}

.new-members {
    background-color: #02020C;
    padding: 26px 40px;
    margin-top: 2rem;
    text-align: center;

    >div {
        margin: auto;
    }

    p {
        margin-top: 20px;
        margin-bottom: 0;
    }

}


.total-earnings {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    background-image: url("../images/earningbg.png");
    background-size: cover;
    border-image: fill 0 linear-gradient(#0003, #000);

    .info {
        text-align: center;

        p {
            margin: 0;
            font-size: 20px;
            font-weight: bold;
            color: $secondary;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        gap: 20px;

        h4 {
            margin: 0;
            color: $primary;
            font-weight: bold;
            font-size: 36px;
            font-family: $font-secondary;
        }
    }
}

.task {
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 0 34px;


    &.completed {}

    b {
        font-size: 20px;
        font-family: $font-secondary;
        color: #FFF;
    }

    .rank-progress {
        height: 18px;
        width: 100%;
        overflow: hidden;
        position: relative;

        .bar {
            border-radius: 54px;
            height: 18px;
            background: linear-gradient(35deg, rgb(0, 195, 255), red, rgb(255, 0, 255), rgb(255, 238, 0));
            position: relative;
            z-index: 1;
            width: var(--progress-width);
            animation: wave 5s linear infinite;
            background-size: 200% 100%;
        }

        .bar-bg {
            background-color: #000000;
            border-radius: 80px;
            height: 18px;
            width: 100%;
            margin-top: -18px;
        }
    }
}

@keyframes wave {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 200% 0; // Adjust based on the desired wave effect
    }
}

.promotion {
    padding-bottom: 50px;

    h1 {
        margin-top: 0;
    }
}


#clockdiv {
    display: flex;
    justify-content: center;
    text-align: center;

    .wrapper {
        padding: 0 14px;
        line-height: 1.3;

        .time {
            color: $primary;
            font-size: 30px;
            font-weight: bold;
        }
    }
}

.dash-countdown {
    .countdown-timer {
        background: rgb(69, 248, 130);
        background: linear-gradient(180deg, rgba(69, 248, 130, 1) 0%, rgba(10, 142, 55, 1) 100%);
        border-radius: 50px;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 38px;

        &.short-timer {
            width: 210px;
        }

        .wrapper {
            padding: 2px 2.5px !important;
            line-height: 1.3 !important;
            display: flex;
            align-items: center;

            .time {
                color: #FFF !important;
                font-size: 25px !important;
                font-weight: bold !important;
            }

            .smalltext {
                color: #FFF !important;
                font-size: 0 !important;
                font-weight: bold !important;
                margin-right: 7px;

                &::first-letter {
                    font-size: initial !important;
                    font-size: 25px !important;
                }

                &::after {
                    content: ':';
                    font-size: 25px;
                    color: #FFF;
                    font-weight: bold !important;
                    margin-left: 7px;
                    font-weight: bold !important;
                }
            }

            &:last-child {
                .smalltext {
                    &::after {
                        display: none;
                    }
                }
            }

        }
    }
}