.placeholder {
    position: fixed;
    z-index: 99999999999999 !important;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    display: flex !important;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: #000 !important;
    opacity: 1 !important;
    transition: opacity 1s ease-out, transform 1s ease-out;
  
    &.loader {
      transform: translate(0, 0);
  
      svg {
        fill: #050709;
        position: absolute;
        top: 0;
        width: 100vw;
        height: 110vh;
      }
    }
  
    &.hidden {
      animation: fadeOut 3s forwards;
      opacity: 0;
      pointer-events: none;
    }
  
    .preloader-heading {
      .load-text {
        font-weight: 200;
        letter-spacing: 10px;
        text-transform: uppercase;
  
        span {
          animation: loading 1s infinite alternate;
          color: white;
          font-weight: 400;
          font-size: 20px;
        }
      }
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
  
  @keyframes loading {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  