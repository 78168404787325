.scrollbar {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: .6rem;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, .1) !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 20px;
    }

    &::-moz-scrollbar {
        width: .6rem;
        border-radius: 20px;
    }

    &::-moz-scrollbar-track {
        background-color: rgba(0, 0, 0, .1) !important;
    }

    &::-moz-scrollbar-thumb {
        background-color: $primary;
        border-radius: 20px;
    }

    &::-ms-scrollbar {
        width: .6rem;
        border-radius: 20px;
    }

    &::-ms-scrollbar-track {
        background-color: rgba(0, 0, 0, .1) !important;
    }

    &::-ms-scrollbar-thumb {
        background-color: $primary;
        border-radius: 20px;
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: $primary rgba(0, 0, 0, .1); // Firefox scrollbar colors
    }
}