.icon-input {
    position: relative;

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;

        svg {
            width: 20px;
            cursor: pointer;
            color: $primary;
        }
    }

    .icon-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;

        svg {
            width: 20px;
            cursor: pointer;
            color: $primary;
        }
    }

    .toggle-password {
        position: absolute;
        right: 14px;
        top: 16px;

        svg {
            width: 26px;
            color: rgba(255, 255, 255, .5);
        }
    }


    .form-control {
        padding-left: 52px !important;

        &.ps-0 {
            padding-left: 14px !important;
        }
    }
}

.form-default {
    label {
        color: #fff;
        margin-bottom: 8px;
    }

    .form-group {
        position: relative;

        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;

            &.with-label {
                top: 66%;
            }

            svg {
                width: 20px;
                cursor: pointer;
                color: $primary;
            }
        }
    }

    .form-control {
        min-height: 60px;
        background-color: #161E26;
        border: 1px solid rgba(255, 255, 255, .2);
        color: #FFF;
        padding: 17px 12px;
    }

    .form-select {
        min-height: 60px;
        background-color: #161E26;
        border: 1px solid rgba(255, 255, 255, .2);
        color: #FFF;
    }
}


.form-control {
    &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, .5);
    }

    &::-moz-placeholder {
        color: rgba(255, 255, 255, .5);
    }

    &::-ms-placeholder {
        color: rgba(255, 255, 255, .5);
    }

    &::placeholder {
        color: rgba(255, 255, 255, .5);
    }
}


.radio-onoff {
    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
    }

    label {
        cursor: pointer;
        width: 120px;
        height: 42px;
        background: linear-gradient(270deg, #BF0205 0%, #CB0909 31.72%, #660002 100%);
        display: block;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 32px;
        height: 32px;
        box-shadow: inset 7px -9px 13px -4px #505050;
        border-radius: 90px;
        transition: 0.3s;
        background: #fff;
    }

    label:before {
        content: "Deactive";
        color: #FFF;
        font-weight: bold;
        right: 12px;
        top: 11px;
        position: absolute;
        font-size: 13px;
    }

    input:checked+label:before {
        content: "Active";
        left: 12px;
        right: unset;
        color: #fff;
    }

    input:checked+label {
        background: linear-gradient(88deg, #85D74A 0%, #28922F 31.72%, #02612B 100%);
       
        
    }

    input:checked+label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
        

    }

    label:active:after {
        // width: 130px;
    }
}