.modal-theme {
    background-color: rgba(0, 0, 0, 0.44);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: rgba(255, 255, 255, 0.6);

    &.size-md {
        .modal-dialog {
            max-width: 500px;
        }
    }

    &.transparent {
        color: #FFF;

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #171f27;

            button[aria-label="Close"] {
                display: none;
            }

            .btn-close {
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                width: auto;
                height: auto;
                padding: .25em .25em;
                color: #000;
                background: none;
                border: 0;
                border-radius: none;
                opacity: 1;

                svg {
                    width: 30px;
                    color: $secondary;
                }
            }

        }

        .modal-body {
            background: #000;
            box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.20);
        }

        .modal-footer {
            padding: 1.2rem 2rem;
            background: #000000;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        }
    }

    .modal-header {
        background-color: #000;
        border: none;
        position: relative;

        &.header-dark {
            margin: -2px;

            .modal-title {
                color: #FFF;
            }

            .btn-close {
                filter: invert(100%);
            }
        }



        .modal-title {
            position: relative;
        }

        .h4 {
            font-size: 1rem;
        }
    }

    &.modal-small {
        .modal-dialog {
            max-width: 550px;
        }
    }


    &.modal-lg {
        .modal-dialog {
            max-width: 960px;

        }
    }

    &.modal-thumbnail {
        .modal-dialog {
            iframe {
                width: 100%;
                height: 397px;
                border-radius: 25px;
            }
        }
    }


    .modal-dialog {
        max-width: 800px;

    }

    .modal-body {
        img {
            border-radius: 12px;
        }
    }

    .modal-footer {
        padding: 1.2rem 2rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
}

.bg-img-modal {
    .modal-header {

        min-height: 115px;
        position: relative;
        filter: grayscale(1);

        .button-close {
            position: absolute;
            right: 1%;
            top: 3%;
        }

        .title {
            position: absolute;
            bottom: 0%;
            color: white;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

    .modal-body {}
}

@media screen and (max-width:767px) {
    .bg-img-modal {
        .modal-header {

            .title {
                bottom: 2.5%;
            }
        }
    }

    .modal-theme {
        .modal-body {
            padding: 1.3rem;
        }

        &.modal-thumbnail {
            .modal-dialog {
                iframe {
                    height: 245px !important;
                }
            }
        }
    }
}