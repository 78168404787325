.dashboard-content {
	position: relative;
	padding: 30px 0;
	width: 100%;
}




.dashboard-icons {
	margin-top: 3rem;
	padding: 2rem;
	border-radius: $radius;

}

.stat_box {
	border-radius: 20px;
	@include gradientReverse();
	padding: 2px;

	.head {
		display: flex;
		align-items: center;
		@include gradientDark();
		color: #FFF;
		border-radius: 18px 18px 0 0;
		padding: 3px 25px;

		.head-box-title {

			margin: 10px 0;
			font-size: 16px;
		}

		span {
			line-height: unset;
			font-size: 2.6rem;
		}

		img {
			width: 38px;
			margin: 10px 0;
		}

		h4 {
			margin: 0;
			padding-left: 20px;
			font-size: 20px;
		}

		a {
			color: #FFF;
			display: block;
			padding-left: 20px;

			p {
				margin: 0;
				font-size: 15px;
				color: #FFF;
				text-transform: uppercase;

			}

			h3 {
				margin: 0;
				font-size: 20px;
			}
		}
	}

	.stat_box_body {
		padding: 25px;
		background-color: $lightGray;
		border-radius: 0 0 20px 20px;



		h2 {
			font-size: 22px;
			font-weight: 600;
		}
	}

	.head_wallet {
		display: flex;
		align-items: center;
		align-items: center;
		margin-bottom: 1.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		padding-bottom: .6rem;

		.icon {
			span {
				line-height: unset;
				color: $primary;
				font-size: 2.6rem;
			}
		}

		.info {
			padding-left: 20px;
			display: flex;
			width: 100%;
			justify-content: space-between;

			p {
				margin: 0;
				font-size: 18px;
				color: #8E8E8E;
			}

			h4 {
				margin: 0;
				font-size: 20px;
			}
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		&.action_list {


			li {
				display: flex;
				align-items: center;
				padding: 8px 16px;
				margin-bottom: 0;
				flex-direction: row-reverse;
				position: relative;

				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					@include gradientReverse();
					position: absolute;
					bottom: 0;
					left: 0;
				}



				&.wallet_heading {
					background-color: #fbefd1;
					padding-left: 20px;
					flex-direction: row;
					padding-bottom: 6px;
				}



				.icon {

					margin-right: 20px;
					width: 62px;

					span {
						color: $primary;
						font-size: 3rem;
					}

					img {
						width: 54px;
					}

					svg {
						width: 30px;
					}
				}

				a {
					display: flex;
					width: 100%;
					flex-direction: column;
					color: $black;

					p {
						font-size: 14px;
						text-transform: uppercase;
						margin-bottom: 6px;
						font-weight: 400;
						margin-bottom: 0;
					}
				}

				.button {
					padding: 0;
					background-color: transparent;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					font-size: 13px;

					span {
						line-height: unset;
					}
				}
			}
		}

		li {
			margin-bottom: 1.5rem;

			p {
				margin: 0;
				font-size: 14px;
			}

			h3 {
				margin: 0;
				font-size: 20px;
				font-weight: 600;
			}
		}
	}
}

.ref_link {
	padding: 1px;
	border-radius: 8px;

	.inner {
		border-radius: 7px;
		background-color: $lightGray;
		padding: 12px;

	}
}

.inline-button-radios {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;

	li {
		flex: 1;
		margin: .5rem;

		.button {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			width: 100%;
			padding: .4rem;
			background-color: #B5A666;
			color: #EBDFAD;

			i {
				opacity: 0;
			}

			&.active {
				background-color: #FFF;
				color: $primary;

				i {
					opacity: 1;
				}
			}
		}
	}
}


.bonus_total {
	position: relative;

	.bonus_status {}
}

.progress-bar {
	background-color: #333 !important;
}

.nav-tabs {
	.nav-item {
		.nav-link {
			color: $primary;

			&.active {
				color: $themeDark;
			}
		}
	}
}

.join_date_boxes {
	display: flex;
	justify-content: space-between;

	span {
		width: 55px;
		height: 50px;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 12px;
		color: #FFF;
		background-color: $primary;
	}
}

.choose-photo {
	label {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-color: rgba(0, 0, 0, 0.04);
		border-radius: $radius;
		border: 1px dashed rgba(0, 0, 0, 0.4);
		height: 300px;
		cursor: pointer;

		i {
			font-size: 5rem;
			color: rgba(0, 0, 0, 0.4);
		}

		input {
			position: absolute;
			opacity: 0;
		}
	}
}

.wallet_item {
	display: flex;
	align-items: center;

	border-radius: 22px;
	padding: 1rem;
	margin-top: 1rem;

	.icon {
		width: 60px;
		height: 60px;
		border-radius: 50%;

		display: flex;
		align-items: center;
		justify-content: center;

		i {
			font-size: 2rem;
			color: $primary;

		}
	}

	.info {
		padding-left: 0.8rem;

		h4 {
			margin: 0;
			font-size: 1.2rem;
		}

		p {
			margin: 0;
			font-size: .9rem;
		}
	}
}

.dash-box {
	display: flex;
	align-items: center;
}


.photo_name {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(0, 0, 0, 0.04);
	background-color: rgba(0, 0, 0, 0.03);
}


#dashboard-placement {
	position: absolute;
	right: 7px;
	top: 64px;
	z-index: 1;
	width: 300px
}

.scroll_content_all {

	overflow-x: scroll;
	overflow-x: hidden;

	&:hover {
		&::-webkit-scrollbar-thumb {
			display: block;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
		}
	}

	&::-webkit-scrollbar {
		width: 4px;

	}

	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		border-radius: 50px;
		display: none;
	}
}

.date-filters {
	display: flex;
	align-items: flex-end;

	.item {
		display: flex;
		align-items: flex-end;

		&.last {
			margin-left: 50px;
		}

		.input {
			width: 250px;

		}

		input[type='date']::-webkit-calendar-picker-indicator {
			background: transparent;
			bottom: 0;
			color: transparent;
			cursor: pointer;
			height: auto;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
		}

		.icon {
			margin-left: 10px;

			button {
				width: 44px;
				height: 44px;

				span {
					min-width: 44px;
					padding: 0;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						color: $primary;
					}
				}
			}
		}
	}

	>.button {
		height: 44px;
		font-weight: normal;
		margin-left: 20px;
	}
}

.filters-right {
	display: flex;
	justify-content: flex-end;

	>.button {
		height: 44px;
		margin-right: 20px;
		font-weight: normal;
	}
}


#overlaymenu {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	z-index: 2;
	display: none;

	&.active {
		display: block;
	}
}

.statistical-card {
	padding-bottom: 0 !important;
	height: 170px;
}

@media screen and (max-width: 767px) {
	// iframe {
	// 	width: 100% !important;
	// 	height: 100vh !important;
	// }


	#ResumeContainer {
		margin: auto;
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.PDFDocument {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.PDFPage {
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
	}

	.PDFPageOne {
		margin-bottom: 25px;
	}

	.PDFPage>canvas {
		max-width: 100%;
		height: auto !important;
	}


	.e-header {
		margin: 10px 0 !important;

		h4 {
			font-size: 17px;
			padding: 0 !important;
		}
	}

	#overlaymenu {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		position: absolute;
		z-index: 3;
		display: none;
		top: 0;
		left: 0;

		&.active {
			display: block;
		}
	}

	.dashboard-content {
		padding: 40px 0 30px 0;
	}

	.date-filters {
		.item {
			.input {
				width: 272px;
			}
		}
	}

	.filters-right {
		justify-content: center;
		margin-bottom: 10px;
	}

	.statistical-card {
		height: 170px;
		padding-bottom: 20px !important;
	}

	.stat_box {
		border-radius: 20px;
		@include gradientReverse();
		padding: 2px;

		.head {
			display: flex;
			align-items: center;
			@include gradientDark();
			color: #FFF;
			border-radius: 18px 18px 0 0;
			padding: 3px 25px;

			.head-box-title {

				margin: 10px 0;
				font-size: 16px;
			}

			span {
				line-height: unset;
				font-size: 2.6rem;
			}

			img {
				width: 30px;
				margin: 10px 0;
			}

			h4 {
				margin: 0;
				padding-left: 20px;
				font-size: 19px;
			}

			a {
				color: #FFF;
				display: block;
				padding-left: 20px;

				p {
					margin: 0;
					font-size: 15px;
					color: #FFF;
					text-transform: uppercase;

				}

				h3 {
					margin: 0;
					font-size: 17px;
				}
			}
		}

		.stat_box_body {
			padding: 15px;
			background-color: $lightGray;
			border-radius: 0 0 20px 20px;



			h2 {
				font-size: 17px;
				font-weight: 600;
			}
		}

		.head_wallet {
			display: flex;
			align-items: center;
			align-items: center;
			margin-bottom: 1.5rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			padding-bottom: .6rem;

			.icon {
				span {
					line-height: unset;
					color: $primary;
					font-size: 2.6rem;
				}
			}

			.info {
				padding-left: 20px;
				display: flex;
				width: 100%;
				justify-content: space-between;

				p {
					margin: 0;
					font-size: 18px;
					color: #8E8E8E;
				}

				h4 {
					margin: 0;
					font-size: 20px;
				}
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			&.action_list {


				li {
					display: flex;
					align-items: center;
					padding: 8px 16px;
					margin-bottom: 0;
					flex-direction: row-reverse;
					position: relative;

					&::after {
						content: "";
						display: block;
						width: 100%;
						height: 1px;
						@include gradientReverse();
						position: absolute;
						bottom: 0;
						left: 0;
					}



					&.wallet_heading {
						background-color: #fbefd1;
						padding-left: 20px;
						flex-direction: row;
						padding-bottom: 6px;
					}



					.icon {

						margin-right: 0px;
						width: 30px;

						span {
							color: $primary;
							font-size: 3rem;
						}

						img {
							width: 34px;
						}

						svg {
							width: 30px;
						}
					}

					a {
						display: flex;
						width: 100%;
						flex-direction: column;
						color: $black;

						p {
							font-size: 14px;
							text-transform: uppercase;
							margin-bottom: 6px;
							font-weight: 400;
							margin-bottom: 0;
						}
					}

					.button {
						padding: 0;
						background-color: transparent;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						font-size: 13px;

						span {
							line-height: unset;
						}
					}
				}
			}

			li {
				margin-bottom: 1.5rem;

				p {
					margin: 0;
					font-size: 14px;
				}

				h3 {
					margin: 0;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
	}


	.scroll_content_all {
		overflow-x: scroll;
		overflow-y: scroll;
	}

	.ref_link {

		.inner {
			font-size: 13px;

		}
	}
}