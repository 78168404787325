.blog-section {
	padding: 3rem 0;
}

.blog-item {
	
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.05);
	border-radius: $radius;
	overflow: hidden;
	margin-bottom: 1rem;
	position: relative;
	

	img {
		border-radius: $radius;
		width: 100%;
		
	}

	&:hover {
		.overlay {
			transform: translateY(0);
		}
	}
	
	.overlay {
		transition: all .5s ease;
		padding: 2rem;
		border-radius: $radius;
		@include bgPrimary(0.8);
		position: absolute;
    	bottom: 0;
    	transform: translateY(100%);
		h3 {
			font-size: 1.2rem;
			color: #000;
		}

		a {
			color: #000;
			font-weight: bold;
		}
	}

	&:hover {
		.thumb {
			img {
				transform: scale(1.1);
			}
		}
	}
}


@media screen and (max-width: 992px) and (min-width: 768px) {
	.blog-item {
		padding: 1.625rem;
	}
}
@media screen and (max-width: 767px) {
	.blog-item {
		margin-bottom: 2rem;
		.info {

			h2 {
				font-size: .8rem;
			}

			.button {
				font-size: .8rem;
			}
		}
	}
}