.card-bg-addmoney {
    position: relative;
    padding: 10px;
}

.deposit-wrap {
    .payment-modes {
        flex-direction: column;
        gap: 20px;

        li {
            width: 100%;

            a {
                padding: 6px 23px;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-radius: 50px;

                .county-info {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    img {
                        margin: 0;
                    }
                }

                .price {
                    text-align: end;

                    h6 {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.5px;
                    }

                    span {
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 12px;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }
}

.copy-ticket {
    border-radius: 16px;
    background: #F2F2F2;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ticket-qr {
        height: auto;
        margin: 0 auto;
        padding: 20px;
        max-width: 204;
        width: 100%;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            font-size: 12px;
            color: #525866;

            span {
                font-weight: 400;
            }

            h6 {
                font-weight: 600;
            }
        }
    }
}

.ticket-instruction {

    h5 {
        color: #525866;
        font-size: 12px;
        font-weight: 700;
        margin-top: 10px;
    }

    ol {
        // padding: 0;
        margin: 0;

        li {
            color: #525866;
            font-size: 12px;
            line-height: 150%;
        }
    }
}

.upload-img {
    border-radius: 12px;
    border: 2px dashed rgba(255, 0, 43, 0.50);
    padding: 0;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 402px;

    svg {
        width: 20px;
    }

    .upload-box {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(45, 45, 45);
        padding: 0;
        flex-direction: column;


        input {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .thumnail {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            svg {
                margin-bottom: 10px;
            }

            p {
                margin: 0;
            }
        }
    }

    &.white {
        background: #FFF;
        height: 220px;
    }

    &.pink {
        height: 456px;
        background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
    }

    &.blue {
        height: 220px;
        background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
    }

    &.gray {
        width: 480px;
        background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
    }

    &.gray {
        width: 480px;
        background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
    }


    .delete {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;
        display: inline-flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        left: 15px;

        svg {
            stroke: $primary;
        }
    }
}

.bt_box {


    border-radius: $radius;

    h1 {
        text-align: center;
        color: #ffffff;
        font-size: 26px;
        margin-top: 30px;
    }

    p {
        text-align: center;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);

        strong {
            color: $primary;
            font-size: 20px;
            display: block;
            margin-top: 10px;
            font-weight: 600;
        }
    }

    h5 {
        font-size: 14px;
        text-align: center;
    }

    .bt_input_group {
        margin-top: 2rem;
        position: relative;

        .form-control {


            height: 60px;
            font-size: 1.3rem;
            background-color: #161E26;
            border: 1px solid rgba(255, 255, 255, .2);
            color: #FFF;
            padding-left: 50px;

            &:focus {
                outline: none;
            }
        }

        .icon-left {
            position: absolute;
            left: 10px;
            top: 16px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .icon-right {
            position: absolute;
            right: 10px;
            top: 16px;

            span {
                cursor: pointer;
            }

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .button {
            display: inline-flex;
            align-items: center;
            color: #E61110;
            padding: 0 1rem;
            background-color: transparent;
        }
    }
}

.payment-modes {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;

    li {
        width: 100%;

        a {
            display: block;

            border-radius: 16px;
            padding: 14px 16px;
            text-transform: uppercase;
            font-weight: bold;
            background-color: #161E26;
            border: 1px solid rgba(255, 255, 255, .2);
            color: #FFF;


            &.active {
                background: rgba(255, 190, 24, 0.10);
                border-color: $primary;
            }

            span {
                margin-right: 10px;
            }

            img {
                width: 34px;
                margin-right: 10px;
            }
        }
    }
}

.bt_amounts {
    margin: 1rem auto 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 8px;

    li {

        margin: 0 5px;


        span {
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
            height: 40px;
            border-radius: 6px;
            font-size: 14px;
            cursor: pointer;
            font-weight: 500;
            transition: all 0.5s ease;
            padding: 0 10px;
            white-space: nowrap;
            color: #000;

            &:hover {
                border-color: $primary;
            }

            &.clear {
                color: $red;
            }

        }
    }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.payment_modes {
    margin: 0 0 2rem 0;
    padding: 0;
    list-style-type: none;


    li {
        width: 100%;
        margin: 8px;

        button {
            background-color: #02020C;
            color: #FFF;
            width: 100%;
        }

        a {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            border-radius: 16px;
            padding: 12px;
            gap: 16px;
            margin: 0;

            img {
                margin: 0;
            }
        }


    }
}

.currency_list {
    margin: 1rem 0 0 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        padding: .5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;

        img {
            width: 50px;
            margin-right: 20px;
        }

    }
}

.crypto_order {
    .crypto_address {
        display: flex;
        align-items: center;
        justify-content: center;


        .address-wrap {
            width: 100%;

        }

        .address {
            border-radius: 12px;
            background-color: #161E26;
            border: 1px solid rgba(255, 255, 255, .2);
            color: #FFF;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            overflow: hidden;

            >div {
                svg {
                    margin-right: 10px;
                }
            }

            svg {
                width: 24px;
            }

            button {
                border: none;
                background-color: transparent;
                position: absolute;
                top: 13px;
                right: 0;
                transform: translate(-13px, 0);

                svg {
                    color: $primary;
                }

            }

        }

        .button {
            padding: .4rem .8rem;
            margin-left: 12px;
            margin-top: 19px;

            i {
                line-height: unset;
            }
        }
    }
}

.deposit-info-box {
    display: flex;
    justify-content: space-between;
    padding: 18px 0;
    align-items: center;
    position: relative;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &.bg {
        background: rgba(0, 0, 0, 0.05);
    }

    >div {
        display: flex;
        align-items: center;

        svg,
        img {
            width: 24px;
            margin-right: 8px;
        }
    }

    &.small {
        padding: 11px 24px;

        h4 {
            font-size: 14px;
        }

        p {
            font-size: 14px;
        }
    }

    h4 {
        font-size: 13px;
        margin: 0;
        display: flex;
        align-items: center;

        img {
            width: 30px;
            margin-right: 10px;
        }
    }

    p {
        font-size: 16px;
        color: $gray;
        margin: 0;
    }
}

.crypto_order {
    padding-top: 40px;

    h2 {
        text-align: center;
        font-size: 20px;

        span {
            color: $primary;
        }
    }

    .sub-heading {
        max-width: 400px;
        margin: auto;
        text-align: center;
        line-height: 18px;
        font-size: 13px;
    }
}

.toggle-exchange-currency {
    position: absolute;
    left: 15px;
    top: 54px;
    background-color: #fafafa;
    padding: 10px;

    h4 {
        cursor: pointer;
    }



}

.instructions {
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: $gray;
    font-size: 14px;

    li {
        line-height: 20px;
    }
}


/*
@media screen and (max-width: 767px) {
    .crypto_order {
        .crypto_address {
            display: flex;
            align-items: center;
            justify-content: center;


            .address-wrap {
                width: 100%;

                .card-gradient {
                    border-radius: 10px;
                    width: 269px;
                    overflow: hidden;

                    .card-inner {
                        border-radius: 8px;
                        width: 288px;
                        padding: 2px !important;
                    }
                }
            }

            .address {

                margin: 0.9rem;
                overflow: hidden;
                width: 95%;
            }

            .button {
                padding: .4rem .8rem;
                margin-left: 12px;
                margin-top: 19px;

                i {
                    line-height: unset;
                }
            }
        }
    }

    .deposit-info-box {
        // padding: 6px 10px;

        h4 {
            font-size: 13px;
        }

        p {
            font-size: 13px;
        }
    }

    .bt_box {
        h1 {
            font-size: 18px;
        }

        p {
            font-size: 12px;
            margin-bottom: 0;

            strong {
                font-size: 16px;
            }
        }

        h5 {
            font-size: 14px;
        }
    }

    .bt_amounts {
        

        li {
            span {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                height: 40px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
                font-weight: 500;
                transition: all 0.5s ease;
                padding: 0 10px;
                white-space: nowrap;
                color: #000;

            }

            &:last-child {
                margin-top: 0;
            }
        }
    }

  

    .payment_modes {
        align-items: center;

        li {
            .button {
                min-width: 200px;
            }
        }
    }

    .payment-modes {
        gap: 10px;

        li {
            width: 100%;

            a {
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border-radius: 16px;
                padding: 12px;
                gap: 16px;
                margin: 0;

                img {
                    margin: 0;
                }
            }
        }
    }

    .payment_modes {
        flex-direction: column;

        li {
            a {
                display: block;

                span {
                    margin-right: 10px;
                }
            }
        }
    }

}*/