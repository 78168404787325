.package-item-new {
    margin-bottom: 2rem;

    .package-box {
        h2 {
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            font-size: 22px;
        }

        p {
            font-weight: bold;
            color: #87888A;
            font-size: 22px;
        }
    }

    .action {
        padding-top: 18px;
        position: relative;

        &::after {
            content: "";
            @include gradientReverse();
            width: 100%;
            height: 3px;
            position: absolute;
            top: 0;
            left: -42px;
        }
    }
}


.package-item {
    position: relative;




    &.fdays {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .info {
        display: flex;
        align-items: center;

        .icon {


            img {
                width: 100px;
            }

            .remaining_days {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                text-align: center;
                color: #FFF;
                font-weight: bold;
                font-size: 36px;
                top: 33px;
            }
        }
    }





    .action {
        .button {
            min-width: 200px;
        }
    }

    .package-box {

        position: relative;
        border-radius: 10px;
        margin: 0 0 0 2rem;

        h2 {
            font-size: 1.7rem;
        }

        .stacked_buttons {
            display: flex;
            justify-content: center;

            .button {
                &.button_repurchase {
                    margin-right: -10px;
                    position: relative;
                }

                &.cashback {
                    background-color: #DEDEDE;
                    color: #ABABAB;
                }
            }
        }



        .button {
            &:hover {
                opacity: .8;
            }

            &:disabled {
                opacity: .5;
                cursor: not-allowed;
            }
        }

        h4 {
            font-size: 40px;
            color: rgba(0, 0, 0, 0.3);
            margin: 0;
        }

        h1 {
            margin: 0;
            font-weight: bold;
            font-size: 55px;
        }

        ul {
            margin: 0 0 .6rem 0;
            padding: 0;
            list-style-type: none;

            li {
                font-size: .8rem;
                color: #8F9198;
            }
        }
    }
}

.packages-row {
    .col-md-4 {

        &:nth-child(1),
        &:nth-child(9) {
            .bg-box {
                background-color: rgba(255, 244, 229, 0.8);

                .bar {
                    background-color: #8D6224;
                }
            }

            .package-box {
                h2 {
                    color: #8D6224;
                }

                .button {
                    background-color: #8D6224;
                    color: #FFF;
                }
            }
        }

        &:nth-child(2),
        &:nth-child(5) {
            .bg-box {
                background-color: rgba(236, 235, 235, 0.8);

                .bar {
                    background-color: #A7A2A2;
                }
            }

            .package-box {
                h2 {
                    color: #A7A2A2;
                }

                .button {
                    background-color: #A7A2A2;
                    color: #FFF;
                }
            }
        }

        &:nth-child(3),
        &:nth-child(8) {
            .bg-box {
                background-color: rgba(236, 196, 64, 0.1);

                .bar {
                    background-color: #ECC440;
                }
            }

            .package-box {
                h2 {
                    color: #ECC440;
                }

                .button {
                    background-color: #ECC440;
                    color: #FFF;
                }
            }
        }

        &:nth-child(4) {
            .bg-box {
                background-color: rgba(223, 242, 254, 0.1);

                .bar {
                    background-color: #016099;
                }
            }

            .package-box {
                h2 {
                    color: #016099;
                }

                .button {
                    background-color: #016099;
                    color: #FFF;
                }
            }
        }

        &:nth-child(6) {
            .bg-box {
                background-color: rgba(75, 146, 155, 0.1);

                .bar {
                    background-color: #4B929B;
                }
            }

            .package-box {
                h2 {
                    color: #4B929B;
                }

                .button {
                    background-color: #4B929B;
                    color: #FFF;
                }
            }
        }

        &:nth-child(7) {
            .bg-box {
                background-color: rgba(77, 203, 249, 0.1);

                .bar {
                    background-color: #4DCBF9;
                }
            }

            .package-box {
                h2 {
                    color: #4DCBF9;
                }

                .button {
                    background-color: #4DCBF9;
                    color: #FFF;
                }
            }
        }
    }


}

.pp_box {
    background-color: $lightGray;
    border-radius: 18px;

    &.duration {
        ul {
            li {
                justify-content: center;

                p {
                    margin: 0;
                    text-align: center;
                    padding: 10px;
                    font-size: 18px;

                    svg {
                        margin-right: 8px;
                    }
                }

                button {
                    margin: 10px;
                }
            }
        }
    }

    .package_name {
        text-align: center;
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        .photo {
            img {
                max-width: 130px;
            }
        }

        .info {
            padding-top: 1rem;

            h4 {
                font-size: 1.1rem;
                text-transform: uppercase;
                color: #000;
            }

            h3 {
                color: #8D8D8D;
                font-weight: bold;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0 0 1rem 0;
        list-style-type: none;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: .9rem;
            padding: .4rem 1rem;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                @include gradientReverse();
                position: absolute;
                bottom: 0;
                left: 0;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.box_head {
    background-color: #FFF;
    padding: .8rem;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    font-weight: bold;
    color: #8E9F9F;

}

.box_list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        background-color: #F5F5F5;
        padding: .8rem;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        font-weight: 400;
        color: #8E9F9F;
        margin-top: 10px;
        min-height: 50px;
    }
}

.package-item-v {
    position: relative;
    margin-bottom: 2rem;

    .bg-box {
        width: 100%;
        height: 200px;
        border-radius: 20px;
        position: absolute;
        left: 0;
        top: 0;

        background-color: rgba(213, 192, 101, 0.2);

        .bar {
            width: 90%;
            height: 12px;
            position: absolute;
            left: 5%;
            top: 0;
            background-color: $primary;
        }
    }

    .icon {
        position: relative;
        padding-top: 23px;

        img {
            width: 130px;
        }

        .remaining_days {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;
            color: #FFF;
            font-weight: bold;
            font-size: 36px;
            top: 33px;
        }
    }



    .package-box {
        width: 90%;
        min-height: 204px;
        // background-image: url("../images/packages/packagebg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        text-align: center;
        padding: 1rem;
        position: relative;
        border-radius: 10px;
        margin: auto;
        background-color: #FFF;

        .stacked_buttons {
            display: flex;
            justify-content: center;
            margin-top: 10px;

            .button {
                padding: 10px 10px;
                font-size: 13px;

                &.button_repurchase {
                    margin-right: -10px;
                    position: relative;
                    background-color: $primary;
                }

                &.cashback {
                    background-color: #DEDEDE;
                    color: #ABABAB;
                }
            }
        }



        .button {
            &:hover {
                opacity: .8;
            }

            border-radius: 0;

            &:disabled {
                opacity: .3;
                cursor: not-allowed;
                background-color: transparent;

            }
        }

        h4 {
            font-size: 40px;
            color: rgba(0, 0, 0, 0.3);
            margin: 0;
        }

        h1 {
            margin: 0;
            font-weight: bold;
            font-size: 55px;
            color: $primary;
        }

        ul {
            margin: 0 0 .6rem 0;
            padding: 0;
            list-style-type: none;

            li {
                font-size: .8rem;
                color: #8F9198;
            }
        }
    }





}


.package_detail {
    border-left: 1px solid rgba(0, 0, 0, .1);
    min-height: 100vh;
    padding-left: 100px;
    margin-left: 100px;

}



.fourty_duration {
    h2 {
        color: $cyan;
        text-align: center;
    }

    .duration-item {
        background-color: #FFF;
        border: 1px solid #EAEAEA;
        border-radius: 6px;
        padding: 10px 20px;
        margin-bottom: 1rem;

        .row {
            align-items: center;
        }

        &.brown {
            .count {
                background-color: #D2C383;
            }

            .d-button {
                color: #D2C383;
                background-color: rgba($color: #D2C383, $alpha: 0.2);
            }
        }

        &.blue {
            .count {
                background-color: #4FC4C4;
            }

            .d-button {
                color: #4FC4C4;
                background-color: rgba($color: #4FC4C4, $alpha: 0.2);
            }
        }

        &.green {
            .count {
                background-color: #66D545;
            }

            .d-button {
                color: #66D545;
                background-color: rgba($color: #66D545, $alpha: 0.2);
            }
        }

        &.cyan {
            .count {
                background-color: #28D7A6;
            }

            .d-button {
                color: #28D7A6;
                background-color: rgba($color: #28D7A6, $alpha: 0.2);
            }
        }

        .count {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #FFF;
            font-weight: bold;
            font-size: 1.4rem;
        }

        p {
            margin: 0;
        }

        .d-button {
            display: inline-block;
            background-color: #FAF8F1;
            display: inline-block;
            padding: 10px 30px;
            border-radius: 60px;
        }
    }
}

.ls-item {
    margin-bottom: 1rem;

    .row {
        align-items: center;

    }

    &:nth-child(even) {
        .ls-info {
            background-color: rgba($color: #D2C383, $alpha: 0.2);
        }
    }

    &:nth-child(odd) {
        .ls-info {
            background-color: rgba($color: #4FC4C4, $alpha: 0.2);
        }
    }

    .ls-label {
        background-color: #FFF;
        text-align: center;
        padding: 10px;
        border-radius: 4px;
    }

    .ls-info {
        padding: 10px 10px 10px 30px;
        border-radius: 4px;
    }
}

.d-dates {
    margin: 0 0 1rem 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;

    li {
        background-color: #F5F5F5;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        color: #8E9F9F;
        margin: 0 20px;

        span {
            font-size: .8rem;
        }

        p {
            font-size: 1.3rem;
            margin: 0 0 0 6px;
            font-weight: bold;

            sup {
                font-weight: normal;
            }
        }
    }
}


.summary-box {
    border-radius: 18px;

    background-color: $lightGray;
    display: flex;
    align-items: center;
    overflow: hidden;

    .icon {

        @include gradientDark();
        align-self: stretch;
        width: 107px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 18px 0 0 18px;
        flex-shrink: 0;

        i {
            font-size: 40px;
            color: #FFF;
        }

        img {
            width: 40px;
        }
    }

    .info {
        padding: 30px 20px;

        p {
            margin: 0;
            font-size: 13px;
            text-transform: uppercase;
            color: rgb(142, 142, 142);
            margin-bottom: 10px;
            font-weight: bold;
        }

        h3 {
            margin: 0;
            color: rgb(31, 31, 31);
            font-size: 18px;
            font-weight: 400;
        }
    }
}

.pp_modal_item {
    @include gradientReverse();
    padding: 2px;
    margin-bottom: .5rem;
    border-radius: 12px;
    overflow: hidden;

    .inner {
        display: flex;
        justify-content: space-between;
        background-color: $lightGray;
        width: 100%;
        padding: 10px 10px;
        border-radius: 10px;

        .pp_modal_label {
            color: #000;
            font-weight: bold;
            font-size: 13px;

            .form-check-label {
                font-weight: normal;
                color: gray;
            }
        }

        .pp_modal_value {
            color: #8D8D8D;
            font-weight: bold;
            font-size: 14px;
        }
    }
}

.package-image {
    img {
        width: 100%;
        border-radius: 16px;
        height: 500px;
    }
}

.package-commodity {
    .card-package-image {
        border-radius: 12px;
        width: 200px;
        margin: auto;

        .card-inner {
            padding: 0;
            border-radius: 10px;

            >img {
                width: 100%;
                border-radius: 10px;
                border: 6px solid #FAFAFA;
            }
        }
    }

    h4 {
        color: $gray;
    }

    h5 {
        color: $gray;
    }

    button {
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 767px) {
    .pp_box {
        &.duration {
            ul {
                li {

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .package_name {
            .info {
                h4 {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #000;
                }
            }
        }

        ul {
            li {
                font-size: 14px;
            }
        }
    }

    .pp_modal_item {
        .inner {
            padding: 4px 10px;
            align-items: center;

            .pp_modal_label {
                font-weight: 500;
            }

            .pp_modal_value {
                font-weight: 500;
            }
        }


    }

    .package-item-new {
        margin-bottom: 2rem;

        .card-gradient {
            max-width: 80%;
            margin: auto;
        }

        .package-box {
            h2 {
                font-weight: bold;
                text-transform: uppercase;
                margin: 0;
                font-size: 18px;
            }

            p {
                font-weight: bold;
                color: #87888A;
                font-size: 18px;
            }
        }

        .action {
            padding-top: 18px;
            padding-bottom: 18px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
            }
        }
    }


}