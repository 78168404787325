.target-section {
    font-family: $font-primary;
    padding-bottom: 80px;

    .target-header {
        @include flexcenter;
        gap: 30px;
        margin-bottom: 70px;

        .target-info {
            width: 45%;

            &.left {
                text-align: right;
            }

            &.right {
                text-align: left;
            }

            p {
                font-size: 14px;
                text-transform: uppercase;
                margin: 0;
            }

            h4 {
                color: $primary;
                margin: 0;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    .polygon {
        clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
        width: 60px;
        height: 60px;
        background-color: #000;
        position: relative;
        margin: auto;

        img {
            position: absolute;
            transform: translate(-50%, 30%);
            width: 40px;
        }
    }

    .arrow-target {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        outline: none;
        background: transparent;
        border: transparent;

        svg {
            cursor: pointer;
        }

        span {
            font-size: 12px;
            opacity: 0.5;
            cursor: pointer;
        }
    }

    .content-bg {
        border-radius: 125px;
        background: #FFF3F2;
        width: 100%;
        height: 155px;
        flex-shrink: 0;
        @include flexcenter;
        gap: 30px;
    }

    .content-info {
        display: flex;
        align-items: flex-start;
        gap: 2px;
        width: 40%;

        &.right {
            justify-content: start;
            text-align: start;
        }

        &.left {
            justify-content: end;
            text-align: end;
        }

        span {
            color: #FE1C3B;
            font-size: 15px;
            text-transform: uppercase;
            padding: 0 6px;
        }

        p {
            text-transform: uppercase;
            font-size: 15px;
            margin-bottom: 0;


        }

        h3 {
            font-size: 17px;
            font-weight: 700;
            margin: 0;
        }

        label {
            font-size: 12px;
            opacity: 0.5;
        }
    }

    .target-graph {
        background-color: #fff;
        border-radius: 50%;
        padding: 10px;
        width: 250px;

        .svg-ellipse-chart {
            filter: none;

            .svg-ellipse-chart-background {
                stroke: #FFF3F2;
            }

            .bottom-line {
                dominant-baseline: middle;
            }

            @keyframes circle-chart-fill {
                to {
                    stroke-dasharray: 0 100;
                }
            }

            @keyframes circle-chart-appear {
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
}

[data-bs-theme="dark"] {
    .target-section {
        .polygon {
            background-color: #17181a;
        }

        .content-bg {
            background: #17181a;
        }

        .target-graph {
            background-color: #000000;


            .svg-ellipse-chart {
                .svg-ellipse-chart-background {
                    stroke: #17181a;
                }

                .svg-ellipse-chart-circle {
                    fill: #000;
                }

                .top-line,
                .bottom-line {
                    fill: #fff;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .target-section {

        .target-header {
            flex-direction: column;
            gap: 15px;
            margin: 20px 0 30px 0;

            .target-info {
                width: 100%;

                p {
                    font-size: 13px;
                }

                h4 {
                    font-size: 17px;
                }

                &.left,
                &.right {
                    text-align: center;
                }
            }


        }

        .content-bg {
            border-radius: 25px;
            height: 100%;
            flex-direction: column;
            gap: 30px;
            padding: 10px;
        }

        .arrow-target {
            margin: auto;
        }

        .content-info {
            align-items: center;
            flex-direction: column;
            text-align: center;

            &.right,
            &.left {
                align-items: center;
                flex-direction: column;
                text-align: center;
            }

            h3 {
                text-wrap: nowrap;
                font-size: 17px;
            }

            p {
                font-size: 14px;

                span {
                    font-size: 14px;
                    padding: 4px;
                }
            }
        }

        .target-graph {
            width: 210px;

            .svg-ellipse-chart {
                .top-line {
                    font-size: 2.2px;
                    transform: translateY(-0.3px);
                }

                .center-line {
                    font-size: 2.8px;
                }

                .bottom-line {
                    font-size: 2.2px;
                    transform: translateY(0.3px);
                    dominant-baseline: central;

                    tspan {
                        font-size: 2.2px;
                    }
                }
            }
        }
    }
}