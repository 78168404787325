.invoice-page {
    background-color: #000;
    font-family: $font-primary;
    .invoice-top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        position: relative;

        img {
            width: 100px;
            padding-left: 20px;
        }

        .title {
            color: #000;
            background: $secondary;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 2px;
            text-transform: uppercase;
            width: 100%;
            padding: 2px 20px;
        }

        >span {
            color: #ffffff;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin: auto;
        }

        .qr-code-wrap {
            position: absolute;
            top: 0;
            right: 22px;
            background-color: #FFF;
            padding: 10px;

            svg {
                width: 90px;
                height: 90px;
            }
        }
    }

    .invoice-content {
        margin-top: 15px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.25);

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                align-items: center;
                // border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                padding: 10px 20px;
                position: relative;

                span {
                    width: 50%;
                    color: #ffffff;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }

                label {
                    width: 50%;
                    color: #ffffff;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(90deg, rgba(69, 248, 129, 40%) 0%, $secondary 50%, rgba(69, 248, 129, 40%) 100%);
                    bottom: 0;
                    right: 0;
                }

                &:last-child {
                    border-bottom: none;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

}

.invoice-footer {
    background: #000000;
    width: 500px;
    padding: 20px 20px 20px 20px;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.invoice-parent-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: rgb(19 19 19);
    min-height: 100vh;

    .invoice-page {
        width: 500px;
        border: 1px solid rgba(0, 0, 0, .25);
    }
}

@media screen and (max-width:767px) {
    .invoice-parent-page {

        .invoice-page {
            width: 95%;
        }
    }

    .invoice-footer {
        width: 95%;
    }

}