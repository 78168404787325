body {
	font-family: $font-primary;
	max-width: 480px;
	min-width: auto;
	
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $secondary;
	}
}


.skiptranslate {
	display: none;
}

@media (min-width: 900px) {
    html, body {
        max-width: 495px;
        margin-left: auto;
        margin-right: auto;
    }
}