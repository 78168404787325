.wallet_card {
    padding: 2px;
    @include gradientReverse();
    border-radius: 20px;

    .wallet_inner {
        background-color: $lightGray;
        border-radius: 18px;
        overflow: hidden;
    }

    .wallet_head {
        text-align: center;
        @include gradientDark();
        padding: 14px 10px;
        font-weight: 600;
        font-size: 18px;
        position: relative;
        color: #FFF;
        border-radius: 18px 18px 0 0;

        >a {
            position: absolute;
            right: 18px;
            font-weight: normal;
            font-size: 14px;
        }
    }

    .wallet_body {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            margin: 0;
            color: $primary;
            font-weight: bold;
        }
    }

    .wallet_footer {
        .accordion {
            .accordion-item {
                border-radius: 0 0 20px 20px;
                background-color: $lightGray;

                .accordion-header {
                    @include gradientReverse();
                    padding: 2px;
                    border-radius: 0 0 20px 20px;
                    margin: -3px;

                    .accordion-button {
                        border-radius: 0 0 20px 20px;
                        background-color: $lightGray;
                        min-height: 65px;

                        &:focus {
                            outline: none;
                            box-shadow: none !important;
                            z-index: 0;
                        }
                    }
                }
            }
        }

        .accordion-button:not(.collapsed) {
            color: $black;
            background-color: transparent;
            box-shadow: none;
        }

        .wallet_footer_form {
            padding: 2rem;
        }
    }

}

.button-addmax {
    position: absolute;
    right: 18px;
    top: 33px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $primary;

    span {
        margin-right: 5px;
    }
}
.Resend{
    font-size: 14px;
    span{
        font-size: 14px;
    }
}
@media screen and (max-width: 767px) {
    .Resend{
        font-size: 12px;
        span{
            font-size: 12px;
        }
    }
    .wallet_card {

        .wallet_footer {
                     
            .wallet_footer_form {
                padding: 5px;
            }
        }

    }

}