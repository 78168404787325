.bt-wrap {
    h3 {
        color: rgba(0,0,0,0.6);
        font-size: 1.5rem;
    }
    .form-control {
        min-height: 56px;
    }
}

.bt-user {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    flex-direction: column;
    text-align: center;
    
    .bt-photo {
        width: 70px;
        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }

    h3 {
        color: $primary;
        font-family: $font-secondary;
        text-transform: capitalize;
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0;
    }

    p {
        margin: 0;
        font-size: .9rem;
    }
}