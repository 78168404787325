.network-list {
    .list-item {
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            &.level-2 {
                padding-left: 5%;

                .tree-card-wrap {
                    box-shadow: 0px 1px 4px 2px $secondary inset;

                    .tree-card-right {
                        ul {
                            li {
                                .tree-icon-info {
                                    .amount {
                                        h5 {
                                            color: $secondary !important;
                                        }
                                    }
                                }
                                .level {
                                    span {
                                        background-color: $secondary;
                                        box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
                                        -webkit-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
                                        -moz-box-shadow: -1px -1px 11px 2px rgba(69, 248, 129, 0.51);
                                    }
                                }
                            }
                        }

                    }
                }

                .profile {
                    h5 {
                        color: $secondary !important;
                    }
                }
            }

            &.level-3 {
                padding-left: 10%;

                .tree-card-wrap {
                    box-shadow: 0px 1px 4px 2px #135EE7 inset;

                    .tree-card-right {
                        ul {
                            li {

                                .tree-icon-info {
                                    .amount {
                                        h5 {
                                            color: #135EE7 !important;
                                        }
                                    }
                                }


                                .level {
                                    span {
                                        background-color: #135EE7;
                                        box-shadow: -1px -1px 11px 2px rgba(19, 94, 231, 0.51);
                                        -webkit-box-shadow: -1px -1px 11px 2px rgba(19, 94, 231, 0.51);
                                        -moz-box-shadow: -1px -1px 11px 2px rgba(19, 94, 231, 0.51);
                                        color: #fff;
                                    }
                                }
                            }
                        }

                    }

                    .profile {
                        h5 {
                            color: #135EE7 !important;
                        }
                    }
                }
            }

            .tree-card-wrap {
                border-radius: 10px;
                background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
                box-shadow: 0px 1px 4px 2px #FFBE18 inset;
                display: flex;
                width: 90%;
                margin: auto;
                min-height: 184px;
                padding: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;
                flex-shrink: 0;
                position: relative;
                display: block;


                .tree-card-right {
                    position: absolute;
                    right: 0;
                    height: 100%;
                    position: relative;
                    width: 100%;

                    ul {
                        margin: 0;
                        padding: 10px 0 0 0;
                        list-style-type: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-top: 30px;

                        li {
                            flex: none;

                            &.total_members {
                                .tree-icon-info {
                                    justify-content: flex-start;
                                }
                            }

                            .tree-info {
                                display: flex;
                                gap: 20px;

                                h2 {
                                    color: #FFF;
                                    font-family: $font-secondary;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 27.54px;
                                    letter-spacing: 0.1px;
                                }

                                p {
                                    margin: 0;
                                    font-size: .9rem;
                                }
                            }

                            .tree-icon-info {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 100%;
                                justify-content: space-between;

                                p {
                                    margin: 0;

                                }

                                span {
                                    color: $primary;
                                    margin: 0 10px;
                                }

                                .amount {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    gap: 20px;
                                    margin-top: 10px;
                                    padding-bottom: 10px;
                                    width: 100%;
                                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    h5 {
                                        margin: 0;
                                        font-weight: 500;
                                        font-size: 1.1rem;
                                        white-space: nowrap;
                                        font-size: 20px;
                                        color: $primary;
                                    }

                                    p {
                                        font-size: .9rem;
                                        color: #7c7c7c;
                                    }
                                }
                            }

                            .level {
                                position: absolute;
                                top: -80px;
                                right: 0;

                                p {
                                    margin: 0;
                                }

                                span {
                                    background-color: $primary;
                                    border-radius: 80px;
                                    color: #142129;
                                    position: relative;
                                    padding: 10px 10px;
                                    width: 40px;
                                    height: 40px;
                                    font-size: 16px;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    border: 3px solid rgba(255, 255, 255, 0.5);
                                    box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
                                    -webkit-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
                                    -moz-box-shadow: -1px -1px 11px 2px rgba(255, 190, 24, 0.51);
                                    @include flexcenter;

                                    &::after {
                                        content: "";
                                        width: 100%;
                                        position: absolute;
                                        height: 100%;
                                        bottom: -24px;
                                        left: 0;
                                        background-color: rgba(0, 0, 0, 0.15);

                                    }
                                }

                            }
                        }
                    }

                }

                .profile {
                    display: flex;
                    align-items: center;
                    min-width: 25%;
                    width: 100%;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }


                    .info {
                        margin-left: 10px;

                        h5 {
                            margin: 0;
                            font-weight: 500;
                            font-size: 1.1rem;
                            line-height: 1.25rem;
                            margin-bottom: 2px;
                            text-transform: capitalize;
                            color: $primary;
                        }

                        p {
                            margin: 0;
                            font-weight: 300;
                            font-size: .8rem;
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }
                }
            }

        }
    }
}

.progress-wrap {
    margin-top: -4px;
}