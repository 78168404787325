.statement-table {
    font-family: $font-primary;

    .partner-table {
        padding-top: 0;

        .table-wrap {

            &::after,
            &::before {
                height: 90px;
            }

            .table {
                tr {
                    &:first-child {
                        th {
                            font-weight: 500;
                        }
                    }

                }
            }
        }
    }


    .filter-head {
        p {
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: #FFF;
            color: rgba(0, 0, 0, 0.6);
            margin: 0;
            padding: 4px 12px;
            height: 32px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}

.statement-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin: 20px 0;
    padding: 0;
    gap: 10px;

    li {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        padding: 10px 30px 10px 10px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border-right: none;
        }

        p {
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
        }

        span {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
        }
    }
}

.white-input {
    width: 100%;

    input {
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;
        display: flex;
        width: 100%;
        padding: 12px 16px;
        justify-content: space-between;
        align-items: center;
        height: 48px;
    }

    input[type="date"] {
        padding-right: 16px;
        display: block;
        width: 100%;

    }
}

[data-bs-theme="dark"] {
    .statement-table {

        .top-row {
            background-color: #303030;

            h4 {
                color: #717171;
            }

            .blance {
                color: #717171;
            }
        }

        .filter-head {
            p {
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: #000000;
                color: #ffffff;
            }
        }
    }
}

@media screen and (max-width:767px) {

    .top-row {

        padding: 25px 10;

        h4 {
            font-size: 15px;
            white-space: nowrap;
        }

        .blance {
            font-size: 13px;
            white-space: nowrap;
        }
    }

}