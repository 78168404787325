.referrals_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;


  li {
    width: 170px;
    flex-shrink: 0;
    padding: 16px;
    
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.03);
    margin: 14px;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    &:hover {
      border-color: $cyan;
    }

    .ref_photo {
      position: relative;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
        margin: auto;
        border: 3px solid $primary;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          border-color: $cyan;
        }
      }

      .verified {
        display: none;
      }

      &.Investor {
        .verified {
          display: block;
          position: absolute;
          right: 20px;
          bottom: 10px;
        }
      }
    }

    h4 {
      text-align: center;
      color: $primary;
      margin: 10px;
      font-size: 1.3rem;
    }

    .count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 4px;
      line-height: 28px;
      margin: 15px 0;
      span {
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
      }
      p {
        font-weight: 700;
        font-size: 30px;
        color: $primary;
        margin: 0;
       
      }
    }

    .button {
      width: 100%;
      text-transform: uppercase;
      font-size: .9rem;
    }
  }
  
   
}



.ref_tree_income {
  padding: 20px;
  border-radius: 10px;
  h3 {
    color: $cyan;
    font-size: 2rem;
  }
}

.circle_list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin-top: .8rem;

    &:nth-child(odd) {
      span {
        background-color: #DBC27D;
      }
      p {
        color: #DBC27D;
      }
    }
    &:nth-child(even) {
      span {
        background-color: #cccccc;
      }
      p {
        color: #cccccc;
      }
    }

    span {
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50%;
      
      margin-right: 10px;
    }
    p {
      margin: 0;
      font-weight: 500;
    }
  }
}

.left_members {
  padding: 20px; 
}

.right_members {
  padding: 20px;
  
}

.directs_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  &.left {
    justify-content: flex-end;
  }

  li {
    
    flex-shrink: 0;
    padding: 6px;
    background-color: #FFF;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.03);
    margin: 5px;
    flex-basis: 18%;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    .ref_photo {
      position: relative;
      text-align: center;
      img {
        width: 50px;
        height: 50px;
        margin: auto;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          border-color: $cyan;
        }
      }

      .verified {
        display: none;
      }

      &.Investor {
        .verified {
          display: block;
          position: absolute;
          right: 15px;
          bottom: 0;
        }
      }
    }

    h4 {
      text-align: center;
      color: $black;
      margin: 6px 0;
      font-size: 10px;
      font-weight: normal;
      
    }

    .count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 4px;
      line-height: 28px;
      margin: 15px 0;
      span {
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
      }
      p {
        font-weight: 700;
        font-size: 30px;
        color: $primary;
        margin: 0;
       
      }
    }

    .button {
      width: 100%;
      text-transform: uppercase;
      font-size: .9rem;
    }
  }
  
   
}
@media screen and (max-width: 767px) {
  
.directs_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  &.left {
    justify-content: center;
  }

  li {
    
    flex-shrink: 0;
    padding: 6px;
    background-color: #FFF;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.03);
    margin: 5px;
    flex-basis: 18%;
    border-radius: 8px;
    min-width: 124px;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    .ref_photo {
      position: relative;
      text-align: center;
      img {
        width: 50px;
        height: 50px;
        margin: auto;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          border-color: $cyan;
        }
      }

      .verified {
        display: none;
      }

      &.Investor {
        .verified {
          display: block;
          position: absolute;
          right: 22px;
          top: 0;
        }
      }
    }

    h4 {
      text-align: center;
      color: $black;
      margin: 10px;
      font-size: 13px;
      font-weight: normal;
      
    }

    .count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 4px;
      line-height: 28px;
      margin: 15px 0;
      span {
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
      }
      p {
        font-weight: 700;
        font-size: 30px;
        color: $primary;
        margin: 0;
       
      }
    }

    .button {
      width: 100%;
      text-transform: uppercase;
      font-size: .9rem;
    }
  }
  
   
}
}