.header-dashboard {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  position: fixed;
  background: black;
  top: 0;
  max-width: 480px;
  width: 100%;
  z-index: 10;

  .header-left {
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      width: 45px;
    }

    .user-logged-in {
      margin-left: 10px;
      display: flex;
      align-items: center;

      .info {
        h4 {
          color: #FFF;
          font-size: 17px;
          margin: 0;
          text-transform: capitalize;
        }

        p {
          font-size: 13px;
          margin: 0;
        }
      }
    }
  }

  .header-right {
    display: flex;
    gap: 10px;
    align-items: center;

    .menu-toggle {
      border: none;
      background-color: transparent;

      svg {
        width: 40px;
        color: $secondary;
      }
    }
  }
}

.bs-notifications {
  font-family: $font-primary;

  .dropdown-toggle {
    border: transparent;
    @include flexcenter;
    background-color: transparent !important;
    position: relative;

    svg {
      stroke: $secondary;
      width: 25px;
    }

    .notifications-icon {
      &.active {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $secondary;
        @include flexcenter;
        flex-shrink: 0;
        position: absolute;
        top: 0%;
        right: 15%;
        transform: translate(0, 0);
      }
    }


    &::after {
      display: none;
    }
  }

  svg {
    width: 20px;
  }

  .notifications-wrap {
    border-radius: 16px;
    background: #000000;
    box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);
    width: 360px;
    min-width: 260px;
    padding: 0 0 5px 0 !important;
    overflow: hidden;
  }

  .noifications-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    .notifications_head {
      background: $secondary;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 15px 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 16px;
      margin-bottom: 5px;

      >label {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }

      >ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style-type: none;
        align-items: center;
        gap: 20px;

        li {
          >div {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
          }

          span {
            color: #000;
            font-family: $font-primary;
            font-size: 12px;
            font-weight: 700;
          }

          svg {
            color: #000;
          }

          &:hover,
          &:active {
            span {
              color: #fff;
            }

            svg {
              stroke: #fff;
            }
          }
        }
      }
    }

    .notification-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 5px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      height: 75vh;
      overflow-y: scroll;


      li {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 10px 0px 30px;
        position: relative;

        .divider {
          margin-top: 10px;
          width: 90%;
          height: 1px;
          background: linear-gradient(90deg, rgba(255, 190, 24, 0.00) 0%, #FFBE18 50%, rgba(255, 190, 24, 0.00) 100%);
        }

        .massage-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 5px;

          h5 {
            color: $secondary;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            margin: 0;
          }

          p {
            margin: 0;
            font-size: 13px;
            line-height: 16px;
            color: #ffffff;
          }

          span {
            color: #45f88299;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
        }


        .indicator {
          &.active {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $secondary;
            @include flexcenter;
            flex-shrink: 0;
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translate(50%, 0);
          }

        }

        >svg {
          cursor: pointer;
          flex-shrink: 0;
          width: 17px;
          z-index: 5;
          color: #ffffff;
        }

        &:hover {
         
          border-radius: 15px;
        }

      }
    }

  }

  .dropdown-menu {
    padding: 10px;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      a {
        color: #525866;
        transition: 0.3s all;

        svg {
          width: 20px;
          margin-right: 10px;
        }

        &:hover {
          color: $secondary;
          transition: width .3;
        }
      }

    }

  }
}

.canvas-menu {
  background-color: rgba(0, 0, 0, 0.44);
  backdrop-filter: blur(5px);
  color: rgba(255, 255, 255, .6);



  .btn-close {
    display: none;
  }


  .btn-close-menu {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      width: 30px;
      color: $secondary;
    }
  }

  .offcanvas-title {}

  .canvas-menu-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    border: 1px solid $secondary;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    gap: 15px;

    li {

      .divider {
        margin-top: 10px;
        width: 90%;
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 190, 24, 0) 0%, #FFBE18 50%, rgba(255, 190, 24, 0) 100%);
      }

      a {
        color: rgba(255, 255, 255, .8);
        display: flex;
        align-items: center;
        width: 300px;

        svg {
          width: 24px;
          color: $secondary;
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }
}