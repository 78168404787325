.tabs-theme {
    margin: -2px;
    border: none;
    border-bottom: 2px solid $primary;

    &.theme-style-1 {
        border-bottom: none;

        .nav-item {
            min-width: 100%;

            .nav-link {
                color: #000;
                width: 100%;
            }
        }
    }



    .nav-item {
        .nav-link {
            color: $primary;
            border: none !important;
            padding: 14px 20px;
            text-align: center;
            border-radius: 18px 18px 0 0;

            &.active {
                @include gradientDark();
                color: #FFF !important;
            }
        }
    }
}

.tab-content-p {
    .tab-content {
        padding: 30px;
    }

}

.wizard-tabs {
    margin: 0;
    padding: 32px 0;
    list-style: none;

    &.tabs-deposit {

       
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

    }

    li {
        position: relative;
        text-align: center;

        &.active {
            .wizard-number-wrap {
                border: 1px solid #000;

                span {
                    background-color: #000;
                    color: $primary;

                    svg {
                        stroke: $white;
                    }
                }
            }

            p {
                color: #FFF;
            }
        }

        .wizard-number-wrap {
            
            margin: auto;
            border-radius: 50%;
            padding: 2px;
            border: 1px solid #00000026;
            width: 38px;
            height: 38px;

          
            span {
                @include flexcenter;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.5);
                font-size: 20px;
                font-size: 14px;

                svg {
                    stroke: $primary;
                    width: 20px;
                }
            }
        }

        p {
            margin-top: 6px;
            font-size: 12px;
            margin-bottom: 0;
        }

        .line {
            display: none;
            width: 1px;
            height: 49px;
            background: #7B8599;
            margin: 10px auto;
        }
    }
}


@media screen and (max-width: 767px) {
    // .tabs-theme {
    //     margin: -2px;
    //     border: none;
    //     border-bottom: 2px solid $primary;

    //     &.theme-style-1 {
    //         .nav-item {
    //             min-width: 100%;

    //             .nav-link {
    //                 color: #000;
    //                 width: 100%;
    //             }
    //         }
    //     }
    // }

    //.wizard-tabs {
        // &.tabs-deposit {
        //     width: 100%;
        //     display: flex;
        //     justify-content: space-between;
        //     padding: 10px;
        // }

    //     li {
    //         .wizard-number-wrap {
    //             width: 38px;
    //             height: 38px;

    //             span {
    //                 font-size: 14px;
    //             }
    //         }

    //         p {
    //             font-size: 9px;
    //             max-width: 70px;
    //         }

    //         .line {
    //             display: none;
    //         }
    //     }
    // }



}