#main {
    max-width: 100%;
    margin: auto;
    background-image: url("../images/dashboard/login-bg.png");
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    color: rgba(255, 255, 255, .6);
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 100px;
    position: relative;
}

.business-content {
    padding-top: 80px;
}

.login_box {
    padding: 20px;
    background-color: #000;

    .login_box_head {
        text-align: center;
        margin-top: 30px;

        h4 {
            font-size: 14px;
            font-weight: normal;
        }

        img {
            width: 207px;
            margin-bottom: 20px;
        }
    }

    .login_box_body {
        background-color: #000;
        padding: 20px 20px;
        border-radius: 6px;
        margin-top: 50px;

        .verify {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .Character {
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                background-color: $secondary;
                width: 40%;
                height: 60px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                text-shadow: 1px solid black;
                letter-spacing: 0.4rem;
                font-style: italic;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .icon-input {
                width: 60%;

                .form-control {
                    padding: 17px 12px !important;
                }
            }
        }
    }
}