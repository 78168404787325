.uc_wrap {
    background-color: #1b1b1a;
	color: #FFF;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
        
        margin-bottom: 2rem;
        img {
            width: 120px;
        }
    }

    h2 {
        color: $primary;
        font-family: 'Bebas';
        font-size: 4rem;
    }
}