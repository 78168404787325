.treeview {
  padding-bottom: 80px;

  .top_member {
    max-width: 680px;
    margin: auto;

    .tp_top_options {
      margin: 0 0 10px 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: space-between;

      li {
        font-size: 13px;

        span {
          font-weight: 300;
        }

        strong {
          color: $primary;
        }
      }
    }

    .tm_center {
      text-align: center;

      .img-wrap {
        width: 60px;
        height: 60px;
        margin: 0 auto 3px auto;

        >img {
          width: 100%;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }



      h5 {
        font-size: 13px;
        margin: 0 0 5px 0;
        font-weight: 600;
        color: #000;
      }

      h3 {
        font-size: 22px;
        margin: 0;
        font-weight: bold;
      }

      p {
        font-size: 13px;
        margin: 12px 0 0 0;
      }
    }

    .tm_side_box {
      .tm_side_box_option {
        padding: 14px 0;
        text-align: center;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          @include gradientReverse();
          width: 100%;
          height: 2px;
          bottom: 0;
        }

        &.b-0 {
          border-bottom: none;
          padding-bottom: 0;

          &::after {
            display: none;
          }
        }

        p {
          font-size: 12px;
          font-weight: 300;
          margin: 0;
        }

        h4 {
          font-size: 20px;
          margin: 0;
          font-weight: bold;
        }
      }
    }
  }
}

.tree_border {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.three_members {
    .bar {
      width: 67%;

      .caret_left {
        left: -7px;
      }

      .caret_right {
        right: -6.5px;
      }
    }
  }

  >span {
    background: $primary;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    // margin-left: -1px;
  }

  .v_line {
    border-left: 1px dashed rgb(31, 31, 31);
    height: 18px;
  }

  .bar {
    border-top: 1px dashed rgb(31, 31, 31);
    border-left: 1px dashed rgb(31, 31, 31);
    border-right: 1px dashed rgb(31, 31, 31);
    border-radius: 5px 5px 0px 0px;
    width: 50%;
    height: 18px;
    position: relative;

    .caret_center {
      position: absolute;
      left: 0;
      bottom: -14px;
      right: 0;
      margin: auto;
      text-align: center;
    }


    .caret_left {
      position: absolute;
      left: -7px;
      bottom: -14px;
    }

    .caret_right {
      position: absolute;
      right: -7px;
      bottom: -14px;
    }
  }
}

.signup_link {
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  margin-top: 20px;

}

.node {

  margin: 10px auto 0 auto;
  text-align: center;
  height: 130px;

  &.disabled {
    .avatar {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    margin: auto;
    // border-radius: 50%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background-color: $primary;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .lr_members {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    .left {
      margin-right: 10px;
    }

    .right {
      margin-left: 10px;
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      line-height: 18px;

    }

    span {
      font-size: 11px;
    }
  }
}

.tree_search_wrap {
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  z-index: 1;
  background-color: #f7f9fc;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    align-items: center;

    .photo {
      width: 40px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .info {
      padding-left: 10px;

      h4 {
        margin: 0;
        font-size: 1rem;
      }

      p {
        margin: 0;
        font-size: .8rem;
      }
    }

    &:hover {
      background-color: $primary;
    }
  }
}

.tree_top_filters {
  margin: -4px;
  padding: 0;
  display: flex;
  list-style-type: none;

  li {
    margin-right: 1rem;

    a,
    button {
      color: rgba(0, 0, 0, 1);
      font-size: 15px;
      padding: 18px 16px;
      border-radius: 12px 12px;
      border: none;
      font-weight: 500;
      display: block;

      &.active {
        @include gradientDark();
        color: #FFF;

      }
    }
  }
}

.tree_bottom_filters {

  //box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.10);



  position: fixed;
  bottom: 0px;
  left: 31px;
  right: 0px;
  width: 96%;

  z-index: 2;



  .filters_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fb_buttons {
      display: flex;
      position: relative;

      .button {
        margin: 0 10px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        span {
          padding: 9px 3.3rem;
          font-size: 14px;
        }

        &.button-placement {
          span {
            padding: 8px 1.3rem;
            line-height: 1.3;
          }
        }
      }

      .default_placement_box {
        position: absolute;
        right: 0;
        top: -205px;
        width: 250px;
        padding: 2px;
        border-radius: 12px;
        @include gradientReverse();

        .card {
          border-radius: 10px;
          background-color: $lightGray;
          padding: 17px;
        }
      }

    }



    .tree_arrows {
      .button {
        padding: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;


        span {
          font-size: 1.5rem;
        }


      }
    }
  }

}

.rightborder {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    background: rgb(180, 164, 88);
    background: linear-gradient(0deg, #FAFAFA 0%, rgb(180, 164, 88) 100%) !important;
    width: 2px;
    height: 100%;
    bottom: 0px;
  }
}

.tree_volume_wrap {
  max-width: 680px;
  margin: auto;

  .tree_volume_item {
    @include gradientReverse();
    border-radius: 12px;
    padding: 2px;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
    margin-bottom: 1rem;

    >div {
      background-color: $lightGray;
      border-radius: 8px;
    }

    &.generation {

      &.active {
        .date_box {
          background-color: #e9e9e9;
          box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;

          h3 {
            color: $primary;
          }

          p {
            color: $black;
          }

        }
      }

      .date_box {
        background-color: #e9e9e9;
        box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
        color: black;
        justify-content: space-around;

        h3 {
          font-weight: 500;
          font-size: 20px;
          margin: 0;
        }

        p {
          margin: 0;
          color: black;
        }
      }
    }

    .date_box {
      background-color: #e9e9e9;
      box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
      color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;

      border-radius: 8px;
    }

    h4 {
      text-align: center;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 8px;
      margin-top: 10px;
    }

    .balance_wrap {
      display: flex;
      justify-content: space-around;

      .b_option {
        text-align: center;

        span {
          font-weight: normal;
          font-size: 12px;
          color: rgb(142, 142, 142);
          margin-bottom: 4px;
          opacity: 0.7;
        }

        p {
          font-size: 15px;
          margin: 0;
          font-weight: 600;
        }
      }
    }
  }
}

.uni_level_item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
    align-items: center;

    .icon {
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .info {
      padding-left: 1.2rem;

      h4 {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
      }

      p {
        margin: 6px 0 0 0;
        color: rgb(90, 90, 90);
        font-size: 12px;
      }
    }
  }

  .left {}

  .center_border {
    width: 60%;
    height: 1px;
    border-bottom: 1px dashed;
  }

  .right {}
}


.modal-tree-detail {

  .modal-content {
    border-radius: 20px;
  }

  .modal-dialog {
    max-width: 359px;
  }

  .modal-info-wrap {
    position: relative;

    .modal-profile-head {
      min-height: 120px;
      border-radius: 20px;
      position: relative;

      >.photo {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -60px;
        border: 3px solid #E0D6C7;
        overflow: hidden;

        img {
          width: 100%;
        }

        .name {
          background-color: rgba(255, 255, 255, 0.8);
          color: #6C5D56;
          text-align: center;
          position: absolute;
          bottom: -22px;
          width: 100%;
          padding-top: 6px;
          height: 70px;

          span {
            max-width: 70px;
            display: block;
            margin: auto;
            line-height: 18px;
            text-align: center;
          }
        }
      }
    }

    .modal-info-body {
      border-radius: 20px;
      padding: 4.4rem 2rem 2rem 2rem;

      h3 {
        text-align: center;
        margin-top: 1.2rem;
        color: $cyan;
        margin-bottom: 0;
      }

      .inv_balance {
        text-align: center;
        margin-top: 2rem;

        p {
          margin: 0;
        }

        h4 {
          margin: 0;
          font-weight: bold;
          color: #FFF;
          font-size: 4rem;
          text-shadow: 2px 2px rgba(0, 0, 0, 0.05);
        }
      }

      .country {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: $cyan;
          font-weight: bold;
          font-size: 1.2rem;
        }

        img {
          width: 20px;
          margin-right: 10px;
        }
      }




    }
  }
}

.p-packages {


  padding: 1rem 4rem;
  border-radius: $radius;

  .p-package-item {
    display: flex;
    align-items: center;

    img {
      width: 50px;
    }

    h4 {
      margin: 0 0 0 .6rem;
      font-size: 1.3rem;
    }
  }
}

.tree-indicators {
  margin: 30px 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;

  li {
    display: flex;
    align-items: center;
    margin: 10px 20px;

    .indicator {
      width: 18px;
      height: 18px;
      border-radius: 50%;

      display: block;
      margin-right: 8px;

      &.active {
        background-color: $primary;
      }

      &.paid {
        background-color: #212121;
      }

      &.inactive {
        background-color: #838383;
      }

      &.block {
        background-color: #C70F01;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .rightborder {
    &::after {
     display: none;
    }
  }
  .tree_volume_wrap {
    .tree_volume_item {
      box-shadow: none;

      &.generation {
        &.active {
          .date_box {
            box-shadow: none;
          }
        }

        .date_box {
          box-shadow: none;
        }
      }

      .date_box {
        box-shadow: none;
      }
    }
  }


  .tree-mobile-wrap {
    overflow-x: scroll;

    .tree-mobile-container {
      width: 800px;
    }
  }

  .node {
    .lr_members {
      margin-top: 0;
    }
  }

  .node .lr_members span {
    font-size: 7px;
    line-height: 13px;
  }

  .node .lr_members {
    strong {
      font-size: 12px;
    }
  }

  span.name {
    font-size: 10px;
  }

  .treeview {
    .top_member {
      .tm_center {
        .img-wrap {
          width: 60px;
          height: 60px;
        }

        h3 {
          font-size: 12px;
        }

        h5 {
          font-size: 11px;
          font-weight: normal;
        }
      }

      .tm_side_box {
        .tm_side_box_option {
          h4 {
            font-size: 13px;
          }
        }
      }

      .tp_top_options {
        display: block;

        li {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }


  .tree_volume_wrap {
    .tree_volume_item {
      &.generation {
        .balance_wrap {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: 12px;

          .b_option {
            text-align: center;
            margin: 5px 10px;
          }
        }

      }

      .balance_wrap {
        display: flex;
        justify-content: space-around;

        .b_option {
          margin-bottom: 13px;

          span {
            font-weight: normal;
            font-size: 12px;
            color: rgb(142, 142, 142);
            margin-bottom: 4px;
            opacity: 0.7;
          }

          p {
            font-weight: 500;
          }
        }
      }
    }
  }


  .tree_top_filters {
    display: block;
    margin-bottom: 15px;

    li {
      margin-right: .2rem;
      display: inline-block;

      a,
      button {

        font-size: 13px;
        padding: 6px 10px;
      }
    }
  }

  .tree-indicators {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;

    li {
      margin: 8px;
      font-size: 12px;

      .indicator {
        width: 12px;
        height: 12px;
      }
    }
  }


  .tree_bottom_filters {
    position: relative;
    left: unset;
    width: 100%;
    z-index: 0;

    .filters_wrap {
      display: block;

      .tree_arrows {
        text-align: center;
      }

      .fb_buttons {
        flex-direction: column;

        .button {
          margin: 10px 0;
        }

        .default_placement_box {
          top: 54px;
          left: 2%;
        }
      }
    }
  }
}