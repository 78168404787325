/** Switch

 -------------------------------------*/

 // Colors
 $switch-default-color: #000 !default;
 $switch-primary-color: $primary !default;
 $switch-info-color: #3498db !default;
 $switch-success-color: #2ecc71 !default;
 $switch-danger-color: #e74c3c !default;
 $switch-warning-color: #e67e22 !default;
 $switch-disabled-color: #d5d5d5 !default;
 $slider-default-color: darken($switch-default-color, 10%) !default;
 $slider-primary-color: lighten($switch-primary-color, 10%) !default;
 $slider-info-color: lighten($switch-info-color, 10%) !default;
 $slider-success-color: lighten($switch-success-color, 10%) !default;
 $slider-danger-color: lighten($switch-danger-color, 10%) !default;
 $slider-warning-color: lighten($switch-warning-color, 10%) !default;
 $slider-disabled-color: lighten($switch-disabled-color, 5%) !default;
 
 // Sizes
 $slider-height: 16px !default;
 $slider-width: $slider-height * 4 !default; // 32px
 $switch-height: $slider-height * 3 !default; // 24px
 $switch-width: $switch-height !default; // 24px
 $switch-shift: $slider-height !default; // 8px
 
 // Transitions
 $transition-duration: .2s !default;
 $transition-function: ease !default;
 $transition: all $transition-duration $transition-function !default;
 
 .switch-box {
   display: flex;
   align-items: center;
 
   span {
     margin-left: 12px;
     margin-top: -5px;
   }

  //  &.switch-small {
  //   .switch-box-slider {
  //     height: 18px;
  //     width: 44px;
  //     &:after {
  //       width: 16px;
  //       height: 16px;
  //     }
  //   }
  //  }
 
   .switch-box-slider {
     position: relative;
     display: inline-block;
     height: 23px;
     width: 50px;
     background: #999;
     border-radius: 100px;
     cursor: pointer;
     transition: $transition;
     
     &:after {
       position: absolute;
       left: 1px;
       top: 1px;
       display: block;
       width: 21px;
       height: 21px;
       border-radius: 50%;
       background: $switch-default-color;
       box-shadow: 0px 2px 2px rgba(#000, .2);
       content: '';
       transition: $transition;
     }
   }
   
   .switch-box-input {
     display: none;
     
     ~ .switch-box-label {
       margin-left: $slider-height;
     }
        
     &:checked ~ .switch-box-slider {
       
       &:after {
         left: 28px;
       }
     }
     
    //  &:disabled ~ .switch-box-slider {
    //    background: $slider-disabled-color;
    //    cursor: default;
       
    //    &:after {
    //      background: $switch-disabled-color;
    //    }
    //  }
   }
   
   &.is-primary {
    //.switch-box-input:checked:not(:disabled) ~ .switch-box-slider {
     .switch-box-input:checked ~ .switch-box-slider {
       background: #000;
       
       &:after {
         background: $primary;
       }
     }
   }  
 
 }

 @media screen and (max-width: 767px) {
    .switch-box {
      .switch-box-slider {
        width: 40px;
        height: 18px;
        &::after {
          width: 16px;
          height: 16px;
        }

      

      }

      .switch-box-input {
       
        
     
           
        &:checked ~ .switch-box-slider {
          
          &:after {
            left: 23px;
          }
        }
        
      
      }

    }
 }