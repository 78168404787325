.dropdown-gradient {

    border-radius: 12px;
    position: relative;

    &.active {
        button {
            span {
                @include gradientDark();
                color: #FFF;
            }
            i {
                color: #FFF;
            }
        }
    }

    >button {
        width: 100%;
        border: none;
        text-align: left;
        @include gradientReverse();
        display: block;
        padding: 1px;
        border-radius: 12px;

        span {
            display: flex;
            align-items: center;
            width: 100%;
            background-color: $lightGray;
            padding: 14px 20px;
            border-radius: 10px;
            position: relative;
            padding-left: 46px;

            i {
                line-height: unset;
                margin-right: 10px;
                position: absolute;
                left: 17px;
            }
        }

        i {
            line-height: unset;
            margin-right: 10px;
            position: absolute;
            right: 12px;
            top: 8px;
        }
    }

    .dropdown-menu {
        width: 100%;
        @include gradientReverse();
        border-radius: 12px;
        overflow: hidden;
        padding: 1px;

        li {
            background-color: $lightGray;

            .dropdown-item {
                padding-left: 54px;
                background-color: $lightGray;

                &:focus,
                &:hover {
                    background-color: $primary;
                    color: #FFF;
                }
            }
        }
    }
}


.dropdown-gradient2 {
    border-radius: 12px;
    position: relative;

    &:not(.default-light) {
        button {
            &[aria-expanded="false"] {
                span {
                    @include gradientDark();
                    color: #FFF;
                }
            }
    
            &[aria-expanded="true"] {
                span {
                    background-color: $lightGray;
                    color: #000;
                }
            }
        }
    }

    &.default-light {
        button {
            i {
                color: #000;
            }
        }
    }

    >button {
        width: 100%;
        border: none;
        text-align: left;
        @include gradientReverse();
        display: block;
        padding: 1px;
        border-radius: 12px;
        //z-index: 2;
        position: relative;

       
        &[aria-expanded="true"] {
            i {
                transform: rotateX(180deg);
                color: #000;
            }
        }


        span {
            display: flex;
            align-items: center;
            width: 100%;
            min-width: 210px;
            padding: 12px 20px;
            border-radius: 10px;
            position: relative;
            background-color: $lightGray;
            color: #000;
            i {
                line-height: unset;
                margin-right: 10px;
                position: absolute;
                left: 17px;
            }
        }

        i {
            line-height: unset;
            margin-right: 10px;
            position: absolute;
            right: 12px;
            top: 8px;
        }
    }

    .dropdown-menu {
        width: 100%;
        @include gradientReverse();
        border-radius: 12px;
        overflow: hidden;
        padding: 1px;
       // top: -26px !important;
       

        li {
            background-color: $lightGray;

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }

            .dropdown-item {

                background-color: $lightGray;

                &:focus,
                &:hover {
                    background-color: $primary;
                    color: #FFF;
                }
            }
        }
    }
}