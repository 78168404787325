.active-link-menu {
    width: 100%;
    text-wrap: nowrap;
    display: flex;

    >.nav {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        background: #000;
        border-radius: 0;
        column-gap: 14px;
        width: 100%;
        overflow-x: scroll;

        .nav-item {
            order: 1;
            position: relative;

            &:last-child {
                .nav-link {
                    &::after {
                        left: -25px;
                    }
                }
            }

            .nav-link {
                cursor: pointer;
                text-wrap: nowrap;
                span {
                    color: #fff;
                    font-family: $font-secondary;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 27.54px;
                    letter-spacing: 0.1px;
                    text-wrap: nowrap;
                }



                &.active {

                    position: relative;

                    &::after {
                        content: "";
                        width: 39px;
                        height: 1px;
                        transform: rotate(-90deg) translate(0, 50%);
                        background: linear-gradient(90deg, rgba(255, 190, 24, 0.00) 0%, #FFBE18 50%, rgba(255, 190, 24, 0.00) 100%);
                        position: absolute;
                        top: 50%;
                        right: -25px;
                    }

                    span {
                        color: $primary;
                        text-wrap: nowrap;
                    }

                    svg {
                        stroke: #000;
                    }
                }
            }

            &.active {
                order: 0;
            }
        }
    }
}

.menu-option {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 24px;
    border: 1px solid $primary;
    list-style: none;
    gap: 5px;
    margin: 23px;
    margin-top: 0;
    padding: 0;

    li {
        .nav-link {
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            padding: 4px 16px;
            border: transparent;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            text-wrap: nowrap;
            cursor: pointer;

            label {
                cursor: pointer;
            }

            svg {
                width: 20px;
            }

            &.active {
                display: flex;
                align-items: center;
                gap: 8px;
                border-radius: 24px;
                color: #000 !important;
                background: $primary;

                svg {
                    color: #000 !important;
                }
            }
        }
    }
}

.verification-wrap {
    padding: 20px;
    width: 100%;
    font-family: $font-primary;

    .tab-content {
        background: #f8faff;
        padding: 50px 0;
    }

    .verification-info {
        width: 50%;
        margin-left: 25%;

        >button {
            margin-top: 10px;
        }
    }

    .verification {
        display: flex;
        align-items: center;
        gap: 16px;

        .icon-input {
            margin-bottom: 0;
            width: 80%;
        }




    }

    .alert-content {
        padding-top: 10px;
        text-align: start;

        p {
            margin: 0;
            padding: 0;
        }
    }

    .transfer-header {
        background: #F8FAFF;
        width: 100%;
        height: 60px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 16px;

        svg {
            width: 20px;
        }

        span {
            color: #000000;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.5px;
        }
    }

    .transfer-body {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 20px 0 10px 0;
        margin-bottom: 10px;

        .add-condition {
            margin: 0;
            color: #7B8599;
        }

        .available-amout {
            color: #7B8599;
            font-size: 16px;
            line-height: 20px;
            margin: 0;

            span {
                font-weight: 700;
            }
        }

        .code {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            border-top: 1px solid rgba(0, 0, 0, 0.15);

            .Resend {
                p {
                    color: #7B8599;
                    font-size: 14px;
                    line-height: 16px;

                    span {
                        color: #7B8599;
                    }

                    b {
                        color: #000;
                    }
                }
            }
        }
    }

    .transfer-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-top: 10px;

        button {
            width: 480%;
        }
    }

}

.fake-input {
    width: 100%;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(0, 0, 0, 0.05);
    height: 56px;
    font-family: $font-primary;

    label {
        font-size: 16px;
        margin: 0;
    }
    

    span {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
    }
}

ul.add-condition {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
}

.verification-section {

    >div {
        text-align: center;
    }

}

[data-bs-theme="dark"] {
    .active-link-menu {
        >.nav {
            background: #000000;

            .nav-item {
                .nav-link {

                    svg {
                        stroke: $white;
                    }

                    span {
                        color: $white;
                    }

                    &.active {
                        span {
                            color: $primary;
                        }

                        svg {
                            stroke: $primary;
                        }
                    }
                }
            }
        }
    }

    .menu-option {
        border: 1px solid rgba(255, 255, 255, 0.1);

        li {
            .nav-link {
                color: $white;

                &.active {
                    color: $primary;
                    background: rgba(255, 26, 64, 0.23);
                }
            }
        }
    }
}

//written by iqra

.print-footer {
    >div {
        display: flex;
        align-items: center;
        margin-top: 18px;

        svg {
            width: 20px;
        }

        img {
            width: 20px;
        }

        p {
            margin: 0;
            font-size: 12px;
            padding-left: 18px;
        }
    }
}

.inv-bordered-items {
    li {
        >div {
            text-transform: uppercase;
            border: 1px solid green;
            border-radius: 33px;
            font-size: 16px;
            font-weight: 800;
            padding: 8px 14px;
            align-items: center;
            display: flex;
            justify-content: space-between;

            span {
                color: green;
            }

            svg {
                width: 20px;

                path {
                    color: #334e0e;
                }
            }
        }
    }
}

.download {
    width: 20px;
    cursor: pointer;

    &:hover {

        color: $primary;
    }
}

.dashboard_wallets {
    .button {
        padding: 0.6rem 1rem;
    }
}

.withdraw-first-step {
    @include flexcenter;
    flex-direction: column;
    gap: 10px;

    svg {
        width: 40px;
        stroke: #c5c5c5;
    }

    p {
        margin: 0;
    }
}

.wallet-head {
    .menu-option {
        display: flex;
        margin: 0;
        margin-bottom: 10px;
        border: none;
        justify-content: center;

        li {

            .nav-link {
                border-radius: 10px;
                background: linear-gradient(180deg, rgba(3, 4, 21, 0.01) 0%, rgba(2, 2, 12, 0.01) 100%);
                box-shadow: 0px 1px 4px 2px #FFBE18 inset;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &.active {
                    background: #FFBE18;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {


    .verification-wrap {
        .verification-info {
            width: 100%;
            margin-left: 0;

            .verification {
                flex-wrap: wrap;

                .icon-input {
                    width: 100%;
                }

                >button {
                    width: 100%;
                }
            }
        }

        .tab-content {
            padding: 25px 12px;
        }
    }


    .menu-option {
        li {
            .nav-link {
                text-wrap: nowrap;
            }
        }
    }

}