.team-section {
    
	min-height: 700px;
	background-size: cover;
	padding: 0;
    .team-item {
        margin-bottom: 2rem;
        img {
            width: 100%;
            border-radius: 8px  8px 0 0;
        }
    
        .name {
            background-color: #000;
            text-align: center;
            padding: 1rem;
            font-size: 1.2rem;
            text-transform: uppercase;
        }
    }
    
   
}