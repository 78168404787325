body {
	&.theme-light {
		.matrix_box {
            .matrix_content { 
                background-color: #F5F5F5;
                .matrix_progress {
                    background-color: #FFF;
                }
                ul {
                    li {
                        > p {
                            color: rgba(0,0,0,.5);
                        }
                    }
                }
            }
        }
	}

	&.theme-dark {
		.matrix_box {
            .matrix_content { 
                background-color: #253250;
            }
        }
	}
}

.matrix_box {
    
    border-radius: 12px;

    &.golden {
        background: rgb(195,183,135);
        background: linear-gradient(90deg, rgba(195,183,135,1) 0%, rgba(230,217,161,1) 100%);
        .matrix_content {
            .matrix_status {
                .matrix_status_box {
                    background: rgb(195,183,135);
                    background: linear-gradient(90deg, rgba(195,183,135,1) 0%, rgba(230,217,161,1) 100%);
                    span {
                        color: $primary;
                        border-color: $primary;
                    }
                }
            }
        }
    }

    &.cyan {
        background: rgb(125,191,191);
        background: linear-gradient(90deg, rgba(125,191,191,1) 0%, rgba(159,230,230,1) 100%);
        .matrix_content {
            .matrix_status {
                .matrix_status_box {
                    background: rgb(125,191,191);
                    background: linear-gradient(90deg, rgba(125,191,191,1) 0%, rgba(159,230,230,1) 100%);

                    span {
                        color: $cyan;
                        border-color: $cyan;
                    }
                }
            }
        }
    }

    .matrix_title {
        text-align: center;
        color: #FFF;
        font-weight: bold;
        padding: 1.5rem;
        font-size: 1.4rem;
    }

    .matrix_content {
        
        border-radius: 12px;
        padding: 2rem 2rem 0 2rem;

        .matrix_status {
            text-align: center;
            h5 {
                color: #95A4A4;
                font-size: 1rem;
            }

            .matrix_status_box {
               
                padding: 0 .3rem;
                border-radius: 12px;
                span {
                    display: block;
                    background-color: #FFF0F5;
                    font-weight: bold;
                    font-size: 2rem;
                    
                    border-radius: 12px;
                    padding: .7rem;
                    border: 2px dashed transparent;
                }
            }
        }

        .matrix_progress {
            
            border-radius: 12px;
            padding: 1rem;
            margin: 1rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
                margin: 0;
                font-size: 1.2rem;
            }
            p {
                margin: 0;
                color: #95A4A4;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                border-bottom: 1px solid rgba(0,0,0,0.2);
                padding: .7rem 0;
                &:last-child {
                    border-bottom: none;
                }
                .matrix_option_item {
                    display: flex;
                    justify-content: space-between;

                    h4 {
                        font-size: 1.2rem;
                        color: #707070;
                        margin: 0;
                    }
                }

                > p {
                    font-size: .9rem;
                    line-height: 1.1rem;
                    
                }
            }
        }
    }
}