#pages-footer {
    background-color: rgb(255, 255, 255) !important;
    color: #000000;
    padding-top: 2rem;

    .footer-about {
        img {
            width: 135px;
        }

        p {
            color: #000000;
            font-size: 16px;
            margin-top: 2rem;
        }
    }

    .footer-widget {
        h3 {
            font-size: 18px;
            font-weight: normal;
            font-family: $font-primary;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                color: #000000;
                line-height: 1.6rem;

                a {
                    color: #000000;
                    font-size: 14px;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .copyright {
        padding: 1rem 0;
        text-align: center;
        color: #000000;
    }
}

#home-footer {
    background-color: black;
    color: #FFF;
    padding-top: 2rem;

    .footer-about {
        img {
            width: 135px;
        }

        p {
            color: #77838f;
            font-size: 16px;
            margin-top: 2rem;
        }
    }

    .footer-widget {
        h3 {
            font-size: 18px;
            font-weight: normal;
            font-family: $font-primary;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                color: #77838f;
                line-height: 1.6rem;

                a {
                    color: #77838f;
                    font-size: 14px;

                    &:hover {
                        color: #FFF;
                    }
                }
            }
        }
    }

    .copyright {
        padding: 1rem 0;
        text-align: center;
        color: #77838f;
        font-size: 14px;
    }
}

.home-footer-social {
    margin-top: 2rem;

    .side_social {
        justify-content: center;

        a {

            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            transition: all 0.5s ease;
            color: #8c8c8c;

            span {
                width: 24px;
                height: 24px;
                background-color: #8c8c8c;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
            }

            svg {
                fill: #181818;
            }

            &:hover {
                color: #FFF;
                transform: translateY(-5px);
            }
        }
    }
}
.footer-social {
    margin-top: 2rem;

    .side_social {
        justify-content: center;

        a {

            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            transition: all 0.5s ease;
            color: #000000;

            span {
                width: 24px;
                height: 24px;
                background-color: #000000;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
            }

            svg {
                fill: #ffffff;
                // path{
                //     fill:#ffffff;
                // }
            }
            // &:nth-last-child(2){
            //     svg {
            //         path{
            //             fill:#ffffff;
            //         }
            //     }
            // }

            &:hover {
                color: #616161;
                transform: translateY(-5px);
            }
        }
    }
}

@media screen and (max-width : 767px) {
    footer {


        .footer-widget {
            h3 {
                font-size: 1.1rem;
                font-weight: 600;
                margin-bottom: 5px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    font-size: 14px !important;
                    text-align: justify;

                    a {
                        color: #77838f;
                        font-size: 14px !important;

                        &:hover {
                            color: #FFF;
                        }
                    }
                }
            }
        }

        .copyright {
            font-size: 12px;
            padding: 1rem 0;
            text-align: center;
            color: #77838f;
        }
    }

}