.page-numbers-wrap {
    padding: 2px;
    border-radius: 70px;
}

.pagination {

    .page-item {
        margin: 0 5px;
        padding: 2px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .page-link {
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            width: 35px;
            color: #000;
            border: none;

            span {
                font-size: 1.5rem;
                line-height: unset;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &.active {
            .page-link {
                border-radius: 8px;
                background: linear-gradient(270deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                color: #ffffff;
                border-color: #FFF2F2;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-numbers-wrap {

        padding: 1px;
        border-radius: 70px;
        margin-top: 13px;
    }

    .pagination-wrap {
        .d-flex {
            justify-content: center;
        }
    }

    .ms-md-2 {
        font-size: 13px !important;
        margin-top: 10px;
    }

    .pagination {
        .page-item {
            .page-link {
                font-size: 13px;
                height: 28px;
                width: 28px;

                span {
                    font-size: 1rem;
                    line-height: unset;
                }
            }
        }
    }
}