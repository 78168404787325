.earning_left {
    padding-right: 60px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 100vh;
}

.title-icon {
    display: flex;
    align-items: center;

    i {
        margin-right: .7rem;
    }

    span {
        font-weight: bold;
    }
}

.earning_box {
    display: block;
    @include bgPrimary(0.1);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
    color: $black;

    &:hover {
        color: $primary;
    }

    h4 {
        font-size: 13px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.5);

    }

    h3 {
        text-align: right;
        font-size: 20px;
        font-weight: 600;
        margin: 10px 0 0 0;
    }

    .today {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 13px;

        span {
            margin: 0 5px 0 0;
        }
    }

    .earning_box_options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;

        >div {

            >a,
            button {
                padding: 0;
                background-color: transparent;
                margin-right: 20px;
                font-size: 13px;
                color: $black;
                text-transform: uppercase;
            }
        }


    }


}

.earning_search_input {
    position: relative;

    .form-control {
        box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
        border-radius: 8px;
        height: 48px;
        background: rgb(255, 255, 255);
        padding: 0px 20px 0 60px;
        font-weight: 400;
        font-size: 14px;
        border: none;
    }

    i {
        position: absolute;
        top: 12px;
        left: 12px;
    }
}

.earning_filter_buttons {
    display: flex;
    justify-content: space-around;

    button {
        padding: 0;
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;

        span {
            line-height: unset;
        }
    }
}

.earning_filter_items {}

.filter_lowhigh {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
    color: rgb(31, 31, 31);

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 6px;
        cursor: pointer;

        span {
            margin-left: 10px;
            line-height: unset;
        }
    }
}

.filter_pills {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: inline-flex;
        margin: 5px;
        background: rgb(255, 255, 255);
        border-radius: 100px;
        padding: 0px 16px;
        height: 40px;
        box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        font-weight: 600;

        &.active {
            background-color: $primary;

            a,
            .button {
                color: #FFF;
            }
        }

        a,
        .button {
            color: $black;



        }

        .button {
            background-color: transparent;
            padding: 0;
        }
    }
}


.pills_round {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    li {
        margin: 5px;

        a,
        button {
            display: inline-flex;

            background: rgb(255, 255, 255);
            border-radius: 100px;
            padding: 0px 16px;
            height: 40px;
            box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;


            &.active,
            &:focus {
                background-color: $primary;
                color: #FFF;

            }
        }




    }
}

@media screen and (max-width: 767px) {
    .pills_round {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;

        li {
            margin: 5px;

            a,
            button {
                display: inline-flex;
                background: rgb(255, 255, 255);
                border-radius: 100px;
                padding: 0px 10px;
                height: 32px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-size: 11px;
                font-weight: 600;


                &.active,
                &:focus {
                    background-color: $primary;
                    color: #FFF;

                }
            }
        }
    }
}