.navbar-website {
    background: transparent;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;

    &.sticky {
        background: #000;
        height: 65px;
    }

    .navbar-toggler {
        .close {
            display: none;
        }

        .open {
            display: block;
        }

        &[aria-expanded="true"] {
            .close {
                display: block;
            }

            .open {
                display: none;
            }

        }

        &[aria-expanded="false"] {}
    }


    .navbar {
        padding: 0;
        padding-top: 4PX;
    }

    .navbar-brand {
        img {
            width: 155px;
            height: auto;
        }
    }

    .navbar-nav {

        .nav-item {
            .nav-link {

                padding: .6rem 1.6rem;
                color: #FFF;
                text-transform: uppercase;

                &:hover,
                &:focus {
                    color: $primary;
                }

                &.button {
                    margin-left: 1rem;
                    border: 1px solid $primary;
                    border-radius: 16px;
                }
            }
        }
    }
}

.mobile_dropdown_language {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 16px;
    text-transform: uppercase;

    .header_user_dropdown {
        .inner {
            padding: 20px;

            ul {
                li {
                    img {
                        width: 31px;
                        margin-right: 10px;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        &::before {}

    }

    button {
        background-color: transparent !important;
        border: 1px solid $primary !important;
        padding: 0.6rem 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 16px;



        svg {
            path {
                fill: white;
            }
        }

        span {
            margin-left: 10px;
            color: white !important;
        }

        &::after {
            display: none !important;
        }
    }
}

@media screen and (max-width: 767px) {

    .mobile_dropdown_language {
        margin-top: 10px;
        margin-left: 0px;

        button {
            padding: 0.4rem 1.6rem;
            color: #FFF;
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
            width: 100px;

            svg {
                path {
                    fill: white;
                }
            }

            span {
                margin-left: 10px;
                color: white !important;
            }

            &::after {
                display: none !important;
            }
        }

        .header_user_dropdown.lang-dd {
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate3d(-7px, 30.5px, 0px) !important;

            .dropdown-menu.show {}

            &::before {
                left: 42% !important;
            }
        }
    }

    .button_center {
        text-align: center;
        margin-top: 10px;



        .button {
            margin-left: 0px !important;
            margin: 0;

            .nav-item {
                &:first-child {
                    margin-top: 0px !important;

                }
            }
        }
    }

    .navbar-website {
        .navbar-brand {
            img {
                width: 120px;
                margin-bottom: 5px;
            }
        }

        #navbarSupportedContent {
            border-top: 1px solid $primary;
        }

        .navbar-nav {

            .nav-item {
                .nav-link {
                    padding: 0.4rem 1.6rem;
                    color: #FFF;
                    font-size: 14px;
                    text-align: center;
                    text-transform: uppercase;


                    &:hover,
                    &:focus {
                        color: $primary;
                    }

                    &.button {
                        margin-left: 0rem;
                        border: 1px solid $primary;
                        border-radius: 16px;
                    }
                }

            }
        }
    }

    .navbar-toggler {
        padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
        font-size: var(--bs-navbar-toggler-font-size);
        line-height: 1;
        color: var(--bs-navbar-color);
        background-color: transparent;
        border: none;
        border-radius: none;
        transition: var(--bs-navbar-toggler-transition);
    }

    .navbar-website {
        background: linear-gradient(90.03deg, #000000 100%, #000000 100%) !important;
    }
}