@mixin textStroke($borderColor) {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $borderColor;
    -webkit-text-fill-color: rgb(0 0 0/0%);
}

@mixin textStrokeNone($fillColor) {
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: translate;
    -webkit-text-fill-color: $fillColor;
}

@mixin flexcenter() {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
 }
 

@mixin textBlack($opacity) {
    color: rgba(0, 0, 0, $opacity);
}

@mixin gradient() {
    background: rgb(180, 164, 88);
    background: linear-gradient(270deg, rgba(180, 164, 88, 1) 0%, rgba(231, 232, 231, 1) 100%);
}

@mixin gradientReverse() {
    background: rgb(180, 164, 88);
    background: linear-gradient(270deg, $lightGray 0%, rgba(180, 164, 88, 1) 100%) !important;
}

@mixin gradientReverse2() {
    background: rgb(180, 164, 88);
    background: linear-gradient(90deg, $lightGray 0%, rgba(180, 164, 88, 1) 100%) !important;
}

@mixin gradientDark() {
    background: rgb(200, 158, 72);
    background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
}


@mixin bgPrimary($opacity) {
    background-color: rgba(191, 154, 65, $opacity);
}


@mixin bgSecondary($opacity) {
    background-color: rgba(255, 109, 76, $opacity);
}


@mixin bgGreen($opacity) {
    background-color: rgba(0, 181, 189, $opacity);
}


@mixin bgWarning($opacity) {
    background-color: rgba(255, 205, 78, $opacity);
}


@mixin GradientCyan() {
    background: rgb(0, 186, 181);
    background: linear-gradient(90deg, rgba(0, 186, 181, 1) 0%, rgba(0, 245, 241, 1) 100%);
}

@mixin GradientGolden() {
    background: rgb(207, 171, 79);
    background: linear-gradient(90deg, rgba(207, 171, 79, 1) 0%, rgba(250, 208, 127, 1) 50%, rgba(221, 184, 89, 1) 100%);
}


@mixin GradientText() {
    background: rgb(191, 154, 65);
    background: linear-gradient(180deg, #D9BE71 0%, #FBF6BA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin ScrollContent() {
    overflow-x: scroll;
    overflow-x: hidden;

    &:hover {   
        &::-webkit-scrollbar-thumb {
            display: block;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
    }

    &::-webkit-scrollbar {
        width: 4px;

    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 50px;
        display: none;
    }
}