.Toastify__toast-container {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    min-height: 200px;
    width: 90vw !important;

}

.Toastify__toast {
    position: relative;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.10);
    display: flex;
    font-family: $font-primary !important;
    border-radius: 20px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &.Toastify__toast-theme--light {
        background: #000000;

    }

    .Toastify__toast-body {
        flex-direction: column;
        align-items: center !important;
        text-align: center;
        gap: 15px;
        height: 100%;

        >div {
            margin: 10px 0;
            color: #ffffff;
        }

        .Toastify__toast-icon {
            display: none;
        }

    }

    .Toastify__close-button {
        display: none;
    }

    .Toastify__progress-bar {
        bottom: 10px !important;
        left: 15px !important;
        width: 90% !important;
        height: 7px !important;
        opacity: 1 !important;
        transform-origin: left !important;
        box-shadow: none !important;
        border-radius: 10px !important;

        // animation-play-state: paused !important;

        &.Toastify__progress-bar--success {
            background: $secondary !important;
        }

        &.Toastify__progress-bar--error {
            background: #FF002B !important;

            &::before {
                content: '';
                background-color: #fff;
                height: 10px;
                width: 90%;
                border-radius: 10px !important;
            }
        }


    }


}

@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.Toastify__toast--success {
    background-image: url("../images/dashboard/tosat/success-bg.png") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    &::before {
        content: url("../images/dashboard/tosat/successToast.svg");
        position: relative;
        z-index: 100000;
        top: 10px;
        animation: zoomInOut 2s infinite;
    }

    &::after {
        content: url('../images/dashboard/tosat/closeToast.svg');
        position: absolute;
        right: 15px;
        top: 15px;
    }
}

.Toastify__toast--error {
    background-image: url("../images/dashboard/tosat/error-bg.png") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: #fff !important;



    &::before {
        content: url("../images/dashboard/tosat/errorToast.svg");
        position: relative;
        z-index: 100000;
        top: 10px;
        animation: zoomInOut 2s infinite;
    }


    &::after {
        content: url('../images/dashboard/tosat/closeToast.svg');
        position: absolute;
        right: 15px;
        top: 15px;

    }

}

[data-bs-theme="dark"] {
    .Toastify__toast {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #000000;
        box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16prgba(255, 255, 255, 0.02);

        .Toastify__toast-body {
            >div {
                color: #ffffff;
            }
        }

    }

    .Toastify__toast--success {

        &::before {
            content: url("../images/dashboard/tosat/successToastDark.svg");
        }
    }

    .Toastify__toast--error {

        &::before {
            content: url("../images/dashboard/tosat/errorToastDark.svg");
        }
    }
}