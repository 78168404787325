.dashboard_footer {
    margin: 1rem 0 2rem 0;
}

.side_social {
    display: flex;
    align-items: center;

    a {
        margin-right: 10px;
    }

    svg {
        width: 20px;
        fill: $primary;
    }
}

.dashboard_copyright {
    text-align: right;
    font-size: 12px;
    color: black;
    font-weight: 600;
}

@media screen and (max-width: 767px) {
    .dashboard_footer {
        margin: 1rem 0 1rem 0;
    }

    .dashboard_footer_social {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .dashboard_copyright {
        text-align: center;
        margin-top: 10px;
    }
}